import { FieldValues } from 'react-hook-form';

import { translate } from '@/i18n';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { Scope } from '@/shared/components/ui/TopSection/TopSection.types.ts';

export function renderScopeItem(item: Scope, methodsList: FieldValues) {
  const assetsCategories = useGlobalStore(state => state.assetsCategories);
  const {
    modifiedAssets,
    modifiedAvailability,
    modifiedFirms,
    modifiedGeofences,
    modifiedRoles,
    modifiedTagsAttached,
    modifiedZones,
  } = methodsList;

  const normalizeCategoryData = assetsCategories
    .filter(category => category.categoryTreeName.includes(AssetTypeEnum.Equipment))
    .flatMap(category => category.categoryPathList)
    .map(category => ({
      id: category.categoryPathResName,
      name: category.categoryPath.split('/').pop() ?? '',
    }));

  const typesFromCategory = assetsCategories
    .filter(category => category.categoryTreeName.includes(AssetTypeEnum.Equipment))
    .map(category => ({
      id: category.categoryTreeResName,
      name: category.categoryTreeName.split('/').pop() ?? '',
    }));

  const renderZoneTitle = () => {
    if (item === 'startZone') return 'Start zone';
    if (item === 'endZone') return 'End zone';
    return 'Zone';
  };

  switch (item) {
    case 'action':
      return {
        data: [],
        label: 'Action',
      };

    case 'actor':
      return {
        data: [],
        label: 'Actor',
      };

    case 'asset':
      return {
        data: modifiedAssets(),
        label: 'Asset',
      };

    case 'assigned':
      return {
        data: [],
        label: 'Assigned',
      };

    case 'attached':
      return {
        data: modifiedTagsAttached(),
        label: 'Tag attached',
      };

    case 'availability':
      return {
        data: modifiedAvailability(),
        label: 'Availability',
      };

    case 'battery':
      return {
        data: [],
        label: 'Battery',
      };

    case 'currentZone':
      return {
        data: modifiedZones() ?? [],
        label: 'currentZone',
      };

    case 'endZone':
    case 'startZone':
    case 'zone':
      return {
        data: modifiedGeofences(),
        label: renderZoneTitle(),
      };

    case 'equipment-status':
      return {
        data: [
          {
            id: 'in-use',
            name: translate('reservation.inUse'),
          },
          {
            id: 'idle',
            name: translate('reservation.idle'),
          },
        ],
        label: 'Status',
      };

    case 'event':
      return {
        data: [],
        label: 'Event',
      };

    case 'firm':
      return {
        data: modifiedFirms(),
        label: 'Firm',
      };

    case 'group':
      return {
        data: [],
        label: 'Group',
      };

    case 'importance':
      return {
        data: [],
        label: 'Importance',
      };

    case 'organization':
      return {
        data: [],
        label: 'Organization',
      };

    case 'role':
      return {
        data: modifiedRoles(),
        label: 'Role',
      };

    case 'rssi':
      return {
        data: [],
        label: 'Rssi',
      };

    case 'status':
      return {
        data: [
          {
            id: 'active',
            name: translate('assets.active'),
          },
          {
            id: 'inactive',
            name: translate('assets.inactive'),
          },
        ],
        label: 'Status',
      };

    case 'subtype':
      return {
        data: normalizeCategoryData,
        label: 'Subtype',
      };
    case 'time':
      return {
        data: [],
        label: 'Time',
      };
    case 'type':
      return {
        data: typesFromCategory,
        label: 'Type',
      };

    default:
      return {
        data: [],
        label: '',
      };
  }
}
