import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { TimelineStatus, UtilizationBody } from '@/modules/Reports/types/types.ts';

type UtilizationTimelineProps = {
  row: UtilizationBody;
  isShowInactive?: boolean;
};

export const UtilizationTimeline: FC<UtilizationTimelineProps> = ({ isShowInactive, row }) => (
  <div className="grid w-full grid-cols-[repeat(32,minmax(33px,auto))] gap-x-1">
    {row.timelines.map(timeline => {
      return (
        <button
          className={classnames(
            'h-[32px] w-full cursor-pointer rounded-md border-[1px] border-slate-200 bg-transparent transition-colors duration-300 hover:opacity-50 focus:border-slate-500',
            {
              'border-blue-500 bg-blue-500': timeline.value === TimelineStatus.ACTIVE,
              'border-slate-300 bg-slate-300': timeline.value == TimelineStatus.INACTIVE,
              'border-slate-500': timeline && isShowInactive,
            },
          )}
          key={timeline.id}
        ></button>
      );
    })}
  </div>
);
