import { FC } from 'react';

import { translate } from '@/i18n';
import { Toggle } from '@/shared/components/form/Toggle';
import { Typography } from '@/shared/components/ui/Typography';

type Props = {
  checked: boolean;
  onChange: () => void;
  label?: string;
  disabled?: boolean;
};

export const ShowStatusToggle: FC<Props> = ({ checked, disabled = false, label, onChange }) => {
  return (
    <div className="hidden w-full items-center gap-x-2 md:ml-auto md:inline-flex md:w-auto">
      <Toggle checked={checked} disabled={disabled} onChange={onChange} />

      <Typography className="text-sm font-medium">
        {label ?? translate('global.showEditInactives')}
      </Typography>
    </div>
  );
};
