import { useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY, URL_PARAMS } from '@/shared/utils/constants.ts';

export const useGetAssetAttachedTags = () => {
  const [searchParameters] = useSearchParams();
  const assetId = searchParameters.get(URL_PARAMS.EQUIPMENT_ID);

  const site = useGlobalStore(store => store.site);

  const { user } = useFetchUserData();

  return useQuery({
    enabled: !!user?.accountResourceName && !!site && !!assetId,

    queryFn: async () => {
      return await AssetsAPINamespace.getAssetAttachedTags(
        user?.accountResourceName ?? '',
        site,
        assetId ?? '',
      );
    },

    queryKey: [QUERY_KEY.EQUIPMENT_TAGS, user?.accountResourceName, site, assetId],
    retry: false,
    retryOnMount: false,
  });
};
