import { FC } from 'react';

import { translate } from '@/i18n';
import { Typography } from '@/shared/components/ui/Typography';

export const NoTableData: FC = () => {
  return (
    <tr>
      <td className="flex flex-col items-center justify-center px-5 py-20 text-center">
        <svg
          className="size-6 shrink-0 text-gray-500 dark:text-neutral-500"
          fill="none"
          height="24"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="8" />
          <path d="m21 21-4.3-4.3" />
        </svg>

        <div className="mx-auto max-w-sm">
          <Typography className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
            {translate('global.noTableData')}
          </Typography>
        </div>
      </td>
    </tr>
  );
};
