import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { ShowStatusToggle } from '@/shared/components/form/ShowInactiveToggle';
import { classnames } from '@/shared/utils/classnames.ts';

import Location from '@/assets/icons/location-sm.svg?react';
import Refresh from '@/assets/icons/refresh-sm.svg?react';
import UploadSm from '@/assets/icons/upload-sm.svg?react';
import { AssetControlProps } from '@/shared/components/ui/AssetControlPanel/AssetControlPanel.types.ts';

export const AssetControlPanel: FC<AssetControlProps> = ({
  checked = false,
  className,
  disabled = false,
  onChange = () => {},
  onCSVDownload = () => {},
  onRefresh,
  onShowOnMap = () => {},
  onUpload = () => {},
  scopes,
}) => {
  const navigate = useNavigate();
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <div
      className={classnames(
        'inline-flex flex-wrap items-center gap-3 md:mt-0',
        { 'w-full flex-wrap': isMobile },
        className,
      )}
    >
      {scopes.map(scope => {
        switch (scope) {
          case 'csv':
            return (
              <Button
                className="inline-flex h-[38px] shrink-0 items-center justify-center gap-x-2 border-blue-600"
                key={scope}
                onClick={onCSVDownload}
                variant="outline"
              >
                {translate('reports.downloadCSV')}
              </Button>
            );

          case 'location':
            return (
              <Button
                className="inline-flex h-[38px] shrink-0 items-center justify-center gap-x-2"
                key={scope}
                onClick={onShowOnMap}
                variant="outline"
              >
                {translate('global.showOnMap')} <Location />
              </Button>
            );

          case 'refresh':
            return (
              <Button
                className="inline-flex h-[38px] shrink-0 items-center justify-center gap-x-2"
                disabled={disabled}
                key={scope}
                onClick={onRefresh ?? (() => navigate(0))}
                variant="outline"
              >
                {translate('global.refresh')} <Refresh />
              </Button>
            );

          case 'toggle':
            return (
              <ShowStatusToggle
                checked={checked}
                disabled={disabled}
                key={scope}
                onChange={onChange}
              />
            );

          case 'upload':
            return (
              <Button
                className="inline-flex h-[38px] shrink-0 items-center justify-center gap-x-2 text-white"
                key={scope}
                onClick={onUpload}
                variant="solid"
              >
                <UploadSm />
                {translate('reports.upload')}
              </Button>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};
