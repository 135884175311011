import { FC, useMemo, useState } from 'react';

import { RadioGroup as RadioComponent } from '@headlessui/react';

import { translate } from '@/i18n';
import { classnames } from '@/shared/utils/classnames';

import { RadioGroupProps } from '@/shared/components/form/RadioGroup/RadioGroup.types';

export const RadioGroup: FC<RadioGroupProps> = ({
  disabled,
  initialSelected,
  items = [],
  label,
  onChange,
}) => {
  const [selected, setSelected] = useState(() => {
    const result = items.find(({ id }) => id === initialSelected);
    return result ?? items[0];
  });

  // Prevents unnecessary re-renders.
  const selectedItem = useMemo(() => {
    return items.find(({ id }) => id === selected.id);
  }, [items, selected]);

  const handleRadioChange = (value: (typeof items)[0]) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <RadioComponent onChange={handleRadioChange} value={selectedItem}>
      <RadioComponent.Label className="sr-only">{label}</RadioComponent.Label>

      <div
        className={classnames(
          'border-2-slate-200 flex w-full cursor-pointer items-center gap-x-1 rounded-xl border bg-gray-100 p-1 shadow-sm transition-colors duration-300 hover:bg-gray-200 focus:outline-none',
          {
            'pointer-events-none opacity-50': disabled,
          },
        )}
      >
        {items.map(item => (
          <RadioComponent.Option
            className={({ active, checked }) =>
              classnames(
                'checkboxAnimation relative flex w-full cursor-pointer rounded-lg transition-all duration-300 focus:outline-none ',
                {
                  'bg-blue-600 text-white': checked,
                  'ring-2 ring-white/60 ring-offset-2 ring-offset-blue-200': active,
                },
              )
            }
            disabled={disabled}
            key={item.id}
            value={item}
          >
            {({ checked }) => (
              <div className=" inline-flex w-full items-center justify-center rounded-lg bg-inherit px-3 py-1.5">
                <RadioComponent.Label
                  as="p"
                  className={classnames(
                    'w-full truncate text-center text-sm font-medium -tracking-[0.07px] first-letter:capitalize',
                    {
                      'text-slate-800': !checked,
                      'text-white': checked,
                    },
                  )}
                >
                  {translate('map.' + item.name)}
                </RadioComponent.Label>
              </div>
            )}
          </RadioComponent.Option>
        ))}
      </div>
    </RadioComponent>
  );
};
