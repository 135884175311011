import { useEffect, useState } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { BuildTimeAPINamespace } from '@/shared/api/build-time/build-time.requests.ts';
import { useUpdateCheckerFeatureFlag } from '@/shared/feature-flags/useUpdateCheckerFeatureFlag.ts';
import { LS_KEYS, QUERY_KEY } from '@/shared/utils/constants.ts';

const REFRESH_INTERVAL = 60000;

export namespace useBuildTimeHooks {
  export const useCheckUpdate = () => {
    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);

    const { updateCheckerFlag } = useUpdateCheckerFeatureFlag();

    const buildTime: UseQueryResult = useQuery({
      enabled: updateCheckerFlag.enabled,

      queryFn: async () => BuildTimeAPINamespace.setBuild(),

      queryKey: [QUERY_KEY.CHECK_UPDATE],
      refetchInterval: REFRESH_INTERVAL,
    });

    useEffect(() => {
      if (!buildTime.isFetching && buildTime.data) {
        const isDevelopmentMode = import.meta.env.MODE === 'development';
        const currentBuildTime = buildTime.data.toString();
        const savedBuildTime = localStorage.getItem(LS_KEYS.BUILD_TIME);
        const buildTimesMismatch = savedBuildTime !== currentBuildTime;

        localStorage.setItem(LS_KEYS.BUILD_TIME, currentBuildTime);

        if (isDevelopmentMode && buildTimesMismatch) {
          setShowPrompt(true);
        }
      }
    }, [buildTime.data, buildTime.isFetching]);

    const handleReload = () => {
      window.location.reload();
      setReloading(true);
    };

    return {
      handleReload,
      reloading,
      showPrompt,
    };
  };
}
