import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGlobalStore } from '@/shared/store/store.ts';

import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

export const useChangeLocation = () => {
  const { pathname } = useLocation();

  const closeActivePopups = useGlobalStore(state => state.closeActivePopups);
  const setCurrentGeofence = useGlobalStore(state => state.setCurrentGeofence);
  const setCurrentGeofenceId = useGlobalStore(state => state.setCurrentGeofenceId);

  useEffect(() => {
    closeActivePopups();
    setCurrentGeofenceId('');
    setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
  }, [closeActivePopups, pathname, setCurrentGeofence, setCurrentGeofenceId]);
};
