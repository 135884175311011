import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';

import { ClusterIconProps } from '@/shared/components/ui/ClusterIcon/ClusterIcon.types';

const getBorder = (workerPercentage: number) => {
  return {
    background: `conic-gradient(#6366F1 0deg ${workerPercentage}deg, #FB923C ${workerPercentage}deg) top right`,
  };
};

export const ClusterIcon: FC<ClusterIconProps> = ({ totalTrackers, workerPercentage }) => {
  return (
    <div
      className="anchorShowAnimation rounded-full p-2 ring-2 ring-white"
      style={getBorder(workerPercentage)}
    >
      <div className="grid min-h-8 min-w-8 place-items-center rounded-full bg-black text-center text-base">
        <Typography as="span" className="font-bold text-white">
          {totalTrackers}
        </Typography>
      </div>
    </div>
  );
};
