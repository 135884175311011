import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import useCustomParams from '@/shared/hooks/global/useCustomParams';
import * as schemas from '@/shared/schemas/validate-schemas';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

import { ExtraPropertyKeys } from '../../Subcontractor/types';

import { AssetsExtraProperties, AssetsUpdateRequest } from '@/shared/api/assets/assets.types';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const useEditEquipment = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const site = useGlobalStore(store => store.site);
  const tags = useGlobalStore(store => store.siteTagsList);
  const content = useGlobalStore(store => store.modalPayload);
  const closeModal = useGlobalStore(store => store.closeModal);

  const assetResourceName = useCustomParams()['equipment-id'];

  const { currentCategory, currentCategoryPath, filteredAssetsCategories, renderInitialData } =
    useAssetsCategories(content);

  const { user } = useFetchUserData();
  const params = useForm<FieldValues>({
    defaultValues: {
      ...(content.assetName && { title: content.assetName }),
      ...(content.assetStatus === 'active' && { active: true }),
      categoryType: content.categoryTreeResName ?? filteredAssetsCategories[0].categoryTreeResName,
      ...(currentCategory &&
        currentCategoryPath && {
          categorySubType:
            currentCategoryPath.categoryPathResName ??
            currentCategory?.categoryPathList[0].categoryPathResName,
        }),
      ...renderInitialData(),
    },
    mode: 'onChange',
    resolver: zodResolver(schemas.equipmentFormSchema),
  });

  const { mutate, ...states } = useMutation({
    mutationFn: async (data: FieldValues) => {
      const { active, categorySubType, categoryType, deleteTags = false, title, ...rest } = data;

      const renderExtraProperties = (): AssetsExtraProperties[] => {
        const result = Object.entries(rest).map(([key, value]) => {
          return {
            key,
            value,
          };
        });

        const filteredResult = result.filter(item => item.key !== 'tag');

        return deleteTags ? filteredResult : result;
      };

      const requestBody: AssetsUpdateRequest = {
        accountResName: user?.accountResourceName ?? '',
        assetName: title,
        assetResName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
        assetStatus: active ? 'active' : 'inactive',
        categoryTreeResName: categoryType,
        siteResName: site,
        ...(categorySubType && { categoryPathResName: categorySubType }),
        extraProperties: [
          ...renderExtraProperties(),
          { key: ExtraPropertyKeys.rentalCompany, value: rest.rentalCompany },
        ],
      };

      await AssetsAPINamespace.updateOne(requestBody);

      if (rest?.tag) {
        const currentTag = tags.find(tag => tag.tagResName === rest?.tag);

        await AssetsAPINamespace.updateAssetAttachedTags({
          accountResourceName: user?.accountResourceName ?? '',
          assetResourceName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
          siteResourceName: site,
          ...(deleteTags
            ? { tags: [] }
            : {
                tags: [
                  {
                    tagName: currentTag?.tagName ?? '-',
                    tagResName: currentTag?.tagResName ?? '-',
                  },
                ],
              }),
        });
      }

      return {
        assetName: requestBody.assetName + '',
        assetResName: requestBody.assetResName,
        orgResName: requestBody.orgResName + '',
      };
    },

    mutationKey: [QUERY_KEY.EQUIPMENT_UPDATE, user?.accountResourceName, site],

    onError: error => {
      console.error(error);
      notifyService.error(ERROR_MESSAGES.EQUIPMENTS_UPDATE_FAILED);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENT_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ASSETS_TAGS],
      });

      navigate(RoutesEnum.EQUIPMENT);
      closeModal();
      notifyService.success(ERROR_MESSAGES.EQUIPMENTS_UPDATE_SUCCESS);
    },
  });

  const { mutate: deleteMutate, ...deleteStates } = useMutation({
    mutationFn: async () => {
      const requestBody = {
        accountResName: user?.accountResourceName ?? '',
        assetResName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
        siteResName: site,
        ...(params.getValues('title') && {
          assetName: params.getValues('title'),
        }),
        assetStatus: 'deleted',
        ...(params.getValues('categoryType') && {
          categoryTreeResName: params.getValues('categoryType'),
        }),
        ...(params.getValues('categorySubType') && {
          categoryPathResName: params.getValues('categorySubType'),
        }),
      };
      await AssetsAPINamespace.updateOne(requestBody);
      return {
        assetName: requestBody.assetName + '',
        assetResName: requestBody.assetResName,
        orgResName: requestBody.orgResName + '',
      };
    },

    mutationKey: [QUERY_KEY.EQUIPMENT_DELETE, user?.accountResourceName, site],

    onError: error => {
      console.error(error);
      notifyService.error(ERROR_MESSAGES.EQUIPMENTS_DEACTIVATE_FAILED);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ASSETS_TAGS],
      });

      navigate(RoutesEnum.EQUIPMENT);
      closeModal();
      notifyService.success(ERROR_MESSAGES.EQUIPMENTS_DEACTIVATE_SUCCESS);
    },
  });

  return {
    content,
    deleteMutate,
    deleteStates,
    mutate,
    params,
    states,
  };
};
