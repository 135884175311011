import { useSearchParams } from 'react-router-dom';

import { MODE, URL_PARAMS } from '@/shared/utils/constants.ts';

export const useLocalSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentMode = searchParams.get(URL_PARAMS.MODE) as string;

  const isHistoricalMode = currentMode === MODE.HISTORICAL;
  const isRealtimeMode = currentMode === MODE.REALTIME;

  return {
    currentMode,
    isHistoricalMode,
    isRealtimeMode,
    searchParams,
    setSearchParams,
  };
};
