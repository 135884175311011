import { REQUESTS_OPTIONS } from '@/shared/utils/constants';

export const dotsPerMeasure = (incomingValue: number): number => {
  const limit = REQUESTS_OPTIONS.LIMIT_POINTS;
  const coefficient = 0.5;

  if (incomingValue <= limit) return 0;

  return Math.round(incomingValue / limit + coefficient);
};
