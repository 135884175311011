import { FC } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { systems } from '@/shared/components/ui/UserMenu/SystemSelect/SystemSelect.constants';
import { handleRenderCurrentSystem } from '@/shared/components/ui/UserMenu/UserMenu.utils';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';

import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import SelectCheck from '@/assets/icons/select-check.svg?react';
import { DropdownType, LanguageSelectProps } from '@/shared/components/ui/UserMenu/UserMenu.types';
import { SystemViewEnum } from '@/shared/types/settings/system.types.ts';

export const SystemSelect: FC<LanguageSelectProps> = ({ setMenuType }) => {
  const systemView = useGlobalStore(state => state.systemView);
  const setSystemView = useGlobalStore(state => state.setSystemView);

  const fullFilledSystemView = handleRenderCurrentSystem(systemView);

  const handleSetSystemView = (lng: { id: SystemViewEnum }) => {
    setSystemView(lng.id);
  };

  return (
    <div className="flex flex-col p-2">
      <Button
        aria-label="cancel-system-select"
        className={classnames(
          'inline-flex w-full items-center gap-3 rounded-md px-3 py-2 text-sm font-medium transition-none duration-300',
        )}
        onClick={() => setMenuType(DropdownType.DEFAULT)}
        variant="custom"
      >
        <ChevronRight className="rotate-180" />

        <Typography as="span">{translate('global.system')}</Typography>
      </Button>

      {systems.map(system => {
        const isChosen = fullFilledSystemView?.id === system.id;

        return (
          <SiteSelectItem
            aria-label={`system-${system.id}-option`}
            className={classnames({
              'pointer-events-none opacity-50': system.id === SystemViewEnum.IMPERIAL,
              'text-blue-600': isChosen,
            })}
            key={system.id}
            onClick={() => handleSetSystemView(system)}
          >
            {system.title}
            {isChosen && <SelectCheck />}
          </SiteSelectItem>
        );
      })}
    </div>
  );
};
