import { create } from 'zustand';

import { useGlobalStore } from '@/shared/store/store.ts';

import {
  SocketConnectionStatusProps,
  SocketMapDataProps,
  SocketMapDataStoreProps,
} from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { LocationHistory } from '@/shared/types/global/locations.types.ts';

export const useSocketMapDataStore = create<SocketMapDataStoreProps>((set, get) => ({
  connectionStatus: 'UNINSTANTIATED',
  historySocketMapData: [],
  initialTrackersMapData: [],

  isSocketMapDataLoading: true,
  setConnectionStatus: (status: SocketConnectionStatusProps) => {
    set({
      connectionStatus: status,
    });
  },

  setHistorySocketMapData: () => {
    set(state => ({
      historySocketMapData: state.socketMapData,
    }));
  },
  setInitialTrackersMapData: (data: LocationHistory[]) => {
    set({
      initialTrackersMapData: data,
    });
  },

  setSocketMapData: (newSocketData: SocketMapDataProps, isLoading = false) => {
    const { popups } = useGlobalStore.getState();

    const isPopupExists = popups.find(popup => popup.id === newSocketData.tagResName);

    if (!isPopupExists) {
      const filteredSocketMapData = get().socketMapData.filter(
        socketData => socketData.tagResName !== newSocketData.tagResName,
      );

      set({
        isSocketMapDataLoading: isLoading,
        socketMapData: [...filteredSocketMapData, newSocketData],
      });
    }
  },
  socketMapData: [],
}));
