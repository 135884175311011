import { queryOptions, useQuery } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export namespace useReservationHooks {
  export const getAll = () => {
    const site = useGlobalStore(store => store.site);

    const { user } = useFetchUserData();

    const reservationsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        return await ReservationsAPINamespace.getAllReservations({
          accountResName: user?.accountResourceName ?? '',
          siteResName: site,
        });
      },

      queryKey: [QUERY_KEY.RESERVATION, user?.accountResourceName, site],

      select: data => data?.results,
    });

    return useQuery(reservationsQueryOptions);
  };
}
