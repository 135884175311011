import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useUserSettings } from '@/shared/hooks/settings/useUserSettings.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';

export function useActions() {
  const userSettings = useGlobalStore(state => state.userSettings);

  const alertSettingsService = new SettingsService(
    userSettings,
    SettingsNameEnum.UserSettings,
    SettingsKeyEnum.AlertSettings,
  );

  const form = useForm();
  const { handleSaveSettings, isCreating, isUpdating } = useUserSettings(
    alertSettingsService,
    true,
  );
  const currentSettings = alertSettingsService.getUserSettingValue();

  const handleFormSubmit = async (data: FieldValues) => {
    try {
      await handleSaveSettings(data);
    } catch (error) {
      notifyService.error('Failed to save settings');
      console.error('[error]:', error);
    }
  };

  useEffect(() => {
    if (currentSettings) form.reset(currentSettings);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  form.watch();

  return { form, handleFormSubmit, isCreating, isUpdating };
}
