import { translate } from '@/i18n';
import { RESERVATIONS } from '@/shared/utils/constants.ts';

import { AssetBody } from '@/shared/types/assets/assets.types';

export const renderBadgeTitle = (content: string, isWorker: boolean, isManager: boolean) => {
  switch (content) {
    case RESERVATIONS.approvedMgr:
      return translate('reservation.approved');
    case RESERVATIONS.approvedSub:
      return isManager ? translate('reservation.requested') : translate('reservation.pending');
    case RESERVATIONS.conflictedMgr:
      return isManager ? translate('reservation.conflict') : translate('reservation.pending');
    case RESERVATIONS.conflictedSub:
      return isWorker ? translate('reservation.pending') : translate('reservation.conflict');
    case RESERVATIONS.draftSub:
      return translate('reservation.draft');
    case RESERVATIONS.rejectedMgr:
      return translate('reservation.rejected');
    case RESERVATIONS.rejectedSub:
      return translate('reservation.rejected');
    case RESERVATIONS.requestedSub:
      return isWorker ? translate('reservation.pending') : translate('reservation.requested');
    default:
      return 'New';
  }
};

export const renderBadgeColor = (content: string, isWorker: boolean, isManager: boolean) => {
  switch (content) {
    case RESERVATIONS.approvedMgr:
      return 'bg-green-600 fill-green-600';
    case RESERVATIONS.approvedSub:
      return isManager ? 'bg-blue-600 fill-blue-600' : 'bg-slate-800 fill-slate-800';
    case RESERVATIONS.conflictedMgr:
      return isManager ? 'bg-amber-600 fill-amber-600' : 'bg-slate-800 fill-slate-800';
    case RESERVATIONS.conflictedSub:
      return isWorker ? 'bg-slate-800 fill-slate-800' : 'bg-amber-600 fill-amber-600';
    case RESERVATIONS.draftSub:
      return 'bg-slate-600 fill-slate-600';
    case RESERVATIONS.rejectedMgr:
      return 'bg-red-600 fill-red-600';
    case RESERVATIONS.rejectedSub:
      return 'bg-red-600 fill-red-600';
    case RESERVATIONS.requestedSub:
      return isWorker ? 'bg-slate-800 fill-slate-800' : 'bg-blue-600 fill-blue-600';
    default:
      return 'bg-slate-800 fill-slate-800';
  }
};

export const hideInactiveAssets = (assets: AssetBody[], showInactive: boolean) => {
  return assets.filter(asset => {
    if (showInactive) {
      return true;
    }
    return asset.assetStatus === 'active';
  });
};
