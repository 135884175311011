import { HTMLAttributes } from 'react';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { Utilization } from '@/shared/types/reports/analytics.types.ts';

export interface NotFoundProps extends HTMLAttributes<HTMLDivElement> {
  secondary?: boolean;
}

export type UtilizationBody = AssetBody & {
  timelines: {
    id: string;
    value: TimelineStatus;
  }[];
};

export type UtilizationAccordsPropsBody = UtilizationBody & { utilization?: Utilization[] };

export enum TimelineStatus {
  ACTIVE = 'active',
  IDLE = 'idle',
  INACTIVE = 'inactive',
}
