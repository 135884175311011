import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

import { useLocationsHistoryHooks } from '@/shared/api/locations-history/locations-history.hooks.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { getTimeZone } from '@/shared/utils/system-time-zone.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { SitePresenceDatasource } from '@/shared/types/reports/reports.types.ts';

export function useSitePresence() {
  const [searchParameters] = useSearchParams();

  const firm = searchParameters.get(URL_PARAMS.FIRM);
  const group = searchParameters.get(URL_PARAMS.GROUP);
  const status = searchParameters.get(URL_PARAMS.STATUS);

  const { assets, assetsTags, companies, locationsHistory, searchFieldValue } = useGlobalStore(
    state => ({
      assets: state.assets,
      assetsTags: state.assetsTags,
      companies: state.companies,
      locationsHistory: state.locationsHistory,
      searchFieldValue: state.searchFieldValue,
    }),
  );

  const { setTagIds, sitePolicies, tagsId } = useGlobalStore(state => ({
    setTagIds: state.setTagIds,
    sitePolicies: state.sitePolicies,
    tagsId: state.tagsId,
  }));

  const defaultSitePolicy = sitePolicies[0];
  const defaultSitePolicyTimeZoneOffset =
    defaultSitePolicy?.operationalPeriod?.localTimezoneOffset || 0;

  const transformTimeToEpoch = () => {
    const now = new Date();
    const fifteenMinutesAgo = new Date(now.getTime() - 15 * 60 * 1000);

    const startDate = fifteenMinutesAgo.toISOString();
    const endDate = now.toISOString();

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);

    const timeZone = getTimeZone(defaultSitePolicyTimeZoneOffset);

    const zonedStartTime = fromZonedTime(startTime, timeZone);
    const zonedEndTime = fromZonedTime(endTime, timeZone);

    return {
      endTime: encodeURIComponent(JSON.stringify({ epochTime: String(zonedEndTime.getTime()) })),
      startTime: encodeURIComponent(
        JSON.stringify({ epochTime: String(zonedStartTime.getTime()) }),
      ),
    };
  };

  const { endTime, startTime } = transformTimeToEpoch();

  const { isLoading, refetch, user } = useLocationsHistoryHooks.getLocationHistory(
    startTime,
    endTime,
    false,
  );

  const renderAssetTagProperty = (asset: AssetBody) => {
    const assetTagProperty = asset.extraProperties?.find(property => property.key === 'tag');
    const tag = tagsId.find(tag => tag === assetTagProperty?.value);

    const locationHistoryData = locationsHistory.find(history => history.tagResName === tag);

    return { locationHistoryData };
  };

  const filterAssets = (asset: AssetBody) => {
    const assetCompanyProperty = asset.extraProperties?.find(
      property => property.key === 'company',
    );

    const company = companies.find(company => company.orgResName === assetCompanyProperty?.value);

    if (firm && status) {
      const isFirmMatch = company?.orgResName === firm;
      const isStatusMatch = status === asset.status;

      return isFirmMatch && isStatusMatch;
    }

    if (firm) return company?.orgResName === firm;

    if (status) return status === asset.status;

    return true;
  };

  const modifiedAssets: SitePresenceDatasource[] = assets
    .filter(asset => asset.assetStatus !== null)
    .map(asset => {
      const assetCompanyProperty = asset.extraProperties?.find(
        property => property.key === 'firm' || property.key === 'company',
      );
      const company = companies.find(company => company.orgResName === assetCompanyProperty?.value);

      const { locationHistoryData } = renderAssetTagProperty(asset);

      const locationTime = locationHistoryData?.location?.timeOfLocation?.epochTime;

      return {
        ...asset,
        firm: company?.orgName ?? '-',
        groups: '-',
        name: asset.assetName,
        status: locationHistoryData ? 'active' : 'inactive',
        updated_at: locationTime ? format(new Date(locationTime * 1000), 'HH:mm dd/MM/yyyy') : '-',
      };
    })
    .filter(asset => filterAssets(asset));

  const filteredModifiedAssets = modifiedAssets.filter(item =>
    item.name.toLowerCase().includes(searchFieldValue.toLowerCase()),
  );

  useEffect(() => {
    const tagIds = [];

    for (const asset of assets) {
      if (asset) {
        const currentAssetTagProperty = asset.extraProperties?.find(
          property => property.key === 'tag',
        );

        if (currentAssetTagProperty?.value) tagIds.push(currentAssetTagProperty.value as string);
      }
    }

    setTagIds(tagIds);
  }, [assets, assetsTags, setTagIds]);

  useEffect(() => {
    if (tagsId.length > 0 && user)
      (async function () {
        try {
          await refetch();
        } catch (error) {
          console.error(error);
        }
      })();
  }, [refetch, tagsId, user]);

  return { assets: filteredModifiedAssets, firm, group, isLoading, status };
}
