import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { Badge } from '@/shared/components/ui/Badge';
import { classnames } from '@/shared/utils/classnames.ts';

import { SitePresenceDatasource } from '@/shared/types/reports/reports.types.ts';

export const renderColumns = (): ColumnDef<SitePresenceDatasource>[] => [
  {
    accessorKey: 'name',
    cell: ({ row }) => row.original.name,
    header: () => translate('global.givenName'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'firm',
    cell: ({ row }) => row.original.firm,
    header: () => translate('global.firm'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'status',
    cell: ({ row }) => (
      <Badge
        className={classnames('capitalize', {
          'border-green-500 text-green-500': row.original.status === 'active',
        })}
        variant="outline"
      >
        {row.original.status}
      </Badge>
    ),
    header: () => translate('filters.status'),
    meta: {
      isFiltered: true,
    },
  },
];
