import React, { FC } from 'react';

import { Html } from '@react-three/drei';

import { I18nProvider, translate } from '@/i18n';
import { AnchorCard } from '@/modules/Home/components/map/AnchorCard';
import { ClusterTab } from '@/modules/Home/components/map/ClusterTab';
import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { ClusterIcon } from '@/shared/components/ui/ClusterIcon';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

type Props = {
  workerPercentage: number;
  totalNumber: number;
  trackersData: Partial<SocketMapDataProps>[];
  isOpened: boolean;
  togglePopup: () => void;
};

export const ClusterPopup: FC<Props> = ({
  isOpened,
  togglePopup,
  totalNumber,
  trackersData,
  workerPercentage,
}) => {
  const currentLanguage = useGlobalStore(state => state.currentLanguage);

  const workerTrackers = trackersData
    .filter(item => item?.categoryTreeName === AssetTypeEnum.Worker)
    .sort((a, b) => (a.assetName ?? '').localeCompare(b.assetName ?? ''));

  const equipmentTrackers = trackersData
    .filter(item => item?.categoryTreeName?.includes(AssetTypeEnum.Equipment))
    .sort((a, b) => (a.assetName ?? '').localeCompare(b.assetName ?? ''));

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement> | React.WheelEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
  };

  const renderTabs = () => {
    return (
      <div className="relative z-[9999] flex flex-col gap-2" onWheel={stopPropagation}>
        <div className="flex flex-col gap-0.5">
          <div className="flex justify-between gap-x-1 ">
            <Typography className="text-left font-semibold">
              {`${totalNumber}`} {translate('messages.countOfObjects')}
            </Typography>

            <IconButton className="absolute right-2 top-2" icon="close" onClick={togglePopup} />
          </div>

          <Tabs.Group>
            <Tabs.List
              className={classnames(
                'grid',
                workerPercentage > 0 && totalNumber > workerPercentage ? 'grid-cols-2' : null,
              )}
            >
              {totalNumber - workerPercentage !== 0 && (
                <ClusterTab count={totalNumber - workerPercentage} type="equipment" />
              )}

              {workerPercentage !== 0 && <ClusterTab count={workerPercentage} type="worker" />}
            </Tabs.List>

            <Tabs.TabPanels className="max-h-[300px] w-full overflow-y-auto py-2">
              {totalNumber - workerPercentage !== 0 && (
                <Tabs.TabPanel className="grid gap-y-1">
                  <>
                    {equipmentTrackers.map((tracker: Partial<SocketMapDataProps>) => {
                      return (
                        <AnchorCard
                          body={tracker}
                          className="w-full min-w-[240px]"
                          key={tracker?.tagResName}
                          togglePopup={togglePopup}
                        />
                      );
                    })}
                  </>
                </Tabs.TabPanel>
              )}

              {workerPercentage !== 0 && (
                <Tabs.TabPanel className="grid gap-y-1">
                  <>
                    {workerTrackers.map(tracker => {
                      return (
                        <AnchorCard
                          body={tracker}
                          className="w-full min-w-[240px]"
                          key={tracker.tagResName}
                          togglePopup={togglePopup}
                        />
                      );
                    })}
                  </>
                </Tabs.TabPanel>
              )}
            </Tabs.TabPanels>
          </Tabs.Group>
        </div>
      </div>
    );
  };

  return (
    <Html
      // Adjusts the position slightly forward to compensate for rotation
      position={isOpened ? [0, 0, 0.01] : [0, 0, 0]}
      // rotation and position below are used to prevent z-fighting when other objects are in front
      // Adds a slight rotation on the Y axis when opened
      rotation={isOpened ? [0, Math.PI / 180, 0] : [0, 0, 0]}
      zIndexRange={[isOpened ? 1e20 : 1e9, 0]}
    >
      <I18nProvider locale={currentLanguage}>
        {isOpened && (
          <div className="canvasPopupAnimation absolute left-1/2 top-0 z-[9999] flex -translate-x-1/2 flex-col gap-1 rounded-lg border border-slate-200 bg-white p-3 shadow-sm md:w-[387px] xl:left-0 xl:translate-x-0">
            {renderTabs()}
          </div>
        )}

        <div className="absolute -bottom-0 left-1/2 z-[800] -translate-x-1/2">
          <Button onClick={togglePopup} variant="custom">
            <ClusterIcon
              totalTrackers={totalNumber}
              workerPercentage={((workerPercentage * 100) / totalNumber) * 3.6}
            />
          </Button>
        </div>
      </I18nProvider>
    </Html>
  );
};
