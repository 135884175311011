import { FC } from 'react';

import { translate } from '@/i18n';
import { renderBadgeColor, renderBadgeTitle } from '@/modules/Subcontractors/utils/helpers.tsx';
import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Badge } from '@/shared/components/ui/Badge';
import { Card } from '@/shared/components/ui/Card';
import { Typography } from '@/shared/components/ui/Typography';
import { useAssetCategoryType } from '@/shared/hooks/assets/useAssetCategoryType.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { classnames } from '@/shared/utils/classnames.ts';
import { RESERVATIONS } from '@/shared/utils/constants';
import { formatTimestamp } from '@/shared/utils/date-fns.ts';

import CalendarIcon from '@/assets/icons/calendar-lg.svg?react';
import Equipment from '@/assets/icons/equipment-sm.svg?react';
import Organization from '@/assets/icons/organization.svg?react';
import { ReservationMobileCardProps } from '@/modules/Subcontractors/types/reservation-mobile-card.types.ts';
import { RolesEnum } from '@/shared/types/global/enums';

const FORMAT_STRING = 'dd/MM/yyyy HH:mm';

export const ReservationMobileCard: FC<ReservationMobileCardProps> = ({
  assetData,
  conflictData,
  fullData,
  handleApprove,
  handleDelete,
  handleReject,
  handleSubmit,
  organization,
  reservationPeriod,
  selectEvent,
  state,
}) => {
  const { categoryType } = useAssetCategoryType(assetData);
  const { renderItemVisibility } = useRole();
  const startDate = Number(reservationPeriod?.startTime?.epochTime);
  const endDate = Number(reservationPeriod?.endTime?.epochTime);
  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  const isWorker = renderItemVisibility([RolesEnum.WORKER]);

  return (
    <Card className="grid gap-y-3">
      <div className="space-y-1 text-sm font-medium text-slate-500">
        <Badge
          className={classnames(renderBadgeColor(state, isWorker, isManager))}
          rounded="md"
          variant="solid"
        >
          {renderBadgeTitle(state, isWorker, isManager)}
        </Badge>

        <div className="flex items-center gap-x-3">
          <Typography as="h3" className="text-sm font-medium">
            {assetData?.assetName}
          </Typography>

          <Badge
            className={classnames('text-[10px]')}
            color={assetData?.inUse ? 'green' : 'blue'}
            rounded="md"
            variant="outline"
          >
            {assetData?.inUse ? translate('reservation.inUse') : translate('reservation.idle')}
          </Badge>
        </div>

        <Typography
          className="flex items-center gap-x-1
        "
        >
          <Equipment className="size-5 shrink-0" />

          {categoryType.includes('/') ? categoryType.split('/').reverse()[0] : categoryType}
        </Typography>

        <Typography className="flex items-center gap-x-1">
          <CalendarIcon className="size-5 shrink-0" />

          <div className="flex flex-col gap-y-2">
            {formatTimestamp(startDate, FORMAT_STRING)} - {formatTimestamp(endDate, FORMAT_STRING)}
            {conflictData?.conflictingReservations?.map((item, index) => (
              <div key={`${item?.reservationResName}-${index}`}>
                {formatTimestamp(Number(item.reservationPeriod.startTime.epochTime), FORMAT_STRING)}
                - {formatTimestamp(Number(item.reservationPeriod.endTime.epochTime), FORMAT_STRING)}
              </div>
            ))}
          </div>
        </Typography>

        <Typography className="flex items-center gap-x-1">
          <Organization className="size-5 shrink-0" />

          {organization}
        </Typography>

        <div className="flex items-center gap-x-2">
          {(!isWorker && state == RESERVATIONS.conflictedSub) ||
          (isManager && state == RESERVATIONS.conflictedMgr) ||
          (!isWorker && state == RESERVATIONS.approvedMgr) ? null : (
            <IconButton
              className="mr-3 h-[38px] w-[38px] rounded-md border-[1px] border-slate-900"
              icon="edit"
              onClick={() => selectEvent(fullData)}
            />
          )}

          {state == RESERVATIONS.draftSub ? (
            <Button
              className="mr-3 inline-flex items-center justify-center gap-x-2"
              onClick={() => handleSubmit(fullData)}
              variant="solid"
            >
              {translate('global.submit')}
            </Button>
          ) : null}

          {(!isWorker && state == RESERVATIONS.requestedSub) ||
          (isManager && state == RESERVATIONS.approvedSub) ? (
            <Button
              className="mr-3 inline-flex items-center justify-center gap-x-2"
              onClick={() => handleApprove(fullData)}
              variant="solid"
            >
              {translate('reservation.approve')}
            </Button>
          ) : null}

          {(!isWorker && state == RESERVATIONS.requestedSub) ||
          (isManager && state == RESERVATIONS.approvedSub) ? (
            <Button
              className="mr-3 inline-flex items-center justify-center gap-x-2 text-red-500"
              onClick={() => handleReject(fullData)}
              variant="outline"
            >
              {translate('reservation.reject')}
            </Button>
          ) : null}

          {state == RESERVATIONS.draftSub ||
          isWorker ||
          (!isManager && state == RESERVATIONS.approvedSub) ? (
            <Button
              className="mr-3 inline-flex items-center justify-center gap-x-2 text-red-500"
              onClick={() => handleDelete(fullData)}
              variant="outline"
            >
              {translate('reservation.delete')}
            </Button>
          ) : null}

          {(!isWorker && state == RESERVATIONS.conflictedSub) ||
          (isManager && state == RESERVATIONS.conflictedMgr) ? (
            <Button
              className="mr-3 inline-flex items-center justify-center gap-x-2"
              onClick={() => selectEvent(fullData)}
              variant="solid"
            >
              {translate('reservation.review')}
            </Button>
          ) : null}
        </div>
      </div>
    </Card>
  );
};
