import { FC } from 'react';
import { useToggle } from 'react-use';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { classnames } from '@/shared/utils/classnames';

import ChevronIcon from '@/assets/icons/chevron.svg?react';
import { WithChildren } from '@/shared/types/global/common.types.ts';

export const Collapse: FC<WithChildren> = ({ children }) => {
  const [isCollapsed, toggleCollapse] = useToggle(true);

  const handleCollapse = () => {
    toggleCollapse();
  };

  return (
    <>
      {isCollapsed ? null : children}

      <Button
        className="ilets-center inline-flex justify-center gap-x-2 px-3 py-2 text-sm font-semibold text-blue-600"
        onClick={handleCollapse}
        variant="custom"
      >
        <ChevronIcon className={classnames('h-5 w-5', isCollapsed ? 'rotate-360' : 'rotate-180')} />
        {translate('siteHours.exceptions')}
      </Button>
    </>
  );
};
