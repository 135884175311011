import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Badge } from '@/shared/components/ui/Badge';
import { Card } from '@/shared/components/ui/Card';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import { MobileCardProps } from '@/shared/components/ui/MobileCard/MobileCard.types.ts';

export const MobileCard: FC<MobileCardProps> = ({
  action,
  details,
  link,
  status,
  statusColor,
  title,
}) => {
  const location = useLocation();

  return (
    <Card>
      <div className="space-y-3 text-sm font-medium text-slate-500">
        {status && (
          <Badge
            className={classnames('text-[10px] capitalize')}
            color={statusColor}
            rounded="md"
            variant="solid"
          >
            {status}
          </Badge>
        )}

        {title && (
          <div className="flex items-center gap-x-3">
            <Typography as="h3" className="text-sm font-medium">
              {title}
            </Typography>
          </div>
        )}

        <div className="space-y-2.5">
          {details.map((detail, index) => (
            <Typography
              className="grid grid-cols-2"
              key={index} //NOSONAR
            >
              <Typography as="span">{detail.label}</Typography>
              <Typography as="span" className="truncate text-wrap capitalize">
                {detail.value}
              </Typography>
            </Typography>
          ))}
        </div>

        <div
          className={classnames('grid grid-cols-2 gap-x-2', action ? 'grid-cols-2' : 'grid-cols-1')}
        >
          {action && (
            <Button disabled={action?.disabled} onClick={action?.onClick} variant="outline">
              {action?.label}
            </Button>
          )}

          <Link
            className={classnames(
              'inline-flex items-center justify-center rounded-lg bg-blue-600 px-3 py-[9px] text-sm font-semibold tracking-wider text-white shadow-sm duration-300 hover:bg-blue-700 active:opacity-80',
              link === '' && 'pointer-events-none opacity-50',
            )}
            state={{ from: location.pathname }}
            to={link}
          >
            {translate('map.viewDetails')}
          </Link>
        </div>
      </div>
    </Card>
  );
};
