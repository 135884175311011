import { FC, HTMLAttributes } from 'react';
import { useMedia } from 'react-use';

import { type Value } from '@number-flow/react';

import { translate } from '@/i18n';
import { NumberFlow } from '@/shared/components/animation/NumberFlow';
import { Card } from '@/shared/components/ui/Card/Card.tsx';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { iconsButtonMap } from '@/shared/components/buttons/IconButton/IconButton.types';

export interface StatisticCardProps extends HTMLAttributes<HTMLDivElement> {
  icon: keyof typeof iconsButtonMap;
  isLoading?: boolean;
  subtitle: string | Value;
  titleId: string;
}

export const StatisticCard: FC<StatisticCardProps> = ({
  children,
  className,
  icon,
  isLoading,
  subtitle,
  titleId,
  ...props
}) => {
  const isMobile = useMedia('(max-width: 767px)');

  const Icon = iconsButtonMap[icon];

  if (isLoading) return <Skeleton isMobile={isMobile} variant="statistic-card" />;

  return (
    <Card
      className={classnames(
        'flex items-center gap-x-4 p-5 md:h-[90px] md:w-[340px]',
        {
          'w-full': isMobile,
        },
        className,
      )}
      {...props}
    >
      <div className="flex size-10 flex-shrink-0 items-center justify-center rounded-lg bg-slate-100 text-slate-600 md:size-[46px]">
        <Icon />
      </div>

      <div className="flex flex-col gap-y-1">
        <Typography
          as="span"
          className="max-w-48 truncate text-xs font-medium uppercase text-slate-500"
        >
          {translate(titleId)}
        </Typography>

        {typeof subtitle === 'string' ? (
          <Typography
            as="span"
            className="max-w-48 truncate text-xl font-medium uppercase text-slate-950"
          >
            {translate(subtitle)}
          </Typography>
        ) : (
          <NumberFlow
            className="max-w-48 truncate text-xl font-medium text-slate-950"
            value={subtitle}
          />
        )}
      </div>
    </Card>
  );
};
