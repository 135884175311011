import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';

import { Input } from '@/shared/components/form/Input/Input';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { onlyNumbers } from '@/shared/utils/helpers';

import { CoordinateValueInputProps } from '@/shared/types/global/geofences.types.ts';

export const CoordinateValueInput = forwardRef(
  (
    { className, defaultValue, error, onChange }: CoordinateValueInputProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [valueX, setValueX] = useState<string>('');
    const [valueY, setValueY] = useState<string>('');

    useEffect(() => {
      if (defaultValue) {
        setValueX(String(defaultValue.x));
        setValueY(String(defaultValue.y));
      }
    }, [defaultValue]);

    useEffect(() => {
      if (valueX && valueY) {
        onChange({ x: String(valueX), y: String(valueY) });
      }
    }, [onChange, valueX, valueY]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      switch (name) {
        case 'x':
          setValueX(onlyNumbers(value));
          break;

        case 'y':
          setValueY(onlyNumbers(value));
          break;

        default:
          break;
      }
    };

    return (
      <div className={classnames('flex gap-x-1', className)} ref={ref}>
        <div className="relative">
          <Typography className="absolute left-4 translate-y-[9px] text-sm text-slate-500">
            X:
          </Typography>

          <Input
            className="w-full pl-8 text-sm"
            error={error}
            isErrorLabelHidden
            max={270}
            min={0}
            name="x"
            onChange={handleOnChange}
            type="number"
            value={String(valueX)}
          />
        </div>

        <div className="relative">
          <Typography className="absolute left-4 translate-y-[9px] text-sm text-slate-500">
            Y:
          </Typography>

          <Input
            className="w-full pl-8 text-sm"
            error={error}
            isErrorLabelHidden
            max={130}
            min={0}
            name="y"
            onChange={handleOnChange}
            type="number"
            value={String(valueY)}
          />
        </div>
      </div>
    );
  },
);
