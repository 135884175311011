import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import EmptyList from '@/assets/icons/list.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
};

export const NoData: FC<Props> = ({ className, subtitle, title }) => {
  return (
    <div
      className={classnames(
        'noDataAnimation flex h-full min-h-[calc(100vh-356px)] w-full flex-col items-center justify-center gap-1',
        className,
      )}
      role="alert"
    >
      <EmptyList className="mb-1 size-[48px]" />

      {title && (
        <Typography as="h2" className="text-center text-base font-semibold text-slate-800">
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography as="p" className="text-center text-sm font-medium text-slate-500">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};
