import { z } from 'zod';

import { BASE_SCHEMA } from '@/shared/schemas/base-schemas';
import { MESSAGES } from '@/shared/schemas/constants';

export const profileFormSchema = z.object({
  family_name: BASE_SCHEMA.TEXT.DEFAULT,
  given_name: BASE_SCHEMA.TEXT.DEFAULT,
});

export const mapSettingsFormSchema = z.object({
  selectedSiteMap: z.string().optional(),
});

export const equipmentFormSchema = z.object({
  active: BASE_SCHEMA.BOOLEAN.DEFAULT,
  categorySubType: BASE_SCHEMA.TEXT.OPTIONAL,
  categoryType: BASE_SCHEMA.TEXT.OPTIONAL,
  dropOff: BASE_SCHEMA.TEXT.OPTIONAL,
  iso4217: BASE_SCHEMA.TEXT.OPTIONAL,
  period: BASE_SCHEMA.TEXT.OPTIONAL,
  price: BASE_SCHEMA.TEXT.PRICE,
  rentalCompany: BASE_SCHEMA.TEXT.OPTIONAL,
  tag: BASE_SCHEMA.TEXT.OPTIONAL,
  title: BASE_SCHEMA.TEXT.SECONDARY,
});

export const changeEmailFormSchema = z.object({
  email: BASE_SCHEMA.EMAIL.DEFAULT,
});

export const changePhoneNumberFormSchema = z.object({
  phoneNumber: BASE_SCHEMA.PHONE_NUMBER.DEFAULT,
});

export const verifyEmailFormSchema = z.object({
  confirmationCode: BASE_SCHEMA.OTP.DEFAULT,
  email: BASE_SCHEMA.EMAIL.DEFAULT,
});

export const changePasswordFormSchema = z
  .object({
    newPassword: BASE_SCHEMA.PASSWORD.REGEX,
    newPasswordConfirm: BASE_SCHEMA.PASSWORD.DEFAULT,
    oldPassword: BASE_SCHEMA.PASSWORD.REGEX,
  })
  .refine(data => data.newPassword === data.newPasswordConfirm, {
    message: MESSAGES.PASSWORD.NOT_MATCH,
    path: ['newPasswordConfirm'],
  })
  .refine(data => data.oldPassword !== data.newPassword, {
    message: MESSAGES.PASSWORD.OLD_NEW_MATCH,
    path: ['newPassword'],
  });

export const loginFormSchema = z.object({
  accountName: BASE_SCHEMA.LOGIN.ACCOUNT_NAME,
  password: BASE_SCHEMA.LOGIN.PASSWORD,
  username: BASE_SCHEMA.EMAIL.DEFAULT,
});

export const restorePasswordFormSchema = z.object({
  accountName: BASE_SCHEMA.LOGIN.NAME,
  username: BASE_SCHEMA.LOGIN.NAME,
});

export const resetPasswordFormSchema = z
  .object({
    confirmationCode: BASE_SCHEMA.OTP.DEFAULT,
    confirmedNewPassword: BASE_SCHEMA.PASSWORD.DEFAULT,
    newPassword: BASE_SCHEMA.PASSWORD.REGEX,
  })
  .refine(data => data.newPassword === data.confirmedNewPassword, {
    message: MESSAGES.PASSWORD.NOT_MATCH,
    path: ['confirmedNewPassword'],
  });

export const geofenceFormSchema = z
  .object({
    endDate: BASE_SCHEMA.DATE.DEFAULT,
    floorResName: BASE_SCHEMA.FLOOR.DEFAULT,
    geofenceCategory: BASE_SCHEMA.GEOFENCE.CATEGORY,
    geofenceName: BASE_SCHEMA.GEOFENCE.NAME,
    points: BASE_SCHEMA.POINTS.SECONDARY,
    startDate: BASE_SCHEMA.DATE.DEFAULT,
  })
  .refine(
    data => {
      const startDate = data.startDate as Date;
      const endDate = data.endDate as Date;

      return startDate < endDate;
    },
    {
      message: MESSAGES.GEOFENCE.END_DATE,
      path: ['endDate'],
    },
  )
  .refine(
    data => {
      const points = data.points as Array<{ x: string; y: string }>;
      const firstPoint = points[0];

      if (points.length <= 0) return false;

      for (const point of points) {
        if (+firstPoint.x > +point.x || +firstPoint.y > +point.y) {
          return false;
        }
      }

      return true;
    },
    {
      message: MESSAGES.POINTS.VALIDATION,
      path: ['points'],
    },
  );

const subcontractorSchema = z.object({
  email: BASE_SCHEMA.EMAIL.DEFAULT.optional().or(z.literal('')),
  familyName: BASE_SCHEMA.TEXT.COMMON,
  firm: z.string(),
  givenName: BASE_SCHEMA.TEXT.COMMON,
  groups: z.string().optional(),
  mode: z.string().optional(),
  phone: BASE_SCHEMA.PHONE_NUMBER.DEFAULT.optional().or(z.literal('')),
  role: z.string(),
  tags: z.array(z.string()).optional(),
  zpsAccess: z.boolean().optional(),
});

export const inviteFirmSchema = z.object({
  color: z.string().optional(),
  firmEmail: BASE_SCHEMA.EMAIL.DEFAULT,
  firmFamilyName: BASE_SCHEMA.TEXT.COMMON,
  firmGivenName: BASE_SCHEMA.TEXT.COMMON,
  firmPhone: BASE_SCHEMA.PHONE_NUMBER.DEFAULT,
  newFirm: BASE_SCHEMA.TEXT.COMMON,
  status: z.string().optional(),
});

export const refinedSubcontractorSchema = subcontractorSchema.refine(
  data => {
    const validEmail = data.email !== undefined && data.email !== '';
    const validPhone = data.phone !== undefined && data.phone !== '';
    if (data.zpsAccess) {
      return validEmail && validPhone;
    }
    return true;
  },
  {
    message: MESSAGES.EMAIL_PHONE_REQUIRED,
    path: ['phone'],
  },
);

export const editFirmSchema = z.object({
  color: z.string().optional(),
  email: z.string().email().optional(),
  familyName: z.string().optional(),
  firm: z.string().optional(),
  givenName: z.string().optional(),
  phone: z.string().optional(),
  status: z.string().optional(),
});

export const reservationFormSchema = z
  .object({
    asset: z.string(),
    categoryType: z.string(),
    firm: z.string(),
    fromDate: z.date(),
    toDate: z.date(),
    zone: z.string().optional(),
  })
  .refine(
    data => {
      const start = new Date(data.fromDate);
      const end = new Date(data.toDate);
      return start.getTime() < end.getTime();
    },
    {
      message: 'End date must be after start date',
      path: ['toDate'],
    },
  )
  .refine(
    data => {
      const start = new Date(data.fromDate);
      const end = new Date(data.toDate);

      const startTime = start.getHours() * 60 + start.getMinutes();
      const endTime = end.getHours() * 60 + end.getMinutes();

      const businessStart = 9 * 60; // 9:00 AM in minutes
      const businessEnd = 17 * 60; // 5:00 PM in minutes

      return (
        startTime >= businessStart &&
        startTime <= businessEnd &&
        endTime >= businessStart &&
        endTime <= businessEnd
      );
    },
    {
      message: 'Reservations must be between 9:00 AM and 5:00 PM',
      path: ['fromDate'],
    },
  );
