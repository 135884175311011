import { useEffect } from 'react';

import { useGlobalStore } from '@/shared/store/store.ts';

export const useAnchorPopupState = (isOpened: boolean, togglePopup: (value: boolean) => void) => {
  const isCanvasPopupsOpen = useGlobalStore(state => state.isCanvasPopupsOpen);
  const setIsCanvasPopupsOpen = useGlobalStore(state => state.setIsCanvasPopupsOpen);

  useEffect(() => {
    if (isOpened) setIsCanvasPopupsOpen(true);
  }, [isOpened, setIsCanvasPopupsOpen]);

  useEffect(() => {
    if (!isCanvasPopupsOpen) togglePopup(false);
  }, [isCanvasPopupsOpen, togglePopup]);
};
