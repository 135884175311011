import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { classnames } from '@/shared/utils/classnames';

import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  src: null | string;
  size: 'lg' | 'md' | 'sm';
};

export const Avatar: FC<Props> = ({ className, size, src }) => {
  const { user } = useFetchUserData();

  const classes = {
    conditional: {
      'h-11 w-11': size === 'md',
      'h-20 w-20': size === 'lg',
      'h-9 w-9': size === 'sm',
    },
    regular: 'inline-flex rounded-lg overflow-hidden',
  };

  const renderUserName = () => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`;
    }

    const username = user?.username ?? 'U';

    return username[0].toUpperCase();
  };

  if (!src) {
    return (
      <div className={classnames(classes.regular, classes.conditional, className)}>
        <div className="flex h-full w-full items-center justify-center bg-slate-500 text-white">
          <Typography className={'text-xl font-semibold'}>{renderUserName()}</Typography>
        </div>
      </div>
    );
  }

  return (
    <img
      alt="User avatar"
      className={classnames(classes.regular, classes.conditional, className)}
      src={src}
    />
  );
};
