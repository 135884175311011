import { dateToEncoded, onEpochTime } from '@/shared/utils/date-to-encoded.ts';

export function initializePickerTime(dateParameter: null | string) {
  const dateStartParameter = dateParameter?.split(',')[0] ?? '';
  const dateEndParameter = dateParameter?.split(',')[1] ?? '';

  const encodedResponse = dateToEncoded('');
  const encodedEndTimeResponse = dateToEncoded('');

  const initialStartTime = dateStartParameter
    ? onEpochTime(Number(dateStartParameter) * 1000)
    : encodedResponse;
  const initialEndTime = dateEndParameter
    ? onEpochTime(Number(dateEndParameter) * 1000)
    : encodedEndTimeResponse;

  return { initialEndTime, initialStartTime };
}
