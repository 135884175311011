import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

import { AssetsUpdateRequest } from '@/shared/api/assets/assets.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useToggleAssetsStatus = () => {
  const queryClient = useQueryClient();

  const site = useGlobalStore(store => store.site);

  const { user } = useFetchUserData();

  const { isPending, mutate } = useMutation({
    mutationFn: async (asset: AssetBody) => {
      const requestBody: AssetsUpdateRequest = {
        accountResName: user?.accountResourceName ?? '',
        assetName: asset?.assetName,
        assetResName: asset?.assetResName,
        assetStatus: asset.assetStatus === 'active' ? 'inactive' : 'active',
        categoryPathResName: asset?.categoryPathResName,
        categoryTreeResName: asset?.categoryTreeResName,
        siteResName: site,
      };

      await notifyService.promise(AssetsAPINamespace.updateOne(requestBody), {
        error: ERROR_MESSAGES.ASSET_STATUS_FAILED,
        loading: ERROR_MESSAGES.ASSET_STATUS_LOADING,
        success: ERROR_MESSAGES.ASSET_STATUS_SUCCESS,
      });
    },

    mutationKey: [QUERY_KEY.TOGGLE_ASSET_STATUS],

    onError: error => {
      notifyService.error(ERROR_MESSAGES.ASSET_STATUS_FAILED);
      console.error(error);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.WORKERS],
      });
    },
  });

  return {
    isPending,
    mutate,
  };
};
