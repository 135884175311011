import { FC } from 'react';

import { useIntercomInitialize } from '@/layout/Layout/hooks';

import QuestionMark from '@/assets/icons/intercom-question.svg?react';

export const Intercom: FC = () => {
  const { handleBootIntercom, intercomImplementation } = useIntercomInitialize();

  return (
    <>
      {intercomImplementation?.enabled && (
        <QuestionMark className="cursor-pointer" onClick={handleBootIntercom} />
      )}
    </>
  );
};
