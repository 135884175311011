import { FC } from 'react';

import { Menu } from '@headlessui/react';

import { translate } from '@/i18n';
import { LicenseAndReleasesLinks } from '@/shared/components/navigation/LicenseAndReleasesLinks';
import { LanguageMenuItem } from '@/shared/components/ui/UserMenu/LanguageMenuItem';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { SettingsMenuItem } from '@/shared/components/ui/UserMenu/SettingsMenuItem';
import { SingOutMenuItem } from '@/shared/components/ui/UserMenu/SingOutMenuItem/SingOutMenuItem.tsx';
import { SiteSelect } from '@/shared/components/ui/UserMenu/SiteSelect';

import ChevronRight from '@/assets/icons/chevron-right.svg?react';

type Props = {
  systemView: string;
  setLanguageMenu: () => void;
  setSystemMenu: () => void;
  onSignOut: () => void;
  onClearStateData: () => void;
};

const IS_VISIBLE = false; // Set to false on request. This is a temporary variable looks like as feature flag.

export const MainSelect: FC<Props> = ({
  onClearStateData,
  onSignOut,
  setLanguageMenu,
  setSystemMenu,
  systemView,
}) => {
  return (
    <div aria-details="main-settings" className="flex flex-col gap-y-2 py-2">
      <SiteSelect />

      <div className="m-auto h-[1px] w-[90%] bg-slate-200" />

      <div className="flex flex-col px-2">
        <LanguageMenuItem className="px-3 py-2 " onClick={setLanguageMenu} />

        {IS_VISIBLE && (
          <SiteSelectItem
            aria-label="system-menu-action"
            className="inline-flex w-full items-center justify-between rounded-md px-3 py-2 text-sm font-medium"
            onClick={setSystemMenu}
          >
            {translate('global.system')} ({systemView})
            <ChevronRight />
          </SiteSelectItem>
        )}

        <Menu.Item>
          <SettingsMenuItem
            className="inline-flex w-full items-center rounded-md px-3 py-2 text-sm font-medium transition-colors duration-300 hover:bg-slate-100 focus:bg-slate-100"
            onClick={onClearStateData}
          />
        </Menu.Item>

        <Menu.Item>
          <SingOutMenuItem
            className="inline-flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-red-600"
            onClick={onSignOut}
          />
        </Menu.Item>
      </div>

      <div className="m-auto h-[1px] w-[90%] bg-slate-200" />

      <LicenseAndReleasesLinks className="px-4 py-2 text-sm font-medium hover:[&_a]:text-blue-500 focus:[&_a]:text-blue-500" />
    </div>
  );
};
