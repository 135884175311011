import { FC } from 'react';

import { ChangeAvatarForm } from '@/modules/Settings/components/common/ChangeAvatarForm';
import { ChangeEmailForm } from '@/modules/Settings/components/common/ChangeEmailForm';
import { ChangePasswordForm } from '@/modules/Settings/components/common/ChangePasswordForm';
import { ChangePhoneForm } from '@/modules/Settings/components/common/ChangePhoneForm';
import { ProfileForm } from '@/modules/Settings/components/common/ProfileForm';
import { UserInfo } from '@/modules/Settings/components/common/UserInfo';
import { Fade } from '@/shared/components/animation/Fade';
import { useAvatarFormFeatureFlag } from '@/shared/feature-flags/useAvatarFormFeatureFlag';
import { useIsEditableFormFeatureFlag } from '@/shared/feature-flags/useIsEditableFormFeatureFlag.ts';

export const Profile: FC = () => {
  const { changeAvatarFormFlag } = useAvatarFormFeatureFlag();
  const { isEditableForm } = useIsEditableFormFeatureFlag();

  return (
    <Fade>
      {changeAvatarFormFlag.enabled && <ChangeAvatarForm />}

      <ProfileForm isEditable={isEditableForm.enabled} />

      <ChangeEmailForm isEditable={isEditableForm.enabled} />

      <ChangePhoneForm isEditable={isEditableForm.enabled} />

      <ChangePasswordForm />

      <UserInfo />
    </Fade>
  );
};
