import { useEffect, useState } from 'react';

import { MAPVIEW_FALLBACK } from '@/modules/Home/utils/MapModel.config';
import { useGlobalStore } from '@/shared/store/store.ts';
import { generateId } from '@/shared/utils/helpers';

import { MapViewType } from '@/modules/Home/types/MapModel.types';

export const useHomePage = () => {
  const [mapView, setMapView] = useState<MapViewType>('full');
  const [mapCoordinates, setMapCoordinates] = useState(MAPVIEW_FALLBACK.FULL);
  const [mapKey, setMapKey] = useState<string>('');
  const [mapThumbnailKey, setMapThumbnailKey] = useState<string>('');

  const site = useGlobalStore(store => store.site);
  const locatedAnchor = useGlobalStore(state => state.locatedAnchor);
  const clearLocatedAnchor = useGlobalStore(state => state.clearLocatedAnchor);
  const config = useGlobalStore(store => store.siteMapConfig);
  const mapBlobUrl = useGlobalStore(store => store.geofenceMapBlobUrl);

  const handleMapView = (value: string) => {
    setMapView(value.toLowerCase() as MapViewType);
    setMapKey(generateId());
    setMapThumbnailKey(generateId());
    clearLocatedAnchor();
  };

  useEffect(() => {
    if (locatedAnchor.length > 0) {
      setMapView('top');
      setMapKey(generateId());
      setMapThumbnailKey(generateId());
    }
  }, [locatedAnchor]);

  useEffect(() => {
    switch (mapView) {
      case 'full':
        return setMapCoordinates(config?.FULL ?? MAPVIEW_FALLBACK.FULL);

      case 'side':
        return setMapCoordinates(config?.SIDE ?? MAPVIEW_FALLBACK.SIDE);

      case 'top':
        return setMapCoordinates(config?.TOP ?? MAPVIEW_FALLBACK.TOP);
    }
  }, [config, mapBlobUrl, mapView, site]);

  return {
    handleMapView,
    mapCoordinates,
    mapKey,
    mapThumbnailKey,
    mapView,
  };
};
