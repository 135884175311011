import { NotFound } from '@/modules/Reports/components/NotFound';
import { useSitePresence } from '@/modules/Reports/hooks/useSitePresence.ts';
import { renderColumns } from '@/modules/Reports/utils/SitePresence.utils.tsx';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { TopSection } from '@/shared/components/ui/TopSection';

type TripsProps = {
  isLoading?: boolean;
};

export const SitePresence = ({ isLoading }: TripsProps) => {
  const { assets, isLoading: isLocationHistoryLoading } = useSitePresence();

  return (
    <div className="flex flex-col gap-4">
      <div className="inline-flex w-full flex-wrap items-center justify-between gap-3">
        <TopSection disableFromTo scope={['firm', 'status']} withMaxDate />
      </div>

      {assets.length <= 0 ? (
        <NotFound />
      ) : (
        <DefaultTable
          className="max-h-[calc(100vh-256px)]"
          columns={renderColumns()}
          data={assets}
          debugTable
          isLoading={isLoading || isLocationHistoryLoading}
        />
      )}
    </div>
  );
};
