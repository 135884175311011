import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAnalyticsHooks } from '@/shared/api/analytics/analytics.hooks.ts';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { useSystemTimeZone } from '@/shared/hooks/reports/useSystemTimeZone.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';
import { initializePickerTime } from '@/shared/utils/initialize-picker-time.ts';

import { TripSliceItem } from '@/shared/types/reports/reports.types.ts';

export function useTrips() {
  const [searchParameters] = useSearchParams();

  const dateParameter = searchParameters.get(URL_PARAMS.MULTI_DATE);
  const asset = searchParameters.get(URL_PARAMS.ASSET);
  const startZone = searchParameters.get(URL_PARAMS.START_ZONE);
  const endZone = searchParameters.get(URL_PARAMS.END_ZONE);

  const navigate = useNavigate();

  const { assets, searchFieldValue } = useGlobalStore(state => ({
    assets: state.assets,
    assetsTags: state.assetsTags,
    searchFieldValue: state.searchFieldValue,
  }));
  const setTagIds = useGlobalStore(state => state.setTagIds);

  const { data: assetsCategories = [] } = useAssetHooks.getCategoryTrees();

  const { transformTimeToEpoch } = useSystemTimeZone();

  const { initialEndTime, initialStartTime } = initializePickerTime(dateParameter);
  const { endTime, startTime } = transformTimeToEpoch(initialStartTime, initialEndTime, 'Trip');

  const { data, isLoading: isTripsLoading } = useAnalyticsHooks.getGeofenceTripsSummaries({
    asset: asset ?? '',
    endTime,
    endZone: endZone ?? '',
    startTime,
    startZone: startZone ?? '',
  });

  const trips = data?.results ?? [];
  const modifiedTrips = trips.reduce<TripSliceItem[]>((acc, trip) => {
    const startTrip = {
      assetName: trip.assetName,
      assetResName: trip.assetResName,
      tripGeofenceEntryTime: trip.tripStartGeofenceEntryTime,
      tripGeofenceExitTime: trip.tripStartGeofenceExitTime,
      tripGeofenceName: trip.tripStartGeofenceName,
      tripGeofenceResName: trip.tripStartGeofenceResName,
    };

    return [...acc, startTrip];
  }, []);

  const onShowMap = () => {
    const decodedStartTime = decodeURIComponent(startTime);
    const decodedEndTime = decodeURIComponent(endTime);

    const parsedStartTime = JSON.parse(decodedStartTime);
    const parsedEndTime = JSON.parse(decodedEndTime);

    const result = assets.find(initialAsset => initialAsset.assetResName === asset);

    const currentResultProperty = result?.extraProperties?.find(property => property.key === 'tag');

    if (!currentResultProperty?.value) return notifyService.error(ERROR_MESSAGES.NO_TAG_DATA);

    navigate(
      `/?sidebar=view&${URL_PARAMS.MODE}=historical&${URL_PARAMS.START_TIME}=${
        parsedStartTime.epochTime / 1000
      }&${URL_PARAMS.END_TIME}=${parsedEndTime.epochTime / 1000}&${
        URL_PARAMS.IS_READY_TO_FETCH
      }=true`,
    );
    setTagIds([currentResultProperty?.value as string]);
  };

  const filteredTrips = modifiedTrips.filter(item =>
    item.tripGeofenceName.toLowerCase().includes(searchFieldValue.toLowerCase()),
  );

  const currentAsset = assets.find(initialAsset => initialAsset.assetResName === asset);
  const currentAssetCategory = assetsCategories.find(
    assetCategory => assetCategory.categoryTreeResName === currentAsset?.categoryTreeResName,
  );
  const assetCategoryPathsList = currentAssetCategory?.categoryPathList.map(
    pathList => pathList.categoryPath,
  );

  const roundsCount = () => {
    const slicedFilteredTrips = filteredTrips.length / 2 || 0;

    return slicedFilteredTrips.toString() ?? '0';
  };

  return {
    assetCategoryPathsList,
    currentAsset,
    currentAssetCategory,
    isLoading: isTripsLoading,
    onShowMap,
    roundsCount,
    trips: filteredTrips,
  };
}
