import { FC } from 'react';

import { translate } from '@/i18n';
import { classnames } from '@/shared/utils/classnames.ts';

import { KeyValueTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';

export const KeyValueTable: FC<KeyValueTableBodyProps> = ({ className, data = [] }) => {
  return (
    <table className={classnames(className)}>
      <tbody className="relative overflow-auto">
        {data.map((item, index) => {
          if (item.key === 'global.zpsAccess' && Array.isArray(item.value)) {
            return (
              <tr
                className="grid w-full  grid-cols-[120px_auto] border-b-[1px] border-slate-200 px-3 py-2 text-sm"
                key={item.key + `${index}`}
              >
                <td className="flex min-h-[44px] w-full items-center">{translate(item?.key)}</td>

                <td className="flex min-h-[44px] w-full flex-wrap items-center gap-1 overflow-auto">
                  {Array.isArray(item?.value) &&
                    item?.value?.map(site => (
                      <div className="mr-1 rounded-md border border-slate-400 px-2 py-1" key={site}>
                        {site}
                      </div>
                    ))}
                </td>
              </tr>
            );
          }

          return (
            <tr
              className="grid w-full  grid-cols-[120px_auto] border-b-[1px] border-slate-200 px-3 text-sm"
              key={item.key + `${index}`}
            >
              <td className="flex min-h-[44px] w-full items-center">
                {translate(item?.key as string)}
              </td>

              <td className="flex min-h-[44px] w-full items-center overflow-auto">{item.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
