import { EquipmentFormBody } from '@/shared/components/ui/Modal/Equipment/FormBody.tsx';
import { useCreateEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useCreateEquipment.ts';

export function CreateEquipment() {
  const { mutate, params, states } = useCreateEquipment();

  const props = {
    mutate,
    params,
    states,
    type: 'create' as 'create' | 'edit',
  };

  return <EquipmentFormBody {...props} />;
}
