export type FormTypes = 'firmEdit' | 'invite' | 'workerEdit';

export const ExtraPropertyKeys = {
  allowedSites: 'allowedSites',
  color: 'color',
  currentZone: 'currentZone',
  email: 'email',
  familyName: 'familyName',
  firm: 'firm',
  givenName: 'givenName',
  groups: 'groups',
  phone: 'phone',
  rentalCompany: 'rentalCompany',
  role: 'role',
  tag: 'tag',
  zpsAccess: 'zpsAccess',
};
