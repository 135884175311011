import { useEffect } from 'react';

export const useFullscreenLoader = () => {
  useEffect(() => {
    const loader = document.querySelector('#initial-loader') as HTMLElement;

    if (loader) {
      loader.style.display = 'none';
    }
  }, []);
};
