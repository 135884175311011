import { FC } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';

import Edit from '@/assets/icons/edit-sm.svg?react';
import Plus from '@/assets/icons/plus.svg?react';
import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const SingleItemButtonGroup: FC = () => {
  const { handler } = useToggleModal();

  return (
    <div className="ml-auto inline-flex items-center gap-x-3 md:mt-0">
      <Button
        className="inline-flex items-center justify-center gap-x-2 border bg-white text-blue-600 hover:border-blue-600 hover:bg-white"
        onClick={() => handler(PopupTypeEnum.SUBCONTRACTORS_FIRM_EDIT)}
        variant="solid"
      >
        <Edit />

        {translate('global.edit')}
      </Button>

      <Button
        className="inline-flex items-center justify-center gap-x-2"
        onClick={() => handler(PopupTypeEnum.SUBCONTRACTORS_INVITE)}
        variant="solid"
      >
        <Plus />

        {translate('global.invite')}
      </Button>
    </div>
  );
};
