import { FC } from 'react';

import { translate } from '@/i18n';
import { NotFound } from '@/modules/Reports/components/NotFound';
import { Button } from '@/shared/components/buttons/Button';
import { AssetControlPanel } from '@/shared/components/ui/AssetControlPanel';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';

import Plus from '@/assets/icons/plus.svg?react';
import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const Groups: FC = () => {
  const { handler } = useToggleModal();
  const { isShowInactive, toggleShowInactive } = useAssetStatus();

  return (
    <div className="flex flex-col gap-y-4">
      <div className="inline-flex h-[38px] items-center justify-between gap-x-2">
        <TopSection scope={['status']} />

        <div className="inline-flex items-center gap-x-3">
          <AssetControlPanel
            checked={isShowInactive}
            onChange={toggleShowInactive}
            scopes={['toggle', 'refresh']}
          />

          <Button
            className="inline-flex shrink-0 items-center justify-center gap-x-2"
            onClick={() => handler(PopupTypeEnum.GROUPS_CREATE)}
            variant="solid"
          >
            <Plus />
            {translate('global.createGroup')}
          </Button>
        </div>
      </div>

      <NotFound />
    </div>
  );
};
