import { FC, useState } from 'react';

import { Html } from '@react-three/drei';

import { AnchorCard } from '@/modules/Home/components/map/AnchorCard';
import { Button } from '@/shared/components/buttons/Button';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { classnames } from '@/shared/utils/classnames.ts';

import { CustomIconType } from '@/shared/components/ui/CustomIcon/CustomIcon.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

type Props = {
  anchor: Partial<SocketMapDataProps>;
  isOpened: boolean;
  type: CustomIconType;
  togglePopup: () => void;
};

export const AnchorPopup: FC<Props> = ({ anchor, isOpened, togglePopup, type }) => {
  const defaultValue = type === 'trackerEquipmentPin' || type === 'trackerWorkerPin' ? 1e6 : 1e8;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Html zIndexRange={[1e9, 0]}>
        <Button
          className="anchorShowAnimation absolute -bottom-2.5 left-1/2 -translate-x-1/2"
          data-anchor={true}
          onClick={togglePopup}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          variant="custom"
        >
          <CustomIcon isActive={isHovered || isOpened} type={type} />
        </Button>
      </Html>

      {isOpened && (
        <Html
          position={isOpened ? [0, 0, 0.1] : [0, 0, 0]}
          rotation={isOpened ? [0, Math.PI / 180, 0] : [0, 0, 0]}
          zIndexRange={[isOpened ? 1e20 : defaultValue, 0]}
        >
          <AnchorCard
            body={anchor}
            className={classnames(
              'absolute left-1/2 top-0 z-[9999] flex -translate-x-1/2 flex-col gap-1 rounded-lg border border-slate-200 bg-white p-3 shadow-sm md:w-[387px] xl:left-0 xl:translate-x-0',
              isOpened && 'canvasPopupAnimation',
            )}
            togglePopup={togglePopup}
          />
        </Html>
      )}
    </>
  );
};
