import { FC } from 'react';
import { Link } from 'react-router-dom';

import { translate } from '@/i18n';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/global/common.types.ts';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

type Props = WithClassName & {
  onClick?: () => void;
};

export const LicenseAndReleasesLinks: FC<Props> = ({ className, onClick }) => {
  return (
    <div
      className={classnames(
        'inline-flex w-full justify-between [&_a]:underline [&_a]:transition-colors [&_a]:duration-300',
        className,
      )}
    >
      <Link aria-label="view-licenses-action" onClick={onClick} to={RoutesEnum.LICENSES}>
        {translate('navbar.licenses')}
      </Link>

      <Link aria-label="view-releases-action" onClick={onClick} to={RoutesEnum.RELEASES}>
        {translate('settings.releaseNotes')}
      </Link>
    </div>
  );
};
