export const Calender_Hours = 8;
export const Calender_Starting_Hour = 9;
export const Calender_Ending_Hour = 17;
export const Calender_Hours_List = [
  '9:00',
  // '10:00',
  // '11:00',
  // '12:00',
  // '13:00',
  // '14:00',
  // '15:00',
  // '16:00',
  '17:00',
];
export const Calender_Minutes_List = [
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
];
