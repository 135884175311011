import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export const AppLayout: FC<WithChildren & WithClassName> = ({ children, className }) => {
  return (
    <div className={classnames('flex min-h-dvh flex-col overflow-hidden bg-slate-50', className)}>
      {children}
    </div>
  );
};
