import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useAssetCategoryType = (assetData: AssetBody) => {
  const assetsCategories = useGlobalStore(state => state.assetsCategories);

  const foundType = assetsCategories
    .flatMap(category => category.categoryPathList)
    .find(category => category.categoryPathResName === assetData?.categoryPathResName);

  const categoryType = foundType?.categoryPath ?? '-';

  return { categoryType };
};
