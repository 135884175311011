import { FieldValues, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { AssetsAPINamespace } from '@/shared/api/assets';
import { refinedSubcontractorSchema } from '@/shared/schemas/validate-schemas';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import {
  AssetsCreateRequest,
  AssetsCreateRequestSuccess,
  AssetsUpdateRequest,
  AttachTagMutation,
  ZPSUserCreateRequestSuccess,
  ZPSUserRequestBody,
} from '@/shared/api/assets/assets.types';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useMutatePeopleAsset = () => {
  const queryClient = useQueryClient();

  const site = useGlobalStore(state => state.site);

  const personForm = useForm<FieldValues>({
    mode: 'onChange',
    resolver: zodResolver(refinedSubcontractorSchema),
  });
  const {
    activeWorkerId,
    assets,
    assetsTags,
    clearDeletedWorkerId,
    closeModal,
    setModalDeleting,
    setModalSubmitting,
    setWorkers,
    siteTagsList,
    updateAssets,
    updateAssetsTags,
    updateZPSUsers,
    workers,
    zpsUsers,
  } = usePeopleAsset();

  const { mutate: createOneZPSUserMutation } = useMutation({
    mutationFn: async (data: ZPSUserRequestBody) => {
      const response = await AssetsAPINamespace.createOneZPSUser(data);

      return { response, ...data };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
    },

    onSuccess: async (result: ZPSUserCreateRequestSuccess) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ZPS_USERS],
      });

      updateZPSUsers([
        ...zpsUsers,
        {
          emailAddress: result.emailAddress,
          status: result.status,
          userResName: result.response,
        },
      ]);
    },
  });

  const { mutate: updateOneZPSUserMutation } = useMutation({
    mutationFn: async (data: ZPSUserRequestBody) => {
      await Promise.all(
        zpsUsers.map(async user => {
          if (user.emailAddress === data.emailAddress) {
            await AssetsAPINamespace.updateOneZPSUser({
              ...data,
              userResName: user.userResName,
            });
          }
        }),
      );
      return data;
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
    },

    onSuccess: async (result: ZPSUserRequestBody) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ZPS_USERS],
      });

      updateZPSUsers(
        zpsUsers.map(user => {
          if (user.emailAddress === result.emailAddress) {
            return { ...user, status: result.status };
          }

          return user;
        }),
      );
    },
  });

  const { mutate: createAssetsMutation } = useMutation({
    mutationFn: async (body: AssetsCreateRequest) => {
      setModalSubmitting(true);

      const response = await AssetsAPINamespace.createOne(body);

      return { response, ...body };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.workerCreateError'));

      setModalSubmitting(false);
    },

    onSuccess: async (result: AssetsCreateRequestSuccess) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.WORKERS],
      });

      const responseBody: AssetBody = {
        assetName: result.assetName,
        assetResName: result.response,
        assetStatus: 'inactive',
        categoryPathResName: result.categoryPathResName,
        categoryTreeResName: result.categoryTreeResName,
        extraProperties: result.extraProperties,
        orgResName: result.orgResName ?? '',
      };

      const modifiedAssets = [...assets, { ...responseBody, assetResName: result.response }];
      const modifiedWorkers = [...workers, { ...responseBody, assetResName: result.response }];

      updateAssets(modifiedAssets);
      setWorkers(modifiedWorkers);

      attachTagMutation({
        accountResName: result.accountResName,
        assetResName: result.response,
        isEditing: false,
        modifiedAssets,
        siteResName: site,
        tags: siteTagsList
          .filter(tag => personForm.getValues('tags')?.includes(tag.name))
          .map(tag => ({ tagName: tag.name, tagResName: tag.id })),
      });
    },
  });

  const { mutate: updateAssetsMutation } = useMutation({
    mutationFn: async (body: AssetsCreateRequest) => {
      setModalSubmitting(true);

      const updateBody: AssetsUpdateRequest = {
        ...body,
        assetResName: activeWorkerId ?? '',
      };

      const response = await AssetsAPINamespace.updateOne(updateBody);

      return { response, ...body };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.workerEditError'));
      setModalSubmitting(false);
    },

    onSuccess: async (result: AssetsCreateRequestSuccess) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.WORKERS],
      });

      const responseBody: AssetBody = {
        assetName: result.assetName,
        assetResName: result.response,
        assetStatus: result.assetStatus,
        categoryPathResName: result.categoryPathResName,
        categoryTreeResName: result.categoryTreeResName,
        extraProperties: result.extraProperties,
        orgResName: result.orgResName ?? '',
      };

      const modifiedAssets = [
        ...assets.filter(asset => asset.assetResName !== activeWorkerId),
        { ...responseBody, assetResName: result.response },
      ];

      const modifiedWorkers = [
        ...workers.filter(asset => asset.assetResName !== activeWorkerId),
        { ...responseBody, assetResName: result.response },
      ];

      updateAssets(modifiedAssets);
      setWorkers(modifiedWorkers);

      attachTagMutation({
        accountResName: result.accountResName,
        assetResName: result.response,
        isEditing: true,
        modifiedAssets,
        siteResName: site,
        tags: siteTagsList
          .filter(tag => personForm.getValues('tags')?.includes(tag.name))
          .map(tag => ({ tagName: tag.name, tagResName: tag.id })),
      });
    },
  });

  const { mutate: deleteAssetsMutation } = useMutation({
    mutationFn: async (body: AssetsUpdateRequest) => {
      setModalDeleting(true);

      const updateBody: AssetsUpdateRequest = {
        ...body,
        assetResName: activeWorkerId ?? '',
      };

      const response = await AssetsAPINamespace.updateOne(updateBody);

      return { response, ...body };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.workerDeleteError'));
      setModalDeleting(false);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.WORKERS],
      });

      updateAssets(assets.filter(asset => asset.assetResName !== activeWorkerId));
      setWorkers(workers.filter(asset => asset.assetResName !== activeWorkerId));
      notifyService.success(translate('messages.workerDeleteSuccess'));
      clearDeletedWorkerId();
      setModalDeleting(false);
      setTimeout(() => closeModal(), 1000);
    },
  });

  const { mutate: attachTagMutation } = useMutation({
    mutationFn: async (data: AttachTagMutation) => {
      await AssetsAPINamespace.attachTags(data);

      return data;
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
    },

    onSuccess: async (result: AttachTagMutation) => {
      const currentAsset = result.modifiedAssets.find(
        asset => asset.assetResName === result.assetResName,
      );

      const tagsInResult = new Set(result.tags.map(tag => tag.tagResName));
      const modifiedSelectedTags = assetsTags.map(assetTag => {
        const isSelected = tagsInResult.has(assetTag.tag.tagResName);
        if (isSelected) {
          assetTag.asset = currentAsset;
        } else if (!isSelected && assetTag.asset?.assetResName === result.assetResName) {
          assetTag.asset = undefined;
        }
        return assetTag;
      });

      updateAssetsTags(modifiedSelectedTags);

      if (result.isEditing) {
        notifyService.success(translate('messages.workerEditSuccess'));
      } else {
        personForm.reset();
        notifyService.success(translate('messages.workerCreateSuccess'));
      }

      closeModal();
      setModalSubmitting(false);
    },
  });

  return {
    createAssetsMutation,
    createOneZPSUserMutation,
    deleteAssetsMutation,
    personForm,
    updateAssetsMutation,
    updateOneZPSUserMutation,
  };
};
