import React, { FC, useId } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type Props = WithChildren & {
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const UploadButton: FC<Props> = ({ children, disabled, onChange, ...props }) => {
  const uploadFileId = useId();

  return (
    <label
      className={classnames(
        'inline-flex cursor-pointer items-center justify-center rounded-lg border border-blue-600 bg-white px-1.5 py-[9px] text-sm font-semibold tracking-wider text-blue-600 duration-300 hover:bg-blue-50 focus:bg-blue-50',
        {
          'pointer-events-none opacity-50': disabled,
        },
      )}
      htmlFor={uploadFileId}
    >
      {children}
      <input
        accept="image/*"
        className="sr-only"
        id={uploadFileId}
        onChange={onChange}
        type="file"
        {...props}
      />
    </label>
  );
};
