import { FC } from 'react';

import { Path } from '@/modules/Home/components/map/Path';

import { MapKey, MappedLocationsHistoryProps } from '@/modules/Home/types/MapModel.types.ts';

type Props = {
  locationsHistory: MappedLocationsHistoryProps[];
  mapKey: MapKey;
};

export const HistoricalTrackers: FC<Props> = ({ locationsHistory = [], mapKey }) => {
  return (
    <>
      {locationsHistory.map(({ key, ...rest }) => {
        return <Path key={`${mapKey}-${key}`} {...{ id: key, ...rest, visible: true }} />;
      })}
    </>
  );
};
