import { useIntl } from 'react-intl';

import { NotFound } from '@/modules/Reports/components/NotFound';
import { useZoneEntryExit } from '@/modules/Reports/hooks/useZoneEntryExit.ts';
import { renderColumns } from '@/modules/Reports/utils/ZoneEntryExit.tsx';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { TopSection } from '@/shared/components/ui/TopSection';

type ZoneEntryExitProps = {
  isLoading?: boolean;
};

export function ZoneEntryExit({ isLoading: isSectionLoading }: ZoneEntryExitProps) {
  const { locale } = useIntl();
  const { isItemsLoading, items } = useZoneEntryExit();

  return (
    <div className="flex flex-col gap-y-4">
      <div className="h-[38px] w-full">
        <TopSection
          className="w-full"
          disableFromTo
          scope={['multi-date', 'asset', 'multi-zone']}
          withMaxDate
        />
      </div>

      {!isItemsLoading && items.length <= 0 ? (
        <NotFound />
      ) : (
        <DefaultTable
          className="max-h-[calc(100vh-420px)]"
          columns={renderColumns(locale)}
          contentRowType="default"
          data={items}
          debugTable
          isLoading={isSectionLoading || isItemsLoading}
          isPaginated
        />
      )}
    </div>
  );
}
