import { useIsMutating } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import Delete from '@/assets/icons/triangle-error.svg?react';

export function DeleteEquipment() {
  const {
    data: { deleteMutate, title },
  } = useGlobalStore(state => state.modalPayload);

  const { closeModal } = useModalHandlerTypes();

  const isDeleting = useIsMutating({ mutationKey: [QUERY_KEY.EQUIPMENT_DELETE] }) > 0;

  return (
    <div className="grid items-center justify-center gap-y-5 p-10">
      <Delete className="mx-auto h-[62px] w-[62px]" />

      <div className="grid gap-y-2.5 text-center">
        <Typography as="strong" className="text-2xl font-bold tracking-[0.12px] text-slate-800">
          {translate('global.deleteEquipment')}
        </Typography>

        <Typography className="text-base font-medium tracking-[0.05px] text-slate-500">
          {translate('global.deleteConfirm')} {`“${title}”`}?
        </Typography>
      </div>

      <div className="flex justify-center gap-x-4">
        <Button
          className="w-fit !border-slate-200 text-slate-800 hover:!bg-slate-100"
          disabled={isDeleting}
          onClick={closeModal}
          type="button"
          variant="outline"
        >
          {translate('settings.cancel')}
        </Button>

        <Button
          className={classnames('w-fit bg-red-600 text-white hover:bg-red-700 focus:bg-red-700')}
          isLoading={isDeleting}
          onClick={deleteMutate}
          variant="solid"
          withIcon
        >
          {translate('confirm.delete')}
        </Button>
      </div>
    </div>
  );
}
