import { FC } from 'react';

import { useSettingsHooks } from '@/shared/api/settings/settings.hooks';
import { useGetSiteCalendars } from '@/shared/hooks/settings/useGetSiteCalendars';
import { useGetSitePolicies } from '@/shared/hooks/settings/useGetSitePolicies';
import { useGetSiteShifts } from '@/shared/hooks/settings/useGetSiteShifts';

export const UserSettingsHooks: FC = () => {
  useGetSiteCalendars();
  useGetSitePolicies();
  useGetSiteShifts();
  useSettingsHooks.getAll();

  return null;
};
