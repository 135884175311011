import { FC, useEffect, useId, useState } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

type Props = {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  color?: 'blue' | 'green';
};

export const Toggle: FC<Props> = ({
  checked: controlledChecked,
  color = 'blue',
  defaultChecked = false,
  disabled,
  onChange,
}) => {
  const id = 'toggle-' + useId();
  const [internalChecked, setInternalChecked] = useState(defaultChecked);

  const isControlled = controlledChecked !== undefined;
  const checkedValue = isControlled ? controlledChecked : internalChecked;

  useEffect(() => {
    if (isControlled) {
      setInternalChecked(controlledChecked);
    }
  }, [isControlled, controlledChecked]);

  const handleChange = () => {
    if (!isControlled) {
      setInternalChecked(!internalChecked);
    }

    onChange?.(!checkedValue);
  };

  return (
    <>
      <input
        checked={checkedValue}
        className={classnames(
          'relative h-6 w-10 shrink-0 cursor-pointer rounded-full border border-slate-300 bg-slate-100 p-px text-transparent transition-colors duration-300 ease-in-out before:inline-block before:size-5 before:translate-x-0 before:transform before:rounded-full before:shadow-md before:ring-0 before:transition before:duration-300 before:ease-in-out checked:bg-none checked:before:translate-x-4 focus:ring-blue-600 disabled:pointer-events-none disabled:opacity-50',
          {
            'before:bg-white checked:border-blue-300 checked:text-blue-100 checked:before:bg-blue-600 focus:checked:border-blue-300':
              color === 'blue',
            'before:bg-white checked:border-green-300 checked:text-green-100 checked:before:bg-green-600 focus:checked:border-green-300':
              color === 'green',
          },
        )}
        disabled={disabled}
        id={id}
        onChange={handleChange}
        type="checkbox"
      />

      <label className="sr-only" htmlFor={id}>
        Switch
      </label>
    </>
  );
};
