import { ColumnDef } from '@tanstack/react-table';

import { UtilizationTimeline } from '@/modules/Reports/components/TabPanels/Utilization/UtilizationTimeline.tsx';
import { Typography } from '@/shared/components/ui/Typography';

import { UtilizationBody } from '@/modules/Reports/types/types.ts';

type Props = {
  isShowInactive?: boolean;
};

export const renderColumns = ({ isShowInactive }: Props): ColumnDef<UtilizationBody>[] => [
  {
    accessorKey: 'asset',
    cell: ({ row }) => (
      <Typography className="max-w-40 truncate text-sm font-bold">
        {row.original.assetName}
      </Typography>
    ),
    header: () => null,
  },
  {
    accessorKey: 'timeline',
    cell: ({ row }) => (
      <UtilizationTimeline
        {...{
          isShowInactive,
          row: row.original,
        }}
      />
    ),
    header: () => null,
  },
];
