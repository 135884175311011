import PinInput from 'react-pin-input';

type CodeInputProps = {
  onChange: (value: string) => void;
};

export const CodeInput = ({ onChange }: CodeInputProps) => {
  return (
    <PinInput
      autoSelect={true}
      focus={true}
      initialValue=""
      inputFocusStyle={{ borderColor: '#3b82f6' }}
      inputMode="number"
      inputStyle={{
        borderColor: '#e2e8f0',
        borderRadius: '8px',
        borderWidth: '1px',
        fontSize: '20px',
        height: '40px',
        margin: '5px',
        width: '40px',
      }}
      length={6}
      onChange={onChange}
      onComplete={onChange}
      style={{ padding: '10px' }}
      type="numeric"
    />
  );
};
