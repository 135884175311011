import { ChangeEvent, SetStateAction } from 'react';

import { translate } from '@/i18n';
import { SelectType } from '@/shared/components/form/ExtendedAssetsSelect';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

type Props = {
  setIsOpen: (nextValue?: boolean) => void;
  setSelectType: (value: SetStateAction<SelectType>) => void;
  onChange: (value: string[]) => void;
  value: string[];
  assets: AssetBody[];
  asSelect?: boolean;
};

export function useExtendedAssetsSelectMethods({
  asSelect = false,
  assets,
  onChange,
  setIsOpen,
  setSelectType,
  value,
}: Props) {
  const onClickAway = () => {
    setIsOpen(false);
    setSelectType('equipment');
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      const newValues = [...value, name];
      onChange(newValues);
    } else {
      const newValues = value.filter(item => item !== name);
      onChange(newValues);
    }
  };

  const renderButtonText = () => {
    if (value.length > 0) {
      const modifiedAssets = value.map(
        item => assets.find(asset => asset.assetResName === item)?.assetName,
      );
      const checkedModifiedAssets = modifiedAssets.filter(item => !!item);

      return checkedModifiedAssets.join(', ');
    } else if (value.length <= 0) {
      return translate('global.asset');
    } else if (asSelect) return translate('auth.select');

    const translateKey = `filters.assetItem`;

    return translate(translateKey);
  };

  const renderOptionLabel = (option: GeofenceCategoryEnum | RolesEnum | string) => {
    const matchedAssetItem = assets.find(asset => asset.assetResName === option);
    return matchedAssetItem?.assetName;
  };

  return { handleCheckboxChange, onClickAway, renderButtonText, renderOptionLabel };
}
