import { FC } from 'react';

import { NotFound } from '@/modules/Reports/components/NotFound';
import { useTrips } from '@/modules/Reports/hooks/useTrips.ts';
import { renderColumns } from '@/modules/Reports/utils/Trips.utils.tsx';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { AssetControlPanel } from '@/shared/components/ui/AssetControlPanel';
import { StatisticCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';

type TripsProps = {
  isLoading?: boolean;
};

export const Trips: FC<TripsProps> = ({ isLoading: isSectionLoading }) => {
  const { assetCategoryPathsList, currentAsset, isLoading, onShowMap, roundsCount, trips } =
    useTrips();

  return (
    <div className="flex flex-col gap-y-4">
      <div className="inline-flex w-full flex-wrap items-center justify-between gap-3">
        <TopSection
          assetsWithTags
          disableFromTo
          scope={['multi-date', 'asset', 'startZone', 'endZone']}
          withMaxDate
        />

        <AssetControlPanel onShowOnMap={onShowMap} scopes={['location']} />
      </div>

      {!isLoading && trips.length <= 0 ? (
        <NotFound />
      ) : (
        <>
          <div className="inline-flex w-full items-center gap-x-3">
            <StatisticCard
              icon="equipment"
              isLoading={isLoading || isSectionLoading}
              subtitle={currentAsset?.assetName ?? 'global.unspecified'}
              titleId={assetCategoryPathsList?.join(', ') ?? 'global.unspecified'}
            />

            <StatisticCard
              icon="item-move"
              isLoading={isLoading || isSectionLoading}
              subtitle={roundsCount()}
              titleId="reports.roundTrips"
            />
          </div>

          <DefaultTable
            className="max-h-[calc(100dvh-420px)]"
            columns={renderColumns()}
            contentRowType="default"
            data={trips}
            debugTable
            isLoading={isLoading || isSectionLoading}
            isPaginated
          />
        </>
      )}
    </div>
  );
};
