import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { Archive } from '@/modules/Subcontractors/components/tabs/Archive';
import { Unresolved } from '@/modules/Subcontractors/components/tabs/Unresolved';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useWorkerProfileTableData } from '@/modules/Subcontractors/hooks/useWorkerProfileTableData.ts';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { Button } from '@/shared/components/buttons/Button';
import { GoBackLink } from '@/shared/components/navigation/GoBackLink';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { KeyValueTable } from '@/shared/components/tables/KeyValueTable';
import { Badge } from '@/shared/components/ui/Badge';
import { Loader } from '@/shared/components/ui/Loader';
import { PageHeader } from '@/shared/components/ui/PageHeader';
import { PageWrapper } from '@/shared/components/ui/PageWrapper';
import { usePersonHideArchivedFlag } from '@/shared/feature-flags/usePersonHideArchivedFlag.ts';
import { useActions } from '@/shared/hooks/actions/useActions.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { classnames } from '@/shared/utils/classnames.ts';
import { PARAM_KEY } from '@/shared/utils/constants';

import Edit from '@/assets/icons/edit-sm.svg?react';
import { PopupTypeEnum, RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

export const WorkerProfile: FC = () => {
  const params = useParams();

  const subcontractorId = params[PARAM_KEY.SUBCONTRACTOR_ID];
  const workerId = params[PARAM_KEY.WORKER_ID];

  const { personHideArchivedFlag } = usePersonHideArchivedFlag();
  const { renderItemVisibility } = useRole();
  const { isAssetsLoading, people } = usePeopleAsset();
  const { handler } = useToggleModal();
  const { totalCount } = useActions();
  const isMobile = useMedia('(max-width: 767px)');
  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
  ]);
  const { workerTableData } = useWorkerProfileTableData(workerId ?? null, isVisible);
  const { isLoading: isWorkersLoading } = useAssetHooks.getAllWorkers();

  const worker = people.find(person => person.id === workerId);

  if (isAssetsLoading || isWorkersLoading) {
    return <Loader appearance="section" fullHeight loaderText={translate('confirm.loading')} />;
  }

  if (!worker) {
    return null;
  }

  return (
    <PageWrapper className="p-0">
      <PageHeader>
        <GoBackLink to={`${RoutesEnum.PEOPLE}/${subcontractorId}`}>
          {`${worker?.given_name} ${worker?.family_name}`}
        </GoBackLink>
      </PageHeader>

      <div
        className={classnames('page-body flex items-start gap-x-4', {
          'w-full flex-col': isMobile,
        })}
      >
        <div
          className={classnames('worker-details rounded-md border bg-white shadow-sm', {
            'w-full': isMobile,
          })}
        >
          <KeyValueTable
            className={classnames('w-full max-w-sm table-fixed border-collapse', {
              'max-w-full': isMobile,
            })}
            data={workerTableData}
          />

          <Button
            className="flex w-full items-center justify-center gap-x-2 self-center bg-white text-blue-600 hover:border-blue-600 hover:bg-white"
            onClick={() => handler(PopupTypeEnum.SUBCONTRACTORS_WORKER_EDIT)}
            variant="ghost"
          >
            <Edit />

            {translate('global.edit')}
          </Button>
        </div>

        {isVisible && (
          <div className={classnames('flex-1', { 'mt-2 w-full': isMobile })}>
            <Tabs.Group>
              <Tabs.Wrapper>
                <Tabs.List withWrapper>
                  <Tabs.Tab className="uppercase">
                    {translate('global.unresolved')}

                    <Badge className="px-1.5" color="blue" rounded="full" variant="solid">
                      {totalCount}
                    </Badge>
                  </Tabs.Tab>

                  {personHideArchivedFlag.enabled && (
                    <Tabs.Tab className="inline-flex items-center gap-x-1 uppercase" withCounter>
                      {translate('global.archive')}
                    </Tabs.Tab>
                  )}
                </Tabs.List>
              </Tabs.Wrapper>

              <Tabs.TabPanels className="p-0 py-3">
                <Tabs.TabPanel>
                  <Unresolved />
                </Tabs.TabPanel>

                {personHideArchivedFlag.enabled && (
                  <Tabs.TabPanel>
                    <Archive />
                  </Tabs.TabPanel>
                )}
              </Tabs.TabPanels>
            </Tabs.Group>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
