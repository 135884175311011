import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { secondsToHHMM } from '@/shared/utils/seconds-transform.ts';

import { TimeInZone } from '@/shared/types/reports/analytics.types.ts';

export const renderColumns = (
  geofencesData: ColumnDef<TimeInZone>[],
  currentExtraGroup: string,
): ColumnDef<TimeInZone>[] => {
  return [
    {
      accessorKey: 'assetName',
      cell: ({ row }) => row.original.assetName,
      header: () => translate('global.asset'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'totalTime',
      cell: ({ row }) => {
        let calculatedTotalTime = 0;
        let calculatedTotalEntries = 0;

        row.original.geofenceResResNames.forEach(name => {
          calculatedTotalTime += Number(name.totalTime);
          calculatedTotalEntries += name.episodes?.length ?? 0;
        });

        if (currentExtraGroup === 'time') return secondsToHHMM(Number(calculatedTotalTime));
        if (currentExtraGroup === 'entries') return calculatedTotalEntries;
      },
      header: () =>
        translate(currentExtraGroup === 'time' ? 'reports.totalTime' : 'reports.totalEntries'),
      meta: {
        isFiltered: true,
      },
    },
    ...geofencesData,
  ];
};
