import { StateCreator } from 'zustand';

import { ModalSliceProps } from '@/shared/store/slices/modal/modal.types.ts';
import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const createModalSlice: StateCreator<ModalSliceProps> = (set, _) => ({
  closeModal: () =>
    set({ isModalOpen: false, modalPayload: null, modalType: PopupTypeEnum.DEFAULT }),
  isModalDeleting: false,
  isModalOpen: false,
  isModalSubmitting: false,
  modalPayload: null,
  modalType: PopupTypeEnum.DEFAULT,
  openModal: (type: PopupTypeEnum, payload?: unknown) =>
    set({ isModalOpen: true, modalPayload: payload, modalType: type }),
  setModalDeleting: (status: boolean) => set({ isModalDeleting: status }),
  setModalSubmitting: (status: boolean) => set({ isModalSubmitting: status }),
});
