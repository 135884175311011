export const checkOnSimilar = <T, K extends keyof T>(data: T[], field: K) => {
  return Object.values(
    data.reduce((uniqueItems: Record<string, T>, item: T) => {
      if (!uniqueItems[item[field] as string]) {
        uniqueItems[item[field] as string] = item;
      }
      return uniqueItems;
    }, {}),
  );
};
