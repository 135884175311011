import { SettingsService } from '@/shared/services/settings.service.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';

export function useNavbar() {
  const userSettings = useGlobalStore(state => state.userSettings);

  const alertSettingsService = new SettingsService(
    userSettings,
    SettingsNameEnum.UserSettings,
    SettingsKeyEnum.AlertSettings,
  );

  const currentSettings = alertSettingsService.getUserSettingValue();

  return { alertsSettings: currentSettings };
}
