import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types';
import { GeofencesProps, Point } from '@/shared/types/global/geofences.types.ts';
import { SystemViewEnum } from '@/shared/types/settings/system.types.ts';

export namespace SystemViewNamespace {
  const INCH = 1;
  const CM_FROM_INCH = 2.54;
  const CM_TO_INCH = INCH / CM_FROM_INCH;

  export namespace GeofencesNamespace {
    export function transformSystemView(systemView: SystemViewEnum, geofences: GeofencesProps[]) {
      switch (systemView) {
        case SystemViewEnum.IMPERIAL:
          return convertToImperial(geofences);

        case SystemViewEnum.METRIC:
          return convertToMetric(geofences);

        default:
          return null;
      }
    }

    export function convertToMetric(geofences: GeofencesProps[]) {
      return geofences.map(geofence => {
        const maxHeight = geofence.polygon?.maxHeight ?? 0;

        return {
          ...geofence,
          ...(geofence.polygon && {
            polygon: {
              maxHeight: +(maxHeight / 100).toFixed(1),
              points: convertToMetricPoint(geofence.polygon.points) || [],
            },
          }),
        };
      });
    }

    export function convertToImperial(geofences: GeofencesProps[]) {
      return geofences.map(geofence => {
        const maxHeight = geofence.polygon?.maxHeight ?? 0;

        return {
          ...geofence,
          ...(geofence.polygon && {
            polygon: {
              maxHeight: +(maxHeight * CM_TO_INCH).toFixed(1),
              points: convertToImperialPoint(geofence.polygon.points) || [],
            },
          }),
        };
      });
    }
  }

  export namespace LocationsNamespace {
    export function transformSystemView(data: SocketMapDataProps) {
      return convertToMetric(data);
    }

    export function convertToMetric(data: SocketMapDataProps) {
      const convertedMetricPoints = convertToMetricPoint([{ x: data.x, y: data.y, z: data.z }])[0];

      return {
        ...data,
        ...convertedMetricPoints,
      };
    }
  }

  export function convertToMetricPoint(points: Point[]) {
    return points.filter(filterNotExistingPoint).map(point => {
      return {
        x: +(+point.x / 100).toFixed(1),
        y: +(+point.y / 100).toFixed(1),
        z: +(+point.z / 100).toFixed(1),
      };
    });
  }

  export function convertToImperialPoint(points: Point[]) {
    return points.filter(filterNotExistingPoint).map(point => {
      return {
        x: +(+point.x * CM_TO_INCH).toFixed(1),
        y: +(+point.y * CM_TO_INCH).toFixed(1),
        z: +(+point.z * CM_TO_INCH).toFixed(1),
      };
    });
  }

  export function convertToDefaultPoints(systemView: SystemViewEnum, points: Point[]) {
    const modifiedPointsList = (value: number) => {
      return points.filter(filterNotExistingPoint).map(point => {
        return {
          x: +(+point.x * value).toFixed(1),
          y: +(+point.y * value).toFixed(1),
          z: +(+point.z * value).toFixed(1),
        };
      });
    };

    switch (systemView) {
      case SystemViewEnum.IMPERIAL:
        return modifiedPointsList(CM_FROM_INCH);
      case SystemViewEnum.METRIC:
        return modifiedPointsList(100);
    }
  }

  export function filterNotExistingPoint(point: Point) {
    const { x, y, z } = point ?? {};

    return x !== undefined && y !== undefined && z !== undefined;
  }
}
