import { FC } from 'react';

import { RequiredFieldIndicator } from '@/shared/components/form/RequiredFieldIndicator';
import { Typography } from '@/shared/components/ui/Typography';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type Props = WithChildren & {
  label: JSX.Element | string;
  isRequired?: boolean;
};

export const InputWrapper: FC<Props> = ({ children, isRequired, label }) => {
  return (
    <div className="grid grid-cols-2 items-center gap-x-3 md:grid-cols-[170px_1fr]">
      <Typography className="inline-flex gap-x-0.5 text-start text-sm font-medium md:w-[310px]">
        {label}

        {isRequired && <RequiredFieldIndicator />}
      </Typography>

      {children}
    </div>
  );
};
