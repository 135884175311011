import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type Props = WithChildren & {
  className?: string;
  label: JSX.Element | string;
};

export const InputWrapper: FC<Props> = ({ children, className, label }) => {
  return (
    <div className={classnames('grid w-full gap-y-2', className)}>
      <Typography className="text-sm font-medium text-slate-950">{label}</Typography>

      {children}
    </div>
  );
};
