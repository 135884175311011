import React, { useRef, useState } from 'react';
import { useClickAway, useToggle } from 'react-use';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Checkbox } from '@/shared/components/form/Checkbox';
import { DatePicker } from '@/shared/components/form/DatePicker';
import { SearchInput } from '@/shared/components/form/Input';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import ChevronDownIcon from '@/assets/icons/chevron.svg?react';
import ChevronUpDownIcon from '@/assets/icons/select-arrow.svg?react';
import ChevronUpIcon from '@/assets/icons/vec-arrow.svg?react';
import { ActionCategoryEnum, ActionSubCategoryEnum } from '@/shared/types/actions/actions.types.ts';

export const ArchiveFilters = () => {
  const [filters, setFilters] = useState({
    date: new Date(),
    searchKey: '',
    subCategories: [] as ActionSubCategoryEnum[],
  });

  const alertFilters = useGlobalStore(store => store.alertsFilters);
  const setAlertFilters = useGlobalStore(store => store.setAlertsFilters);
  const isArchiveAlertsDataLoading = useGlobalStore(store => store.isArchiveAlertsDataLoading);
  const setIsArchiveAlertsDataLoading = useGlobalStore(
    store => store.setIsArchiveAlertsDataLoading,
  );

  const resetFilters = () => {
    setFilters({
      date: alertFilters.archive.date,
      searchKey: alertFilters.archive.searchKey,
      subCategories: [...alertFilters.archive.subCategories],
    });
  };

  const saveFilters = () => {
    setAlertFilters({ archive: filters });
    setIsArchiveAlertsDataLoading(true);
  };

  return (
    <div className="flex items-stretch flex-wrap gap-3 py-2">
      <div className="relative">
        <SearchInput
          className="!w-48"
          onChange={searchKey => setFilters({ ...filters, searchKey: searchKey as string })}
          value={filters.searchKey}
        />
      </div>

      <ArchiveSubCategorySelect
        onChange={categories => setFilters({ ...filters, subCategories: categories })}
        values={filters.subCategories}
      />

      <div className="flex items-center gap-1.5 w-48">
        <DatePicker
          className="w-full"
          dateFormat="dd.MM.yyy"
          icon="calendar"
          onChange={value => setFilters({ ...filters, date: value ?? new Date() })}
          selected={filters.date}
          withMaxDate
        />
      </div>

      <div className="inline-flex gap-2">
        <Button
          isLoading={isArchiveAlertsDataLoading}
          onClick={saveFilters}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate('global.submit')}
        </Button>

        <Button className="text-red-500" onClick={resetFilters} type="reset" variant="ghost">
          {translate('global.clear')}
        </Button>
      </div>
    </div>
  );
};

interface ArchiveSubcategoryDropdownProps {
  onChange: (values: ActionSubCategoryEnum[]) => void;
  values: ActionSubCategoryEnum[];
}

const ArchiveSubCategorySelect = ({ onChange, values }: ArchiveSubcategoryDropdownProps) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickAway(dropdownRef, () => setIsOpen(false));

  const translateButtonText = () => {
    if (values.length === 0) return translate('settings.category');

    return values.map((value, index) => {
      return (
        <>
          {translate(`alert.subCategory.${value}`)}
          {index < values.length - 1 ? ', ' : null}
        </>
      );
    });
  };

  return (
    <div className="relative w-48">
      <Button
        className={classnames(
          'disabled:hover-none inline-flex w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2 pr-2.5 text-left text-sm font-normal text-slate-500 transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50',
          isOpen && 'pointer-events-none',
        )}
        onClick={setIsOpen}
        variant="custom"
      >
        <Typography className="truncate">{translateButtonText()}</Typography>

        <ChevronUpDownIcon aria-hidden="true" className="h-4 w-4 shrink-0" />
      </Button>

      {isOpen && (
        <div
          className="filterSectionAnimation absolute left-0 z-50 mt-2 grid max-h-80 w-full origin-top-left overflow-y-auto rounded-lg border border-slate-200 bg-white p-3 shadow-lg"
          ref={dropdownRef}
        >
          <ArchiveSubCategoryToggle
            category={ActionCategoryEnum.EQUIPMENT}
            onChange={onChange}
            subCategories={[ActionSubCategoryEnum.RESERVATION, ActionSubCategoryEnum.UTILIZATION]}
            values={values}
          />

          <ArchiveSubCategoryToggle
            category={ActionCategoryEnum.SAFETY}
            onChange={onChange}
            subCategories={[
              ActionSubCategoryEnum.AFTER_HOURS,
              ActionSubCategoryEnum.EMERGENCY,
              ActionSubCategoryEnum.DANGER,
            ]}
            values={values}
          />

          <ArchiveSubCategoryToggle
            category={ActionCategoryEnum.SYSTEM}
            onChange={onChange}
            subCategories={[ActionSubCategoryEnum.TAG, ActionSubCategoryEnum.READER]}
            values={values}
          />

          <ArchiveSubCategoryToggle
            category={ActionCategoryEnum.ZONE}
            onChange={onChange}
            subCategories={[
              ActionSubCategoryEnum.RED_ZONE,
              ActionSubCategoryEnum.WORK_ZONE,
              ActionSubCategoryEnum.ZONE,
            ]}
            values={values}
          />
        </div>
      )}
    </div>
  );
};

interface ArchiveSubCategoryToggleProps {
  category: ActionCategoryEnum;
  onChange: (values: ActionSubCategoryEnum[]) => void;
  subCategories: ActionSubCategoryEnum[];
  values: ActionSubCategoryEnum[];
}

const ArchiveSubCategoryToggle = ({
  category,
  onChange,
  subCategories,
  values,
}: ArchiveSubCategoryToggleProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(false);

  const categoryChecked = subCategories.every(subCat => values.includes(subCat));
  const categoryPartiallyChecked =
    !categoryChecked && subCategories.some(subCat => values.includes(subCat));

  const onToggleCategory = () => {
    const someSubCategoriesChecked = subCategories.some(subCat => values.includes(subCat));

    if (someSubCategoriesChecked) {
      const newValues = values.filter(value => !subCategories.includes(value));
      onChange(newValues);
    } else onChange([...values, ...subCategories]);
  };

  const onToggleSubCategory = (subCat: ActionSubCategoryEnum) => {
    if (values.includes(subCat)) {
      onChange(values.filter(value => value !== subCat));
    } else onChange([...values, subCat]);
  };

  const onToggle = (event: React.MouseEvent) => {
    if (event.target === inputRef.current) return;
    setExpanded(!expanded);
  };

  return (
    <div>
      <button className="w-full flex items-center gap-3 py-[0.3125rem]" onClick={onToggle}>
        <input
          checked={categoryChecked}
          className="checkboxAnimation defaultFocusStyle h-4 w-4 shrink-0 rounded border border-slate-200 text-blue-600
                      data-[partial=true]:border-[2.5px] data-[partial=true]:border-blue-600"
          data-partial={categoryPartiallyChecked}
          onChange={onToggleCategory}
          ref={inputRef}
          type="checkbox"
        />

        <Typography className="flex-1 text-left text-sm font-medium first-letter:capitalize">
          {translate(`actions.${category.toLowerCase()}`)}
        </Typography>

        <span className="flex-1" />

        {expanded ? (
          <ChevronUpIcon aria-hidden="true" className="h-4 w-4 shrink-0" />
        ) : (
          <ChevronDownIcon aria-hidden="true" className="h-4 w-4 shrink-0" />
        )}
      </button>

      {expanded && (
        <div className="pl-4">
          {subCategories.map(subCat => (
            <Checkbox
              checked={values.includes(subCat)}
              key={subCat}
              onChange={() => onToggleSubCategory(subCat)}
            >
              <Typography className="flex-1 text-left text-sm font-medium first-letter:capitalize">
                {translate(`alert.subCategory.${subCat.toLowerCase()}`)}
              </Typography>
            </Checkbox>
          ))}
        </div>
      )}
    </div>
  );
};
