import { FC } from 'react';

import { IconButton } from '@/shared/components/buttons/IconButton';

type Props = {
  category: string;
  selection: string;
  details: string;
  onEdit: () => void;
  onDelete: () => void;
};

export const TableBodyRow: FC<Props> = ({ category, details, onDelete, onEdit, selection }) => {
  return (
    <tr className="text-sm font-normal tracking-[0.07px] text-slate-800">
      <td className="max-w-20 truncate whitespace-nowrap px-4 py-3">{category || 'N/A'}</td>

      <td className="max-w-80 truncate whitespace-nowrap px-4 py-3 font-semibold">
        {selection || 'N/A'}
      </td>

      <td className="max-w-80 truncate whitespace-nowrap px-4 py-3">{details || 'N/A'}</td>

      <td className="flex shrink-0 items-center justify-end gap-x-2.5 whitespace-nowrap px-4 py-3 text-end font-medium">
        <IconButton
          className="h-[38px] w-[38px] rounded-lg border border-slate-200 p-3 text-slate-950 shadow-sm"
          disabled
          icon="edit-sm"
          onClick={onEdit}
        />

        <IconButton
          className="h-[38px] w-[38px] rounded-lg border border-slate-200 p-3 text-red-500 shadow-sm"
          disabled
          icon="delete-sm"
          onClick={onDelete}
        />
      </td>
    </tr>
  );
};
