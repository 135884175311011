import { FC } from 'react';

import { ReservationMobileCard } from '@/modules/Reports/components/ReservationMobileCard';

import { ReservationAssetType } from '@/shared/types/global/reservations.types.ts';

type Props = {
  reservations: ReservationAssetType[];
  handleSubmit: (responseId: ReservationAssetType) => void;
  handleReject: (responseId: ReservationAssetType) => void;
  handleApprove: (responseId: ReservationAssetType) => void;
  handleDelete: (responseId: ReservationAssetType) => void;
  selectEvent: (temporaryData: ReservationAssetType) => void;
};

export const ReservationMobileList: FC<Props> = ({
  handleApprove,
  handleDelete,
  handleReject,
  handleSubmit,
  reservations,
  selectEvent,
}) => {
  return (
    <>
      {reservations.map(reservation => (
        <ReservationMobileCard
          assetData={reservation?.assetData}
          conflictData={
            reservation?.conflictData as {
              startTime: {
                epochTime: number;
              };
              endTime: {
                epochTime: number;
              };
              conflictingReservations: ReservationAssetType[];
            }
          }
          fullData={reservation}
          handleApprove={handleApprove}
          handleDelete={handleDelete}
          handleReject={handleReject}
          handleSubmit={handleSubmit}
          key={reservation?.reservationResName}
          organization={reservation?.user?.orgName}
          reservationPeriod={reservation?.reservationPeriod}
          selectEvent={selectEvent}
          state={reservation?.workflowContext?.currentStateName}
        />
      ))}
    </>
  );
};
