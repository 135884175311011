import { useEffect, useState } from 'react';

import { useFlags, useFlagsmith } from 'flagsmith/react';

const TIMEOUT = 500;

export const useFeatureFlags = () => {
  const [isLoading, setIsLoading] = useState(true);

  const flagsmith = useFlagsmith();

  const features = [
    'change_avatar_form',
    'equipment_hide_reservations_types',
    'filters_hide_groups_zones_showmap',
    'firm_hide_tag_id',
    'historical_hide_zone_popup',
    'intercom_implementation',
    'is_editable_form',
    'map_hide_groups_ui',
    'mix_panel_implementation',
    'new_relic',
    'pages_hide_groups_zones',
    'person_hide_archived',
    'reports_hide_tag_audit_progress',
    'reservations_hide_groups_auto_approval',
    'settings_hide_site_hours',
    'update_checker',
    'reservations_control_flag',
    'user_settings_control_flag',
    'show_alerts',
  ];

  const flags = useFlags(features);

  useEffect(() => {
    if (flags) setTimeout(() => setIsLoading(false), TIMEOUT);
  }, [flags]);

  const logout = () => {
    flagsmith.logout();
  };

  return {
    flags,
    flagsmith,
    isLoading,
    logout,
  };
};
