import { FC } from 'react';
import { useMedia } from 'react-use';

import { NotFound } from '@/modules/Reports/components/NotFound';
import { TimeZoneAccordsBody } from '@/modules/Reports/components/TabPanels/TimeZone/TimeZoneAccordsBody.tsx';
import { useTimeZone } from '@/modules/Reports/hooks/useTimeZone.ts';
import { useTimeZoneDeps } from '@/modules/Reports/hooks/useTimeZoneDeps.tsx';
import { renderColumns } from '@/modules/Reports/utils/TimeZone.utils.tsx';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { StatisticCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';
import { classnames } from '@/shared/utils/classnames.ts';

import { TimeInZone } from '@/shared/types/reports/analytics.types.ts';

type TimeZoneProps = {
  isLoading?: boolean;
};

export const TimeZone: FC<TimeZoneProps> = ({ isLoading: isSectionLoading }) => {
  const {
    currentExtraGroup,
    initialTimes,
    isLoading: isTimesLoading,
    selectedEquipment,
    selectedPeople,
    times,
    totalEntries,
    totalManHours,
  } = useTimeZone();
  const { geofenceHeaders, geofencesTableHeaderData, renderTotalCardProps } = useTimeZoneDeps({
    currentExtraGroup,
    initialTimes,
    times,
    totalEntries,
    totalManHours,
  });
  const isMobile = useMedia('(max-width: 767px)');

  const selectedAssets = selectedEquipment + selectedPeople;

  return (
    <div className="flex flex-col gap-y-4">
      <div className="w-full">
        <TopSection
          className="w-full"
          disableFromTo
          extraButtonsGroup
          scope={['multi-date', 'multi-asset', 'multi-zone']}
          withMaxDate
        />
      </div>

      {selectedAssets <= 0 || (!isTimesLoading && times.length <= 0) ? (
        <NotFound />
      ) : (
        <>
          <div
            className={classnames('inline-flex w-full items-center gap-x-3', {
              'flex-col gap-y-2': isMobile,
            })}
          >
            <StatisticCard
              icon="worker"
              isLoading={isSectionLoading || isTimesLoading}
              subtitle={selectedPeople || 0}
              titleId={'reports.peopleSelected'}
            />

            <StatisticCard
              icon="clock"
              isLoading={isSectionLoading || isTimesLoading}
              subtitle={renderTotalCardProps()}
              titleId={
                currentExtraGroup === 'time' ? 'reports.totalManHours' : 'reports.totalEntries'
              }
            />

            <StatisticCard
              icon="equipment"
              isLoading={isSectionLoading || isTimesLoading}
              subtitle={selectedEquipment || 0}
              titleId={'reports.equipmentSelected'}
            />
          </div>

          <DefaultTable
            accordedChildren={items => (
              <TimeZoneAccordsBody
                currentExtraGroup={currentExtraGroup}
                geofenceHeaders={geofenceHeaders}
                items={items}
                times={times as unknown as TimeInZone[]}
              />
            )} //NOSONAR
            className="max-h-[calc(100dvh-300px)]"
            columns={renderColumns(geofencesTableHeaderData, currentExtraGroup)}
            contentRowType="equipment"
            data={times as unknown as TimeInZone[]}
            debugTable
            isBodyHeightLimited
            isFirstRowReduced
            isLoading={isSectionLoading || isTimesLoading}
          />
        </>
      )}
    </div>
  );
};
