import { FC } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';

import translations from '@/i18n/translations';
import { keyExistsInTranslation } from '@/shared/utils/translation';

import { I18nProviderProps } from '@/i18n/extensions/Provider/provider.types';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const translate = (id: string, value = {}): JSX.Element => {
  if (!keyExistsInTranslation(id)) return <>{id}</>;

  return <FormattedMessage id={id} values={{ ...value }} />;
};

export const I18nProvider: FC<I18nProviderProps> = ({ children, locale = LocaleEnum.EN }) => {
  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      {children}
    </IntlProvider>
  );
};
