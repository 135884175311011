import flagsmith from 'flagsmith';
import mp, { RequestOptions } from 'mixpanel-browser';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { MixpanelEvent } from '@/shared/types/global/mixpanel.types.ts';

interface UserIdentity {
  $email?: string; // $ required
  $name?: string; // $ required
  'Acct Res Name'?: string;
  Role?: RolesEnum;
  Username?: string;
  'User Res Name'?: string;
}

class MixpanelService {
  private log(
    event: MixpanelEvent,
    additionalData?: Record<string, unknown>,
    options?: RequestOptions,
  ) {
    if (!this.mpInitialized()) {
      console.warn(`Mixpanel flag disabled`);
      return;
    }

    mp.track(event, additionalData, options);
  }

  private mpInitialized() {
    try {
      mp.get_config(); // Will throw an error if mp is not initialized
      return !!flagsmith.getAllFlags()?.mix_panel_implementation?.enabled;
    } catch {
      console.warn('Mixpanel not initialized!');
      return false;
    }
  }

  identify(userId: string, props?: UserIdentity) {
    if (this.mpInitialized()) {
      mp.identify(userId);
      if (props) mp.people.set(props);
    }
  }

  logAddEquipment(assetCategory: string, assetFirm: string, equipmentName: string) {
    this.log(MixpanelEvent.ADD_EQUIPMENT, {
      'Asset category': assetCategory,
      'Asset firm': assetFirm,
      'Equipment name': equipmentName,
    });
  }

  logAddWorker(assetCategory: string, assetFirm: string, isZpsUser?: boolean) {
    this.log(MixpanelEvent.ADD_WORKER, {
      'Asset category': assetCategory,
      'Asset firm': assetFirm,
      'ZPS user': !!isZpsUser,
    });
  }

  logChangeSite(siteName: string, siteResName: string) {
    this.log(MixpanelEvent.CHANGE_SITE, {
      'Site name': siteName,
      'Site resource name': siteResName,
    });
  }

  logLogin() {
    this.log(MixpanelEvent.LOGIN);
  }

  logLogout() {
    this.log(MixpanelEvent.LOGOUT, undefined, {
      send_immediately: true,
    });
  }

  logRunHistory(startTime: string, endTime: string, assetCount: number, historyCount: number) {
    this.log(MixpanelEvent.RUN_HISTORY, {
      'Asset count': assetCount,
      'End time': endTime,
      'History points': historyCount,
      'Start time': startTime,
    });
  }

  logSelectSite(siteName: string, siteResName: string) {
    this.log(MixpanelEvent.SELECT_SITE, {
      'Site name': siteName,
      'Site resource name': siteResName,
    });
  }

  reset() {
    if (this.mpInitialized()) {
      mp.reset();
    }
  }
}

export const mixpanelService = new MixpanelService();
