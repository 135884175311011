import { FC } from 'react';

import { translate } from '@/i18n';
import { NoData } from '@/shared/components/ui/NoData';
import { classnames } from '@/shared/utils/classnames';

import { NotFoundProps } from '@/modules/Reports/types/types';

export const NotFound: FC<NotFoundProps> = ({ className, secondary, ...props }) => {
  return (
    <div
      className={classnames('h-full w-full rounded-lg border border-slate-200 p-8', className)}
      {...props}
    >
      <NoData
        className="mt-0 h-full"
        subtitle={secondary ? undefined : translate('assets.noReportsMessage')}
        title={secondary ? 'Data is empty' : translate('assets.noReportsTitle')}
      />
    </div>
  );
};
