import { ReactNode } from 'react';

import { Badge } from '@/shared/components/ui/Badge';
import { Typography } from '@/shared/components/ui/Typography';
import {
  CategorySubTypes,
  CategoryTypes,
  useTableAccordions,
} from '@/shared/hooks/table/useTableAccordions.tsx';
import { classnames } from '@/shared/utils/classnames.ts';
import { generateId } from '@/shared/utils/helpers.ts';
import { secondsToHHMM } from '@/shared/utils/seconds-transform.ts';

import { TimeInZone } from '@/shared/types/reports/analytics.types.ts';

type Props<K> = {
  items: (data: K) => ReactNode;
  times: TimeInZone[];
  currentExtraGroup: string;
  geofenceHeaders: string[];
};

export function TimeZoneAccordsBody<T extends Record<string, string>, K>({
  currentExtraGroup,
  geofenceHeaders,
  items,
  times,
}: Readonly<Props<K>>) {
  const { currentCategories, handleToggleAccordion } = useTableAccordions('all');

  const renderCustomMeta = <T extends Record<string, string>>(args: T, flow: 'subs' | 'types') => {
    const filteredTimesCount = times.filter(asset =>
      flow === 'types'
        ? asset.categoryTreeResName === args.categoryTreeResName
        : asset.categoryPathResName === args.categoryPathResName,
    );

    const typeTimesMap = new Map<string, number>();
    const subTimesMap = new Map<string, number>();

    times.forEach(asset => {
      if (asset.categoryTreeResName === args.categoryTreeResName) {
        asset.geofenceResResNames.forEach(geofence => {
          const currentTotal = typeTimesMap.get(geofence.geofenceResResName) || 0;
          typeTimesMap.set(geofence.geofenceResResName, currentTotal + geofence.totalTime);
        });
      }

      if (asset.categoryPathResName === args.categoryPathResName) {
        asset.geofenceResResNames.forEach(geofence => {
          const currentTotal = subTimesMap.get(geofence.geofenceResResName) || 0;
          subTimesMap.set(geofence.geofenceResResName, currentTotal + geofence.totalTime);
        });
      }
    });

    const geofences = [
      ...new Set(
        times.flatMap(asset =>
          asset.geofenceResResNames.map(geofence => geofence.geofenceResResName),
        ),
      ),
    ];

    let calculatedTypeAllTimes = 0;
    const totalTypeTimes = geofences.map(geofence => {
      const totalTime = typeTimesMap.get(geofence) || 0;
      calculatedTypeAllTimes += totalTime;

      return (
        <Typography className="pl-[22px] text-start" key={geofence + generateId()}>
          {secondsToHHMM(totalTime)}
        </Typography>
      );
    });

    let calculatedSubAllTimes = 0;
    const totalSubTimes = geofences.map(geofence => {
      const totalTime = subTimesMap.get(geofence) || 0;
      calculatedSubAllTimes += totalTime;

      return (
        <Typography className="pl-[38px] text-start" key={geofence + generateId()}>
          {secondsToHHMM(totalTime)}
        </Typography>
      );
    });

    const wrapperClassName = `grid w-full items-center text-sm max-w-[calc(100%-240px)] ml-auto`;

    return (
      <>
        <Badge className="bg-blue-100 text-[10px] text-blue-600" variant="solid">
          {filteredTimesCount.length || 0}
        </Badge>

        {currentExtraGroup === 'time' &&
          flow === 'types' &&
          filteredTimesCount.length > 0 &&
          totalTypeTimes.length > 0 &&
          geofenceHeaders.length > 0 && (
            <div
              className={wrapperClassName}
              style={{
                gridTemplateColumns: `repeat(${geofenceHeaders.length + 1}, minmax(0, 1fr))`,
              }}
            >
              <Typography className="pl-[22px] text-start">
                {secondsToHHMM(calculatedTypeAllTimes)}
              </Typography>

              {totalTypeTimes}
            </div>
          )}

        {currentExtraGroup === 'time' &&
          flow === 'subs' &&
          filteredTimesCount.length > 0 &&
          geofenceHeaders.length > 0 && (
            <div
              className={classnames(wrapperClassName, 'max-w-[calc(100%-207px)]')}
              style={{
                gridTemplateColumns: `repeat(${geofenceHeaders.length + 1}, 1fr)`,
              }}
            >
              <Typography className="pl-[38px] text-start">
                {secondsToHHMM(calculatedSubAllTimes)}
              </Typography>

              {totalSubTimes}
            </div>
          )}
      </>
    );
  };

  return (
    <CategoryTypes
      currentCategories={currentCategories}
      handleToggleAccordion={handleToggleAccordion}
      meta={(category: T) => renderCustomMeta(category, 'types')}
      replacedWithPeople
    >
      {category => (
        <CategorySubTypes
          category={category}
          handleToggleAccordion={handleToggleAccordion}
          items={(subCategory: K) => items(subCategory)}
          meta={(pathList: T) => renderCustomMeta(pathList, 'subs')}
        />
      )}
    </CategoryTypes>
  );
}
