import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { profileFormSchema } from '@/shared/schemas/validate-schemas.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { Inputs } from '@/shared/types/global/common.types.ts';

export const useProfileForm = () => {
  const { user } = useFetchUserData();

  const setAuthorized = useGlobalStore(state => state.setAuthorized);

  const form = useForm<Inputs>({
    mode: 'onChange',
    resolver: zodResolver(profileFormSchema),
  });

  const isButtonDisabled = Object.keys(form.formState.errors).length > 0;

  useEffect(() => {
    if (user)
      form.reset({
        email: user.email,
        family_name: user.lastName,
        given_name: user.firstName,
      });
  }, [form, form.reset, user]);

  return { ...form, isButtonDisabled, setAuthorized, user };
};
