import { useRef, useState } from 'react';

import { useCursor } from '@react-three/drei';
import { Mesh } from 'three';

export const useMapModelDetails = () => {
  const ref = useRef<Mesh>(null);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  useCursor(isHovered);

  return { isHovered, ref, setIsHovered };
};
