import { FC, HTMLAttributes } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { usePagesHideGroupsZonesFlag } from '@/shared/feature-flags/usePagesHideGroupsZonesFlag.ts';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';
import { MISSING_DATA } from '@/shared/utils/constants.ts';

import Edit from '@/assets/icons/edit-sm.svg?react';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { PopupTypeEnum, RolesEnum } from '@/shared/types/global/enums.ts';

export interface EquipmentCardProps extends HTMLAttributes<HTMLDivElement> {
  equipment: AssetBody;
  renderItemVisibility: (list: RolesEnum[]) => boolean;
}

export const EquipmentCard: FC<EquipmentCardProps> = ({
  className,
  equipment,
  renderItemVisibility,
  ...props
}) => {
  const siteTagsList = useGlobalStore(store => store.siteTagsList);
  const geofences = useGlobalStore(store => store.geofences);

  const { currentCategory, currentCategoryPath, renderExtraPropertyField } =
    useAssetsCategories(equipment);
  const { pagesHideGroupsZonesFlag } = usePagesHideGroupsZonesFlag();
  const { handler } = useToggleModal();

  const currentDropOff = geofences.find(
    geofence => geofence.geofenceResName === renderExtraPropertyField('dropOff')?.value,
  );
  const currentTag = siteTagsList.find(
    tag => tag.tagResName === renderExtraPropertyField('tag')?.value,
  );

  const isRentalCompanyVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
  ]);
  const isPriceVisible = renderItemVisibility([RolesEnum.MANAGER]);

  const extendedDetails = [
    {
      id: 'type',
      isHidden: false,
      label: translate('filters.type'),
      value: currentCategory?.categoryTreeName ?? MISSING_DATA,
    },
    {
      id: 'subType',
      isHidden: false,
      label: translate('filters.subType'),
      value: currentCategoryPath?.categoryPath ?? MISSING_DATA,
    },
    {
      id: 'availability',
      isHidden: false,
      label: translate('global.availability'),
      value: equipment.assetStatus === 'active' ? 'Available' : 'Unavailable',
    },
    {
      id: 'rentalCompany',
      isHidden: false,
      label: translate('global.rentalCompany'),
      value: isRentalCompanyVisible
        ? renderExtraPropertyField('rentalCompany')?.value
        : MISSING_DATA,
    },
    {
      id: 'price',
      isHidden: false,
      label: translate('global.price'),
      value: isPriceVisible ? renderExtraPropertyField('price')?.value : MISSING_DATA,
    },
    {
      id: 'groups',
      isHidden: pagesHideGroupsZonesFlag.enabled,
      label: translate('global.groups'),
      value: MISSING_DATA,
    },
    {
      id: 'tags',
      isHidden: false,
      label: translate('global.tags'),
      value: currentTag?.tagName ?? MISSING_DATA,
    },
    {
      id: 'dropOff',
      isHidden: false,
      label: translate('global.dropOff'),
      value: currentDropOff?.geofenceName ?? MISSING_DATA,
    },
  ];

  return (
    <div
      className={classnames(
        'flex h-full w-full flex-col gap-y-2 rounded-lg border-[1px] border-slate-200 bg-white',
        className,
      )}
      {...props}
    >
      <div className="flex flex-col divide-y divide-slate-200">
        {extendedDetails.map(detail => {
          if (detail.isHidden) return null;

          return (
            <div
              className="grid h-[44px] grid-cols-[150px_1fr] items-center last:border-transparent"
              key={detail.id}
            >
              <Typography className="px-4 text-sm">{detail.label}</Typography>
              <Typography className="truncate px-4 text-sm">{detail.value}</Typography>
            </div>
          );
        })}

        <Button
          className="inline-flex h-[38px] gap-x-2 rounded-t-none border-0 border-t border-slate-200"
          onClick={() => {
            handler(PopupTypeEnum.EQUIPMENT_EDIT, equipment);
          }}
          variant="outline"
        >
          <Edit />
          {translate('global.edit')}
        </Button>
      </div>
    </div>
  );
};
