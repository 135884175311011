import { FC, HTMLAttributes, ReactNode } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type Props = WithChildren &
  HTMLAttributes<HTMLButtonElement> & {
    className?: string;
    color?: 'black' | 'blue' | 'gray' | 'green' | 'red';
    rounded?: 'full' | 'md';
    variant: 'custom' | 'outline' | 'solid';
    isLoading?: boolean;
  };

const renderAriaDetails = (children: ReactNode) => {
  const childrenType = typeof children;
  const isString = childrenType === 'string';
  const isNumber = childrenType === 'number';
  const isNotNumber = !Number.isNaN(Number(children));

  return (isString && isNotNumber) || isNumber ? 'badge counter' : 'badge label';
};

export const Badge: FC<Props> = ({
  children,
  className,
  color = 'black',
  rounded = 'full',
  variant,
  ...props
}) => {
  const ariaDetails = renderAriaDetails(children);

  if (variant === 'custom')
    return (
      <Typography as="span" className={classnames(className)} {...props}>
        {children}
      </Typography>
    );

  return (
    <Typography
      aria-describedby="badge"
      aria-details={ariaDetails}
      as="span"
      className={classnames(
        'inline-flex min-h-4 items-center gap-x-1 px-1.5 text-[12px] font-medium leading-4 tracking-[0.05px] shadow-sm',
        {
          '!border-green-600 bg-transparent !font-bold !text-green-600':
            variant === 'outline' && color === 'green',

          'bg-black': variant === 'solid' && color === 'black',

          'bg-blue-100 text-blue-600': variant === 'solid' && color === 'blue',

          'bg-green-600': variant === 'solid' && color === 'green',

          'bg-red-600 px-1.5 py-[1px]': variant === 'solid' && color === 'red',

          'bg-slate-100 text-slate-600': variant === 'solid' && color === 'gray',

          'border-slate-400 text-slate-800': variant === 'outline' && color === 'gray',

          'border border-slate-400 bg-transparent text-slate-800': variant === 'outline',
          'rounded-full': rounded === 'full',

          'rounded-md': rounded === 'md',

          'text-white': variant === 'solid' && color !== 'blue',
        },
        className,
      )}
      {...props}
    >
      {children}
    </Typography>
  );
};
