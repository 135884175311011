import { FC } from 'react';

import { MobileList } from '@/shared/components/ui/MobileList';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { classnames } from '@/shared/utils/classnames.ts';

import { ReservationMobileCard } from '../ReservationMobileCard/ReservationMobileCard';

import { WithClassName } from '@/shared/types/global/common.types';
import { ReservationType } from '@/shared/types/global/reservations.types';

type Props = WithClassName & {
  reservations: ReservationType[];
  isLoading: boolean;
};

export const ReservationMobileList: FC<Props> = ({ className, isLoading, reservations }) => {
  if (isLoading) {
    return <Skeleton count={3} subTitleCount={4} variant="mobile-card" withStatus withTitle />;
  }

  return (
    <MobileList className={classnames(className)}>
      {reservations.map(reservation => (
        <ReservationMobileCard key={reservation.assetResName} reservation={reservation} />
      ))}
    </MobileList>
  );
};
