/* eslint-disable perfectionist/sort-objects */
import { useNavigate, useSearchParams } from 'react-router-dom';

import { URL_PARAMS } from '@/shared/utils/constants';

import { ActionCategoryEnum } from '@/shared/types/actions/actions.types.ts';
import { RoutesEnum } from '@/shared/types/global/enums.ts';
import { SidebarParameters } from '@/shared/types/global/parameters.types.ts';

export const useActionPageDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateSearchParams = (key: string, value: string) => {
    setSearchParams(searchParams => {
      searchParams.set(key, value);
      return searchParams;
    });
  };

  const getTabIndex = () => {
    const actionUrlParameter = searchParams.get(URL_PARAMS.ACTIONS);

    if (actionUrlParameter) {
      const index = {
        [SidebarParameters.SYSTEM]: 1,
        [SidebarParameters.SAFETY]: 2,
        [SidebarParameters.EQUIPMENT]: 3,
        [SidebarParameters.ZONE]: 4,
        [SidebarParameters.ARCHIVE]: 5,
      }[actionUrlParameter];

      return index ?? 0;
    } else return 0;
  };

  const onTabChange = (tab: number) => {
    const sidebarParameter = {
      0: SidebarParameters.ALL,
      1: SidebarParameters.SYSTEM,
      2: SidebarParameters.SAFETY,
      3: SidebarParameters.EQUIPMENT,
      4: SidebarParameters.ZONE,
      5: SidebarParameters.ARCHIVE,
    }[tab];

    if (sidebarParameter) {
      updateSearchParams(URL_PARAMS.ACTIONS, sidebarParameter);
    }
  };

  const goToCurrentTab = (category: 'archive' | `${ActionCategoryEnum}`) => {
    switch (category) {
      case 'archive':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ARCHIVE);
        break;
      case 'equipment':
        navigate({
          pathname: RoutesEnum.ACTIONS,
          search: `?${URL_PARAMS.ACTIONS}=${SidebarParameters.EQUIPMENT}`,
        });
        break;
      // case 'Reservation':
      //   updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.RESERVATION);
      //   break;
      case 'safety':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.SAFETY);
        break;
      case 'system':
        navigate({
          pathname: RoutesEnum.ACTIONS,
          search: `?${URL_PARAMS.ACTIONS}=${SidebarParameters.SYSTEM}`,
        });
        break;
      case 'zone':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ZONE);
        break;
      default:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ALL);
        break;
    }
  };

  return {
    getTabIndex,
    goToCurrentTab,
    onTabChange,
    searchParams,
    updateSearchParams,
  };
};
