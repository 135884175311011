import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { translate } from '@/i18n';
import { FormWrapper } from '@/modules/Settings/components/common/FormWrapper';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { Card } from '@/shared/components/ui/Card';
import { Typography } from '@/shared/components/ui/Typography';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import * as schemas from '@/shared/schemas/validate-schemas';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages';
import { attributesList } from '@/shared/utils/helpers';

import Bell from '@/assets/icons/bell.svg?react';
import { SettingsFormProps } from '@/modules/Settings/types/types.ts';
import { Inputs } from '@/shared/types/global/common.types.ts';

export const ChangeEmailForm: FC<SettingsFormProps> = ({ isEditable }) => {
  const [isSuccessfullySendCode, setIsSuccessfullySendCode] = useState<boolean>(false);

  const { user } = useFetchUserData();

  const setAuthorized = useGlobalStore(state => state.setAuthorized);

  const {
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: zodResolver(
      isSuccessfullySendCode ? schemas.verifyEmailFormSchema : schemas.changeEmailFormSchema,
    ),
  });

  const isButtonDisabled = Object.keys(errors).length > 0;

  useEffect(() => {
    if (user && !getValues('email'))
      reset({
        email: user?.email,
      });
  }, [getValues, reset, user]);

  const handleSendConfirmationCode = async (data: Inputs) => {
    if (user?.username) {
      try {
        const attributes = attributesList(data);

        await cognitoUserPoolService.updateUserAttributes(attributes, user.username);

        setIsSuccessfullySendCode(true);
      } catch (error) {
        console.error('[error]:', error);
        notifyService.error(ERROR_MESSAGES.UPDATE_USER_FAILED);
      }
    }
  };

  const handleConfirmEmail = async (data: Inputs) => {
    if (user?.username) {
      try {
        await cognitoUserPoolService.verifyAttribute(data?.confirmationCode, user.username);

        setIsSuccessfullySendCode(false);
        setAuthorized(true);
      } catch (error: unknown) {
        console.error('[error]:', error);
      } finally {
        reset({
          confirmationCode: '',
        });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      className="flex items-end gap-4"
      id="changeEmail"
      onSubmit={
        isSuccessfullySendCode
          ? handleSubmit(handleConfirmEmail)
          : handleSubmit(handleSendConfirmationCode)
      }
    >
      <FormWrapper className="grid-cols-1">
        <Label labelText={translate('settings.email')}>
          <Input
            {...register('email')}
            aria-label="email-textfield"
            defaultValue={user?.email}
            disabled={!isEditable}
            error={errors?.email?.message}
          />
        </Label>

        {isSuccessfullySendCode && (
          <>
            <Label labelText={translate('settings.confirmationCode')}>
              <Input
                {...register('confirmationCode')}
                aria-label="confirmation-code-textfield"
                error={errors?.confirmationCode?.message}
              />
            </Label>

            <Card className="flex w-full gap-x-4 p-4 align-baseline" role="alert">
              <Bell className="h-6 w-6 shrink-0 text-slate-950" />

              <Typography className="text-base text-slate-500">
                {translate('settings.changeEmailInfo')}
              </Typography>
            </Card>
          </>
        )}
      </FormWrapper>

      {isEditable && (
        <Button
          aria-label="change-email-action"
          data-confirm-email={isSuccessfullySendCode}
          disabled={isButtonDisabled || isSubmitting}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate(isSubmitting ? 'settings.saving' : 'settings.save')}
        </Button>
      )}
    </form>
  );
};
