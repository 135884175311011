import { useEffect, useState } from 'react';

import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';
import { LS_KEYS } from '@/shared/utils/constants.ts';
import { WSInterfacesNamespace } from '@/shared/ws/utils';

export const useConnectLocalSockets = () => {
  const [token, setToken] = useState<null | string>(null);
  const [socketUrl, setSocketUrl] = useState<null | string>(null);

  const connectionStatus = useSocketMapDataStore(state => state.connectionStatus);

  const userLocalStorageData = WebAPIStorageNamespace.LocalStorageService.getLocalItemParsed(
    LS_KEYS.USER_DATA,
  );
  const userSessionData = WebAPIStorageNamespace.SessionService.getSessionItemParsed(
    LS_KEYS.USER_DATA,
  );

  const storedUser = userSessionData ?? userLocalStorageData;

  // Set token from local storage and trigger re-render
  useEffect(() => {
    if (storedUser?.accessToken !== token) {
      setToken(storedUser?.accessToken);
    }
  }, [token, storedUser?.accessToken]);

  useEffect(() => {
    if (connectionStatus === 'UNINSTANTIATED' || connectionStatus === 'CLOSED') {
      const accessInfo = {
        access_key: storedUser?.accessKeyId ?? '',
        secret_key: storedUser?.secretAccessKey ?? '',
        session_token: storedUser?.sessionToken ?? '',
      };
      const socketUrl = WSInterfacesNamespace.generateSocketSigner(accessInfo);

      setSocketUrl(socketUrl);
    }
  }, [
    storedUser?.accessKeyId,
    storedUser?.secretAccessKey,
    storedUser?.sessionToken,
    connectionStatus,
  ]);

  return {
    socketUrl,
  };
};
