import { useEffect } from 'react';

import Intercom, { boot, onShow, show } from '@intercom/messenger-js-sdk';

import { useIntercomFeatureFlag } from '@/shared/feature-flags/useIntercomFeatureFlag';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';

export function useIntercomInitialize() {
  const { user } = useFetchUserData();
  const { intercomImplementation } = useIntercomFeatureFlag();

  const credential = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    custom_attributes: { role: user?.role },
    email: user?.email,
    name: `${user?.firstName} ${user?.lastName}`,
    user_id: user?.userResourceName,
  };

  useEffect(() => {
    if (user?.firstName && intercomImplementation?.enabled) {
      Intercom(credential);
    }
  }, [user, intercomImplementation]);

  const handleBootIntercom = () => {
    boot(credential);
    show();
    onShow(() => {
      show();
    });
  };

  return { handleBootIntercom, intercomImplementation };
}
