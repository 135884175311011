import { SystemViewEnum } from '@/shared/types/settings/system.types.ts';

export enum GeofenceCategoryEnum {
  INACTIVE = 'inactive',
  RED_ZONE = 'red-zone',
  WORK_ZONE = 'work-zone',
}

export type GetGeofencesServiceProps = {
  setGeofences: (geofences: GeofencesProps[], isFetching: boolean) => void;
  setCurrentGeofenceId: (id: string) => void;
};

export type CreateGeofencesServiceProps = {
  geofenceCategory: GeofenceCategoryEnum;
  geofenceName: string;
  points: Point[];
  maxHeight: number;
  systemView: SystemViewEnum;
};

export type UpdateGeofencesServiceProps = CreateGeofencesServiceProps & {
  geofenceResName: string;
  status: GeofenceStatusEnum;
  systemView: SystemViewEnum;
};

export type UpdateGeofenceServiceResponse = {
  geofenceResName: string;
  geofenceName: string;
  geofenceCategory: string;
  status: string;
  floorResName: string;
  creationTime: {
    epochTime: number;
  };
  lastUpdateTime: {
    epochTime: number;
  };
};

export type GeofencesRootProps = {
  page: GeofencesPage;
  results: GeofencesProps[];
};

export type GeofencesPage = {
  pageItemsCount: number;
  hasNext: boolean;
};

export type GeofencesProps = {
  geofenceCategory: GeofenceCategoryEnum;
  geofenceResName: string;
  geofenceName: string;
  status?: GeofenceStatusEnum;
  floorResName: string;
  polygon?: Polygon;
  circle?: Circle;
  operationalPeriod?: OperationalPeriod;
  localTimezoneOffset?: number;
  creationTime?: number;
  lastUpdateTime?: number;
  visible?: boolean;
  selected?: boolean;
  id?: string;
};

export enum GeofenceStatusEnum {
  ACTIVE = 'active',
  DELETED = 'deleted',
  INACTIVE = 'inactive',
}

export type Polygon = {
  points: Point[];
  maxHeight?: number;
};

export type Point = {
  x: number | string;
  y: number | string;
  z: number | string;
};

export interface Circle {
  center: Center;
  maxHeight: number;
  radius: number;
}

export interface Center extends Point {}

export type OperationalPeriod = {
  periodStartDate: PeriodDateProps;
  periodEndDate: PeriodDateProps;
  operatingDaysOfWeek: string[];
  operatingDayStartTime: string;
  operatingDayEndTime: string;
};

export type PeriodDateProps = {
  epochTime: number;
};

export type CoordinateValueInputProps = {
  className?: string;
  error?: string;
  defaultValue: null | Omit<Point, 'z'> | undefined;
  onChange: (defaultValue: Omit<Point, 'z'>) => void;
};
