import { useSearchParams } from 'react-router-dom';

import { useAnalyticsHooks } from '@/shared/api/analytics/analytics.hooks.ts';
import { useGeofencesHooks } from '@/shared/api/geofences/geofences.hooks';
import { useSystemTimeZone } from '@/shared/hooks/reports/useSystemTimeZone.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { initializePickerTime } from '@/shared/utils/initialize-picker-time.ts';

import { SlicedTimeInZone, TimeInZone } from '@/shared/types/reports/analytics.types.ts';

export function useZoneEntryExit() {
  const [searchParameters] = useSearchParams();

  const dateParameter = searchParameters.get(URL_PARAMS.MULTI_DATE);
  const assetParameter = searchParameters.get(URL_PARAMS.ASSET);
  const multiZones = searchParameters.get(URL_PARAMS.MULTI_ZONES);

  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const { transformTimeToEpoch } = useSystemTimeZone();
  const { initialEndTime, initialStartTime } = initializePickerTime(dateParameter);
  const { endTime, startTime } = transformTimeToEpoch(initialStartTime, initialEndTime, 'ZEE');

  const { data: geofences = [] } = useGeofencesHooks.getAll();
  const { data, isLoading } = useAnalyticsHooks.getGeofenceSummaries({ endTime, startTime });

  const initializedItems = (data?.results as unknown as TimeInZone[]) || [];

  const combinedMultiZones = multiZones?.split(',').filter(item => item !== '') ?? [];
  const filteredResult = initializedItems?.filter(item => {
    if (combinedMultiZones.length > 0)
      return combinedMultiZones.includes(String(item.geofenceResResName));

    return true;
  });

  const modifiedInitializedItems = filteredResult?.flatMap(item =>
    item.episodes.map(episode => {
      const currentGeofence = geofences.find(
        geofence => geofence.geofenceResName === item.geofenceResResName,
      );

      return {
        asset: item.asset,
        episode: {
          dwellTime: episode.dwellTime,
          entryTime: episode.entryTime,
          exitTime: episode.exitTime,
        },
        geofenceName: currentGeofence?.geofenceName ?? '-',
        geofenceResName: currentGeofence?.geofenceResName ?? '-',
      };
    }),
  ) as unknown as SlicedTimeInZone[];

  const filteredFormedResult = modifiedInitializedItems.filter(item =>
    item.asset.assetName.toLowerCase().includes(searchFieldValue.toLowerCase()),
  );

  const finalFilteredResult = assetParameter
    ? filteredFormedResult.filter(item => item.asset.assetResName === assetParameter)
    : filteredFormedResult;

  return {
    isItemsLoading: isLoading,
    items: finalFilteredResult,
  };
}
