import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { AssetsUpdateRequest } from '@/shared/api/assets/assets.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useToggleAssetsStatusInBatch = () => {
  const queryClient = useQueryClient();

  const site = useGlobalStore(store => store.site);
  const setBatchedWorkers = useGlobalStore(state => state.setBatchedWorkers);

  const { user } = useFetchUserData();

  const { isPending, mutate } = useMutation({
    mutationFn: async (assets: AssetBody[]) => {
      notifyService.loading('Updating assets status...', { duration: 3000 });
      await Promise.all(
        assets.map(async asset => {
          const requestBody: AssetsUpdateRequest = {
            accountResName: user?.accountResourceName ?? '',
            assetName: asset?.assetName,
            assetResName: asset?.assetResName,
            assetStatus: asset.assetStatus === 'active' ? 'inactive' : 'active',
            categoryPathResName: asset?.categoryPathResName,
            categoryTreeResName: asset?.categoryTreeResName,
            siteResName: site,
          };
          await AssetsAPINamespace.updateOne(requestBody);
        }),
      );
    },

    mutationKey: [QUERY_KEY.TOGGLE_ASSET_STATUS_BATCH],

    onError: error => {
      notifyService.error('Failed to update assets status');
      console.error(error);
    },

    onSuccess: async () => {
      setBatchedWorkers([]);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.WORKERS],
      });
      notifyService.success('Assets status has been updated successfully');
    },
  });

  return {
    isPending,
    mutate,
  };
};
