import { useMutation } from '@tanstack/react-query';

import { AppsBootstrapAPINamespace } from '@/shared/api/apps-bootstrap/apps-bootstrap.requests.ts';
import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service.ts';
import { LS_KEYS, QUERY_KEY } from '@/shared/utils/constants.ts';

export namespace useAppsBootstrapHooks {
  export const getUserPool = () => {
    const { mutateAsync: getUserPool } = useMutation({
      mutationFn: async (args: { accountName: string }) => {
        const userPool = await AppsBootstrapAPINamespace.getAll(args.accountName);
        const awsRegion = userPool.identityPoolId.split(':').shift();

        WebAPIStorageNamespace.LocalStorageService.setLocalItem(
          LS_KEYS.CLIENT_ID,
          userPool.clientId,
          false,
        );

        WebAPIStorageNamespace.LocalStorageService.setLocalItem(
          LS_KEYS.IDENTITY_POOL_ID,
          userPool.identityPoolId,
          false,
        );

        WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.REGION, awsRegion, false);

        WebAPIStorageNamespace.LocalStorageService.setLocalItem(
          LS_KEYS.USER_POOL_ID,
          userPool.userPoolId,
          false,
        );

        WebAPIStorageNamespace.LocalStorageService.setLocalItem(
          LS_KEYS.ACCOUNT_RES_NAME,
          userPool.accountResName,
          false,
        );

        cognitoUserPoolService.userPoolOptions = {
          accountResName: userPool.accountResName,
          awsRegion: awsRegion as string,
          clientId: userPool.clientId,
          identityPoolId: userPool.identityPoolId,
          userPoolId: userPool.userPoolId,
        };

        return userPool;
      },

      mutationKey: [QUERY_KEY.USER_POOL],

      retry: false,
    });

    return { getUserPool };
  };
}
