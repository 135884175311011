import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { Select } from '@/shared/components/form/Select';
import { selectCountList } from '@/shared/components/tables/Pagination/data.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { useTablePagination } from '@/shared/hooks/table/useTablePagination.ts';
import { classnames } from '@/shared/utils/classnames.ts';
import { PAGINATION } from '@/shared/utils/constants.ts';

import { PaginationProps } from './Pagination.types.ts';

import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import ArrowRight from '@/assets/icons/arrow-right.svg?react';

export function Pagination<T>({
  customPageSize,
  perPageCount,
  setPageIndex,
  setPageSize,
  table,
  ...props
}: Readonly<PaginationProps<T>>) {
  const { isNextNavigationDisabled, isPrevNavigationDisabled, step } = useTablePagination({
    perPageCount,
    table,
  });
  const isMobile = useMedia('(max-width: 767px)');

  const renderPages = () => {
    const pageCount = table.getPageCount() + 1;
    const midRange = 5;
    let startPage = Math.max(0, step - Math.floor(midRange / 2));
    const endPage = Math.min(pageCount - 1, startPage - 1);

    if (endPage - startPage + 1 < midRange) {
      startPage = Math.max(0, endPage - midRange + 1);
    }

    return Array.from({ length: midRange }).map((_, index) => {
      const currentPageIndex = startPage + index + 1;

      if (currentPageIndex >= 0 && currentPageIndex <= pageCount - 1)
        return (
          <button
            className={classnames(
              'flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-lg text-sm font-bold text-slate-800 duration-300',
              {
                'bg-slate-800 text-white': step === currentPageIndex,
              },
            )}
            key={currentPageIndex}
            onClick={() => {
              handleSteps(currentPageIndex);
            }}
          >
            {currentPageIndex}
          </button>
        );

      return null;
    });
  };

  const handleSteps = (step: number) => {
    setPageIndex(step);
  };

  return (
    <div {...props} className="inline-flex w-full justify-between gap-2">
      <div className="inline-flex">
        <button
          className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2.5 rounded-md p-2.5 text-sm font-medium text-[#1e314d] duration-300"
          disabled={isPrevNavigationDisabled}
          onClick={() => handleSteps(table.getState().pagination.pageIndex - 1)}
        >
          <ArrowRight />
        </button>

        {renderPages()}

        <button
          className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2.5 rounded-md p-2.5 text-sm font-medium text-[#1e314d] duration-300"
          disabled={isNextNavigationDisabled}
          onClick={() => handleSteps(table.getState().pagination.pageIndex + 1)}
        >
          <ArrowLeft />
        </button>
      </div>

      <div className="inline-flex items-center gap-x-3">
        {!isMobile && (
          <Typography className="font-sm w-full font-medium">
            {translate('global.showPerPage')}:
          </Typography>
        )}

        <div>
          <Select
            className="w-[82px] bg-white"
            data={selectCountList}
            defaultValue={customPageSize?.toString() ?? PAGINATION.PAGE_SIZE.toString()}
            onChange={value => {
              setPageSize(Number(value));
              setPageIndex(1);
            }}
            reversed
            variant="default"
          />
        </div>
      </div>
    </div>
  );
}
