import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export const PageHeader: FC<WithChildren & WithClassName> = ({ children, className }) => {
  return (
    <div className={classnames('mb-3 flex flex-wrap items-center justify-between', className)}>
      {children}
    </div>
  );
};
