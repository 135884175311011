import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import { translate } from '@/i18n';
import { ArchiveFilters } from '@/modules/Actions/components/ArchiveFilters.tsx';
import { alertSeveritySortOrder, localizedSectionHeading } from '@/modules/Actions/utils';
import { actionIsAlert } from '@/shared/api/actions/actions.utils.ts';
import { FilterSelect } from '@/shared/components/form/FilterSelect';
import { Select } from '@/shared/components/form/Select';
import { Pagination } from '@/shared/components/navigation/Pagination';
import { ActionCard } from '@/shared/components/ui/ActionCard';
import { Loader } from '@/shared/components/ui/Loader';
import { NoData } from '@/shared/components/ui/NoData';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';

import { DisclosureItemProps } from '@/modules/Actions/types/types.ts';
import {
  ActionCategoryEnum,
  ActionSortCriteriaEnum,
  AlertProps,
} from '@/shared/types/actions/actions.types.ts';
import { FilterSelectNameEnum } from '@/shared/types/global/filters.types.ts';

type Props = Pick<DisclosureItemProps, 'items'> & {
  category: 'archive' | `${ActionCategoryEnum}`;
  withPagination?: boolean;
  withInfiniteScroll?: {
    hasNextPage: boolean;
    isLoading: boolean;
    onFetchNextPage: () => void;
    overflowScrollElement: HTMLDivElement;
  };
};

export const FullList = ({ category, items = [], withInfiniteScroll, withPagination }: Props) => {
  const [pageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const infiniteTriggerRef = useRef(null);

  const alertFilters = useGlobalStore(store => store.alertsFilters);
  const setAlertFilters = useGlobalStore(store => store.setAlertsFilters);

  const infiniteTriggerIntersection = useIntersection(infiniteTriggerRef, {
    root: withInfiniteScroll?.overflowScrollElement,
    rootMargin: '0px',
    threshold: 1,
  });

  const categoryFilterParams = useMemo(() => {
    switch (category) {
      case 'equipment':
      case 'reservation': {
        return {
          filterItems: alertFilters.equipment,
          filterName: FilterSelectNameEnum.ACTION_EQUIPMENT_SUBCATEGORY,
          filterUpdate: (value: string[]) => setAlertFilters({ equipment: value as never }),
        };
      }
      case 'safety': {
        return {
          filterItems: alertFilters.safety,
          filterName: FilterSelectNameEnum.ACTION_SAFETY_SUBCATEGORY,
          filterUpdate: (value: string[]) => setAlertFilters({ safety: value as never }),
        };
      }
      case 'system': {
        return {
          filterItems: alertFilters.system,
          filterName: FilterSelectNameEnum.ACTION_SYSTEM_SUBCATEGORY,
          filterUpdate: (value: string[]) => setAlertFilters({ system: value as never }),
        };
      }
      case 'zone': {
        return {
          filterItems: alertFilters.zone,
          filterName: FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY,
          filterUpdate: (value: string[]) => setAlertFilters({ zone: value as never }),
        };
      }
      default: {
        return {
          filterItems: [],
          filterName: FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY,
          filterUpdate: (_: string[]) => {},
        };
      }
    }
  }, [alertFilters, category, setAlertFilters]);

  const paginatedItems = useMemo(() => {
    return [...items]
      .filter(item => {
        if (actionIsAlert(item)) {
          const alert = item as AlertProps;
          if (categoryFilterParams.filterItems.length === 0) return true;
          return categoryFilterParams.filterItems.includes(alert.attribute);
        } else return true;
      })
      .sort((a, b) => {
        if (actionIsAlert(a)) {
          const alertA = a as AlertProps;
          const alertB = b as AlertProps;

          if (alertFilters.sort === ActionSortCriteriaEnum.MOST_IMPORTANT) {
            return alertA.alertSeverity === alertB.alertSeverity
              ? alertB.timestamp - alertA.timestamp
              : alertSeveritySortOrder[alertB.alertSeverity] -
                  alertSeveritySortOrder[alertA.alertSeverity];
          } else if (alertFilters.sort === ActionSortCriteriaEnum.MOST_RECENT) {
            return alertB.timestamp - alertA.timestamp;
          } else return 0;
        } else return 0;
      })
      .filter((_, index) => {
        if (!withPagination) return true;

        const pageStart = (currentPage - 1) * pageSize;
        const pageEnd = currentPage * pageSize;
        return pageStart <= index && index < pageEnd;
      });
  }, [
    alertFilters.sort,
    categoryFilterParams.filterItems,
    items,
    currentPage,
    pageSize,
    withPagination,
  ]);

  useEffect(() => {
    if (alertFilters) {
      setCurrentPage(1);
    }
  }, [alertFilters]);

  if (items.length <= 0) {
    return <NoData title={translate('actions.noActionsFound')} />;
  }

  return (
    <div className="flex w-full flex-col gap-1.5">
      <Typography className="text-[18px] font-medium">
        {localizedSectionHeading(category)}
      </Typography>

      {category === 'archive' ? (
        <ArchiveFilters />
      ) : (
        <div className="flex flex-wrap gap-3 py-2">
          <Select
            className="bg-white min-w-40"
            data={[
              {
                id: ActionSortCriteriaEnum.MOST_IMPORTANT,
                name: translate('actions.mostImportant'),
              },
              { id: ActionSortCriteriaEnum.MOST_RECENT, name: translate('actions.mostRecent') },
            ]}
            defaultValue={alertFilters.sort}
            onChange={sort => setAlertFilters({ sort: sort as ActionSortCriteriaEnum })}
            variant="default"
          />

          <FilterSelect
            asSelect
            className="min-w-40"
            name={categoryFilterParams.filterName}
            onChange={categoryFilterParams.filterUpdate}
            value={categoryFilterParams.filterItems}
          />
        </div>
      )}

      <div className="flex w-full flex-col gap-3">
        {paginatedItems?.map(item => (
          <ActionCard
            action={item}
            buttonPlacement={category === 'archive' ? undefined : 'row'}
            key={item.alertResName}
          />
        ))}
      </div>

      {withPagination && (
        <Pagination
          className="justify-center"
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          totalCount={items.length}
        />
      )}

      {withInfiniteScroll && (
        <InfiniteScrollTrigger
          fullyInView={
            infiniteTriggerIntersection
              ? infiniteTriggerIntersection.intersectionRatio === 1
              : false
          }
          hasNextPage={withInfiniteScroll.hasNextPage}
          isLoading={withInfiniteScroll.isLoading}
          onFullyInView={withInfiniteScroll.onFetchNextPage}
          ref={infiniteTriggerRef}
        />
      )}
    </div>
  );
};

interface InfiniteScrollTriggerProps {
  fullyInView: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  onFullyInView: () => void;
}

const InfiniteScrollTrigger = forwardRef<HTMLDivElement, InfiniteScrollTriggerProps>(
  ({ fullyInView, hasNextPage, isLoading, onFullyInView }: InfiniteScrollTriggerProps, ref) => {
    useEffect(() => {
      if (fullyInView && hasNextPage && !isLoading) onFullyInView();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullyInView]);

    return (
      <div className="mb-1 py-4" ref={ref}>
        {isLoading && (
          <div className="flex items-center justify-center [&_span]:!size-8 [&_span]:!text-blue-500">
            <Loader appearance="inline" />
          </div>
        )}

        {!hasNextPage && (
          <Typography className="text-center text-sm text-slate-500">
            {translate('actions.noMoreActionsToShow')}
          </Typography>
        )}
      </div>
    );
  },
);
