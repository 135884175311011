import { useLocation } from 'react-router-dom';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const useCurrentRoute = (): Record<string, boolean> => {
  const { pathname } = useLocation();

  const isLoginPage = pathname === RoutesEnum.LOGIN;
  const isHomePage = pathname === RoutesEnum.HOME;
  const isActionsPage = pathname === RoutesEnum.ACTIONS;
  const isSettingsPage = pathname === RoutesEnum.SETTINGS;

  return {
    isActionsPage,
    isHomePage,
    isLoginPage,
    isSettingsPage,
  };
};
