import Actions from '@/assets/icons/alerts.svg?react';
import Equipment from '@/assets/icons/equipment.svg?react';
import Location from '@/assets/icons/location.svg?react';
import Reports from '@/assets/icons/reports.svg?react';
import Requests from '@/assets/icons/requests.svg?react';
import Subcontractors from '@/assets/icons/subcontractors.svg?react';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const icons = {
  actions: Actions,
  equipment: Equipment,
  location: Location,
  reports: Reports,
  requests: Requests,
  subcontractors: Subcontractors,
};

export type NavLinkProps = {
  name: string;
  path: RoutesEnum;
  icon: keyof typeof icons;
  disabled: boolean;
  withCounter?: boolean;
  hasNewEvent?: boolean;
  onClick?: () => void;
};

export type NavbarProps = {
  className?: string;
  role?: 'navigation';
};
