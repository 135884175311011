import { baseQuery, signRequest } from '@/shared/api/utils';

import { UserPoolProps } from '@/shared/types/auth/cognito-user-pool.types.ts';

const APP_RES_NAME = 'default_client';

export namespace AppsBootstrapAPINamespace {
  const client = baseQuery('APPS_BOOTSTRAP');

  export const getAll = async (accountName: string): Promise<UserPoolProps> => {
    const url = `/accounts/${accountName}/apps/${APP_RES_NAME}/user-pools/clients`;
    const signedRequest = await signRequest('APPS_BOOTSTRAP', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
