import { ThreeEvent } from '@react-three/fiber';

import { useGlobalStore } from '@/shared/store/store.ts';

export const useClickOutsideMap = () => {
  const isCanvasPopupsOpen = useGlobalStore(state => state.isCanvasPopupsOpen);
  const setIsCanvasPopupsOpen = useGlobalStore(state => state.setIsCanvasPopupsOpen);
  // const closeActivePopups = useGlobalStore(store => store.closeActivePopups); //TODO: Remove this line after successful testing

  const handleCanvasClick = (event: MouseEvent | ThreeEvent<PointerEvent>) => {
    const targetElement = event.target as HTMLElement;
    const isHasParent = targetElement.closest?.('button');

    if (!isHasParent) {
      setIsCanvasPopupsOpen(false);
      // closeActivePopups(); //TODO: Remove this line after successful testing
    }
  };

  return {
    handleCanvasClick,
    isCanvasPopupsOpen,
    setIsCanvasPopupsOpen,
  };
};
