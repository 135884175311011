import { FC } from 'react';

import { Fade } from '@/shared/components/animation/Fade';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export const AuthWrapper: FC<WithChildren & WithClassName> = ({ children, className }) => {
  return (
    <Fade className={classnames('h-full')} triggerOnce>
      <div className="flex h-full min-h-full items-center justify-center bg-white md:bg-slate-100">
        <div
          className={classnames(
            'grid w-full max-w-[476px] gap-y-5 rounded-2xl border-slate-200 bg-white px-5 py-[30px] md:border',
            className,
          )}
        >
          {children}
        </div>
      </div>
    </Fade>
  );
};
