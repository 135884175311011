import { GroupsFormBody } from '@/shared/components/ui/Modal/Groups/FormBody.tsx';
import { useCreateGroup } from '@/shared/components/ui/Modal/Groups/hooks/useCreateGroup.ts';

export function CreateGroup() {
  const { params } = useCreateGroup();

  const props = {
    handler: () => null,
    params,
    type: 'create' as 'create' | 'edit',
  };

  return <GroupsFormBody {...props} />;
}
