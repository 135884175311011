import { EquipmentFormBody } from '@/shared/components/ui/Modal/Equipment/FormBody.tsx';
import { useEditEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEditEquipment.ts';

export function EditEquipment() {
  const { deleteMutate, deleteStates, mutate, params, states } = useEditEquipment();

  const props = {
    deleteMutate,
    deleteStates,
    mutate,
    params,
    states,
    type: 'edit' as 'create' | 'edit',
  };

  return <EquipmentFormBody {...props} />;
}
