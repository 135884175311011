import { ReactNode } from 'react';

import { flexRender, Row } from '@tanstack/react-table';

import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';
import { generateId } from '@/shared/utils/helpers.ts';

import Line from '@/assets/icons/line.svg?react';
import { UseTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

const timeLabels = ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'];

export function useTableChartBody<T>({
  contentRowType,
  extendedRow,
  rowClassName,
  table,
  withToggle,
}: UseTableBodyProps<T>) {
  const items = <K extends Record<string, string>>(
    data?: K,
    customRow?: (row: Row<T>) => ReactNode,
  ) => {
    const tableRows = table.getRowModel().rows;

    const filteredTableRows = data
      ? tableRows.filter(
          row => (row.original as AssetBody).categoryPathResName === data.categoryPathResName,
        )
      : tableRows;

    const renderMaxTimelines = () => {
      return (
        <TableData className="ml-[191px] h-[40px] border-l-[1px] border-slate-200 pl-[22px]">
          <div className="relative grid w-full grid-cols-[repeat(8,1fr)]">
            {timeLabels.map((label, index, array) => {
              const lastIndex = array.length - 1 === index;

              return (
                <div
                  className={classnames('flex flex-col items-start', {
                    'absolute right-0 items-end': lastIndex,
                  })}
                  key={label}
                >
                  <Typography className="text-xs font-medium text-slate-500">{label}</Typography>

                  <Typography className="text-slate-500">
                    <Line />
                  </Typography>
                </div>
              );
            })}
          </div>
        </TableData>
      );
    };

    return (
      <>
        {filteredTableRows.length > 0 && (
          <TableRow
            className={classnames(
              'h-[40px] border-b-[0px] bg-slate-50 py-0',
              !data && 'pl-4',
              rowClassName,
            )}
            ref={node => node}
          >
            {renderMaxTimelines()}
          </TableRow>
        )}

        {filteredTableRows.map(row => {
          if (customRow) return customRow(row);

          if (contentRowType === 'chart')
            return (
              <TableRow
                className={classnames(
                  'h-[40px] border-b-[0px] bg-slate-50 py-0',
                  !data && 'pl-4',
                  rowClassName,
                )}
                data-index={row.index}
                key={row.id}
                ref={node => node}
              >
                {row.getVisibleCells().map((cell, index) => {
                  const data = cell.row?.original as AssetBody;
                  const isActive = data?.assetStatus === 'active';
                  const isInactiveRow = !isActive && withToggle;
                  const isFirstIndex = index === 0;

                  return (
                    <TableData
                      className={classnames(
                        'pl-[22px]',
                        extendedRow && 'h-[64px]',
                        !cell.column.columnDef.header && 'min-w-[120px] max-w-[120px]',
                        isInactiveRow && 'first:opacity-30',
                        isFirstIndex && 'max-w-48 border-r-[1px] border-slate-200',
                      )}
                      data-index={row.index + generateId()}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableData>
                  );
                })}
              </TableRow>
            );
        })}
      </>
    );
  };

  return { items };
}
