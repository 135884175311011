import { FC } from 'react';
import { Link } from 'react-router-dom';

import { FaInfoCircle } from 'react-icons/fa';

import { translate } from '@/i18n';
import { Typography } from '@/shared/components/ui/Typography';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

type Props = {
  to?: RoutesEnum;
};

export const AssetHighlightAlert: FC<Props> = ({ to = RoutesEnum.HOME }) => {
  return (
    <div className="flex items-center justify-between rounded-md bg-blue-500 px-4 py-2">
      <div className="flex gap-2">
        <FaInfoCircle className="fill-white" />

        <Typography className="text-sm font-semibold text-white">
          {translate('global.showingHighlightedAsset')}
        </Typography>
      </div>

      <span className="flex-1" />

      <Link className="whitespace-nowrap text-sm font-semibold text-white underline" to={to}>
        {translate('global.showAll')}
      </Link>
    </div>
  );
};
