import { NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const links: NavLinkProps[] = [
  {
    disabled: false,
    icon: 'location',
    name: 'navbar.map',
    path: RoutesEnum.HOME,
  },
  {
    disabled: false,
    icon: 'requests',
    name: 'global.reservations',
    path: RoutesEnum.RESERVATIONS,
  },
  {
    disabled: false,
    icon: 'equipment',
    name: 'global.equipment',
    path: RoutesEnum.EQUIPMENT,
  },
  {
    disabled: false,
    icon: 'subcontractors',
    name: 'navbar.subContractors',
    path: RoutesEnum.PEOPLE,
  },
  {
    disabled: false,
    icon: 'reports',
    name: 'navbar.reports',
    path: RoutesEnum.REPORTS,
  },
  {
    disabled: false,
    icon: 'actions',
    name: 'navbar.actions',
    path: RoutesEnum.ACTIONS,
  },
];
