import { FC } from 'react';

import { translate } from '@/i18n';
import { Badge } from '@/shared/components/ui/Badge';
import { Card } from '@/shared/components/ui/Card';
import { Typography } from '@/shared/components/ui/Typography';
import { useFiltersHideGroupsZonesShowMapFlag } from '@/shared/feature-flags/useFiltersHideGroupsZonesShowMapFlag.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetOrganization } from '@/shared/types/assets/assets.types.ts';
import { WithChildren } from '@/shared/types/global/common.types.ts';

const NO_DATA = '-';

const CardColumn: FC<WithChildren> = ({ children }) => {
  return <div className="cardColumn h-11">{children}</div>;
};

export const UserInfo = () => {
  const zpsUser = useGlobalStore(store => store.zpsUser);
  const companies = useGlobalStore(store => store.companies);

  const { user } = useFetchUserData();
  const { filtersHideGroupsZonesShowMapFlag } = useFiltersHideGroupsZonesShowMapFlag();

  const userCompany = companies.find(
    company => company.orgResName === zpsUser?.orgResName,
  ) as AssetOrganization;
  const isUserExistGroup = (zpsUser?.userGroups?.length ?? 0) > 0;

  return (
    <Card className="grid w-full max-w-[532px] grid-cols-[120px_1fr] items-center p-0 shadow-none">
      {/*Firm*/}
      <CardColumn>
        <Typography>{translate('settings.firm')}</Typography>
      </CardColumn>

      <CardColumn>
        <Typography>{userCompany?.orgName ?? NO_DATA}</Typography>
      </CardColumn>

      {/*Role*/}
      <CardColumn>
        <Typography>{translate('settings.role')}</Typography>
      </CardColumn>

      <CardColumn>
        <Typography className="first-letter:capitalize">
          {translate(`roles.${user?.role}`)}
        </Typography>
      </CardColumn>

      {/*Group(s)*/}
      {filtersHideGroupsZonesShowMapFlag.enabled && (
        <>
          <CardColumn>
            <Typography>{translate('settings.group')}</Typography>
          </CardColumn>

          <CardColumn>
            {isUserExistGroup ? (
              <>
                {zpsUser?.userGroups.map(group => (
                  <Badge className="px-2.5 py-1.5" key={group} rounded="md" variant="outline">
                    {group}
                  </Badge>
                ))}
              </>
            ) : (
              NO_DATA
            )}
          </CardColumn>
        </>
      )}

      {/*Tag*/}
      <CardColumn>
        <Typography>{translate('settings.tag')}</Typography>
      </CardColumn>

      <CardColumn>
        <Typography>{NO_DATA}</Typography>
      </CardColumn>

      {/*ZPS Access*/}
    </Card>
  );
};
