import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const hasRequiredFields = (data: AssetBody) => {
  return (
    !data?.assetResName ||
    !data?.assetName ||
    !data?.categoryPathResName ||
    !data?.categoryTreeResName ||
    !data?.assetStatus
  );
};
