/* eslint-disable perfectionist/sort-objects */
import { translate } from '@/i18n';

import { ActionCategoryEnum, ActionSeverityEnum } from '@/shared/types/actions/actions.types.ts';

export const localizedSectionHeading = (category: 'archive' | `${ActionCategoryEnum}`) => {
  switch (category) {
    case 'archive':
      return translate('actions.archive');
    case 'equipment':
      return translate('actions.equipment');
    case 'reservation':
      return translate('actions.reservation');
    case 'safety':
      return translate('actions.safety');
    case 'system':
      return translate('global.system');
    case 'zone':
      return translate('actions.zone');
    default:
      return translate('actions.all');
  }
};

export const alertSeveritySortOrder = {
  [ActionSeverityEnum.HIGH]: 3,
  [ActionSeverityEnum.MEDIUM]: 2,
  [ActionSeverityEnum.LOW]: 1,
  [ActionSeverityEnum.DEFAULT]: 0,
};
