import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { getTagValue } from '@/modules/Equipment/components/tabs/Equipment/EquipmentsAccordsBody';
import { useUpdateReservations } from '@/modules/Reservations/hooks/useUpdateReservations';
import { useWorkflowEngine } from '@/modules/Reservations/hooks/useWorkflowEngine';
import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { DatePicker } from '@/shared/components/form/DatePicker';
import { RadioGroup } from '@/shared/components/form/RadioGroup';
import { Select } from '@/shared/components/form/Select';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';
import { RESERVATIONS } from '@/shared/utils/constants';
import { formatTimestamp } from '@/shared/utils/date-fns';

import { UpdateReservationEvent } from '@/shared/api/reservations/reservations.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';
import {
  EventData,
  ReservationType,
  ReservationUpdateParamsWithEvent,
  SelectedReservation,
} from '@/shared/types/global/reservations.types.ts';

interface ConflictingReservation {
  assetResName: string;
  fromDate: Date;
  fromTime: Date;
  mode: string;
  reservationResName: string;
  toDate: Date;
  toTime: Date;
  workflowResName: string;
}

const modes = [
  {
    id: 'approve',
    name: 'approve',
  },
  {
    id: 'edit',
    name: 'edit',
  },
  {
    id: 'reject',
    name: 'reject',
  },
];

const conflictModes = [
  {
    disabled: true,
    id: 'approve',
    name: 'approve',
  },
  {
    id: 'edit',
    name: 'edit',
  },
  {
    id: 'reject',
    name: 'reject',
  },
];

export const ReservationPopup: FC<SelectedReservation> = ({ closePopup, eventData }) => {
  const { locale } = useIntl();
  const isMobile = useMedia('(max-width: 767px)');
  const TIME_FORMAT = locale === LocaleEnum.JPN ? 'HH:mm' : 'hh:mm a';

  const [selectedMode, setSelectedMode] = useState(modes[0].name);
  const [selectedConflictMode, setSelectedConflictMode] = useState(conflictModes[0].name);
  const [fromDate, setFromDate] = useState(
    new Date(eventData?.selectedEvent.reservationPeriod.startTime.epochTime),
  );
  const [toDate, setToDate] = useState<Date | null>(
    new Date(eventData?.selectedEvent.reservationPeriod.endTime.epochTime),
  );
  const [fromTime, setFromTime] = useState(
    new Date(eventData?.selectedEvent.reservationPeriod.startTime.epochTime),
  );
  const [toTime, setToTime] = useState<Date | null>(
    new Date(eventData?.selectedEvent.reservationPeriod.endTime.epochTime),
  );
  const [selectedAsset, setSelectedAsset] = useState(eventData?.assetResName);
  const allStates = {
    approved: 'approved',
    conflicted: 'conflicted',
    draft: 'draft',
    pending: 'pending',
    rejected: 'rejected',
    requested: 'requested',
  };
  const [currentState, setCurrentState] = useState('');

  const site = useGlobalStore(store => store.site);
  const isModalSubmitting = useGlobalStore(state => state.isModalSubmitting);
  const setModalSubmitting = useGlobalStore(state => state.setModalSubmitting);

  const { user } = useFetchUserData();
  const workFlowCall = useWorkflowEngine();
  const updateCall = useUpdateReservations();
  const { activeAssets } = useEquipment();
  const { filteredSocketMapData } = useFilteredSocketData();
  const { reservationDeleteHandler } = useModalHandlerTypes();
  const { renderItemVisibility } = useRole();

  const mergeWithSocket = activeAssets.map((asset: AssetBody) => {
    const propertyValue = getTagValue(asset);
    const currentAsset = filteredSocketMapData.find(item => item.tagResName === propertyValue);

    return { ...asset, inUse: !!currentAsset };
  });

  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  //const isSubContractor = renderItemVisibility([RolesEnum.SUBCONTRACTOR]);
  const isWorker = renderItemVisibility([RolesEnum.WORKER]);

  useEffect(() => {
    switch (eventData?.selectedEvent.workflowContext.currentStateName) {
      case RESERVATIONS.approvedMgr: {
        setCurrentState(allStates.approved);

        break;
      }
      case RESERVATIONS.approvedSub: {
        if (isManager) {
          setCurrentState(allStates.requested);
        } else {
          setCurrentState(allStates.pending);
        }

        break;
      }
      case RESERVATIONS.conflictedMgr: {
        if (isManager) setCurrentState(allStates.conflicted);
        else setCurrentState(allStates.pending);

        break;
      }
      case RESERVATIONS.conflictedSub: {
        if (isWorker) setCurrentState(allStates.pending);
        else setCurrentState(allStates.conflicted);

        break;
      }
      case RESERVATIONS.draftSub: {
        setCurrentState(allStates.draft);

        break;
      }
      case RESERVATIONS.rejectedMgr:
      case RESERVATIONS.rejectedSub: {
        setCurrentState(allStates.rejected);

        break;
      }
      case RESERVATIONS.requestedSub: {
        if (isWorker) setCurrentState(allStates.pending);
        else setCurrentState(allStates.requested);

        break;
      }
      // No default
    }
  }, []);

  const modifiedAssets = useMemo(() => {
    return mergeWithSocket.map(asset => ({
      id: String(asset.assetResName),
      name: asset.assetName,
    }));
  }, [mergeWithSocket]);

  const modeChange = (mode: { id: string; name: string }) => {
    setSelectedMode(mode.name);
  };

  const conflictModeChange = (mode: { id: string; name: string }) => {
    setSelectedConflictMode(mode.name);
  };

  const [conflictingReservations, setConflictingReservations] = useState<ConflictingReservation[]>(
    eventData?.reservations?.[0]
      .filter(
        reservation =>
          reservation.reservationResName !== eventData.selectedEvent.reservationResName,
      )
      .map(reservation => ({
        assetResName: reservation.assetResName,
        fromDate: new Date(reservation.reservationPeriod.startTime.epochTime),
        fromTime: new Date(reservation.reservationPeriod.startTime.epochTime),
        mode: conflictModes[1].name,
        reservationResName: reservation.reservationResName,
        reservedAt: reservation.lastUpdateTime,
        reservedBy: reservation.user.orgName,
        toDate: new Date(reservation.reservationPeriod.endTime.epochTime),
        toTime: new Date(reservation.reservationPeriod.endTime.epochTime),
        workflowResName: reservation.workflowContext.workflowResName,
      })) ?? [],
  );

  const handleConflictModeChange = (
    reservationResName: string,
    mode: { id: string; name: string },
  ) => {
    setConflictingReservations(prev =>
      prev.map(reservation =>
        reservation.reservationResName === reservationResName
          ? { ...reservation, mode: mode.name }
          : reservation,
      ),
    );
  };

  const handleConflictingReservationChange = (
    reservationResName: string,
    field: keyof ConflictingReservation,
    value: Date | string,
  ) => {
    setConflictingReservations(prev =>
      prev.map(reservation =>
        reservation.reservationResName === reservationResName
          ? { ...reservation, [field]: value }
          : reservation,
      ),
    );
  };

  const submitDataWithConflicts = async () => {
    if (toTime && toDate) {
      setModalSubmitting(true);
      try {
        if (currentState == allStates.conflicted) {
          // Handle conflicting reservations based on their individual modes
          const updatePromises = conflictingReservations
            .filter(reservation => reservation.mode == conflictModes[0].name)
            .map(async reservation => {
              const conflictFromTime = new Date(
                reservation.fromDate.setHours(
                  reservation.fromTime.getHours(),
                  reservation.fromTime.getMinutes(),
                ),
              );
              const conflictToTime = new Date(
                reservation.toDate.setHours(
                  reservation.toTime.getHours(),
                  reservation.toTime.getMinutes(),
                ),
              );

              return {
                accountResName: user?.accountResourceName ?? '',
                assetResName: reservation.assetResName,
                event: false,
                reservationPeriod: {
                  endTime: {
                    epochTime: Number(conflictToTime.getTime()),
                  },
                  startTime: {
                    epochTime: Number(conflictFromTime.getTime()),
                  },
                },
                reservationResName: reservation.reservationResName,
                siteResName: site,
                status: 'active',
                user: {
                  userId: eventData.selectedEvent.user.userId,
                },
                workflowResName: reservation.workflowResName,
              };
            });
          const rejectPromises = conflictingReservations
            .filter(reservation => reservation.mode != conflictModes[1].name)
            .map(async reservation => {
              // Handle approve/reject for conflicting reservation
              return {
                accountResName: user?.accountResourceName ?? '',
                reservationResName: reservation.reservationResName,
                siteResName: site,
                triggerEventType:
                  reservation.mode === 'approve'
                    ? RESERVATIONS.eventApprovedSub
                    : RESERVATIONS.eventRejectSub,
                userResName: user?.userResourceName,
              };
            });

          await Promise.all(updatePromises);
          // update main reservation
          const fromTimeUpdated = new Date(
            fromDate.setHours(fromTime.getHours(), fromTime.getMinutes()),
          );
          const toTimeUpdated = new Date(toDate.setHours(toTime.getHours(), toTime.getMinutes()));

          const mainReservation: ReservationUpdateParamsWithEvent = {
            accountResName: user?.accountResourceName ?? '',
            assetResName: selectedAsset,
            event: true,
            reservationPeriod: {
              endTime: { epochTime: Number(toTimeUpdated.getTime()) },
              startTime: {
                epochTime: Number(fromTimeUpdated.getTime()),
              },
            },
            reservationResName: eventData.selectedEvent.reservationResName,
            siteResName: site,
            status: 'active',
            user: eventData.selectedEvent.user,
            workflowResName: eventData.selectedEvent.workflowContext.workflowResName,
          };

          await updateCall.mutateAsync(mainReservation);
          if (selectedConflictMode !== 'edit') {
            // Handle approve/reject for main reservation
            const mainStateUpdate: UpdateReservationEvent = {
              accountResName: user?.accountResourceName ?? '',
              reservationResName: eventData.selectedEvent.reservationResName,
              siteResName: site,
              triggerEventType: isManager
                ? RESERVATIONS.eventRejectMgr
                : RESERVATIONS.eventRejectSub,
              userResName: user?.userResourceName,
            };

            await workFlowCall.mutateAsync(mainStateUpdate);
          }

          await Promise.all(rejectPromises);
        } else if (selectedMode === 'edit' || currentState == allStates.draft) {
          // Handle regular edit/draft case
          const fromTimeUpdated = new Date(
            fromDate.setHours(fromTime.getHours(), fromTime.getMinutes()),
          );
          const toTimeUpdated = new Date(toDate.setHours(toTime.getHours(), toTime.getMinutes()));

          const updateParams: ReservationUpdateParamsWithEvent = {
            accountResName: user?.accountResourceName ?? '',
            assetResName: selectedAsset,
            event: currentState !== allStates.requested,
            reservationPeriod: {
              endTime: { epochTime: Number(toTimeUpdated.getTime()) },
              startTime: {
                epochTime: Number(fromTimeUpdated.getTime()),
              },
            },
            reservationResName: eventData.selectedEvent.reservationResName,
            siteResName: site,
            status: 'active',
            user: eventData.selectedEvent.user,
            workflowResName: eventData.selectedEvent.workflowContext.workflowResName,
          };

          await updateCall.mutateAsync(updateParams);
        } else {
          // Handle regular approve/reject case
          const stateUpdate: UpdateReservationEvent = {
            accountResName: user?.accountResourceName ?? '',
            reservationResName: String(eventData?.selectedEvent.reservationResName),
            siteResName: site,
            triggerEventType:
              selectedMode === 'approve'
                ? isManager
                  ? RESERVATIONS.eventApprovedMgr
                  : RESERVATIONS.eventApprovedSub
                : isManager
                ? RESERVATIONS.eventRejectMgr
                : RESERVATIONS.eventRejectSub,
            userResName: user?.userResourceName,
          };

          await workFlowCall.mutateAsync(stateUpdate);
        }

        closePopup();
      } catch (error) {
        console.error('Error updating reservations:', error);
        setModalSubmitting(false);
      }
    }
  };

  const submitData = async () => {
    if (toTime && toDate) {
      setModalSubmitting(true);

      if (
        selectedMode == 'edit' ||
        currentState == allStates.draft ||
        currentState == allStates.pending
      ) {
        const fromTimeUpdated = new Date(
          fromDate.setHours(fromTime.getHours(), fromTime.getMinutes()),
        );
        const toTimeUpdated = new Date(toDate.setHours(toTime.getHours(), toTime.getMinutes()));

        const mainReservation: ReservationUpdateParamsWithEvent = {
          accountResName: user?.accountResourceName ?? '',
          assetResName: selectedAsset,
          event: currentState !== allStates.requested && currentState !== allStates.pending,
          reservationPeriod: {
            endTime: { epochTime: Number(toTimeUpdated.getTime()) },
            startTime: {
              epochTime: Number(fromTimeUpdated.getTime()),
            },
          },
          reservationResName: eventData.selectedEvent.reservationResName,
          siteResName: site,
          status: 'active',
          user: eventData.selectedEvent.user,
          workflowResName: eventData.selectedEvent.workflowContext.workflowResName,
        };

        const conflictingUpdates = conflictingReservations.map(reservation => {
          const conflictFromTime = new Date(
            reservation.fromDate.setHours(
              reservation.fromTime.getHours(),
              reservation.fromTime.getMinutes(),
            ),
          );
          const conflictToTime = new Date(
            reservation.toDate.setHours(
              reservation.toTime.getHours(),
              reservation.toTime.getMinutes(),
            ),
          );

          return {
            accountResName: user?.accountResourceName ?? '',
            assetResName: reservation.assetResName,
            event:
              currentState !== allStates.requested &&
              currentState !== allStates.approved &&
              currentState !== allStates.pending,
            reservationPeriod: {
              endTime: {
                epochTime: Number(conflictToTime.getTime()),
              },
              startTime: {
                epochTime: Number(conflictFromTime.getTime()),
              },
            },
            reservationResName: reservation.reservationResName,
            siteResName: site,
            status: 'active',
            user: {
              userId: eventData.selectedEvent.user.userId,
            },
            workflowResName: eventData.selectedEvent.workflowContext.workflowResName,
          };
        });

        const allUpdates = [mainReservation, ...conflictingUpdates];

        try {
          await Promise.all(allUpdates.map(update => updateCall.mutateAsync(update)));
          closePopup();
        } catch (error) {
          console.error('Error updating reservations:', error);
          setModalSubmitting(false);
        }
      } else {
        submitDataWithConflicts();
      }
    }
  };

  const submitDraftData = async () => {
    if (toTime && toDate) {
      setModalSubmitting(true);

      const fromTimeUpdated = new Date(
        fromDate.setHours(fromTime.getHours(), fromTime.getMinutes()),
      );
      const toTimeUpdated = new Date(fromDate.setHours(toTime?.getHours(), toTime?.getMinutes()));

      const fData: ReservationUpdateParamsWithEvent = {
        accountResName: user?.accountResourceName ?? '',
        assetResName: selectedAsset,
        event: false,
        reservationPeriod: {
          endTime: { epochTime: Number(toTimeUpdated.getTime()) },
          startTime: {
            epochTime: Number(fromTimeUpdated.getTime()),
          },
        },
        reservationResName: eventData.selectedEvent.reservationResName,
        siteResName: site,
        status: 'active',
        user: eventData.selectedEvent.user,
        workflowResName: eventData.selectedEvent.workflowContext.workflowResName,
      };

      await updateCall.mutateAsync(fData);

      closePopup();
    }
  };

  const getEventDataType = (eventData: EventData) => {
    if (!eventData) return;

    if (currentState == allStates.requested) {
      return translate('reservation.requested');
    }

    if (currentState == allStates.rejected) {
      return translate('reservation.rejected');
    }

    if (currentState == allStates.approved) {
      return translate('reservation.approved');
    }

    if (currentState == allStates.conflicted) {
      return translate('reservation.conflict');
    }
    if (currentState == allStates.draft) {
      return translate('reservation.draft');
    }
    if (currentState == allStates.pending) {
      return translate('reservation.pending');
    }
    return 'New';
  };

  const generalInfo = ({
    fromDate,
    fromTime,
    reservedAt,
    reservedBy,
    toTime,
  }: {
    reservedAt: number;
    reservedBy: string;
    fromDate: Date;
    fromTime: Date;
    toDate: Date;
    toTime: Date;
  }) => {
    return (
      <>
        <div className="mb-[10px] grid grid-cols-3 grid-rows-[4]">
          <div className="col-span-3 mb-1 text-sm font-bold text-slate-800">{reservedBy}</div>
          <div className="mb-[5px] text-sm text-slate-500">
            {translate('reservation.reserveat')}:
          </div>

          <div className="col-span-2 text-sm text-slate-800">
            {formatTimestamp(reservedAt, 'dd/MM/yyyy hh:mm a')}
          </div>

          <div className="mb-[5px] text-sm text-slate-500">{translate('global.date')}:</div>

          <div className="col-span-2 text-sm text-slate-800">
            {formatTimestamp(Number(fromDate.getTime()), 'dd/MM/yyyy')}
          </div>

          <div className="mb-[5px] text-sm text-slate-500">{translate('global.time')}:</div>

          <div className="col-span-2 text-sm text-slate-800">
            {`${formatTimestamp(Number(fromTime.getTime()), TIME_FORMAT)} - ${formatTimestamp(
              Number(toTime.getTime()),
              TIME_FORMAT,
            )}`}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="mainContainerPopup fixed bottom-0 left-0 right-0 z-[999] h-[calc(100vh-64px)] w-[100vw]">
      <div
        className={classnames(
          `absolute right-0 z-[9999] h-[calc(100vh-64px)] w-[34%] min-w-[500px] bg-white shadow-lg shadow-slate-600`,
          { 'w-full min-w-[350px]': isMobile },
        )}
      >
        <div className={`h-full p-[20px] ${isMobile ? 'px-2' : ''}`}>
          <div className="flex">
            <div
              className={classnames(
                'mb-2 w-fit rounded-md  border-[1px] p-[6px] px-2.5 text-xs capitalize text-white',
                {
                  'bg-amber-600 fill-amber-600': eventData && currentState == allStates.conflicted,
                  'bg-blue-600 fill-blue-600': eventData && currentState == allStates.requested,
                  'bg-green-600 fill-green-600': eventData && currentState == allStates.approved,
                  'bg-red-600 fill-red-600': eventData && currentState == allStates.rejected,
                  'bg-slate-600 fill-slate-600': eventData && currentState == allStates.draft,
                  'bg-slate-800 fill-slate-800': eventData && currentState == allStates.pending,
                },
              )}
            >
              {getEventDataType(eventData)}
            </div>

            <IconButton
              className="absolute right-2 top-2 h-[55px] w-[55px]"
              icon="close"
              onClick={() => {
                closePopup();
              }}
            />
          </div>

          <div className="text-xl font-bold text-slate-800">
            {translate('reservation.popupHeading')}
          </div>

          <div className="text-base text-slate-800">{eventData?.assetName}</div>

          <div className="mt-5 h-[80%] overflow-y-auto">
            {(eventData && currentState == allStates.draft) ||
            currentState == allStates.pending ||
            currentState == allStates.conflicted ||
            currentState == allStates.rejected ? null : (
              <div className="mb-[10px] grid grid-cols-3 grid-rows-[4] rounded-xl border p-5">
                <div className="mb-[5px] text-sm text-slate-500">
                  {translate('reservation.reserveby')}:
                </div>

                <div className="col-span-2 text-sm text-slate-800">
                  {eventData?.selectedEvent.user.orgName}
                </div>

                <div className="mb-[5px] text-sm text-slate-500">
                  {translate('reservation.reserveat')}:
                </div>

                <div className="col-span-2 text-sm text-slate-800">
                  {formatTimestamp(
                    Number(eventData?.selectedEvent.lastUpdateTime?.epochTime),
                    'dd/MM/yyyy',
                  )}
                </div>

                <div className="mb-[5px] text-sm text-slate-500">{translate('global.date')}:</div>

                <div className="col-span-2 text-sm text-slate-800">
                  {formatTimestamp(
                    Number(eventData?.selectedEvent.reservationPeriod.startTime.epochTime),
                    'dd/MM/yyyy',
                  )}
                </div>

                <div className="mb-[5px] text-sm text-slate-500">{translate('global.time')}:</div>

                <div className="col-span-2 text-sm text-slate-800">
                  {`${formatTimestamp(
                    Number(eventData?.selectedEvent.reservationPeriod.startTime.epochTime),
                    TIME_FORMAT,
                  )} - ${formatTimestamp(
                    Number(eventData?.selectedEvent.reservationPeriod.endTime.epochTime),
                    TIME_FORMAT,
                  )}`}
                </div>
              </div>
            )}

            {eventData ? (
              <>
                {currentState == allStates.requested ? (
                  <RadioGroup
                    initialSelected={selectedMode}
                    items={modes}
                    label=""
                    onChange={modeChange}
                  />
                ) : null}

                {currentState == allStates.draft ||
                currentState == allStates.conflicted ||
                currentState == allStates.rejected ||
                currentState == allStates.pending ||
                currentState == allStates.requested ? (
                  <div
                    className={`${
                      currentState == allStates.requested
                        ? selectedMode == 'edit'
                          ? 'border'
                          : ''
                        : 'border'
                    } p-5`}
                  >
                    {(currentState == allStates.rejected ||
                      currentState == allStates.pending ||
                      currentState == allStates.conflicted) &&
                      generalInfo({
                        fromDate: new Date(
                          eventData?.selectedEvent.reservationPeriod.startTime.epochTime,
                        ),
                        fromTime: new Date(
                          eventData?.selectedEvent.reservationPeriod.startTime.epochTime,
                        ),
                        reservedAt: Number(eventData?.selectedEvent.lastUpdateTime?.epochTime),
                        reservedBy: eventData?.selectedEvent.user.orgName,
                        toDate: new Date(
                          eventData?.selectedEvent.reservationPeriod.endTime.epochTime,
                        ),
                        toTime: new Date(
                          eventData?.selectedEvent.reservationPeriod.endTime.epochTime,
                        ),
                      })}
                    {currentState == allStates.conflicted ? (
                      <RadioGroup
                        initialSelected={selectedConflictMode}
                        items={conflictModes}
                        label=""
                        onChange={conflictModeChange}
                      />
                    ) : null}
                    {(currentState == allStates.draft ||
                      ((selectedConflictMode == 'edit' || selectedMode == 'edit') &&
                        (currentState == allStates.rejected ||
                          currentState == allStates.conflicted ||
                          currentState == allStates.pending ||
                          currentState == allStates.requested))) && (
                      <>
                        <Select
                          className="mt-2 min-h-[38px] w-full bg-white"
                          data={modifiedAssets}
                          defaultValue={selectedAsset}
                          label="Equipment"
                          onChange={(dt: string) => setSelectedAsset(dt)}
                          variant={'default'}
                        />
                        <div className="mt-5">
                          <div className="flex w-full content-between items-center">
                            <div className="w-[50px] shrink-0 text-sm text-slate-950 sm:w-[64px]">
                              {translate('calendar.from')}:
                            </div>

                            <div className="flex items-center gap-x-6 sm:gap-x-8">
                              <div>
                                <DatePicker
                                  className="w-50"
                                  dateFormat="dd/MM/yyyy"
                                  icon="calendar"
                                  minDate={new Date()}
                                  onChange={(dt: Date) => {
                                    setFromDate(dt);
                                    setToDate(null);
                                    setToTime(null);
                                  }}
                                  selected={fromDate}
                                  showArrows={false}
                                  showTimeSelect={false}
                                />
                              </div>

                              <div>
                                {fromDate ? (
                                  <DatePicker
                                    className="w-50"
                                    customDateFormat
                                    dateFormat={TIME_FORMAT}
                                    icon="clock"
                                    maxTime={
                                      new Date(new Date(fromDate.setHours(16)).setMinutes(45))
                                    }
                                    minDate={new Date()}
                                    minTime={new Date(new Date(fromDate.setHours(9)).setMinutes(0))}
                                    onChange={(dt: Date) => {
                                      setFromTime(dt);
                                      setToTime(null);
                                    }}
                                    selected={fromTime}
                                    showArrows={false}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="mb-5 mt-5 flex items-center">
                            <div className="w-[50px] shrink-0 text-sm text-slate-950 sm:w-[64px]">
                              {translate('calendar.to')}:
                            </div>

                            <div className="flex w-full items-center gap-x-6 sm:gap-x-8">
                              <div>
                                <DatePicker
                                  className="w-50"
                                  dateFormat="dd/MM/yyyy"
                                  icon="calendar"
                                  maxDate={fromDate}
                                  minDate={fromDate}
                                  onChange={(dt: Date) => setToDate(dt)}
                                  selected={toDate}
                                  showArrows={false}
                                  showTimeSelect={false}
                                />
                              </div>

                              <div>
                                <DatePicker
                                  className="w-50"
                                  customDateFormat
                                  dateFormat={TIME_FORMAT}
                                  icon="clock"
                                  maxDate={fromDate}
                                  maxTime={new Date(new Date(new Date().setHours(17, 0)))}
                                  minDate={fromDate}
                                  minTime={new Date(fromTime.getTime() + 15 * 60000)}
                                  onChange={(dt: Date) => setToTime(dt)}
                                  selected={toTime}
                                  showArrows={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
                {currentState == allStates.conflicted &&
                  eventData?.reservations &&
                  eventData?.reservations[0]
                    .filter(
                      reservation =>
                        reservation.reservationResName !==
                        eventData.selectedEvent.reservationResName,
                    )
                    .map((reservation: ReservationType, index: number) => {
                      const conflictingReservation = conflictingReservations.find(
                        r => r.reservationResName === reservation.reservationResName,
                      );

                      if (!conflictingReservation) return null;

                      return (
                        <div
                          className={`mt-5 border p-5 ${
                            index ===
                            (eventData?.reservations?.length &&
                              eventData?.reservations[0].length - 1)
                              ? 'mb-10'
                              : ''
                          }`}
                          key={reservation.reservationResName}
                        >
                          {generalInfo({
                            fromDate: conflictingReservation.fromDate,
                            fromTime: conflictingReservation.fromTime,
                            reservedAt: Number(reservation.lastUpdateTime?.epochTime),
                            reservedBy: reservation.user.orgName,
                            toDate: conflictingReservation.toDate,
                            toTime: conflictingReservation.toTime,
                          })}

                          <RadioGroup
                            initialSelected={conflictingReservation.mode}
                            items={conflictModes}
                            label=""
                            onChange={mode =>
                              handleConflictModeChange(reservation.reservationResName, mode)
                            }
                          />

                          {conflictingReservation.mode === 'edit' && (
                            <>
                              <Select
                                className="mb-4 mt-4 min-h-[38px] w-full bg-white"
                                data={modifiedAssets}
                                defaultValue={conflictingReservation.assetResName}
                                label="Equipment"
                                onChange={(dt: string) =>
                                  handleConflictingReservationChange(
                                    reservation.reservationResName,
                                    'assetResName',
                                    dt,
                                  )
                                }
                                variant={'default'}
                              />
                              <div className="mb-3 flex items-center gap-x-8">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  icon="calendar"
                                  minDate={new Date()}
                                  onChange={(dt: Date) =>
                                    handleConflictingReservationChange(
                                      reservation.reservationResName,
                                      'fromDate',
                                      dt,
                                    )
                                  }
                                  selected={conflictingReservation.fromDate}
                                  showArrows={false}
                                  showTimeSelect={false}
                                />
                                <DatePicker
                                  customDateFormat
                                  dateFormat={TIME_FORMAT}
                                  icon="clock"
                                  maxTime={new Date(new Date().setHours(17, 0))}
                                  minTime={new Date(new Date().setHours(9, 0))}
                                  onChange={(dt: Date) =>
                                    handleConflictingReservationChange(
                                      reservation.reservationResName,
                                      'fromTime',
                                      dt,
                                    )
                                  }
                                  selected={conflictingReservation.fromTime}
                                  showArrows={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                />
                              </div>
                              <div className="flex items-center gap-x-8">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  icon="calendar"
                                  maxDate={conflictingReservation.fromDate}
                                  minDate={conflictingReservation.fromDate}
                                  onChange={(dt: Date) =>
                                    handleConflictingReservationChange(
                                      reservation.reservationResName,
                                      'toDate',
                                      dt,
                                    )
                                  }
                                  selected={conflictingReservation.toDate}
                                  showArrows={false}
                                  showTimeSelect={false}
                                />
                                <DatePicker
                                  customDateFormat
                                  dateFormat={TIME_FORMAT}
                                  icon="clock"
                                  maxTime={new Date(new Date().setHours(17, 0))}
                                  minTime={
                                    new Date(conflictingReservation.fromTime.getTime() + 15 * 60000)
                                  }
                                  onChange={(dt: Date) =>
                                    handleConflictingReservationChange(
                                      reservation.reservationResName,
                                      'toTime',
                                      dt,
                                    )
                                  }
                                  selected={conflictingReservation.toTime}
                                  showArrows={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
              </>
            ) : null}

            {selectedMode != 'edit' &&
            (currentState == allStates.rejected || currentState == allStates.pending) ? (
              <>
                <Button
                  className="mr-2 mt-3 text-red-500"
                  onClick={() => reservationDeleteHandler(eventData.selectedEvent)}
                  variant="outline"
                  withIcon
                >
                  {translate('reservation.cancelReservation')}
                </Button>

                {currentState != allStates.rejected && (
                  <Button
                    className="mt-3 text-slate-600"
                    onClick={() => setSelectedMode('edit')}
                    variant="outline"
                    withIcon
                  >
                    {translate('global.edit')}
                  </Button>
                )}
              </>
            ) : null}
          </div>
        </div>

        <div className="absolute bottom-0 z-20 flex w-full content-center justify-between border-t bg-slate-100 px-[10px] py-[10px]">
          <div className="self-start">
            {currentState == allStates.draft ? (
              <div className="left-3 h-[40px]">
                <Button
                  className="text-red-500"
                  onClick={() => reservationDeleteHandler(eventData.selectedEvent)}
                  variant="outline"
                  withIcon
                >
                  {translate('reservation.delete')}
                </Button>
              </div>
            ) : (
              <div className="left-0 h-[40px]">
                <Button
                  className="border-grey ml-5 bg-white text-slate-800"
                  onClick={() => closePopup()}
                  variant="outline"
                >
                  {translate('reservation.cancel')}
                </Button>
              </div>
            )}
          </div>
          <div className="flex self-end">
            {eventData && currentState == allStates.draft ? (
              <div className="right-20 mr-[20px] h-[40px]">
                <Button
                  isLoading={isModalSubmitting}
                  onClick={() => submitDraftData()}
                  variant="outline"
                  withIcon
                >
                  {translate('reservation.saveAsDraft')}
                </Button>
              </div>
            ) : null}
            {eventData &&
            ((currentState != allStates.rejected &&
              selectedMode == 'edit' &&
              (!isManager || currentState !== allStates.approved)) ||
              conflictingReservations.length > 0 ||
              currentState == allStates.requested ||
              currentState == allStates.conflicted) ? (
              <div className="right-0 mr-[20px] h-[40px]">
                <Button
                  isLoading={isModalSubmitting}
                  onClick={() => {
                    if (conflictingReservations.length > 0 && currentState !== allStates.pending) {
                      submitDataWithConflicts();
                    } else {
                      submitData();
                    }
                  }}
                  variant="solid"
                  withIcon
                >
                  {translate('global.submit')}
                </Button>
              </div>
            ) : null}

            {eventData && currentState == allStates.approved ? (
              <div className="right-[100px] mr-[5px] h-[40px] text-red-500">
                <Button
                  className="text-red-500"
                  onClick={() => reservationDeleteHandler(eventData.selectedEvent)}
                  variant="outline"
                  withIcon
                >
                  {translate('reservation.delete')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
