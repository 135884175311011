import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants';

import { SitePolicy } from '@/shared/api/site-operations/types';

export const useUpdateSitePolicy = () => {
  const site = useGlobalStore(state => state.site);
  const policies = useGlobalStore(state => state.sitePolicies);

  const defaultPolicy = policies[0];

  const queryClient = useQueryClient();
  const { user } = useFetchUserData();

  const { isPending: isUpdatingPolicy, mutate: updateSitePolicy } = useMutation({
    mutationFn: async (newPolicy: SitePolicy) => {
      return await SiteOperationsAPINamespace.updateOneSitePolicy({
        ...newPolicy,
        accountResName: user?.accountResourceName as string,
        policyResName: defaultPolicy.policyResName as string,
        siteResName: site,
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.sitePolicyUpdateFail'));
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.SITE_POLICIES_GET],
      });
      notifyService.success(translate('messages.sitePolicyUpdateSuccess'));
    },
  });

  return {
    isUpdatingPolicy,
    updateSitePolicy,
  };
};
