import { useQuery } from '@tanstack/react-query';

import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export const useGetSitePolicies = () => {
  const site = useGlobalStore(state => state.site);
  const setSitePolicies = useGlobalStore(state => state.setSitePolicies);

  const { user } = useFetchUserData();

  const getSitePolicies = async () => {
    const sitePolicies = await SiteOperationsAPINamespace.getSitePolicies({
      accountResName: user?.accountResourceName as string,
      siteResName: site,
    });

    setSitePolicies(sitePolicies);

    return sitePolicies;
  };

  return useQuery({
    enabled: !!user?.accountResourceName && !!user?.userResourceName,
    queryFn: getSitePolicies,
    queryKey: [QUERY_KEY.SITE_POLICIES_GET, user?.accountResourceName, user?.userResourceName],
  });
};
