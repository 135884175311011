import { FC } from 'react';

import * as Lists from '@/modules/Actions/components';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useActions } from '@/shared/hooks/actions/useActions.ts';

export const Unresolved: FC = () => {
  const {
    equipmentAlertCount,
    equipmentAlerts,
    safetyAlertCount,
    safetyAlerts,
    systemAlertCount,
    systemAlerts,
    zoneAlertCount,
    zoneAlerts,
  } = useActions();

  return (
    <div className="flex flex-col gap-y-4">
      <div className="inline-flex h-[38px] w-full items-center justify-between">
        <TopSection scope={[]} />
      </div>

      <Lists.DisclosureItem category="system" count={systemAlertCount} items={systemAlerts} />

      <Lists.DisclosureItem category="safety" count={safetyAlertCount} items={safetyAlerts} />

      <Lists.DisclosureItem
        category="equipment"
        count={equipmentAlertCount}
        items={equipmentAlerts}
      />

      <Lists.DisclosureItem category="zone" count={zoneAlertCount} items={zoneAlerts} />
    </div>
  );
};
