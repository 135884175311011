import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type Props = WithChildren & {
  className?: string;
};

export const FormWrapper: FC<Props> = ({ children, className }) => {
  return (
    <div className={classnames('grid w-full gap-4 md:max-w-[456px] md:grid-cols-2', className)}>
      {children}
    </div>
  );
};
