import { baseQuery, signRequest } from '@/shared/api/utils';

import {
  CalendarsGetPayload,
  SiteCalendar,
  SitePoliciesGetPayload,
  SitePolicy,
  SitePolicyCreateRequest,
  SitePolicyGetPayload,
  SitePolicyUpdateRequest,
  SiteShift,
  SiteShiftCreateRequest,
  SiteShiftGetPayload,
  SiteShiftsGetPayload,
  SiteShiftUpdateRequest,
} from './types';

export namespace SiteOperationsAPINamespace {
  const client = baseQuery('SITE_OPERATIONS');

  export const getSiteShifts = async ({
    accountResName,
    calendarResName,
    siteResName,
  }: SiteShiftsGetPayload): Promise<SiteShift[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/calendars/${calendarResName}/shifts?status=inactive`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'GET');

    const response = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return response.data.results;
  };

  export const getSiteShift = async ({
    accountResName,
    calendarResName,
    shiftResName,
    siteResName,
  }: SiteShiftGetPayload): Promise<SiteShift[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/calendars/${calendarResName}/shifts/${shiftResName}`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOneShift = async ({
    accountResName,
    calendarResName,
    siteResName,
    ...body
  }: SiteShiftCreateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/calendars/${calendarResName}/shifts`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOneShift = async ({
    accountResName,
    calendarResName,
    shiftResName,
    siteResName,
    ...body
  }: SiteShiftUpdateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/calendars/${calendarResName}/shifts/${shiftResName}`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'PATCH', body);

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getSitePolicies = async ({
    accountResName,
    siteResName,
  }: SitePoliciesGetPayload): Promise<SitePolicy[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/policies?dataType=mr`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'GET');

    const response = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return response.data.results;
  };

  export const getSitePolicy = async ({
    accountResName,
    policyResName,
    siteResName,
  }: SitePolicyGetPayload): Promise<SiteShift[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/policies/:${policyResName}`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOnePolicy = async ({
    accountResName,
    siteResName,
    ...body
  }: SitePolicyCreateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/policies`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOneSitePolicy = async ({
    accountResName,
    policyResName,
    siteResName,
    ...body
  }: SitePolicyUpdateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/policies/${policyResName}`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'PATCH', body);

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getSiteCalendars = async ({
    accountResName,
    siteResName,
  }: CalendarsGetPayload): Promise<SiteCalendar[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/calendars?year=2024`;

    const signedRequest = await signRequest('SITE_OPERATIONS', url, 'GET');

    const response = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return response.data.results;
  };
}
