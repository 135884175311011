import { translate } from '@/i18n';

import { CurrencyEnum } from '@/shared/types/settings/system.types.ts';

export const currency = [
  { id: 'usd', name: CurrencyEnum.USD },
  { id: 'jpy', name: CurrencyEnum.JPY },
];

export const ianaTimeZones = [
  { iana: 'Asia/Tokyo', id: 'jst', name: translate('timeZone.jst'), offset: 9 },
  { iana: 'America/Los_Angeles', id: 'pdt', name: translate('timeZone.pdt'), offset: -7 },
  { iana: 'America/New_York', id: 'est', name: translate('timeZone.est'), offset: -5 },
  { iana: 'Europe/London', id: 'bst', name: translate('timeZone.bst'), offset: 1 },
  { iana: 'Europe/Paris', id: 'cet', name: translate('timeZone.cet'), offset: 1 },
  { iana: 'Asia/Kolkata', id: 'ist', name: translate('timeZone.ist'), offset: 5.5 },
  { iana: 'Australia/Sydney', id: 'aest', name: translate('timeZone.aest'), offset: 10 },
  { iana: 'Asia/Seoul', id: 'kst', name: translate('timeZone.kst'), offset: 9 },
  { iana: 'Africa/Johannesburg', id: 'sast', name: translate('timeZone.sast'), offset: 2 },
  { iana: 'America/Sao_Paulo', id: 'brt', name: translate('timeZone.brt'), offset: -3 },
  { iana: 'Etc/UTC', id: 'utc', name: translate('timeZone.utc'), offset: 0 },
  { iana: 'America/Denver', id: 'mst', name: translate('timeZone.mst'), offset: -7 },
  { iana: 'America/Chicago', id: 'cst', name: translate('timeZone.cst'), offset: -6 },
  { iana: 'Asia/Hong_Kong', id: 'hkt', name: translate('timeZone.hkt'), offset: 8 },
  { iana: 'America/Los_Angeles', id: 'pst', name: translate('timeZone.pst'), offset: -8 },
  { iana: 'America/Anchorage', id: 'akst', name: translate('timeZone.akst'), offset: -9 },
  { iana: 'America/St_Johns', id: 'nst', name: translate('timeZone.nst'), offset: -3.5 },
  { iana: 'Europe/London', id: 'gmt', name: translate('timeZone.gmt'), offset: 0 },
  { iana: 'Australia/Perth', id: 'awst', name: translate('timeZone.awst'), offset: 8 },
  { iana: 'Asia/Jakarta', id: 'wib', name: translate('timeZone.wib'), offset: 7 },
  { iana: 'Asia/Makassar', id: 'wita', name: translate('timeZone.wita'), offset: 8 },
  { iana: 'Asia/Jayapura', id: 'wit', name: translate('timeZone.wit'), offset: 9 },
  { iana: 'Europe/Berlin', id: 'cest', name: translate('timeZone.cest'), offset: 2 },
  { iana: 'Africa/Nairobi', id: 'eat', name: translate('timeZone.eat'), offset: 3 },
];

export const timeZones = ianaTimeZones.map(timeZone => ({ id: timeZone.id, name: timeZone.name }));

export const workDays = [
  {
    id: 'sunday',
    name: translate('siteHours.sunday'),
  },
  { id: 'monday', name: translate('siteHours.monday') },
  { id: 'tuesday', name: translate('siteHours.tuesday') },
  { id: 'wednesday', name: translate('siteHours.wednesday') },
  { id: 'thursday', name: translate('siteHours.thursday') },
  {
    id: 'friday',
    name: translate('siteHours.friday'),
  },
  {
    id: 'saturday',
    name: translate('siteHours.saturday'),
  },
];

export const timeOptions = [
  { id: 'oneHour', name: translate('autoApproval.oneHour') },
  { id: 'twoHours', name: translate('autoApproval.twoHours') },
  { id: 'fourHours', name: translate('autoApproval.fourHours') },
  { id: 'sixHours', name: translate('autoApproval.sixHours') },
  { id: 'eightHours', name: translate('autoApproval.eightHours') },
  { id: 'twentyFourHours', name: translate('autoApproval.twentyFourHours') },
  { id: 'today', name: translate('autoApproval.today') },
  { id: 'tomorrow', name: translate('autoApproval.tomorrow') },
  { id: 'thisWeek', name: translate('autoApproval.thisWeek') },
];

export const buffers = [
  { id: '0mins', name: translate('autoApproval.zeroMins') },
  { id: '15mins', name: translate('autoApproval.fifteenMins') },
  { id: '30mins', name: translate('autoApproval.thirtyMins') },
  { id: '45mins', name: translate('autoApproval.fortyFiveMins') },
  { id: '1hour', name: translate('autoApproval.oneHour') },
];

export const categories = [{ id: 'category', name: 'Category' }];

export const tableHeaders = [
  { id: 'category', name: translate('settings.category') },
  { id: 'selection', name: translate('settings.selection') },
  { id: 'details', name: translate('settings.details') },
  { id: 'actions', name: translate('settings.actions') },
];

export const defaultValues = {
  endTime: new Date(new Date().setHours(17, 0, 0, 0)),
  friday: false,
  monday: false,
  saturday: false,
  siteTimeZone: timeZones[0].id,
  startTime: new Date(new Date().setHours(9, 0, 0, 0)),
  sunday: false,
  thursday: false,
  tuesday: false,
  wednesday: false,
};

export const startWeek = new Date();
export const endWeek = new Date(new Date().setDate(new Date().getDate() + 7));
