import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { translate } from '@/i18n';
import { FormWrapper } from '@/modules/Settings/components/common/FormWrapper';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { changePhoneNumberFormSchema } from '@/shared/schemas/validate-schemas';
import { notifyService } from '@/shared/services/notify.service';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages';

import { SettingsFormProps } from '@/modules/Settings/types/types.ts';
import { Inputs } from '@/shared/types/global/common.types.ts';

export const ChangePhoneForm: FC<SettingsFormProps> = ({ isEditable }) => {
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: zodResolver(changePhoneNumberFormSchema),
  });

  const isButtonDisabled = Object.keys(errors).length > 0;

  const handleChangePhoneNumber = async (data: Inputs) => {
    try {
      console.log('data:', data);
    } catch (error) {
      console.error('[error]:', error);
      notifyService.error(ERROR_MESSAGES.UPDATE_USER_FAILED);
    }
  };

  return (
    <form
      autoComplete="off"
      className="flex items-end gap-4"
      id="changePhoneNunber"
      onSubmit={handleSubmit(handleChangePhoneNumber)}
    >
      <FormWrapper className="grid-cols-1">
        <Label labelText={translate('settings.phoneNumber')}>
          <Input
            {...register('phoneNumber')}
            aria-label="phoneNumber-textfield"
            disabled={!isEditable}
            error={errors?.phoneNumber?.message}
            placeholder={'(684) 555-0102'}
            type="tel"
          />
        </Label>
      </FormWrapper>

      {isEditable && (
        <Button
          aria-label="change-phoneNumber-action"
          disabled={isButtonDisabled || !isValid}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate(isSubmitting ? 'settings.saving' : 'settings.save')}
        </Button>
      )}
    </form>
  );
};
