import { useMemo } from 'react';

import { useGetReservations } from '@/modules/Reservations/hooks/userGetReservations';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import { useGlobalStore } from '@/shared/store/store.ts';

import { ReservationType } from '@/shared/types/global/reservations.types';

export const useFilteredReservationData = (subcontractorId: null | string) => {
  const assetsCategories = useGlobalStore(store => store.assetsCategories);

  const allReservations = useGetReservations();
  const { assets } = useEquipment();

  const filteredReservations: ReservationType[] = useMemo(() => {
    return (
      allReservations
        ?.filter(reservation => reservation.user.orgResName === subcontractorId)
        .map(reservation => {
          const equipment = assets.find(asset => asset.assetResName === reservation.assetResName);
          const category = assetsCategories.find(
            cat => cat.categoryTreeResName === equipment?.categoryTreeResName,
          );
          const subCategory = category?.categoryPathList.filter(
            sub => sub.categoryPathResName === equipment?.categoryPathResName,
          );
          return { ...reservation, category, equipment, subCategory };
        })
        .filter(reservation => reservation.equipment) ?? []
    );
  }, [allReservations, assets, assetsCategories, subcontractorId]);

  return { filteredReservations: filteredReservations };
};
