import { ComponentProps, forwardRef } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type TableRowProps = ComponentProps<'tr'> & WithChildren & WithClassName;

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <tr
        className={classnames(
          'flex w-full items-center border-b-[1px] border-slate-200 px-4 py-0.5 transition-colors duration-300 hover:bg-slate-50',
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </tr>
    );
  },
);
