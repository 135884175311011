import { useRef } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY, RESERVATIONS } from '@/shared/utils/constants';

import { useWorkflowEngine } from './useWorkflowEngine';

import { UpdateReservationEvent } from '@/shared/api/reservations/reservations.types';
import { ReservationUpdateParamsWithEvent } from '@/shared/types/global/reservations.types.ts';

export function useUpdateReservations() {
  const isEvent = useRef(false);

  const site = useGlobalStore(state => state.site);
  const setModalSubmitting = useGlobalStore(state => state.setModalSubmitting);

  const { user } = useFetchUserData();
  const workFlowCall = useWorkflowEngine();
  const queryClient = useQueryClient();

  const { mutateAsync, ...states } = useMutation({
    mutationFn: async (data: ReservationUpdateParamsWithEvent) => {
      const { event, ...body } = data;
      isEvent.current = event;
      setModalSubmitting(true);
      return await ReservationsAPINamespace.updateReservation(body);
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      setModalSubmitting(false);
      notifyService.error('Reservation update failed');
    },

    onSuccess: async successData => {
      if (isEvent.current) {
        const stateUpdate: UpdateReservationEvent = {
          accountResName: user?.accountResourceName ?? '',
          reservationResName: String(successData.reservationResName),
          siteResName: site,
          triggerEventType:
            successData.workflowContext.currentStateName == RESERVATIONS.conflictedSub
              ? RESERVATIONS.eventResolveConflictSub
              : successData.workflowContext.currentStateName == RESERVATIONS.rejectedSub
              ? RESERVATIONS.eventNewFromRejectSub
              : successData.workflowContext.currentStateName == RESERVATIONS.conflictedMgr
              ? RESERVATIONS.eventResolveConflictMgr
              : RESERVATIONS.eventPendingSub,
          userResName: user?.userResourceName,
        };

        await workFlowCall.mutateAsync(stateUpdate);
      } else {
        setModalSubmitting(false);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
        notifyService.success('Reservation updated successfully');
      }
    },
  });

  return { mutateAsync, states };
}
