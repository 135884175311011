import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { SidebarParameters } from '@/shared/types/global/parameters.types.ts';

export const useGeofenceParams = () => {
  const queryClient = useQueryClient();

  const [searchParameters, setSearchParameters] = useSearchParams();

  const updateSearchParameters = () => {
    setSearchParameters(searchParameters => {
      searchParameters.set(URL_PARAMS.SIDEBAR, SidebarParameters.EDIT);
      return searchParameters;
    });
  };

  return {
    queryClient,
    searchParameters,
    setSearchParameters,
    updateSearchParameters,
  };
};
