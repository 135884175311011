import { queryOptions, useQuery } from '@tanstack/react-query';

import { ReadersAPINamespace } from '@/shared/api/readers/readers.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { isWithinZRange } from '@/shared/utils/assets';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { FloorProps } from '../floors/floors.types';

export namespace useReadersHooks {
  export const getAll = () => {
    const site = useGlobalStore(state => state.site);
    const setReaders = useGlobalStore(state => state.setReaders);
    const readerVisibility = useGlobalStore(state => state.readerVisibility);
    const floor = useGlobalStore(state => state.floor);

    const { user } = useFetchUserData();
    const { isHistoricalMode } = useLocalSearchParams();

    const readersQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        const data = await ReadersAPINamespace.getAll({
          accountResourceName: user?.accountResourceName as string,
          siteResourceName: site,
        });

        const modifiedData = data?.results
          .map(reader => {
            return {
              ...reader,
              id: reader.readerResName,
              visible: readerVisibility,
            };
          })
          .filter(reader => reader.readerLocation);

        const filteredData = modifiedData.filter(reader => {
          const zMeters = (reader.readerLocation.z as number) / 10000;
          return isWithinZRange(zMeters, { ...floor } as FloorProps);
        });

        setReaders(filteredData ?? []);

        return modifiedData;
      },

      queryKey: [
        QUERY_KEY.GET_READERS,
        user?.accountResourceName,
        user,
        site,
        isHistoricalMode,
        readerVisibility,
        floor,
      ],
    });

    return useQuery(readersQueryOptions);
  };
}
