import { FC } from 'react';

import { translate } from '@/i18n';
import { equipmentHistoryList } from '@/modules/Equipment/components/tabs/Details/data';
import { renderColumns } from '@/modules/Equipment/utils/Details.utils.tsx';
import { Button } from '@/shared/components/buttons/Button';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { EquipmentCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';
import { Typography } from '@/shared/components/ui/Typography';
import { useEquipmentHideReservationsTypes } from '@/shared/feature-flags/useEquipmentHideReservationsTypes.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { classnames } from '@/shared/utils/classnames.ts';

import Refresh from '@/assets/icons/refresh-sm.svg?react';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';

export const EquipmentDetails: FC<Partial<AssetBody>> = equipment => {
  const { equipmentHideReservationsTypesFlag } = useEquipmentHideReservationsTypes();

  const { renderItemVisibility } = useRole();

  const isReservationHistoryVisible = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);

  return (
    <div className="relative inline-flex w-full gap-x-4">
      {equipment && (
        <div
          className={classnames(
            'sticky top-4 flex h-fit flex-shrink-0 flex-col justify-between gap-y-3',
            equipmentHideReservationsTypesFlag.enabled ? 'w-[400px]' : 'w-full',
          )}
        >
          <EquipmentCard {...{ equipment: equipment as AssetBody, renderItemVisibility }} />
        </div>
      )}

      {equipmentHideReservationsTypesFlag.enabled && isReservationHistoryVisible && (
        <div className="flex h-full w-full flex-col gap-y-3 overflow-auto">
          <Typography className="text-xl font-bold">{translate('global.reservation')}</Typography>

          <div className="flex flex-col gap-y-4">
            <div className="inline-flex items-center justify-between gap-x-4">
              <TopSection scope={['zone', 'date']} />

              <Button className="inline-flex items-center justify-center gap-x-2" variant="outline">
                {translate('global.refresh')} <Refresh />
              </Button>
            </div>

            <DefaultTable
              className="max-h-[calc(100dvh-349px)]"
              columns={renderColumns()}
              data={equipmentHistoryList}
              debugTable
              isPaginated
            />
          </div>
        </div>
      )}
    </div>
  );
};
