import { FC } from 'react';

import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { DOTS, usePagination } from '@/shared/components/navigation/Pagination/hooks/usePagination';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { PaginationProps } from '@/shared/components/navigation/Pagination/Pagination.types';

export const Pagination: FC<PaginationProps> = ({
  className,
  currentPage,
  onPageChange,
  pageSize,
  siblingCount = 1,
  totalCount,
}) => {
  const paginationRange = usePagination({
    currentPage,
    pageSize,
    siblingCount,
    totalCount,
  }) as (number | string)[];

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange.at(-1);

  return (
    <nav className={classnames('flex items-center gap-x-1 px-2 py-4', className)}>
      <IconButton
        className="size-[38px] border-transparent p-3 transition-colors duration-300 hover:bg-slate-800 hover:text-white hover:opacity-100 focus:bg-slate-800 focus:text-white focus:opacity-100"
        disabled={currentPage === 1}
        icon="left-arrow"
        onClick={onPrevious}
      />

      <div className="flex items-center gap-x-1">
        {paginationRange.map(page => {
          if (page === DOTS) {
            return (
              <Typography as="span" className="" key={page}>
                {DOTS}
              </Typography>
            );
          }

          return (
            <Button
              className={classnames(
                'inline-flex size-[38px] items-center justify-center rounded-lg border border-transparent px-3 py-2 text-sm text-gray-800 transition-colors duration-300 ease-in-out hover:bg-slate-800 hover:text-white focus:bg-slate-800 focus:text-white ',
                {
                  'bg-slate-800 text-white': currentPage === page,
                },
              )}
              key={page}
              onClick={() => onPageChange(page as number)}
              variant="custom"
            >
              {page}
            </Button>
          );
        })}
      </div>

      <IconButton
        className="size-[38px] rotate-180 border-transparent p-3 transition-colors duration-300 hover:bg-slate-800 hover:text-white hover:opacity-100 focus:bg-slate-800 focus:text-white focus:opacity-100"
        disabled={currentPage === lastPage}
        icon="left-arrow"
        onClick={onNext}
      />
    </nav>
  );
};
