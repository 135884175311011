import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { renderPrice } from '@/modules/Equipment/utils/helpers.tsx';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { TableLink } from '@/shared/components/tables/TableLink';
import { Badge } from '@/shared/components/ui/Badge';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { PopupTypeEnum, RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

export const renderColumns = (
  renderItemVisibility: (list: RolesEnum[]) => boolean,
): ColumnDef<AssetBody>[] => [
  {
    accessorFn: row => {
      return row.assetName ?? '';
    },
    accessorKey: 'equipment',
    cell: ({ row }) => (
      <TableLink className="max-w-full" to={`${RoutesEnum.EQUIPMENT}/${row.original.assetResName}`}>
        {row.original.assetName}
      </TableLink>
    ),
    header: () => translate('global.equipment'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'isReserved',
    cell: ({ row }) => (row.original.isReserved ? 'Reserved' : 'Available'),
    header: () => translate('assets.availability'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'tagsAttached',
    cell: ({ row }) => <div>{row.original.attachedTags?.map(tag => tag.tagName).join('\n')}</div>,
    header: () => translate('assets.tagAssigned'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorFn: row => {
      const property = row.extraProperties?.find(property => property.key === 'price');

      return property?.value ? Number(property.value) : '';
    },
    accessorKey: 'price',
    cell: ({ row }) => {
      const isVisible = renderItemVisibility([RolesEnum.MANAGER]);

      return isVisible ? renderPrice(row.original) : '-';
    },
    header: () => translate('global.price'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'status',
    cell: ({ row }) => (
      <Badge
        className={classnames(
          'h-6 rounded-md border-slate-400  p-[8px] text-slate-800 first-letter:uppercase',
          {
            'border-green-600 text-green-600': row.original.inUse,
          },
        )}
        variant="outline"
      >
        {row.original.inUse ? 'In use' : 'Idle'}
      </Badge>
    ),
    header: () => translate('filters.status'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'actions',
    cell: ({ row }) => {
      const { handler } = useToggleModal();

      const isVisible = renderItemVisibility([
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.KOTO,
      ]);

      const isGreyedOut = renderItemVisibility([RolesEnum.KOTO]);

      return (
        isVisible && (
          <div className="ml-auto mr-4 inline-flex gap-x-[10px]">
            <IconButton
              className="size-[38px] rounded-md border-[1px] border-slate-200"
              disabled={isGreyedOut}
              icon="edit-sm"
              onClick={() =>
                handler(PopupTypeEnum.EQUIPMENT_EDIT, {
                  tableUpdate: true,
                  ...row.original,
                })
              }
            />
          </div>
        )
      );
    },
    header: '',
  },
];
