import { useSearchParams } from 'react-router-dom';

import { useAnalyticsHooks } from '@/shared/api/analytics/analytics.hooks.ts';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { useSystemTimeZone } from '@/shared/hooks/reports/useSystemTimeZone.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { initializePickerTime } from '@/shared/utils/initialize-picker-time.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { TimeInZone } from '@/shared/types/reports/analytics.types.ts';

export function useTimeZone() {
  const [searchParameters] = useSearchParams();

  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const dateParameter = searchParameters.get(URL_PARAMS.MULTI_DATE);
  const multiAssets = searchParameters.get(URL_PARAMS.MULTI_ASSETS);
  const multiZones = searchParameters.get(URL_PARAMS.MULTI_ZONES);
  const currentExtraGroup = searchParameters.get(URL_PARAMS.EXTRA_GROUP);

  const { transformTimeToEpoch } = useSystemTimeZone();
  const { initialEndTime, initialStartTime } = initializePickerTime(dateParameter);
  const { endTime, startTime } = transformTimeToEpoch(initialStartTime, initialEndTime, 'TIZ');

  const { data, isLoading } = useAnalyticsHooks.getGeofenceSummaries({ endTime, startTime });
  const { data: workers = [] } = useAssetHooks.getAllWorkers();
  const { data: equipment = [] } = useAssetHooks.getAllEquipments();

  const result = data?.results?.map(item => ({
    episodes: item.episodes,
    geofenceResResName: item.geofenceResResName,
    totalTime: item.totalDwellTimeSeconds,
    ...(item.asset as AssetBody),
  })) as unknown as TimeInZone[];

  const combinedMultiZones = multiZones?.split(',').filter(item => item !== '') ?? [];
  const combinedMultiAssets = multiAssets?.split(',').filter(item => item !== '') ?? [];

  const filteredMultiZonesResult = result?.filter(item => {
    if (combinedMultiZones.length > 0)
      return combinedMultiZones.includes(String(item.geofenceResResName));

    return false;
  });

  const filteredMultiAssetsResult = filteredMultiZonesResult?.filter(item => {
    if (combinedMultiAssets.length > 0)
      return combinedMultiAssets.includes(String(item.assetResName));

    return true;
  });

  const renderTimes = () => {
    const uniqueItems: Record<string, Pick<TimeInZone, 'assetName' | 'geofenceResResNames'>> = {};

    filteredMultiAssetsResult?.forEach(item => {
      const { assetResName, episodes, geofenceResResName, totalTime, ...asset } = item;

      if (!uniqueItems[assetResName]) {
        uniqueItems[assetResName] = {
          ...asset,
          geofenceResResNames: [],
        };
      }

      uniqueItems[assetResName].geofenceResResNames.push({
        episodes,
        geofenceResResName,
        totalTime,
      });
    });

    const transformedResult = Object.entries(uniqueItems).map(([assetResourceName, data]) => {
      return {
        assetResourceName,
        ...data,
        geofenceResResNames: data.geofenceResResNames,
      };
    });

    const filteredFormedResult = transformedResult.filter(
      item =>
        item.assetName.toLowerCase().includes(searchFieldValue.toLowerCase()) ||
        item.geofenceResResNames.some(zone =>
          zone.geofenceResResName.toLowerCase().includes(searchFieldValue.toLowerCase()),
        ),
    );

    return filteredFormedResult ?? [];
  };

  const times = renderTimes();

  let totalManHours = 0;
  times.forEach(item =>
    item.geofenceResResNames.forEach(name => (totalManHours += Number(name.totalTime))),
  );

  let totalEntries = 0;
  times.forEach(item =>
    item.geofenceResResNames.forEach(name => {
      totalEntries += Number(name.episodes.length ?? 0);
    }),
  );

  const renderAssetsByTypes = (type: 'equipment' | 'people') => {
    if (type === 'people') {
      const modifiedElements = workers.filter(item =>
        combinedMultiAssets.includes(item.assetResName),
      );

      return modifiedElements.length;
    }

    if (type === 'equipment') {
      const modifiedElements = equipment.filter(item =>
        combinedMultiAssets.includes(item.assetResName),
      );

      return modifiedElements.length;
    }

    return 0;
  };

  const selectedPeople = renderAssetsByTypes('people');
  const selectedEquipment = renderAssetsByTypes('equipment');

  return {
    currentExtraGroup: currentExtraGroup ?? '',
    initialTimes: filteredMultiAssetsResult ?? [],
    isLoading,
    selectedEquipment,
    selectedPeople,
    times,
    totalEntries,
    totalManHours,
  };
}
