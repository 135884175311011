import { RolesEnum } from '@/shared/types/global/enums.ts';
import { RuleType } from '@/shared/types/global/rules.types.ts';

export namespace Rules {
  export const Login: RuleType[] = [
    {
      name: 'account_name',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'username',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'password',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'language_selector',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'forgot_password',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'terms_of_use_popup',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'select_site',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'select_floor',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
  ];

  export const MapRules: RuleType[] = [
    {
      name: 'real_time_view',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'show_filter_equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'show_filter_readers',
      roles: [RolesEnum.MANAGER, RolesEnum.WORKER, RolesEnum.KOTO],
    },
    {
      name: 'show_filter_people_from_own_firm',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'show_filter_people_from_other_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'show_filter_people_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'show_filter_equipment_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'create_edit_red_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'toggle_red_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'toggle_worker_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'toggle_inactive',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'show_inactive_zones',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'show_all_zones',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'show_filter_redzones',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'create_edit_public_work_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'show_filter_public_work_zones',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'show_filter_map_view',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'create_edit_private_work_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'show_filter_private_work_zones',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'historical_view',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'historical_time_in_zone',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
  ];

  export const Reservation: RuleType[] = [
    {
      name: 'calendar_view',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'list_view',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'auto_approval_settings_button',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'show_own_firm_reservations',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'show_other_firm_reservations',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'show_own_firm_reservation_history',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'show_other_firm_reservation_history',
      roles: [RolesEnum.MANAGER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'highlight_label_conflicting_reservation_requests',
      roles: [RolesEnum.MANAGER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'reservation_drafts',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
  ];

  export const Equipment: RuleType[] = [
    {
      name: 'show_all_equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'show_equipment_price',
      roles: [RolesEnum.MANAGER],
    },
    {
      name: 'add_new_equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'add_reservation_button',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'show_total_equipment_and_equipment_in_use_stats',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'show_tag_id',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'create_edit_public_equipment_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'view_public_equipment_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'create_private_equipment_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'view_private_equipment_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_individual_equipment_page_show_rental_company',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_individual_equipment_page_show_reservation_history',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'on_individual_equipment_page_show_price',
      roles: [RolesEnum.MANAGER],
    },
    {
      name: 'edit_equipment_types_subtypes',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
  ];

  export const People: RuleType[] = [
    {
      name: 'show_all_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'click_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'click_other_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'invite_new_workers',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'on_firm_page_show_tag_id',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'on_firm_page_show_unresolved_actions',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_firm_page_show_current_zone',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'on_firm_page_show_total_workers_and_workers_on_site',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'access_individual_person_page_for_people_in_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'access_individual_person_page_for_people_in_other_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'on_individual_person_page_see_unresolved_actions',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_individual_person_page_see_archived_actions',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_individual_person_page_see_email_phone',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'on_individual_person_page_see_zps_access_list',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'create_edit_public_people_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'view_public_people_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'create_edit_private_people_groups_with_people_from_all_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'create_edit_private_people_groups_with_people_from_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'view_private_people_groups',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'on_people_show_reservation_tab',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
  ];

  export const Reports: RuleType[] = [
    {
      name: 'time_in_zone_report_for_equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'time_in_zone_report_for_people_in_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'time_in_zone_report_for_people_in_other_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'utilization_report',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'trips_report',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'site_presence_report_for_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'site_presence_report_for_people',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'tag_diagnostics_report_for_equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'tag_diagnostics_report_for_people_in_own_firm',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.KOTO],
    },
    {
      name: 'tag_diagnostics_report_for_people_in_other_firms',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'audit_log_report',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'plan_upload_report',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'zone_exit_entry_report',
      roles: [RolesEnum.KOTO],
    },
  ];

  export const Actions: RuleType[] = [
    {
      name: 'all_view',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'system',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'safety',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'equipment',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR, RolesEnum.WORKER],
    },
    {
      name: 'zones',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'reservation',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'progress',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.SUBCONTRACTOR],
    },
    {
      name: 'archive',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
  ];

  export const DropDownMenu: RuleType[] = [
    {
      name: 'site_selection',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'language_selector',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'settings_link',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'sign_out_button',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'licenses_link',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'release_notes_link',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'map_tab',
      roles: [RolesEnum.WORKER],
    },
    {
      name: 'reservations_tab',
      roles: [RolesEnum.WORKER],
    },
    {
      name: 'equipment_tab',
      roles: [RolesEnum.WORKER],
    },
    {
      name: 'actions_tab',
      roles: [RolesEnum.WORKER],
    },
  ];

  export const Settings: RuleType[] = [
    {
      name: 'profile_tab',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'system_tab',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
    {
      name: 'on_system_tab_currency_option',
      roles: [RolesEnum.MANAGER],
    },
    {
      name: 'site_hours_tab',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'site_map_tab',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER, RolesEnum.KOTO],
    },
    {
      name: 'auto_approval_tab',
      roles: [RolesEnum.MANAGER, RolesEnum.ENGINEER],
    },
    {
      name: 'actions_tab',
      roles: [
        RolesEnum.MANAGER,
        RolesEnum.ENGINEER,
        RolesEnum.SUBCONTRACTOR,
        RolesEnum.WORKER,
        RolesEnum.KOTO,
      ],
    },
  ];
}

export const rules = [
  ...Rules.Login,
  ...Rules.MapRules,
  ...Rules.Reservation,
  ...Rules.Equipment,
  ...Rules.People,
  ...Rules.Reports,
  ...Rules.Actions,
  ...Rules.DropDownMenu,
  ...Rules.Settings,
];
