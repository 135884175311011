import { useGlobalStore } from '@/shared/store/store.ts';

import { FirmsDatasource } from '@/shared/types/users/subcontractors.types.ts';

export const useFirmsSearch = (firms: FirmsDatasource[], firmZone: null | string) => {
  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const filteredFirms = firms.filter(firm => {
    const firmZoneIds = firm.zones.flatMap(occupant =>
      occupant.enclosingGeofences.map(geofence => geofence.geofenceResName),
    );
    const matchesFirmZone = firmZone ? firmZoneIds.includes(firmZone) : true;
    return firm.firm_name.toLowerCase().includes(searchFieldValue.toLowerCase()) && matchesFirmZone;
  });

  return { filteredFirms };
};
