import { useGlobalStore } from '@/shared/store/store.ts';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useToggleModal = () => {
  const openModal = useGlobalStore(state => state.openModal);

  const handler = <T>(window: PopupTypeEnum, payload?: T) => {
    openModal(window, payload);
  };

  return { handler };
};
