import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithChildren &
  WithClassName & {
    withSidebar?: boolean;
  };

export const PageWrapper: FC<Props> = ({ children, className, withSidebar = false, ...props }) => {
  if (withSidebar) {
    return (
      <section
        className={classnames('fullHeightWithoutHeader grid grid-cols-[320px_1fr]', className)}
        {...props}
      >
        {children}
      </section>
    );
  }

  return (
    <section className={classnames('fullHeightWithoutHeader px-6 py-3', className)} {...props}>
      {children}
    </section>
  );
};
