import { baseQuery, signRequest } from '@/shared/api/utils';

import { FloorProps, FloorsDefaultProps } from '@/shared/api/floors/floors.types.ts';

export namespace FloorsAPINamespace {
  const client = baseQuery('ENVIRONMENT');

  export const getAll = async (data: FloorsDefaultProps): Promise<FloorProps[]> => {
    const url = `/accounts/${data.accountResourceName}/sites/${data.siteResourceName}/floors`;

    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result?.data?.results;
  };
}
