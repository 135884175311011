import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

import { translate } from '@/i18n';
import { Typography } from '@/shared/components/ui/Typography';
import { MISSING_DATA } from '@/shared/utils/constants.ts';

import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';
import { SlicedTimeInZone } from '@/shared/types/reports/analytics.types.ts';

export const renderColumns = (locale: string): ColumnDef<SlicedTimeInZone>[] => [
  {
    accessorKey: 'assetName',
    cell: ({ row }) => (
      <Typography className="text-sm font-bold">{row.original.asset.assetName}</Typography>
    ),
    header: () => translate('global.givenName'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'familyName',
    cell: () => <Typography className="text-sm font-bold">{MISSING_DATA}</Typography>,
    header: () => translate('global.familyName'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'geofenceName',
    cell: ({ row }) => (
      <Typography className="text-sm font-bold">{row.original.geofenceName}</Typography>
    ),
    header: () => translate('global.zone'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'entryTime',
    cell: ({ row }) => (
      <Typography className="text-sm">
        {format(
          new Date(row.original.episode.entryTime.epochTime),
          locale === LocaleEnum.JPN ? 'HH:mm:ss yyyy/MM/dd' : 'HH:mm:ss dd.MM.yyyy',
        )}
      </Typography>
    ),
    header: () => translate('reports.zoneEntryTime'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'exitTime',
    cell: ({ row }) => (
      <Typography className="text-sm">
        {format(
          new Date(row.original.episode.exitTime.epochTime),
          locale === LocaleEnum.JPN ? 'HH:mm:ss yyyy/MM/dd' : 'HH:mm:ss dd.MM.yyyy',
        )}
      </Typography>
    ),
    header: () => translate('reports.zoneExitTime'),
    meta: {
      isFiltered: true,
    },
  },
];
