import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import { useGlobalStore } from '@/shared/store/store.ts';

import { useGetReservationsHistory } from './useGetReservationsHistory';

export const useModifyReservationHistoryData = () => {
  const [searchParameters] = useSearchParams();
  const searchParametersWithoutStep = new URLSearchParams(searchParameters);
  searchParametersWithoutStep.delete('step');
  const { activeAssets, inactiveAssets } = useEquipment();
  const { firms, search } = useGlobalStore(store => ({
    firms: store.companies,
    search: store.searchFieldValue.toLowerCase(),
  }));
  //   const resourcePointer = searchParameters.get('resourcePointer');
  const workflowState = searchParametersWithoutStep.get('workflowState');
  const status = searchParametersWithoutStep.get('status');
  const orgResourceName = searchParametersWithoutStep.get('firm');
  //   const activityType = searchParameters.get('activityType');
  const multiDate = searchParametersWithoutStep.get('multi-date');
  const isEquipmentInactive = status === 'inactive';
  const multiDateArray = multiDate?.split(',');
  const startTime = multiDateArray ? multiDateArray[0] : null;
  const endTime = multiDateArray ? multiDateArray[1] : null;

  const { data, fetchNextPage, refetch } = useGetReservationsHistory({
    params: {
      //   resourcePointer,
      // status,
      workflowState,
      //   assetResName,
      orgResName: orgResourceName,
      //   activityType,
      endTime,
      startTime,
    },
  });

  const { pages } = data ?? {};

  const allReservationsHistory =
    pages
      ?.flatMap(item => item.results)
      ?.filter(item => {
        if (searchParameters.size === 0) return true;

        //   const matchesResourcePointer = !resourcePointer || item.resourcePointer === resourcePointer;
        const matchesWorkflowState = !workflowState || item.workflowStateName === workflowState;
        const matchesStatus = !status || item.status === status;
        //   const matchesEquipment = !orgResName || item.assetResName === orgResName;
        const matchesFirm = !orgResourceName || item.user.orgResName === orgResourceName;
        //   const matchesActivityType = !activityType || item.activityType === activityType;
        //   const matchesTimeRange = (!startTime || item.reservationPeriod.startTime.epochTime >= Number(startTime) * 1000) &&
        //                           (!endTime || item.reservationPeriod.endTime.epochTime <= Number(endTime) * 1000);

        return matchesStatus && matchesFirm && matchesWorkflowState;
      })
      .map(item => {
        const asset = isEquipmentInactive
          ? inactiveAssets.find(asset => asset.assetResName === item.assetResName)
          : activeAssets.find(asset => asset.assetResName === item.assetResName);
        return {
          date: item.timestamp.epochTime,
          endTime: item.reservationPeriod.endTime.epochTime,
          equipment: asset?.assetName,
          firm: firms.find(firm => firm.orgResName === item.user.orgResName)?.orgName,
          reservation: item.workflowStateName,
          startTime: item.reservationPeriod.startTime.epochTime,
          subType: asset?.subType,
          type: asset?.type,
        };
      })
      .filter(item => item.equipment?.toLowerCase().includes(search)) ?? [];

  // Filtering will be locally on client side for now
  useEffect(() => {
    if (searchParametersWithoutStep.size > 0 && startTime !== null && endTime !== null) {
      refetch();
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (data?.pages[data?.pages.length - 1].page.hasNext) {
      fetchNextPage();
    }
  }, [data]);

  return {
    allReservationsHistory,
    refetch,
  };
};
