import { FC } from 'react';

import { NumberFlow } from '@/shared/components/animation/NumberFlow';
import { Badge } from '@/shared/components/ui/Badge';

type Props = {
  count: number;
};

export const TabCounter: FC<Props> = ({ count }) => {
  return (
    <Badge className="px-1.5" color="blue" rounded="full" variant="solid">
      <NumberFlow value={count > 0 ? count : 0} />
    </Badge>
  );
};
