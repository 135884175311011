import { useGlobalStore } from '@/shared/store/store.ts';
import { formatDate } from '@/shared/utils/date-fns';

import { ReservationType } from '@/shared/types/global/reservations.types';

type ReservationSearchParams = null | string;

type Props = {
  reservations: ReservationType[];
  reservationStatus: ReservationSearchParams;
  reservationDate: ReservationSearchParams;
};

export const useSearchReservation = ({
  reservationDate,
  reservations,
  reservationStatus,
}: Props) => {
  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const searchedFilteredReservations = reservations?.filter(reservation => {
    const matchesStatus = reservationStatus
      ? reservationStatus.toLowerCase() === reservation.status.toLowerCase()
      : true;
    const startDate = formatDate(new Date(reservation.reservationPeriod.startTime.epochTime));
    const endDate = formatDate(new Date(reservation.reservationPeriod.endTime.epochTime));
    const searchDate = formatDate(new Date(reservationDate as string));
    const matchesDate = reservationDate ? searchDate === startDate || searchDate === endDate : true;

    const matchesSearchField = reservation.equipment?.assetName
      .toLowerCase()
      .includes(searchFieldValue.toLowerCase());

    return matchesSearchField && matchesDate && matchesStatus;
  });

  return { searchedFilteredReservations };
};
