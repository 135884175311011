import { FC, useEffect, useState } from 'react';

import { useThree } from '@react-three/fiber';

import { AnchorPopup } from '@/modules/Home/components/map/AnchorPopup';
import {
  useAnchorPopupState,
  useMapModelDetails,
  useSmoothAnchorPosition,
} from '@/modules/Home/hooks/map';
import { CLUSTER_CONFIG } from '@/modules/Home/utils/MapModel.config';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AnchorProps, AssetTypeEnum } from '@/modules/Home/types/MapModel.types';
import { CustomIconType } from '@/shared/components/ui/CustomIcon/CustomIcon.types.ts';

export const Anchor: FC<AnchorProps> = ({ active, anchor, position, visible }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { camera } = useThree();

  const locatedAnchors = useGlobalStore(state => state.locatedAnchor);
  const setLocatedAnchor = useGlobalStore(state => state.setLocatedAnchor);
  const siteId = useGlobalStore(state => state.site);

  const { setIsHovered } = useMapModelDetails();
  const { anchorRef } = useSmoothAnchorPosition(position);
  useAnchorPopupState(isOpened, setIsOpened);

  const isLocatedAnchor = locatedAnchors.includes(anchor?.tagResName as string);

  useEffect(() => {
    setIsOpened(isLocatedAnchor);
  }, [isLocatedAnchor]);

  const renderPinType = (): CustomIconType => {
    const isWorker = anchor?.categoryTreeName?.includes(AssetTypeEnum.Worker);
    const isEquipment = anchor?.categoryTreeName?.includes(AssetTypeEnum.Equipment);

    if (isEquipment) {
      return anchor?.tracker ?? !active ? 'trackerEquipmentPin' : 'equipmentPin';
    }

    if (isWorker) {
      return anchor?.tracker ?? !active ? 'trackerWorkerPin' : 'workerPin';
    }

    return 'equipmentPin';
  };

  const handleTogglePopup = () => {
    if (isLocatedAnchor && isOpened) {
      setLocatedAnchor(anchor?.tagResName as string);
    }

    setIsOpened(isOpened => !isOpened);
  };
  const zoomLevel =
    CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL_CUSTOM[siteId] ?? CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL;

  const hazardArea =
    typeof anchor?.hazardArea === 'string' ? JSON.parse(anchor.hazardArea) : anchor?.hazardArea;

  const hazardRadius = hazardArea?.r / 100;

  return (
    <mesh ref={anchorRef}>
      {(camera.position.z < zoomLevel || visible || isOpened) && (
        <mesh
          onPointerOut={() => setIsHovered(false)}
          onPointerOver={() => setIsHovered(true)}
          renderOrder={1} //NOSONAR
        >
          <AnchorPopup
            anchor={anchor ?? {}}
            isOpened={isOpened}
            togglePopup={handleTogglePopup}
            type={renderPinType()}
          />
        </mesh>
      )}

      {hazardArea && (
        <mesh
          position={[0, 1, 0]} //NOSONAR
          rotation={[-Math.PI / 2, 0, 0]} //NOSONAR
        >
          <circleGeometry
            args={[hazardRadius || 0, 64]} //NOSONAR
          />

          <meshBasicMaterial
            color="red"
            opacity={0.4}
            transparent={true} //NOSONAR
          />
        </mesh>
      )}
    </mesh>
  );
};
