import { FC } from 'react';

import { renderColumns } from '@/modules/Subcontractors/utils/Archive.utils';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { TopSection } from '@/shared/components/ui/TopSection';

export const Archive: FC = () => (
  <div className="flex flex-col gap-y-4">
    <div className="inline-flex h-[38px] w-full items-center justify-between">
      <TopSection scope={['all']} />
    </div>

    <DefaultTable columns={renderColumns()} data={[]} debugTable isPaginated />
  </div>
);
