import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { mapSettingsFormSchema } from '@/shared/schemas/validate-schemas';
import { useGlobalStore } from '@/shared/store/store.ts';

import { Inputs } from '@/shared/types/global/common.types.ts';

export const useSiteMapSettings = () => {
  const { mapPreview, setMapPreview, siteMapList } = useGlobalStore();
  const floor = useGlobalStore(state => state.floor);

  const form = useForm<Inputs>({
    mode: 'onChange',
    resolver: zodResolver(mapSettingsFormSchema),
  });

  const preview = siteMapList.find(map => map.mapResName === mapPreview);
  const floorMap = siteMapList.find(map => map.mapName === floor?.name);
  const activeMap = preview ?? floorMap ?? siteMapList[0];

  const handlePreviewSelected = (data: Inputs): void => {
    const selected = siteMapList.find(map => map.mapName === data.selectedSiteMap);
    console.log({ selected });
    if (selected) {
      setMapPreview(selected.mapResName);
    }
  };

  useEffect(() => {
    form.setValue('selectedSiteMap', activeMap?.mapName);
  }, [activeMap, form]);

  useEffect(() => {
    return () => {
      setMapPreview(null);
    };
  }, [setMapPreview]);

  return {
    ...form,
    activeMap,
    handlePreviewSelected,
    mapList: siteMapList.map(map => ({ id: map.mapName, name: map.mapName })),
  };
};
