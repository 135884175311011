import { Tab } from '@headlessui/react';

import { translate } from '@/i18n';
import { CustomHorizontalTab } from '@/shared/components/ui/CustomHorizontalTabs/CustomHorizontalTabs.tsx';
import { SubcontractorsFormBody } from '@/shared/components/ui/Modal/Subcontractor/FormBody.tsx';
import { useInviteForm } from '@/shared/components/ui/Modal/Subcontractor/hooks/useInviteForm.ts';
import { Typography } from '@/shared/components/ui/Typography';

export function InviteSubcontractor() {
  const {
    firmForm,
    firmFormHandler,
    personForm,
    personFormHandler,
    props: params,
  } = useInviteForm();

  const tabs = [{ name: 'global.firm' }, { name: 'global.person' }];

  const renderTitle = () => {
    switch (params.type) {
      case 'firmEdit':
        return translate('global.editFirm');
      case 'invite':
        return translate('global.invite');
      case 'workerEdit':
        return translate('global.editWorker');
    }
  };

  return (
    <div className="space-y-4">
      <Typography as="strong" className="text-xl font-bold">
        {renderTitle()}
      </Typography>

      <CustomHorizontalTab setTabMode={value => params.setMode(value)} tabs={tabs}>
        <Tab.Panel>
          <SubcontractorsFormBody {...params} handler={firmFormHandler} params={firmForm} />
        </Tab.Panel>

        <Tab.Panel>
          <SubcontractorsFormBody {...params} handler={personFormHandler} params={personForm} />
        </Tab.Panel>
      </CustomHorizontalTab>
    </div>
  );
}
