import { FC, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { TransitionChild } from '@/shared/components/animation/TransitionChild';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Overlay } from '@/shared/components/ui/Overlay';
import { Typography } from '@/shared/components/ui/Typography';
import { useLockBodyScroll } from '@/shared/hooks/ui/useLockBodyScroll.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithChildren &
  WithClassName & {
    isOpen: boolean;
    onClose: () => void;
    overlayBg?: 'blue' | 'gray';
    dialogTitle?: ReactNode;
  };

export const MobileDialog: FC<Props> = ({
  children,
  className,
  dialogTitle = '',
  isOpen,
  onClose,
  overlayBg = 'gray',
}) => {
  useLockBodyScroll(isOpen);

  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="relative z-10 focus:outline-none md:hidden" onClose={onClose}>
        <Overlay
          className={classnames(
            'z-40  backdrop-blur-sm',
            overlayBg === 'blue' && 'bg-blue-600 ',
            overlayBg === 'gray' && 'bg-black/25',
          )}
        />

        <div className="fixed inset-0 z-50 h-dvh w-screen overflow-y-auto overflow-x-hidden">
          <TransitionChild to="content">
            <Dialog.Panel
              className={classnames(
                'absolute right-0 top-0 z-50 min-h-[calc(100dvh)] w-full bg-white',
                className,
              )}
            >
              {dialogTitle && (
                <header className="flex justify-between px-4">
                  <Typography as="h2" className="text-xl font-semibold text-slate-800">
                    {dialogTitle}
                  </Typography>

                  <IconButton icon="close" onClick={onClose} />
                </header>
              )}
              {children}
            </Dialog.Panel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
