import { SystemViewNamespace } from '@/shared/services/system-view.service';
import { generatePoints } from '@/shared/utils/generate-points';

import { Point } from '@/shared/types/global/geofences.types.ts';
import { SystemViewEnum } from '@/shared/types/settings/system.types.ts';

/** Note: Consider removing since it doesn't alter the point in any way */
export const mapPolygonPoints = (points: Point[]) => {
  return points?.map(point => {
    return {
      x: +point?.x,
      y: +point?.y,
      z: +point?.z,
    };
  });
};

export const processPolygonPoints = (points: Point[], systemView: SystemViewEnum): Point[] => {
  const mappedPoints = mapPolygonPoints(points);

  const generatedPoints = mappedPoints.length === 2 ? generatePoints(mappedPoints) : mappedPoints;

  return SystemViewNamespace.convertToDefaultPoints(systemView, generatedPoints);
};
