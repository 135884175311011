import { ForwardedRef, forwardRef, ReactNode } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { DefaultInputProps } from '@/shared/types/global/common.types.ts';

interface InputProps extends DefaultInputProps {
  children: ReactNode;
  className?: string;
  error?: string;
}

export const Radio = forwardRef(
  ({ children, className, error, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <label
        className={classnames(
          'inline-flex items-center gap-x-2 p-1.5',
          props.disabled ? 'pointer-events-none' : 'cursor-pointer',
          className,
        )}
      >
        <input
          className="checkboxAnimation defaultFocusStyle mt-0.5 size-4 shrink-0 rounded-full border-gray-200 text-blue-600 md:size-3.5"
          ref={ref}
          type="radio"
          {...props}
        />
        {children}
      </label>
    );
  },
);
