import { useGlobalStore } from '@/shared/store/store.ts';

import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const useSetCurrentLanguage = () => {
  const setCurrentLanguage = useGlobalStore(state => state.setCurrentLanguage);

  const handleSetLanguage = (lang: string) => {
    setCurrentLanguage(lang as LocaleEnum);
  };

  return { handleSetLanguage };
};
