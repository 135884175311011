import { useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useSessionStorage, useTitle } from 'react-use';

import { AppLayout } from '@/shared/components/layout/AppLayout';
import { Footer } from '@/shared/components/layout/Footer';
import { Main } from '@/shared/components/layout/Main';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useTermsOfUse } from '@/shared/hooks/observer/useTermsOfUse.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { LS_KEYS, TEST_ID } from '@/shared/utils/constants';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const AuthLayout = () => {
  const site = useGlobalStore(store => store.site);

  const { isAuthorized } = useFetchUserData();
  const [location] = useSessionStorage(LS_KEYS.LOCATION);
  const { handleInitializeTermOfUse, termsOfUseStatus } = useTermsOfUse();
  const [searchParams] = useSearchParams();

  useTitle('ZaiNar | Login');

  useEffect(() => {
    handleInitializeTermOfUse(termsOfUseStatus);
  }, [termsOfUseStatus, handleInitializeTermOfUse]);

  useEffect(() => {
    if (isAuthorized && site && termsOfUseStatus === 'done') {
      notifyService.success(ERROR_MESSAGES.LOGIN_SUCCESS);
    }
  }, [isAuthorized, site, termsOfUseStatus]);

  if (isAuthorized && site && termsOfUseStatus === 'done') {
    const storedLocation = location || RoutesEnum.HOME;
    const navigateTo =
      searchParams.size > 0 ? `${storedLocation}?${searchParams.toString()}` : storedLocation;

    return <Navigate data-testid={TEST_ID.NAVIGATE} to={navigateTo} />;
  }

  return (
    <AppLayout>
      <Main className="content-center bg-white md:bg-slate-100">
        <Outlet data-testid={TEST_ID.OUTLET} />
      </Main>

      <Footer variant="guest" />
    </AppLayout>
  );
};
