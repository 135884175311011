import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useUpdateAssetCompanyName } from '@/modules/Home/hooks/features';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { SidebarFilterService } from '@/shared/services/sidebar-filter.service.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

export const useFilteredData = () => {
  const [searchParams] = useSearchParams();

  const assetResourceName = searchParams.get(URL_PARAMS.ASSET_RES_NAME);

  const workers = useGlobalStore(state => state.workersInUse);
  const geofences = useGlobalStore(state => state.geofences);
  const equipment = useGlobalStore(state => state.equipmentInUse);
  const readers = useGlobalStore(state => state.readers);
  const sidebarSearchKey = useGlobalStore(state => state.sidebarFilterValue);
  const mapFilter = useGlobalStore(state => state.mapFilter);

  const { filteredAssetsCategories } = useAssetsCategories();
  const { updatedData: workersWithFirm } = useUpdateAssetCompanyName(workers);
  const { updatedData: equipmentWithFirm } = useUpdateAssetCompanyName(equipment);

  const filterService = useMemo(
    () => new SidebarFilterService(mapFilter, sidebarSearchKey, assetResourceName),
    [mapFilter, sidebarSearchKey, assetResourceName],
  );

  return useMemo(
    () =>
      filterService.getFilteredData(
        filteredAssetsCategories,
        workersWithFirm,
        equipmentWithFirm,
        geofences,
        readers,
      ),
    [
      filterService,
      filteredAssetsCategories,
      workersWithFirm,
      equipmentWithFirm,
      geofences,
      readers,
    ],
  );
};
