import { ChangeEvent, FC, ReactNode, useRef } from 'react';
import { useClickAway, useMedia, useToggle } from 'react-use';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Checkbox } from '@/shared/components/form/Checkbox';
import { useMapFilter } from '@/shared/components/ui/Modal/MapFilter/hooks/useMapFilter.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';

import ChevronUpDownIcon from '@/assets/icons/select-arrow.svg?react';
import { ActionSubCategoryEnum } from '@/shared/types/actions/actions.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { FilterSelectNameEnum } from '@/shared/types/global/filters.types.ts';
import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

type FilterSelectProps = {
  name: FilterSelectNameEnum;
  className?: string;
  asSelect?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  assetsWithTags?: boolean;
  longText?: boolean;
};

export const FilterSelect: FC<FilterSelectProps> = ({
  asSelect,
  assetsWithTags,
  className,
  longText = true,
  name,
  onChange,
  value,
}: FilterSelectProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const geofences = useGlobalStore(state => state.geofences);
  const assets = useGlobalStore(state => state.assets);

  const { filterOptions } = useMapFilter();
  const [isOpen, setIsOpen] = useToggle(false);

  const options = filterOptions[name];

  const translatedOption = (option: string) => {
    return translate(`map.${option.replace(/[_-]/g, '').toLowerCase()}`);
  };

  const renderSortedOptions = () => {
    if (name === FilterSelectNameEnum.ZONE_ITEM) {
      const sortedGeofences = geofences
        .filter(geofence => (options as string[]).includes(geofence.geofenceResName))
        .sort((a, b) => a.geofenceName.localeCompare(b.geofenceName));

      return sortedGeofences.map(geofence => geofence.geofenceResName ?? '-');
    } else if (name === FilterSelectNameEnum.ASSET_ITEM) {
      const filteredAssets = assets.filter(asset => {
        const currentTagProperty = asset.extraProperties?.find(property => property.key === 'tag');

        return !!currentTagProperty;
      });

      const result = assetsWithTags ? filteredAssets : assets;

      const sortedAssets = result
        .filter(asset => (options as string[]).includes(asset.assetResName))
        .sort((a, b) => a.assetName.localeCompare(b.assetName));

      return sortedAssets.map(asset => asset.assetResName ?? '-');
    } else return options;
  };

  useClickAway(dropdownRef, () => setIsOpen(false));
  const isDeepMobile = useMedia('(max-width: 475px)');

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      const newValues = [...value, name];
      onChange(newValues);
    } else {
      const newValues = value.filter(item => item !== name);
      onChange(newValues);
    }
  };

  const renderButtonText = () => {
    if (value.length > 0) {
      switch (name) {
        case FilterSelectNameEnum.ACTION_EQUIPMENT_SUBCATEGORY:
        case FilterSelectNameEnum.ACTION_SAFETY_SUBCATEGORY:
        case FilterSelectNameEnum.ACTION_SYSTEM_SUBCATEGORY:
        case FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY: {
          return value.map((v, index) => {
            return (
              <>
                {actionSubCategoryTranslations[v]}
                {index < value.length - 1 ? ', ' : null}
              </>
            );
          });
        }
        case FilterSelectNameEnum.ASSET_ITEM: {
          const modifiedAssets = value.map(
            item => assets.find(asset => asset.assetResName === item)?.assetName,
          );
          const checkedModifiedAssets = modifiedAssets.filter(item => !!item);

          return checkedModifiedAssets.join(', ');
        }
        case FilterSelectNameEnum.ZONE_ITEM: {
          const modifiedGeofences = value.map(
            item => geofences.find(geofence => geofence.geofenceResName === item)?.geofenceName,
          );
          const checkedModifiedGeofences = modifiedGeofences.filter(item => !!item);

          return checkedModifiedGeofences.join(', ');
        }
        case FilterSelectNameEnum.ZONE_TYPE: {
          return value.map((v, index) => (
            <>
              {translatedOption(v)}
              <>{index < value.length - 1 && ', '}</>
            </>
          ));
        }
        default: {
          return value.join(', ');
        }
      }
    } else if (value.length <= 0) {
      if (name === FilterSelectNameEnum.ZONE_ITEM) {
        return translate('filters.zone');
      } else if (name === FilterSelectNameEnum.ASSET_ITEM) {
        return translate('global.asset');
      }
    } else if (asSelect) {
      return translate('auth.select');
    }

    const translateKey = `filters.${name.split('.').reverse()[0]}`;
    return translate(translateKey);
  };

  const renderOptionLabel = (
    option: ActionSubCategoryEnum | GeofenceCategoryEnum | RolesEnum | string,
    name: FilterSelectNameEnum,
  ) => {
    switch (name) {
      case FilterSelectNameEnum.ASSET_ITEM: {
        const matchedAssetItem = assets.find(asset => asset.assetResName === option);
        return matchedAssetItem?.assetName;
      }
      case FilterSelectNameEnum.ZONE_ITEM: {
        const matchedGeofenceItem = geofences.find(geofence => geofence.geofenceResName === option);
        return matchedGeofenceItem?.geofenceName;
      }
      case FilterSelectNameEnum.ZONE_TYPE: {
        return translatedOption(option);
      }
      default:
        return [
          FilterSelectNameEnum.ACTION_EQUIPMENT_SUBCATEGORY,
          FilterSelectNameEnum.ACTION_SAFETY_SUBCATEGORY,
          FilterSelectNameEnum.ACTION_SYSTEM_SUBCATEGORY,
          FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY,
        ].includes(name)
          ? actionSubCategoryTranslations[option]
          : option;
    }
  };

  const renderCheckbox = (
    option: GeofenceCategoryEnum | RolesEnum | string,
    name: FilterSelectNameEnum,
  ) => (
    <Checkbox
      checked={value.includes(option)}
      key={option}
      onChange={event => handleCheckboxChange(event, option)}
    >
      <Typography
        className={classnames('flex-1 text-left text-sm font-medium first-letter:capitalize', {
          'break-anywhere': longText,
          'max-w-44 truncate': !longText,
        })}
      >
        {renderOptionLabel(option, name)}
      </Typography>
    </Checkbox>
  );

  return (
    <div
      className={classnames('relative', {
        'w-full': isDeepMobile,
      })}
    >
      <Button
        className={classnames(
          'disabled:hover-none inline-flex w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2 pr-2.5 text-left text-sm font-normal text-slate-500 transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50',
          isOpen && 'pointer-events-none',
          isDeepMobile && 'w-full min-w-full',
          className,
        )}
        disabled={options.length === 0}
        onClick={setIsOpen}
        variant="custom"
      >
        <Typography
          className={classnames('', {
            'break-anywhere': longText,
            truncate: !longText,
          })}
        >
          {renderButtonText()}
        </Typography>

        <ChevronUpDownIcon aria-hidden="true" className="h-4 w-4 shrink-0" />
      </Button>

      {isOpen && (
        <div
          className="filterSectionAnimation absolute left-0 z-50 mt-2 grid max-h-80 w-full origin-top-left overflow-y-auto rounded-lg border border-slate-200 bg-white p-3 shadow-lg"
          ref={dropdownRef}
        >
          {renderSortedOptions()?.map(option => renderCheckbox(option, name))}
        </div>
      )}
    </div>
  );
};

const actionSubCategoryTranslations: { [k: string]: ReactNode } = {
  [ActionSubCategoryEnum.AFTER_HOURS]: translate('alert.subCategory.after_hours'),
  [ActionSubCategoryEnum.BEHIND]: translate('alert.subCategory.behind'),
  [ActionSubCategoryEnum.DANGER]: translate('alert.subCategory.danger'),
  [ActionSubCategoryEnum.EMERGENCY]: translate('alert.subCategory.emergency'),
  [ActionSubCategoryEnum.READER]: translate('alert.subCategory.reader'),
  [ActionSubCategoryEnum.RED_ZONE]: translate('alert.subCategory.red_zone'),
  [ActionSubCategoryEnum.RESERVATION]: translate('alert.subCategory.reservation'),
  [ActionSubCategoryEnum.TAG]: translate('alert.subCategory.tag'),
  [ActionSubCategoryEnum.UPDATE]: translate('alert.subCategory.update'),
  [ActionSubCategoryEnum.UTILIZATION]: translate('alert.subCategory.utilization'),
  [ActionSubCategoryEnum.WORK_ZONE]: translate('alert.subCategory.work_zone'),
  [ActionSubCategoryEnum.ZONE]: translate('alert.subCategory.zone'),
};
