import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table } from '@tanstack/react-table';

import { URL_PARAMS } from '@/shared/utils/constants.ts';

type Props<T> = {
  table: Table<T>;
  perPageCount?: string;
};

export function useTablePagination<T>({ perPageCount: customPerPageCount, table }: Props<T>) {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const count = Number(searchParameters.get(URL_PARAMS.COUNT));
  const step = Number(searchParameters.get(URL_PARAMS.STEP));

  const isPrevNavigationDisabled = table.getState().pagination.pageIndex <= 1;
  const isNextNavigationDisabled = table.getPageCount() === step;

  useEffect(() => {
    table.setPageSize(Number(customPerPageCount) || count);
  }, [count, customPerPageCount, table]);

  useEffect(() => {
    table.setPageIndex(step - 1);
  }, [step, table]);

  useEffect(() => {
    const pageCount = table.getPageCount();

    if (step !== 1 && step > pageCount)
      setSearchParameters(searchParameters => {
        searchParameters.set(URL_PARAMS.STEP, String(pageCount));
        return searchParameters;
      });
  }, [setSearchParameters, step, table]);

  const handleSetSearchParameter = (field: string, value: string) => {
    setSearchParameters(searchParameters => {
      searchParameters.set(field, value);
      return searchParameters;
    });
  };

  return {
    count,
    handleSetSearchParameter,
    isNextNavigationDisabled,
    isPrevNavigationDisabled,
    step: table.getState().pagination.pageIndex,
  };
}
