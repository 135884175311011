import { ActionTitleEnum } from '@/shared/types/actions/actions.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';

export const actionRoleAccessMap: {
  [k in ActionTitleEnum]: {
    [v in RolesEnum]: boolean;
  };
} = {
  [ActionTitleEnum.CRITICAL_BATTERY]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.DEAD_BATTERY]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: true,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.DEVICE_UNRESPONSIVE]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_ENTERED_RED_ZONE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_IN_USE_BY_WRONG_SUB_FIRM]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_IN_USE_WITHOUT_RESERVATION]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.EQUIPMENT_NOT_UTILIZED_ON_WORKDAY]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_REDZONE_ACTIVATED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.EQUIPMENT_REDZONE_RELEASED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_APPROVED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_CANCELLED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_EDITED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_REQUEST]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_SUB_REQUEST]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVATION_WORKER_REQUEST]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_RESERVED_NOTINUSE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.LOW_BATTERY]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.NEW_RESERVATION]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.NO_UPCOMING_EQUIPMENT_RESERVATION]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.PROXIMITY_RULE_REDZONE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.READER_OFFLINE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.READER_ONLINE]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.RESERVATION_APPROVED_WITH_CHANGES]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.RESERVATION_CONFLICT]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.RESERVATION_DRAFT_EXPIRED]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.RESERVATION_REQUEST_EXPIRED]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.TAG_LEFT_SITE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.TAG_OFFLINE]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.TAG_ONLINE]: {
    [RolesEnum.ENGINEER]: false,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: false,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.WORKER_REDZONE_ENTERED]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: true,
    [RolesEnum.WORKER]: true,
  },
  [ActionTitleEnum.WORKERS_LEFT_SITE_FOR_DAY]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
  [ActionTitleEnum.WORKERS_STILL_ON_SITE_AFTER_5PM]: {
    [RolesEnum.ENGINEER]: true,
    [RolesEnum.KOTO]: false,
    [RolesEnum.MANAGER]: true,
    [RolesEnum.SUBCONTRACTOR]: false,
    [RolesEnum.WORKER]: false,
  },
};
