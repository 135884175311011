import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';
import { LS_KEYS } from '@/shared/utils/constants';

class UserStorageService {
  public getAccountName() {
    return WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.ACCOUNT_NAME);
  }

  public getTermsOfUse() {
    return WebAPIStorageNamespace.LocalStorageService.getLocalItemParsed(LS_KEYS.TERM_OF_USE) as
      | 'done'
      | 'idle';
  }

  public getUserName() {
    return WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USERNAME);
  }

  public migrateToSession(): void {
    const rememberMe =
      WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.REMEMBER_ME) === 'true';

    const savedUserName = WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USERNAME);

    const savedAccountName = WebAPIStorageNamespace.LocalStorageService.getLocalItem(
      LS_KEYS.ACCOUNT_NAME,
    );

    WebAPIStorageNamespace.LocalStorageService.removeLocalItem(LS_KEYS.USER_DATA);
    WebAPIStorageNamespace.SessionService.removeSessionItem(LS_KEYS.USER_DATA);
    WebAPIStorageNamespace.SessionService.removeSessionItem(LS_KEYS.LOCATION);

    if (rememberMe) {
      WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.REMEMBER_ME, rememberMe);

      WebAPIStorageNamespace.LocalStorageService.setLocalItem(
        LS_KEYS.USERNAME,
        savedUserName,
        false,
      );

      WebAPIStorageNamespace.LocalStorageService.setLocalItem(
        LS_KEYS.ACCOUNT_NAME,
        savedAccountName,
        false,
      );
    }
  }

  public setRememberMe(
    rememberMe: boolean,
    userData: Record<string, unknown>,
    accountName: string,
  ): void {
    WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.REMEMBER_ME, rememberMe);

    if (rememberMe) {
      WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.USER_DATA, userData);

      WebAPIStorageNamespace.LocalStorageService.setLocalItem(
        LS_KEYS.USERNAME,
        userData.username,
        false,
      );

      WebAPIStorageNamespace.LocalStorageService.setLocalItem(
        LS_KEYS.ACCOUNT_NAME,
        accountName,
        false,
      );
    } else {
      WebAPIStorageNamespace.SessionService.setSessionItem(LS_KEYS.USER_DATA, userData);
    }
  }
}

export const userStorageService = new UserStorageService();
