import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useAnalyticsHooks } from '@/shared/api/analytics/analytics.hooks.ts';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment.ts';
import { useSystemTimeZone } from '@/shared/hooks/reports/useSystemTimeZone.ts';
import { LS_KEYS, URL_PARAMS } from '@/shared/utils/constants.ts';
import { dateToEncoded } from '@/shared/utils/date-to-encoded.ts';
import { generateId } from '@/shared/utils/helpers.ts';

import { TimelineStatus, UtilizationAccordsPropsBody } from '@/modules/Reports/types/types.ts';

export function useUtilization() {
  const [searchParameters] = useSearchParams();

  const dateParameter = searchParameters.get(URL_PARAMS.DATE);
  const multiAssets = searchParameters.get(URL_PARAMS.MULTI_ASSETS);
  const zoneParameter = searchParameters.get(URL_PARAMS.ZONE);

  const [isShowInactive, toggle] = useLocalStorage(LS_KEYS.IS_SHOW_INACTIVE, false);
  const { transformTimeToEpoch } = useSystemTimeZone();
  const { assets, categoryTreeResourceName } = useEquipment();

  const startEncodedTimeResponse = dateToEncoded(dateParameter ?? '');
  const endEncodedTimeResponse = dateToEncoded(dateParameter ?? '', true);
  const { endTime, startTime } = transformTimeToEpoch(
    startEncodedTimeResponse,
    endEncodedTimeResponse,
    'Utilization',
  );

  const { data, isLoading: isUtilizationLoading } = useAnalyticsHooks.getAssetUtilizationSummaries({
    categoryTreeResourceName,
    dateParameter,
    endTime,
    startTime,
  });

  const unDeletedAssets = assets.filter(asset => asset.assetStatus !== 'deleted');
  const filteredAssets = unDeletedAssets.filter(
    asset => data?.results.find(item => asset.assetResName === item.asset.assetResName),
  );
  const combinedMultiAssets = multiAssets?.split(',').filter(item => item !== '') ?? [];
  const fileredAssetsWithParameters = filteredAssets.filter(asset => {
    return combinedMultiAssets.includes(asset.assetResName);
  });
  const modifiedUtilization = fileredAssetsWithParameters.map(asset => {
    const existingItems = data?.results.filter(
      item => item.asset.assetResName === asset.assetResName,
    );

    const timelines = Array.from({ length: 32 }).fill(TimelineStatus.IDLE) as TimelineStatus[];

    const WORK_DAY_START_HOUR = 9;
    const WORK_DAY_END_HOUR = 17;
    const WORK_DAY_TOTAL_HOURS = WORK_DAY_END_HOUR - WORK_DAY_START_HOUR;
    const INTERVAL = (WORK_DAY_TOTAL_HOURS * 3600 * 1000) / 32;

    existingItems?.forEach(item => {
      const startTime = new Date(item.startTime.epochTime);
      let endTime = new Date(item.endTime.epochTime);

      endTime = new Date(endTime.getTime() + 60 * 1000);

      const startHour = startTime.getUTCHours();
      const endHour = endTime.getUTCHours();

      if (startHour < WORK_DAY_END_HOUR && endHour >= WORK_DAY_START_HOUR) {
        const adjustedStartTime = Math.max(
          startTime.getTime(),
          new Date(startTime).setUTCHours(WORK_DAY_START_HOUR, 0, 0, 0),
        );
        const adjustedEndTime = Math.min(
          endTime.getTime(),
          new Date(endTime).setUTCHours(WORK_DAY_END_HOUR, 0, 0, 0),
        );
        const adjustedDuration = adjustedEndTime - adjustedStartTime;

        const startIndex = Math.floor(
          (adjustedStartTime - new Date(startTime).setUTCHours(WORK_DAY_START_HOUR, 0, 0, 0)) /
            INTERVAL,
        );
        const endIndex = Math.min(31, startIndex + Math.floor(adjustedDuration / INTERVAL));

        for (let index = startIndex; index <= endIndex; index++) {
          timelines[index] = item.containingGeofenceResNames.includes(zoneParameter)
            ? TimelineStatus.ACTIVE
            : TimelineStatus.INACTIVE;
        }
      }
    });

    return {
      ...asset,
      timelines: timelines.map(timeline => ({
        id: generateId(),
        value: timeline,
      })),
      utilization: existingItems,
    };
  });

  let totalTimeInUse = 0;

  modifiedUtilization?.forEach(item =>
    item.timelines.forEach(timeline => {
      if (timeline.value !== TimelineStatus.IDLE) totalTimeInUse += 15 * 60;
    }),
  );

  const totalCalculatedTime = totalTimeInUse / 60;

  const filteredModifiedUtilization = modifiedUtilization.filter(item => {
    const mappedTimelines = new Set(item.timelines.map(timeline => timeline.value));

    return (
      mappedTimelines.has(TimelineStatus.IDLE) && !mappedTimelines.has(TimelineStatus.INACTIVE)
    );
  });

  const toggleShowInactive = () => toggle(!isShowInactive);

  return {
    filteredModifiedUtilization,
    isShowInactive,
    isUtilizationLoading,
    toggleShowInactive,
    totalCalculatedTime,
    utilization: modifiedUtilization as UtilizationAccordsPropsBody[],
  };
}
