import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';

import { ActionsDatasource } from '@/shared/types/users/subcontractors.types.ts';

export const renderColumns = (): ColumnDef<ActionsDatasource>[] => [
  {
    accessorKey: 'action',
    cell: ({ row }) => <div className="font-bold">{row.original.action ?? '-'}</div>,
    header: () => translate('Action'),
  },
  {
    accessorKey: 'type',
    cell: ({ row }) => <div className="font-bold">{row.original.type ?? '-'}</div>,
    header: () => translate('Type'),
  },
  {
    accessorKey: 'timestamp',
    cell: ({ row }) => <div>{row.original.timestamp ?? '-'}</div>,
    header: () => translate('Timestamp'),
  },
  {
    accessorKey: 'details',
    cell: ({ row }) => <div>{row.original.details ?? '-'}</div>,
    header: () => translate('Details'),
  },
];
