import { FC } from 'react';

import { translate } from '@/i18n';
import { renderCurrentZone, renderPrice } from '@/modules/Equipment/utils/helpers.tsx';
import { MobileCard } from '@/shared/components/ui/MobileCard';
import { useAssetCategoryType } from '@/shared/hooks/assets/useAssetCategoryType.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { MISSING_DATA } from '@/shared/utils/constants.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { PopupTypeEnum, RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

export const EquipmentMobileCard: FC<{
  equipment: AssetBody;
}> = ({ equipment }) => {
  const { renderItemVisibility } = useRole();
  const { handler } = useToggleModal();
  const { categoryType } = useAssetCategoryType(equipment);
  const isVisible = renderItemVisibility([RolesEnum.MANAGER]);

  const normalizedCategoryType = categoryType.split('/').slice(1).join(', ') || '-';

  return (
    <MobileCard
      action={{
        label: translate('map.edit'),
        onClick: () =>
          handler(PopupTypeEnum.EQUIPMENT_EDIT, {
            tableUpdate: true,
            ...equipment,
          }),
      }}
      details={[
        { label: translate('assets.type'), value: normalizedCategoryType },
        {
          label: translate('global.price'),
          value: isVisible ? renderPrice(equipment) : MISSING_DATA,
        },
        {
          label: translate('filters.status'),
          value: equipment?.inUse ? translate('assets.inUse') : translate('reservation.idle'),
        },
        { label: translate('global.currentZone'), value: renderCurrentZone(equipment) },
      ]}
      link={`${RoutesEnum.EQUIPMENT}/${equipment.assetResName}`}
      status={equipment.assetStatus}
      statusColor={equipment.assetStatus === 'active' ? 'green' : 'gray'}
      title={equipment?.assetName}
    />
  );
};
