import { FC } from 'react';

import { translate } from '@/i18n';
import { MobileCard } from '@/shared/components/ui/MobileCard';
import { getCurrentDateAndTime } from '@/shared/utils/date-fns';

import { ReservationStatusEnum, RoutesEnum } from '@/shared/types/global/enums';
import { LocaleEnum } from '@/shared/types/global/i18n.types';
import { ReservationType } from '@/shared/types/global/reservations.types';

export const ReservationMobileCard: FC<{
  reservation: ReservationType;
}> = ({ reservation }) => {
  return (
    <MobileCard
      action={null}
      details={[
        {
          label: translate('assets.type'),
          value: reservation.subCategory?.map(subCategory => subCategory.categoryPath).join('\n'),
        },
        {
          label: translate('global.reservationStart'),
          value: getCurrentDateAndTime(
            new Date(reservation.reservationPeriod.startTime.epochTime),
            LocaleEnum.JPN,
          ),
        },
        {
          label: translate('global.reservationEnd'),
          value: getCurrentDateAndTime(
            new Date(reservation.reservationPeriod.endTime.epochTime),
            LocaleEnum.JPN,
          ),
        },
      ]}
      link={`${RoutesEnum.EQUIPMENT}/${reservation.assetResName}`}
      status={reservation.status}
      statusColor={
        reservation.status === ReservationStatusEnum.ACTIVE ||
        reservation.status === ReservationStatusEnum.APPROVED
          ? 'green'
          : 'gray'
      }
      title={reservation?.equipment?.assetName}
    />
  );
};
