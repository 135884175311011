export const QUERY_SCHEMA = {
  GARBAGE_COLLECTOR_TIME: 3 * 60 * 1000,
  STALE_TIME: {
    '10_MINUTES': 10 * 60 * 1000,
    '15_MINUTES': 15 * 60 * 1000,
    '5_MINUTES': 5 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
  },
};
