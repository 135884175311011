import { queryOptions, useQuery } from '@tanstack/react-query';

import { FloorsAPINamespace } from '@/shared/api/floors/floors.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { checkOnSimilar } from '@/shared/utils/check-on-similar.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export namespace useFloorsHooks {
  export const getAll = () => {
    const { user } = useFetchUserData();

    const site = useGlobalStore(state => state.site);
    const floor = useGlobalStore(state => state.floor);
    const setFloor = useGlobalStore(state => state.setFloor);
    const setFloorsList = useGlobalStore(state => state.setFloorsList);

    const floorsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        const data = await FloorsAPINamespace.getAll({
          accountResourceName: user?.accountResourceName as string,
          siteResourceName: site,
        });

        const mappedResult = data.map(({ floorName, floorResName, ...rest }) => ({
          ...rest,
          id: floorResName,
          name: floorName,
        }));

        const result = checkOnSimilar(mappedResult, 'id').sort((a, b) =>
          a.floorIndex > b.floorIndex ? 1 : -1,
        );

        setFloorsList(result);

        if (!floor) setFloor(result[0] ?? null);

        return data;
      },

      queryKey: [QUERY_KEY.FLOORS, user?.accountResourceName, site, floor],
    });

    return useQuery(floorsQueryOptions);
  };
}
