import { baseQuery, signRequest } from '@/shared/api/utils.ts';

import {
  CreateReservationProps,
  GetOneReservationProps,
  GetReservationHistoryProps,
  GetReservationProps,
  ReservationHistoryResponse,
  ReservationResponse,
  ReservationsListResponse,
  UpdateReservationEvent,
} from '@/shared/api/reservations/reservations.types.ts';
import {
  Reservation,
  ReservationType,
  ReservationUpdateParams,
} from '@/shared/types/global/reservations.types.ts';

export namespace ReservationsAPINamespace {
  const client = baseQuery('RESERVATIONS');

  export const getAllReservations = async ({
    accountResName,
    siteResName,
  }: GetReservationProps): Promise<ReservationResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations?status=active&limit=1000`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getOne = async ({
    accountResName,
    reservationResName,
    siteResName,
  }: GetOneReservationProps): Promise<Reservation> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations/${reservationResName}`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllReservationHistory = async ({
    accountResName,
    pageParam,
    params,
    siteResName,
  }: GetReservationHistoryProps): Promise<ReservationHistoryResponse> => {
    let url = `/accounts/${accountResName}/sites/${siteResName}/assets/histories/reservations?limit=1000&resourcePointer=${pageParam}`;

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          url += `&${key}=${value}`;
        }
      });
    }

    const signedRequest = await signRequest('RESERVATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createReservations = async ({
    accountResName,
    siteResName,
    ...body
  }: CreateReservationProps): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'POST', {
      ...body,
      accountResName,
      siteResName,
    });

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });
    return result.data;
  };

  export const updateReservationState = async ({
    accountResName,
    reservationResName,
    siteResName,
    ...body
  }: UpdateReservationEvent): Promise<ReservationsListResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations/${reservationResName}/workflow-events`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateReservation = async ({
    accountResName,
    reservationResName,
    siteResName,
    ...body
  }: ReservationUpdateParams): Promise<ReservationType> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations/${reservationResName}`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'PATCH', {
      ...body,
      siteResName,
    });

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
