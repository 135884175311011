import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useFiltersHideGroupsZonesShowMapFlag } from '@/shared/feature-flags/useFiltersHideGroupsZonesShowMapFlag.ts';

export const useWorkerProfileTableData = (workerId: null | string, isVisible: boolean) => {
  const { filtersHideGroupsZonesShowMapFlag } = useFiltersHideGroupsZonesShowMapFlag();
  const { activeWorker, assetsTags, firms } = usePeopleAsset();

  const tableData = () => {
    const data = [
      {
        key: 'global.firm',
        value: firms.find(firm => firm.id === activeWorker?.orgResName)?.firm_name ?? '',
      },
      {
        key: 'global.role',
        value: activeWorker?.role + '',
      },
      {
        key: 'global.currentZone',
        value: '-',
      },
      {
        key: 'global.tags',
        value: assetsTags
          .filter(assetTag => assetTag.asset?.assetResName === workerId)
          .map(assetTag => assetTag.tag?.tagName)
          .join(', '),
      },
      {
        key: 'global.email',
        value: isVisible ? activeWorker?.email + '' : '-',
      },
      {
        key: 'global.phone',
        value: isVisible ? activeWorker?.phone + '' : '-',
      },
      {
        key: 'global.zpsAccess',
        value: isVisible ? activeWorker?.allowedSites ?? '-' : '-',
      },
    ];

    if (filtersHideGroupsZonesShowMapFlag.enabled) {
      data.splice(3, 0, { key: 'global.groups', value: '-' });
    }

    return data;
  };

  return {
    workerTableData: tableData(),
  };
};
