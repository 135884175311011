import { FC } from 'react';

import { DetailsMobileCard } from '@/modules/Subcontractors/components/common/DetailsMobileCard';
import { MobileList } from '@/shared/components/ui/MobileList';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/global/common.types.ts';
import { TableActions, WorkersDatasource } from '@/shared/types/users/subcontractors.types.ts';

type Props = WithClassName & {
  workers: WorkersDatasource[];
  action: (type: TableActions, id: string) => void;
  isLoading: boolean;
};

export const DetailsMobileList: FC<Props> = ({ action, className, isLoading, workers }) => {
  if (isLoading) {
    return <Skeleton count={3} subTitleCount={5} variant="mobile-card" withStatus />;
  }

  return (
    <MobileList className={classnames(className)}>
      {workers.map(worker => (
        <DetailsMobileCard action={action} key={worker.id} worker={worker} />
      ))}
    </MobileList>
  );
};
