import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';

export const RequiredFieldIndicator: FC = () => {
  return (
    <Typography as="span" className="text-sm text-red-500">
      *
    </Typography>
  );
};
