import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGlobalStore } from '@/shared/store/store.ts';

export function useEditGroup() {
  const content = useGlobalStore(state => state.modalPayload);

  const params = useForm<FieldValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    params.reset({
      ...(content.categoryTreeName && { title: content.categoryTreeName }),
    });
  }, [content, params]);

  return { content, params };
}
