import type { ReactNode } from 'react';

import { addMinutes, format } from 'date-fns';

import { translate } from '@/i18n';
import { Badge } from '@/shared/components/ui/Badge';
import { Typography } from '@/shared/components/ui/Typography';
import {
  CategorySubTypes,
  CategoryTypes,
  useTableAccordions,
} from '@/shared/hooks/table/useTableAccordions.tsx';

import { TimelineStatus, UtilizationAccordsPropsBody } from '@/modules/Reports/types/types.ts';

type Props<K> = {
  items: (data: K) => ReactNode;
  utilization: UtilizationAccordsPropsBody[];
};

export function UtilizationAccordsBody<T extends Record<string, string>, K>({
  items,
  utilization,
}: Readonly<Props<K>>) {
  const { currentCategories, handleToggleAccordion } = useTableAccordions('equipment');

  const renderCustomMeta = <T extends Record<string, string>>(args: T, flow: 'subs' | 'types') => {
    let totalTimeInUse = 0;
    let utilizationRate = 0;
    let filteredUtilization: UtilizationAccordsPropsBody[] = [];

    if (flow === 'types') {
      filteredUtilization = utilization.filter(
        item => item.categoryTreeResName === args.categoryTreeResName,
      );
    } else if (flow === 'subs') {
      filteredUtilization = utilization.filter(
        item => item.categoryPathResName === args.categoryPathResName,
      );
    }

    filteredUtilization.forEach(item =>
      item.timelines.forEach(timeline => {
        if (timeline.value !== TimelineStatus.IDLE) {
          totalTimeInUse += 15 * 60;
          utilizationRate += 15;
        }
      }),
    );

    let totalCalculatedTime = new Date(totalTimeInUse * 1000);
    totalCalculatedTime = addMinutes(totalCalculatedTime, totalCalculatedTime.getTimezoneOffset());

    const renderUtilizationFrame = () => {
      const calculatedUtilizationPercent = filteredUtilization.length * 8 * 60;
      const calculatedUtilizationFrame = utilizationRate / calculatedUtilizationPercent;
      const resultUtilizationFrame = calculatedUtilizationFrame * 100 || 0;

      return resultUtilizationFrame.toFixed(2);
    };

    return (
      <>
        <Badge className="shrink-0 bg-blue-100 text-[10px] uppercase text-blue-600" variant="solid">
          {translate('reports.totalTimeInUse')}: {format(totalCalculatedTime, 'HH:mm:ss') ?? '-'}
        </Badge>

        <Typography as="span" className="text-xs">
          /
        </Typography>

        <Badge
          className="shrink-0 bg-green-100 text-[10px] uppercase text-green-600"
          variant="solid"
        >
          {translate('global.utilization')}: {renderUtilizationFrame()}%
        </Badge>
      </>
    );
  };

  return (
    <CategoryTypes
      currentCategories={currentCategories}
      handleToggleAccordion={handleToggleAccordion}
      meta={(category: T) => renderCustomMeta(category, 'types')}
    >
      {category => (
        <CategorySubTypes
          category={category}
          handleToggleAccordion={handleToggleAccordion}
          items={(subCategory: K) => items(subCategory)}
          meta={(pathList: T) => renderCustomMeta(pathList, 'subs')}
        />
      )}
    </CategoryTypes>
  );
}
