export function dateToEncoded(date: string = '', endTime?: boolean) {
  const dateTimeString = date
    ? `${date.split('T')[0]}T00:00:00`
    : new Date().toISOString().split('T')[0] + 'T00:00:00';

  const localDate = new Date(dateTimeString + 'Z');

  const offset = 7 * 60 * 60 * 1000;
  const adjustedDate = new Date(localDate.getTime() + offset);

  adjustedDate.setHours(0, 0, 0, 0);
  adjustedDate.setDate(adjustedDate.getDate() + 1);
  if (endTime) adjustedDate.setDate(adjustedDate.getDate() + 1);

  const epochTime = adjustedDate.getTime();

  const response = {
    epochTime: epochTime.toString(),
  };

  return encodeURIComponent(JSON.stringify(response));
}

export const onEpochTime = (time: number) => {
  const response = {
    epochTime: time.toString(),
  };

  return encodeURIComponent(JSON.stringify(response));
};
