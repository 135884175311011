import { useSinglePrismicDocument } from '@prismicio/react';

import { usePrismicLocale } from '@/shared/hooks/prismic/usePrismicLocale.ts';
import { PrismicTypeEnum } from '@/shared/prismic-client/prismic.ts';

export const useTermsOfUseContent = () => {
  const { lang } = usePrismicLocale();
  const [document, { state }] = useSinglePrismicDocument(PrismicTypeEnum.TERMS, {
    lang,
  });

  const field = document?.data.terms_of_use;

  return { document, field, state };
};
