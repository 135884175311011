import { MISSING_DATA } from '@/shared/utils/constants';
import { generateId } from '@/shared/utils/helpers';

export const equipmentHistoryList = Array.from({ length: 100 }).map(_ => {
  return {
    date: MISSING_DATA,
    endTime: MISSING_DATA,
    firm: MISSING_DATA,
    id: generateId(),
    reservation: MISSING_DATA,
    startTime: MISSING_DATA,
  };
});
