import { FC, useEffect } from 'react';

import { useThree } from '@react-three/fiber';

import { useGlobalStore } from '@/shared/store/store.ts';

export const CameraDistance: FC = () => {
  const setCurrentDistance = useGlobalStore(state => state.setDistance);
  const { camera } = useThree();

  useEffect(() => {
    setCurrentDistance(camera.position.z);
  }, [camera.position.z, setCurrentDistance]);

  return null;
};
