import { useEffect, useState } from 'react';

import { Vector3NumberProps } from '@/modules/Home/types/MapModel.types';
import { Point } from '@/shared/types/global/geofences.types.ts';

export const useSetGeofencePosition = (points: Point[], maxHeight: number) => {
  const [position, setPosition] = useState<Vector3NumberProps>([0, 0, 0]);
  const [size, setSize] = useState<Vector3NumberProps>([1, 1, 1]);

  useEffect(() => {
    if (!points) return;

    const xSize = Number(points[2]?.x ?? 0) - Number(points[0]?.x ?? 0);
    const ySize = Number(points[2]?.y ?? 0) - Number(points[0]?.y ?? 0);
    const newSize = [xSize, maxHeight, ySize];

    const positionX = Number(points[0]?.x ?? 0) + 0.5 * xSize;
    const positionY = Number(points[0]?.y ?? 0) + 0.5 * ySize;
    const positionZ = Number(points[0]?.z ?? 0) + 0.5 * maxHeight;
    const newPosition = [positionX, positionZ, -positionY];

    setPosition(newPosition as Vector3NumberProps);
    setSize(newSize as Vector3NumberProps);
  }, [maxHeight, points]);

  return {
    position,
    setPosition,
    setSize,
    size,
  };
};
