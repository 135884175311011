import { useEffect, useState } from 'react';
import { Controller, FieldValues, useWatch } from 'react-hook-form';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Toggle } from '@/shared/components/form/Toggle';
import { actionCardIcons, actionCardThemes } from '@/shared/components/ui/ActionCard';
import { useActionCardData } from '@/shared/components/ui/ActionCard/hooks/useActionCardData.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import ArrowSm from '@/assets/icons/arrow-sm.svg?react';
import {
  ActionsCategoryProps,
  ActionsGroupProps,
  ActionsSubCategoryProps,
  AlertCategory,
} from '@/modules/Settings/components/common/ActionsGroup/types';
import {
  ActionCategoryEnum,
  ActionSeverityEnum,
  AlertProps,
} from '@/shared/types/actions/actions.types';

const groupAlertsByAttribute = (alerts: AlertProps[]): AlertCategory[] => {
  const groupedAlerts = alerts.reduce(
    (acc, alert) => {
      const attribute = alert.attribute || 'uncategorized';

      if (!acc[attribute]) {
        acc[attribute] = [];
      }
      acc[attribute].push(alert);

      return acc;
    },
    {} as Record<string, AlertProps[]>,
  );

  return Object.entries(groupedAlerts).map(([attribute, alerts]) => ({
    alerts,
    attribute,
  }));
};

const CategoryAttributeToggle = ({ category, control, isGloballyEnabled, title }: FieldValues) => {
  const parent = useWatch({
    control,
    name: `categories.${title}.enabled`,
  });

  const name = `categories.${title}.${category.attribute}.enabled`;

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field: { onChange, value } }) => {
        useEffect(() => {
          if (!parent) onChange(false);
        }, [parent, onChange]);

        return (
          <Toggle
            checked={value}
            color="green"
            disabled={!parent || !isGloballyEnabled}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

function AlertItem(alert: AlertProps) {
  const { severity, titleNode } = useActionCardData({ action: alert });

  const { fgClass } = actionCardThemes[severity] ?? actionCardThemes[ActionSeverityEnum.DEFAULT];

  return (
    <div className="flex h-[44px] shrink-0 items-center border-b-[1px] border-slate-200 pl-[85px]">
      <div className="flex items-center gap-2">
        <Typography as="span" className="text-sm">
          {titleNode}
        </Typography>

        <Typography
          aria-details="tag"
          as="span"
          className={`inline-block rounded px-2 py-0.5 text-xs capitalize text-white ${fgClass}`}
        >
          {translate(`alert.severity.${severity}`)}
        </Typography>
      </div>
    </div>
  );
}

export function ActionsSubCategory({
  category,
  control,
  isGloballyEnabled,
  isVisible,
  title,
}: ActionsSubCategoryProps) {
  const [isSubCategoryListVisible, setIsSubCategoryListVisible] = useState(true);

  const isTitleIndividual = title !== category.attribute;
  const lastActiveItems = category.alerts.slice(-50);

  return (
    <>
      {isTitleIndividual && (
        <Button
          className={classnames(
            'none flex h-[44px] shrink-0 items-center gap-4 border-b-[1px] border-slate-200 bg-slate-50 pl-12',
            { hidden: !isVisible },
          )}
          onClick={() => setIsSubCategoryListVisible(!isSubCategoryListVisible)}
          variant="custom"
        >
          <ArrowSm
            className={classnames('transform transition-transform', {
              'rotate-180': isSubCategoryListVisible,
            })}
          />

          <div className="flex items-center justify-between gap-x-2">
            <div onClick={event => event.stopPropagation()}>
              <CategoryAttributeToggle
                category={category}
                control={control}
                isGloballyEnabled={isGloballyEnabled}
                title={title}
              />
            </div>

            <Typography as="span" className="text-sm font-medium capitalize">
              {translate(`alert.subCategory.${category.attribute}`)}
            </Typography>
          </div>
        </Button>
      )}

      {isVisible && (
        <div className="flex w-full flex-col">
          {isSubCategoryListVisible &&
            lastActiveItems.map(alert => <AlertItem key={alert.alertResName} {...alert} />)}
        </div>
      )}
    </>
  );
}

export function ActionsCategory({ alerts, control, title }: ActionsCategoryProps) {
  const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);

  const categories = groupAlertsByAttribute(alerts);
  const ActionCardIcon = actionCardIcons[title] ?? actionCardIcons[ActionCategoryEnum.SAFETY];

  const isGloballyEnabled = useWatch({
    control,
    name: `enabled`,
  });

  if (categories.length <= 0) return null;

  return (
    <div>
      <Button
        className={classnames(
          'flex h-[44px] w-full shrink-0 items-center gap-4 border-b-[1px] border-slate-200 bg-slate-50 pl-6',
        )}
        onClick={() => setIsCategoryListVisible(!isCategoryListVisible)}
        variant="custom"
      >
        <ArrowSm
          className={classnames('transform transition-transform', {
            'rotate-180': isCategoryListVisible,
          })}
        />

        <div className="flex items-center justify-between gap-x-2">
          <div onClick={event => event.stopPropagation()}>
            <Controller
              control={control}
              defaultValue={false}
              name={`categories.${title}.enabled`}
              render={({ field: { onChange, value } }) => (
                <Toggle
                  checked={value ?? false}
                  color="green"
                  disabled={!isGloballyEnabled}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <ActionCardIcon />

          <Typography as="span" className="text-sm font-medium capitalize">
            {translate(`actions.${title}`)}
          </Typography>
        </div>
      </Button>

      <div className="flex w-full flex-col">
        {categories.map(category => (
          <ActionsSubCategory
            category={category}
            control={control}
            isGloballyEnabled={isGloballyEnabled}
            isVisible={isCategoryListVisible}
            key={category.attribute}
            title={title}
          />
        ))}
      </div>
    </div>
  );
}

export function ActionGroup({ children, className }: ActionsGroupProps) {
  return (
    <div
      className={classnames(
        'flex h-fit max-h-[600px] w-full flex-col overflow-y-auto rounded-md border border-gray-200 bg-white',
        className,
      )}
    >
      {children}
    </div>
  );
}
