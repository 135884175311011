import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PeopleMobileList } from '@/modules/Subcontractors/components/common/PeopleMobileList';
import { useFirmsSearch } from '@/modules/Subcontractors/hooks/useFirmsSearch.ts';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { renderColumns } from '@/modules/Subcontractors/utils/Firms.utils';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { AssetControlPanel } from '@/shared/components/ui/AssetControlPanel';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { URL_PARAMS } from '@/shared/utils/constants';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';
import { TableActions } from '@/shared/types/users/subcontractors.types.ts';

export const Firms: FC = () => {
  const [searchParams] = useSearchParams();

  const { isFetching: isWorkersLoading, refetch } = useAssetHooks.getAllWorkers();

  const { handler } = useToggleModal();
  const { firms } = usePeopleAsset();
  const { isShowInactive, toggleShowInactive } = useAssetStatus();
  const { renderItemVisibility } = useRole();
  const firmZone = searchParams.get(URL_PARAMS.CURENT_ZONE);
  const { filteredFirms } = useFirmsSearch(firms, firmZone);

  const handleTableAction = (action: TableActions, firmId: string) => {
    if (action == 'edit') {
      handler(PopupTypeEnum.SUBCONTRACTORS_FIRM_EDIT, firmId);
    } else {
      handler(PopupTypeEnum.CONFIRM_DELETE_FIRM, '');
    }
  };

  return (
    <div className="grid gap-y-4">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <TopSection scope={[]} />

        <AssetControlPanel
          checked={isShowInactive}
          className="2xl:ml-auto"
          disabled={isWorkersLoading}
          onChange={toggleShowInactive}
          onRefresh={refetch}
          scopes={['toggle', 'refresh']}
        />
      </div>

      <PeopleMobileList
        action={handleTableAction}
        className="md:hidden"
        firms={filteredFirms}
        isLoading={isWorkersLoading}
      />

      <div className="hidden gap-y-5 md:grid">
        <DefaultTable
          className="max-h-[calc(100dvh-260px)]"
          columns={renderColumns(handleTableAction, renderItemVisibility)}
          contentRowType="firm"
          data={filteredFirms}
          isLoading={isWorkersLoading}
          isPaginated
          withToggle={isShowInactive}
        />
      </div>
    </div>
  );
};
