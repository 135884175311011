import { FC } from 'react';

import { useActions } from '@/shared/hooks/actions/useActions.ts';

import Actions from '@/assets/icons/alerts.svg?react';

export const AlertsNotification: FC = () => {
  const { badgeCount } = useActions();

  return (
    <div className="relative md:hidden">
      <Actions className="text-white" />

      {badgeCount !== '0' && (
        <span className="absolute -right-1.5 -top-1 inline-flex size-2.5 rounded-full bg-red-600" />
      )}
    </div>
  );
};
