import { FC, useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useClearGlobalStore } from '@/shared/hooks/global/useClearGlobalStore.ts';
import { useTermsOfUse } from '@/shared/hooks/observer/useTermsOfUse.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { TEST_ID } from '@/shared/utils/constants';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const SecureLayout: FC = () => {
  const [params] = useSearchParams();

  const site = useGlobalStore(state => state.site);
  const { registerLogoutBroadcastListener } = useClearGlobalStore();
  const { isAuthorized } = useFetchUserData();
  const { termsOfUseStatus } = useTermsOfUse();

  useEffect(() => {
    registerLogoutBroadcastListener();
  }, [registerLogoutBroadcastListener]);

  const withParams = (path: string) => {
    return params.size > 0 ? `${path}?${params.toString()}` : path;
  };

  if (!isAuthorized) {
    return <Navigate data-testid={TEST_ID.NAVIGATE} replace to={withParams(RoutesEnum.LOGIN)} />;
  } else if (termsOfUseStatus === 'idle') {
    return <Navigate replace to={withParams(RoutesEnum.TERMS)} />;
  } else if (!site) {
    return <Navigate replace to={withParams(RoutesEnum.SITE_SELECT)} />;
  }

  return <Outlet data-testid={TEST_ID.OUTLET} />;
};
