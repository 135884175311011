import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { translate } from '@/i18n';
import { useSitesHooks } from '@/shared/api/sites/sites.hooks.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { useReleaseNotes } from '@/shared/hooks/prismic/useReleaseNotes.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

type Props = {
  variant: 'default' | 'guest';
};

export const Footer: FC<Props> = ({ variant }) => {
  const location = useLocation();
  const { document, lastVersion } = useReleaseNotes();

  const status = useGlobalStore(state => state.status);
  const { isLoading } = useSitesHooks.getAll();

  return (
    <footer
      className="flex max-h-[34px] shrink-0 items-center justify-between border-t border-slate-200 bg-white px-5 py-2 text-xs font-medium text-slate-600"
      role="contentinfo"
    >
      <Typography>
        ZaiNar © {new Date().getFullYear()}. {translate('global.allRightsReserved')}
      </Typography>

      {variant === 'default' && document && (
        <Typography>
          {translate('settings.version')} {lastVersion}
        </Typography>
      )}

      {variant === 'guest' && (
        <NavLink
          className={({ isActive }) =>
            classnames(
              'text-blue-600 underline transition-colors duration-300 hover:text-blue-400 focus:text-blue-400',
              (status === 'loading' || isLoading) && 'pointer-events-none opacity-50',
              isActive && 'pointer-events-none',
            )
          }
          state={{ from: location.pathname }}
          to={RoutesEnum.TERMS_OF_USE}
        >
          {translate('termsOfUse')}
        </NavLink>
      )}
    </footer>
  );
};
