export const CUSTOM_ZONE_MIN_POINTS = 3;

export const ZONE_MESH_COLOR = '#5270FF';
export const ZONE_MESH_LINE_COLOR = '#3648F5';
export const ZONE_MESH_OVERLAP_COLOR = '#f53636';

export const ZONE_CONTROL_POINT_RADIUS = 8;
export const ZONE_CONTROL_POINT_COLOR = '#3648F5';
export const ZONE_CONTROL_POINT_COLOR_2 = '#9ba7ff';

export const ZONE_EDITOR_MAP_FILE_OFFSETS = {
  FALLBACK: [30, 50, 100],
};

export const MIN_ZONE_Z = -50;
export const MAX_ZONE_Z = 40;
export const MAX_ZONE_NAME_LENGTH = 32;

export const CONCAVE_ZONE_MAX_LINE_INTERSECTIONS = 2;
