export enum SystemViewEnum {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export enum CurrencyEnum {
  JPY = 'JPY',
  USD = 'USD',
}

export enum DisplayTimeZoneEnum {
  BROWSER = 'browser',
  SITE = 'site',
}

export enum TimeFormatEnum {
  H12 = '12h',
  H24 = '24h',
}
