import { useSearchParams } from 'react-router-dom';

import { useInitialTrackersMapData, useLastTrackersLocation } from '@/modules/Home/hooks/map';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

export const useMapModel = (filteredSocketMapData?: SocketMapDataProps[]) => {
  const [searchParams] = useSearchParams();
  const assetResourceName = searchParams.get(URL_PARAMS.ASSET_RES_NAME);

  const closeActivePopups = useGlobalStore(state => state.closeActivePopups);
  const currentGeofenceMap = useGlobalStore(state => state.geofenceMapBlobUrl);
  const equipment = useGlobalStore(state => state.equipment);
  const equipmentInUse = useGlobalStore(state => state.equipmentInUse);
  const geofences = useGlobalStore(state => state.geofences);
  const initialCameraDistance = useGlobalStore(state => state.distance);
  const mapFilter = useGlobalStore(state => state.mapFilter);
  const modifyLastLocations = useGlobalStore(state => state.modifyLastLocations);
  const popups = useGlobalStore(state => state.popups);
  const readers = useGlobalStore(state => state.readers);
  const sidebarSearchKey = useGlobalStore(state => state.sidebarFilterValue);
  const workers = useGlobalStore(state => state.workers);

  const filteredInitialTrackersMapData = useInitialTrackersMapData(
    filteredSocketMapData ?? [],
    workers,
    equipment,
  );

  const lastTrackersLocation = useLastTrackersLocation(
    modifyLastLocations,
    equipmentInUse,
    mapFilter,
    sidebarSearchKey,
  );

  const filteredReaders = readers.filter(
    reader =>
      reader.readerLocation && (reader.visible || assetResourceName === reader.readerResName),
  );

  return {
    closeActivePopups,
    currentGeofenceMap,
    filteredInitialTrackersMapData,
    filteredReaders,
    geofences,
    initialCameraDistance,
    lastTrackersLocation,
    popups,
  };
};
