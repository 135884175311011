import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { ActionSubCategoryEnum } from '@/shared/types/actions/actions.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import {
  FilterSelectNameEnum,
  FilterSelectValueType,
} from '@/shared/types/global/filters.types.ts';

const defaultValues: Omit<FilterSelectValueType, 'searchKey'> = {
  [FilterSelectNameEnum.ACTION_EQUIPMENT_SUBCATEGORY]: [],
  [FilterSelectNameEnum.ACTION_SAFETY_SUBCATEGORY]: [],
  [FilterSelectNameEnum.ACTION_SYSTEM_SUBCATEGORY]: [],
  [FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY]: [],
  [FilterSelectNameEnum.ASSET_ITEM]: [],
  [FilterSelectNameEnum.EQUIPMENT_RESERVED_BY]: [],
  [FilterSelectNameEnum.EQUIPMENT_SUB_TYPE]: [],
  [FilterSelectNameEnum.EQUIPMENT_TYPE]: [],
  [FilterSelectNameEnum.FIRM]: [],
  [FilterSelectNameEnum.GROUP]: [],
  [FilterSelectNameEnum.RES_NAME]: [],
  [FilterSelectNameEnum.TAG]: [],
  [FilterSelectNameEnum.WORKER_ROLE]: [],
  [FilterSelectNameEnum.ZONE_FLOOR]: [],
  [FilterSelectNameEnum.ZONE_ITEM]: [],
  [FilterSelectNameEnum.ZONE_TYPE]: [],
};

const hasSelectedFilters = (data: Record<string, string | string[]>) =>
  Object.values(data)
    .flatMap(value => Object.values(value))
    .some(value => value.length > 0);

export const useMapFilter = () => {
  const closeModal = useGlobalStore(state => state.closeModal);
  const mapFilter = useGlobalStore(state => state.mapFilter);
  const setMapFilter = useGlobalStore(state => state.setMapFilter);
  const companies = useGlobalStore(state => state.companies);
  const geofences = useGlobalStore(state => state.geofences);
  const assets = useGlobalStore(state => state.assets);
  const floorsList = useGlobalStore(state => state.floorsList);

  const { filteredAssetsCategories } = useAssetsCategories();
  const { siteTagsList } = usePeopleAsset();

  const form = useForm<Omit<FilterSelectValueType, 'searchKey'>>({
    defaultValues: defaultValues,
  });

  const formData = form.watch();
  const filtersSelected = hasSelectedFilters(formData) || hasSelectedFilters(mapFilter);

  const filterOptions = useMemo(() => {
    return {
      [FilterSelectNameEnum.ACTION_EQUIPMENT_SUBCATEGORY]: [
        ActionSubCategoryEnum.RESERVATION,
        ActionSubCategoryEnum.UTILIZATION,
      ],
      [FilterSelectNameEnum.ACTION_SAFETY_SUBCATEGORY]: [
        ActionSubCategoryEnum.AFTER_HOURS,
        ActionSubCategoryEnum.EMERGENCY,
        ActionSubCategoryEnum.DANGER,
      ],
      [FilterSelectNameEnum.ACTION_SYSTEM_SUBCATEGORY]: [
        ActionSubCategoryEnum.TAG,
        ActionSubCategoryEnum.READER,
      ],
      [FilterSelectNameEnum.ACTION_ZONE_SUBCATEGORY]: [
        ActionSubCategoryEnum.RED_ZONE,
        ActionSubCategoryEnum.WORK_ZONE,
        ActionSubCategoryEnum.ZONE,
      ],
      [FilterSelectNameEnum.ASSET_ITEM]: assets.map(asset => asset.assetResName),
      [FilterSelectNameEnum.EQUIPMENT_RESERVED_BY]: [],
      [FilterSelectNameEnum.EQUIPMENT_SUB_TYPE]: [
        ...filteredAssetsCategories.reduce((subTypeNames, category) => {
          category.categoryPathList.forEach(treePath => subTypeNames.add(treePath.categoryPath));
          return subTypeNames;
        }, new Set<string>()),
      ],
      [FilterSelectNameEnum.EQUIPMENT_TYPE]: filteredAssetsCategories
        .map(item => item.categoryTreeName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [FilterSelectNameEnum.FIRM]: companies
        .map(({ orgName }) => orgName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [FilterSelectNameEnum.GROUP]: [],
      [FilterSelectNameEnum.RES_NAME]: [],
      [FilterSelectNameEnum.TAG]: siteTagsList
        ?.map(({ name }) => name)
        .filter((value, index, self) => self.indexOf(value) === index),
      [FilterSelectNameEnum.WORKER_ROLE]: Object.values(RolesEnum),
      [FilterSelectNameEnum.ZONE_FLOOR]: floorsList.map(({ name }) => name),
      [FilterSelectNameEnum.ZONE_ITEM]: geofences.map(geofence => geofence.geofenceResName),
      [FilterSelectNameEnum.ZONE_TYPE]: geofences
        .map(({ geofenceCategory }) => geofenceCategory)
        .filter((value, index, self) => self.indexOf(value) === index),
    };
  }, [companies, filteredAssetsCategories, floorsList, geofences, assets, siteTagsList]);

  const handleFormSubmit = form.handleSubmit(values => {
    try {
      setMapFilter(values);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  });

  const handleFormReset = () => {
    form.reset(defaultValues);
  };

  useEffect(() => {
    form.reset(mapFilter);
  }, [mapFilter, form]);

  return { closeModal, filterOptions, filtersSelected, form, handleFormReset, handleFormSubmit };
};
