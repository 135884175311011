import * as prismic from '@prismicio/client';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export enum PrismicTypeEnum {
  LICENSES = 'licenses',
  RELEASES = 'release_notes',
  TERMS = 'terms_of_use',
}

const repositoryName = import.meta.env.VITE_PRISMIC_REPOSITORY_NAME;
const accessToken = import.meta.env.VITE_PRISMIC_ACCESS_TOKEN;

export const client = prismic.createClient(repositoryName, {
  accessToken,

  routes: [
    {
      path: RoutesEnum.TERMS,
      type: PrismicTypeEnum.TERMS,
    },
  ],
});
