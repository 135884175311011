import { FC, useRef } from 'react';
import { useClickAway, useMedia, useToggle } from 'react-use';

import { Tab } from '@headlessui/react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Checkbox } from '@/shared/components/form/Checkbox';
import { SearchInput } from '@/shared/components/form/Input';
import { CustomHorizontalTab } from '@/shared/components/ui/CustomHorizontalTabs/CustomHorizontalTabs.tsx';
import { Typography } from '@/shared/components/ui/Typography';
import { useExtendedAssetsSelect } from '@/shared/hooks/components/useExtendedAssetsSelect.tsx';
import { useExtendedAssetsSelectMethods } from '@/shared/hooks/components/useExtendedAssetsSelectMethods.ts';
import { classnames } from '@/shared/utils/classnames';

import ChevronUpDownIcon from '@/assets/icons/select-arrow.svg?react';
import { AssetBody, AssetCategory } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

export type SelectType = 'equipment' | 'people';

type ExtendedAssetsSelectProps = {
  className?: string;
  asSelect?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  assetsWithTags?: boolean;
  initialType?: SelectType;
};

export const ExtendedAssetsSelect: FC<ExtendedAssetsSelectProps> = ({
  asSelect,
  assetsWithTags,
  className,
  initialType,
  onChange,
  value,
}: ExtendedAssetsSelectProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useToggle(false);
  const isDeepMobile = useMedia('(max-width: 475px)');
  const isMobile = useMedia('(max-width: 767px)');

  const {
    assets,
    getFilteredAssetsByType,
    handleToggleAll,
    isAllChecked,
    modifiedAssets,
    renderFilteredCategory,
    renderSortedOptions,
    searchValue,
    selectType,
    setSearchValue,
    setSelectType,
  } = useExtendedAssetsSelect(value, assetsWithTags);

  const methodsProps = {
    asSelect,
    assets,
    onChange,
    setIsOpen,
    setSelectType,
    value,
  };

  const { handleCheckboxChange, onClickAway, renderButtonText, renderOptionLabel } =
    useExtendedAssetsSelectMethods(methodsProps);

  const renderSelectType = () => {
    const renderCheckValue = () => {
      let filteredAssets: AssetBody[] = [];

      if (selectType === 'equipment') filteredAssets = getFilteredAssetsByType('Equipment');
      if (selectType === 'people') filteredAssets = getFilteredAssetsByType('Worker');

      const modifiedFilteredAssets = filteredAssets.map(asset => asset.assetResName);

      return modifiedFilteredAssets.every(assetName => value.includes(assetName));
    };

    const isChecked = renderCheckValue();

    return (
      <Checkbox checked={isChecked} onChange={() => handleToggleAll(onChange, selectType)}>
        <Typography className="max-w-80 flex-1 space-x-1 truncate text-left text-sm font-medium first-letter:capitalize">
          <Typography as="span" className="lowercase">
            {translate(isChecked ? 'auth.deselect' : 'auth.select')}
          </Typography>

          <Typography as="span" className="lowercase">
            {translate('actions.all')}
          </Typography>

          <Typography as="span" className="lowercase">
            {translate(`filters.${selectType}`)}
          </Typography>
        </Typography>
      </Checkbox>
    );
  };

  const renderCheckbox = (option: GeofenceCategoryEnum | RolesEnum | string) => (
    <Checkbox
      checked={value.includes(option)}
      key={option}
      onChange={event => handleCheckboxChange(event, option)}
    >
      <Typography className="max-w-60 flex-1 truncate text-left text-sm font-medium first-letter:capitalize">
        {renderOptionLabel(option)}
      </Typography>
    </Checkbox>
  );

  const renderExtraActions = () => {
    return (
      <div className="flex flex-col gap-y-2 pb-2">
        <div className="relative">
          <SearchInput
            className="w-full"
            onChange={value => setSearchValue(value as string)}
            value={searchValue}
          />
        </div>

        {renderSelectType()}
      </div>
    );
  };

  const renderCategoryItems = (category?: AssetCategory) => {
    return (
      <div className="flex flex-col gap-y-1">
        <Typography className="pb-2 pl-7 text-sm font-medium">
          {category?.categoryTreeName}
        </Typography>

        {category?.categoryPathList.map(path => {
          const pathOptions = renderSortedOptions()?.filter(
            option => option.categoryPathResName === path.categoryPathResName,
          );

          if (!pathOptions?.length) return null;

          const areAllOptionsSelected = pathOptions.every(option =>
            value.includes(option.assetResName),
          );

          const handlePathToggle = () => {
            if (areAllOptionsSelected) {
              const newValues = value.filter(
                selectedValue => !pathOptions.some(option => option.assetResName === selectedValue),
              );

              onChange(newValues);
            } else {
              const newValues = [
                ...value,
                ...pathOptions
                  .filter(option => !value.includes(option.assetResName))
                  .map(option => option.assetResName),
              ];

              onChange(newValues);
            }
          };

          return (
            <div className="flex flex-col gap-y-1 pb-1 pl-12" key={path.categoryPathResName}>
              <Checkbox
                checked={areAllOptionsSelected}
                className="items-center"
                key={path?.categoryPath}
                onChange={handlePathToggle}
              >
                <Typography className="text-sm font-medium truncate max-w-72">
                  {path?.categoryPath}
                </Typography>
              </Checkbox>

              <div className="flex flex-col gap-y-1 pl-6">
                {pathOptions?.map(option => renderCheckbox(option.assetResName))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTabsGroup = () => {
    const tabs = [{ name: 'actions.equipment' }, { name: 'assets.people' }];

    const equipmentCategory = renderFilteredCategory('Equipment');
    const workerCategory = renderFilteredCategory('Worker');

    return (
      <CustomHorizontalTab
        setTabMode={value => {
          if (value === 'actions.equipment') setSelectType('equipment');
          if (value === 'assets.people') setSelectType('people');
        }}
        tabs={tabs}
      >
        <Tab.Panel>
          {renderExtraActions()}

          {renderCategoryItems(equipmentCategory)}
        </Tab.Panel>

        <Tab.Panel>
          {renderExtraActions()}

          {renderCategoryItems(workerCategory)}
        </Tab.Panel>
      </CustomHorizontalTab>
    );
  };

  const renderGlobalToggle = () => {
    return (
      <Checkbox checked={isAllChecked} onChange={() => handleToggleAll(onChange)}>
        <Typography className="max-w-80 flex-1 space-x-1 truncate text-left text-sm font-medium first-letter:capitalize">
          <Typography as="span" className="lowercase">
            {translate(isAllChecked ? 'auth.deselect' : 'auth.select')}
          </Typography>

          <Typography as="span" className="lowercase">
            {translate('actions.all')}
          </Typography>

          {!initialType && (
            <Typography as="span" className="lowercase">
              {translate(`assets.title`)}
            </Typography>
          )}
        </Typography>
      </Checkbox>
    );
  };

  const renderContent = () => {
    if (initialType === 'equipment') {
      const equipmentCategory = renderFilteredCategory(initialType);

      return (
        <>
          <div className="relative">
            <SearchInput
              className="w-full"
              onChange={value => setSearchValue(value as string)}
              value={searchValue}
            />
          </div>

          {renderGlobalToggle()}

          {renderCategoryItems(equipmentCategory)}
        </>
      );
    }

    return (
      <>
        {renderGlobalToggle()}

        {renderTabsGroup()}
      </>
    );
  };

  useClickAway(dropdownRef, onClickAway);

  return (
    <div
      className={classnames('relative', {
        'w-full': isDeepMobile,
      })}
    >
      <Button
        className={classnames(
          'disabled:hover-none inline-flex w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2 pr-2.5 text-left text-sm font-normal text-slate-500 transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50',
          isOpen && 'pointer-events-none',
          isDeepMobile && 'w-full min-w-full',
          className,
        )}
        disabled={modifiedAssets.length === 0}
        onClick={setIsOpen}
        variant="custom"
      >
        <Typography className="truncate">{renderButtonText()}</Typography>

        <ChevronUpDownIcon aria-hidden="true" className="h-4 w-4 shrink-0" />
      </Button>

      {isOpen && (
        <div
          className={classnames(
            'filterSectionAnimation absolute left-0 z-50 mt-2 grid max-h-[496px] w-[450px] origin-top-left gap-y-2 overflow-y-auto overflow-x-hidden rounded-lg border border-slate-200 bg-white p-3 shadow-lg',
            {
              'fixed left-0 top-0 mt-0 h-full max-h-full w-full rounded-none': isMobile,
            },
          )}
          ref={dropdownRef}
        >
          {renderContent()}

          {isMobile && (
            <div className="sticky bottom-[-12px] w-full bg-white pb-2">
              <Button className="w-full active:opacity-100" onClick={onClickAway} variant="solid">
                {translate('global.submit')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
