import { baseQuery, signRequest } from '@/shared/api/utils';
import { REQUESTS_OPTIONS } from '@/shared/utils/constants';

import {
  LocationsHistoryAllProps,
  LocationsHistoryListResponse,
  LocationsHistoryRequest,
} from '@/shared/api/locations-history/locations-history.types';
import { LocationHistory } from '@/shared/types/global/locations.types.ts';

export namespace LocationsHistoryAPINamespace {
  const client = baseQuery('LOCATION_HISTORY');

  export const getAll = async (
    options: LocationsHistoryAllProps,
  ): Promise<LocationsHistoryListResponse> => {
    const { accountResName, endTime, pageEndResPointer, signal, siteResName, startTime, tagIds } =
      options;

    const tagListQueryParameter = tagIds.map(tag => tag).join(',');

    const modifiedUrl = `/accounts/${accountResName}/sites/${siteResName}/tags/histories/locations?startTime=${startTime}&endTime=${endTime}&tagList=${tagListQueryParameter}&resourcePointer=${pageEndResPointer}&limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;
    const url = `/accounts/${accountResName}/sites/${siteResName}/tags/histories/locations?startTime=${startTime}&endTime=${endTime}&tagList=${tagListQueryParameter}&limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;

    const signedRequest = await signRequest(
      'LOCATION_HISTORY',
      pageEndResPointer ? modifiedUrl : url,
      'GET',
    );

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
      signal,
    });

    return result.data;
  };

  export const getLast = async ({
    accountResName,
    siteResName,
    tagIds,
  }: LocationsHistoryRequest): Promise<LocationHistory[]> => {
    const tagListQueryParameter = tagIds.map(tag => tag).join(',');

    const url = `/accounts/${accountResName}/sites/${siteResName}/tags/histories/locations?tagList=${tagListQueryParameter}&limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;
    const signedRequest = await signRequest('LOCATION_HISTORY', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data.results;
  };
}
