import { FC, useMemo } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { CustomIconProps, customIcons } from '@/shared/components/ui/CustomIcon/CustomIcon.types';

export const CustomIcon: FC<CustomIconProps> = ({
  className,
  color,
  isActive,
  type,
}: CustomIconProps) => {
  const Icon = useMemo(() => {
    if (isActive && type === 'equipmentPin') return customIcons['selectedEquipmentPin'];
    else if (isActive && type === 'workerPin') return customIcons['selectedWorkerPin'];
    return customIcons[type];
  }, [type, isActive]);

  const renderStyle = () => {
    if (type.includes('historicalEquipmentPin') || type.includes('historicalWorkerPin'))
      return { color };

    return {};
  };

  return <Icon className={classnames(className)} style={renderStyle()} />;
};
