import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';

import { App } from '@/App';
import { QUERY_SCHEMA } from '@/shared/schemas/query-schemas.ts';

import 'react-datepicker/dist/react-datepicker.css';

import '@/styles/index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: QUERY_SCHEMA.GARBAGE_COLLECTOR_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
