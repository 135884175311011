//new relic options config based on environment (dev, int, prd)
export const new_relic_dev = {
  init: {
    session_replay: {
      enabled: true,
      block_selector: '',
      mask_text_selector: '*',
      sampling_rate: 10,
      error_sampling_rate: 100,
      mask_all_inputs: true,
      collect_fonts: true,
      inline_images: false,
      inline_stylesheet: true,
      mask_input_options: {},
    },
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net', 'mp.zainar.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134502646',
    sa: 1,
  },
  loader_config: {
    accountID: '4566726',
    trustKey: '4566726',
    agentID: '1134502646',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134502646',
  },
};

export const new_relic_int = {
  init: {
    session_replay: {
      enabled: true,
      block_selector: '',
      mask_text_selector: '*',
      sampling_rate: 10,
      error_sampling_rate: 100,
      mask_all_inputs: true,
      collect_fonts: true,
      inline_images: false,
      inline_stylesheet: true,
      mask_input_options: {},
    },
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net', 'mp.zainar.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134504099',
    sa: 1,
  },
  loader_config: {
    accountID: '4566726',
    trustKey: '4566726',
    agentID: '1134504099',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134504099',
  },
};

export const new_relic_prd = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net', 'mp.zainar.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134504100',
    sa: 1,
  },
  loader_config: {
    accountID: '4566726',
    trustKey: '4566726',
    agentID: '1134504100',
    licenseKey: 'NRJS-0c7166dfe3ad62acd4c',
    applicationID: '1134504100',
  },
};
