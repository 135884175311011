import { FC } from 'react';

import { useThree } from '@react-three/fiber';

import { useMapModel } from '@/modules/Home/hooks/map';

export const PerspectiveCamera: FC = () => {
  const { viewport } = useThree();

  const { initialCameraDistance } = useMapModel();

  return (
    <perspectiveCamera
      args={[undefined, viewport.width / viewport.height]}
      // aspect={viewport.width / viewport.height}
      position={[0, 0, Math.max(initialCameraDistance, 1)]}
    />
  );
};
