import { FC } from 'react';

import { Loader } from '@/shared/components/ui/Loader';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  onClick: () => void;
};

export const SiteMenuItem: FC<Props> = ({ className, onClick }) => {
  const activeSite = useGlobalStore(state => state.site);
  const sitesList = useGlobalStore(state => state.sitesList);
  const sitesListLoading = useGlobalStore(state => state.sitesListLoading);

  const siteName = sitesList.find(site => site.siteResName === activeSite)?.siteName ?? 'N/A';

  return (
    <SiteSelectItem
      aria-label="site-menu-action"
      className={classnames(
        'inline-flex w-full items-center justify-between rounded-none text-sm font-medium',
        className,
      )}
      onClick={onClick}
    >
      {siteName}
      {sitesListLoading ? <Loader appearance="inline" /> : <ChevronRight />}
    </SiteSelectItem>
  );
};
