import { ChangeEvent, FC, useState } from 'react';

import { translate } from '@/i18n';
import { UploadButton } from '@/modules/Settings/components/common/UploadButton';
import { Button } from '@/shared/components/buttons/Button';
import { Avatar } from '@/shared/components/ui/UserMenu/Avatar';
import { getBase64 } from '@/shared/utils/helpers';

export const ChangeAvatarForm: FC = () => {
  const [imagePreview, setImagePreview] = useState<null | string>(null);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) return;

    const file = event.target.files[0];
    getBase64(file, setImagePreview);

    event.target.value = '';
  };

  return (
    <div className="inline-flex w-full items-center gap-x-4" id="changeUserAvatar">
      <Avatar size="lg" src={imagePreview} />

      <UploadButton aria-label="change-avatar-action" onChange={handleUpload}>
        {translate('settings.upload')}
      </UploadButton>

      {imagePreview && (
        <Button
          aria-label="reset-avatar-action"
          className="!bg-red-100 text-red-600 hover:!bg-red-200 focus:bg-red-200"
          onClick={() => setImagePreview(null)}
          variant="ghost"
        >
          {translate('settings.remove')}
        </Button>
      )}
    </div>
  );
};
