import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import Arrow from '@/assets/icons/arrow-right.svg?react';
import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export const GoBackLink: FC<LinkProps & WithChildren & WithClassName> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Link
      className={classnames(
        'mr-auto inline-flex w-fit items-center gap-2 hover:underline focus:underline',
        className,
      )}
      {...props}
    >
      <Arrow className="shrink-0 text-blue-600" />

      <Typography className="flex min-w-full text-nowrap text-xl font-medium tracking-[0.1px]">
        {children}
      </Typography>
    </Link>
  );
};
