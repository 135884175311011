import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'react-use';

import { iconsButtonMap } from '@/shared/components/buttons/IconButton/IconButton.types.tsx';
import { PageHeader } from '@/shared/components/ui/PageHeader';
import { TabCounter } from '@/shared/components/ui/TabCounter';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/global/common.types.ts';

export type PageNavbarItemProps = {
  label: JSX.Element | string;
  to: string;
  condition?: boolean;
  count?: number;
  icon?: keyof typeof iconsButtonMap;
};

type PageNavbarProps = WithClassName & {
  title: JSX.Element | string;
  buttonsGroup?: JSX.Element;
  navItems: PageNavbarItemProps[];
  sidebar?: boolean;
};

export const PageNavbar: FC<PageNavbarProps> = ({
  buttonsGroup,
  className,
  navItems,
  sidebar = false,
  title,
}) => {
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <>
      <PageHeader
        className={classnames('mb-3 flex flex-wrap items-center justify-between', className)}
      >
        <Typography as="h1">{title}</Typography>

        {buttonsGroup}
      </PageHeader>

      <header
        className={classnames(
          'flex items-center gap-x-2 overflow-x-auto border-b border-slate-200',
          {
            'flex-col gap-y-2 border-b-0': sidebar,
          },
        )}
      >
        {navItems.map(({ condition = true, count, icon, label, to }) => {
          if (!condition) return null;

          const Icon = icon && iconsButtonMap[icon];

          return (
            <NavLink
              className={({ isActive }) =>
                classnames(
                  'relative shrink-0 p-3 text-sm font-medium uppercase text-slate-800 duration-300 hover:text-blue-600',
                  {
                    'bg-blue-600 !text-white after:hidden': sidebar && isActive,
                    'flex w-full items-center justify-between rounded-xl border-0 px-3 py-2 capitalize text-slate-950':
                      sidebar,
                    'text-blue-600 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-blue-600':
                      isActive,
                  },
                )
              }
              end
              key={to}
              to={to}
            >
              <Typography as="span" className="flex gap-x-2">
                {Icon && <Icon />}

                {label}
              </Typography>

              {!isMobile && count && <TabCounter count={count} />}
            </NavLink>
          );
        })}
      </header>
    </>
  );
};
