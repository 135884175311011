import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';

import { EquipmentHistoryDatasource } from '@/shared/types/assets/equipment.types.ts';

export const renderColumns = (): ColumnDef<EquipmentHistoryDatasource>[] => [
  {
    accessorKey: 'date',
    cell: ({ row }) => <div>{row.original.date}</div>,
    header: () => translate('global.date'),
  },
  {
    accessorKey: 'startTime',
    cell: ({ row }) => <div>{row.original.startTime}</div>,
    header: () => translate('global.startTime'),
  },
  {
    accessorKey: 'endTime',
    cell: ({ row }) => <div>{row.original.endTime}</div>,
    header: () => translate('global.endTime'),
  },
  {
    accessorKey: 'firm',
    cell: ({ row }) => <div>{row.original.firm}</div>,
    header: () => translate('global.firms'),
  },
  {
    accessorKey: 'reservation',
    cell: ({ row }) => row.original.reservation,
    header: () => translate('global.reservation'),
  },
];
