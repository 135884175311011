import { AssetBody, Employee } from '@/shared/types/assets/assets.types.ts';
import { Equipment } from '@/shared/types/assets/equipment.types.ts';
import { TagBody } from '@/shared/types/assets/tags.types.ts';
import { GeofencesProps } from '@/shared/types/global/geofences.types.ts';
import { ReadersProps } from '@/shared/types/global/readers.types.ts';
import { Reservation } from '@/shared/types/global/reservations.types.ts';

export type AlertPageProps = {
  forward?: boolean;
  hasNext: boolean;
  pageEndResPointer: string;
  pageItemsCount: number;
  pageStartResPointer: string;
};

export type AlertDataProps = {
  page: AlertPageProps;
  results: AlertProps[];
};

export type MetricDataProps = {
  metrics: MetricProps[];
};

export interface MetaProps extends Equipment, Employee, ReadersProps {
  actionUrls?: string;
  alertResName: string;
  id: string;
}

export interface AlertParsedMeta {
  assetAResName?: string;
  assetBResName?: string;
  assetResName?: string;
  deviceResName?: string;
  geofenceResName?: string;
  proximityZone?: string;
  reservationResName?: string;
}

export type AlertProps = {
  alertResName: string;
  accountResName: string;
  siteResName: string;
  deviceResName?: string;
  assetResName?: string;
  alertName: string;
  alertDescription: string;
  alertCategory: ActionCategoryEnum;
  attribute: ActionSubCategoryEnum;
  alertSeverity: ActionSeverityEnum;
  metadata?: string;
  actionUrls?: string;
  active: boolean;
  timestamp: number;
  parsedMeta?: AlertParsedMeta;
  asset?: Pick<AssetBody, 'assetName' | 'assetResName'>;
  tag?: Pick<TagBody, 'tagName' | 'tagResName'>;
  reader?: Pick<ReadersProps, 'readerName' | 'readerResName'>;
  geofence?: Pick<GeofencesProps, 'geofenceName' | 'geofenceResName'>;
  reservation?: Pick<Reservation, 'reservationPeriod' | 'reservationResName' | 'user'>;
  proximityPersonAsset?: Pick<AssetBody, 'assetName' | 'assetResName'>;
  proximityEquipmentAsset?: Pick<AssetBody, 'assetName' | 'assetResName'>;
};

export type ActionI18NData = {
  assetName?: string;
  closingTime?: string;
  firmName?: string;
  personName?: string;
  reservationDay?: string;
  reservationFirmName?: string;
  timeRange?: string;
  timestamp?: string;
  zoneName?: string;
  proximityZone?: string;
};

export type ActionFiltersProps = {
  sort: ActionSortCriteriaEnum;
  system: ActionSubCategoryEnum[];
  equipment: ActionSubCategoryEnum[];
  safety: ActionSubCategoryEnum[];
  zone: ActionSubCategoryEnum[];
  archive: {
    searchKey: string;
    subCategories: ActionSubCategoryEnum[];
    date: Date;
  };
};

export type MetricProps = {
  alertResName: string; // TODO: Determine if this is valid for metrics
  accountResName: string;
  siteResName: string;
  deviceResName: string;
  metricName: string;
  metricValue: string;
  timestamp: number;
} & { meta?: MetaProps };

export enum ActionSeverityEnum {
  DEFAULT = 'default',
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum ActionCategoryEnum {
  EQUIPMENT = 'equipment',
  RESERVATION = 'reservation',
  SAFETY = 'safety',
  SYSTEM = 'system',
  ZONE = 'zone',
}

export enum ActionSubCategoryEnum {
  AFTER_HOURS = 'after-hours',
  BEHIND = 'behind',
  DANGER = 'danger',
  EMERGENCY = 'emergency',
  READER = 'reader',
  RED_ZONE = 'red_zone',
  RESERVATION = 'reservation',
  TAG = 'tag',
  UPDATE = 'update',
  UTILIZATION = 'utilization',
  WORK_ZONE = 'work_zone',
  ZONE = 'zone',
}

export enum ActionTitleEnum {
  CRITICAL_BATTERY = 'device.battery.critical',
  DEAD_BATTERY = 'device.battery.dead',
  DEVICE_UNRESPONSIVE = 'device.state.unresponsive',
  EQUIPMENT_ENTERED_RED_ZONE = 'equipment.redzone.entered',
  EQUIPMENT_IN_USE_BY_WRONG_SUB_FIRM = 'equipment.in.use.by.wrong.subcontractor.firm',
  EQUIPMENT_IN_USE_WITHOUT_RESERVATION = 'equipment.in.use.without.reservation',
  EQUIPMENT_NOT_UTILIZED_ON_WORKDAY = 'equipment.utilization.notutilizedworkday',
  EQUIPMENT_REDZONE_ACTIVATED = 'equipment.redzone.activated',
  EQUIPMENT_REDZONE_RELEASED = 'equipment.redzone.released',
  EQUIPMENT_RESERVATION_APPROVED = 'equipment.reservation.approved',
  EQUIPMENT_RESERVATION_CANCELLED = 'equipment.reservation.cancelled',
  EQUIPMENT_RESERVATION_EDITED = 'equipment.reservation.edited',
  EQUIPMENT_RESERVATION_REQUEST = 'equipment.reservation.subcontractor.request',
  EQUIPMENT_RESERVATION_SUB_REQUEST = 'equipment.reservation.sub.request',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED = 'equipment.reservation.subcontractor.request.approved',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED = 'equipment.reservation.subcontractor.request.rejected',
  EQUIPMENT_RESERVATION_WORKER_REQUEST = 'equipment.reservation.worker.request',
  EQUIPMENT_RESERVED_NOTINUSE = 'equipment.reserved.notinuse',
  EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS = 'equipment.still.in.use.after.reservation.ends',
  LOW_BATTERY = 'device.battery.low',
  NEW_RESERVATION = 'equipment.reservation.new',
  NO_UPCOMING_EQUIPMENT_RESERVATION = 'equipment.reservation.no_upcoming',
  PROXIMITY_RULE_REDZONE = 'proximity.rule.redzone',
  READER_OFFLINE = 'device.reader.offline',
  READER_ONLINE = 'new.reader.online',
  RESERVATION_APPROVED_WITH_CHANGES = 'equipment.reservation.subcontractor.request.approved.with.changes',
  RESERVATION_CONFLICT = 'equipment.reservation.conflict',
  RESERVATION_DRAFT_EXPIRED = 'reservation.draft.expired',
  RESERVATION_REQUEST_EXPIRED = 'reservation.request.expired',
  TAG_LEFT_SITE = 'tag.left.site',
  TAG_OFFLINE = 'device.tag.offline',
  TAG_ONLINE = 'new.tag.online',
  WORKER_REDZONE_ENTERED = 'worker.redzone.entered',
  WORKERS_LEFT_SITE_FOR_DAY = 'workers.left.site.for.day',
  WORKERS_STILL_ON_SITE_AFTER_5PM = 'workers.still.on.site.after.5pm',
}

export enum ProximityZoneEnum {
  EQUIPMENT_MOVED = 'red.equipment_moved',
  EQUIPMENT_OVERHEAD = 'red.equipment_overhead',
  EQUIPMENT_OVERLOADED = 'red.equipment_too_many_people_3',
}

export enum ActionSortCriteriaEnum {
  MOST_IMPORTANT = 'most-important',
  MOST_RECENT = 'most-recent',
}
