import { FC } from 'react';

import {
  useChangeLocation,
  useExecuteMapParams,
  useGlobalSearchParams,
  useIntercomInitialize,
} from '@/layout/Layout/hooks';

export const LayoutHooks: FC = () => {
  useExecuteMapParams();
  useGlobalSearchParams();
  useChangeLocation();
  useIntercomInitialize();

  return null;
};
