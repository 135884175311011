import { FC, memo } from 'react';

import { Anchors } from '@/modules/Home/components/map/Anchors';
import { Clusters } from '@/modules/Home/components/map/Clusters';
import { Geofences } from '@/modules/Home/components/map/Geofences';
import { HistoricalTrackers } from '@/modules/Home/components/map/HistoricalTrackers';
import { InitialTrackers } from '@/modules/Home/components/map/InitialTrackers';
import { Readers } from '@/modules/Home/components/map/Readers';
import { useClusters, useLocationHistory, useMapModel } from '@/modules/Home/hooks/map';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams.ts';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData.ts';

import { TrackersProps } from '@/modules/Home/types/InitialTrackers.types.ts';
import { MapModelProps } from '@/modules/Home/types/MapModel.types.ts';

const OFFSET_Y = 4;

const MapModelBody: FC<Pick<MapModelProps, 'isThumbnail' | 'mapKey'>> = ({
  isThumbnail,
  mapKey,
}) => {
  const { filteredSocketMapData } = useFilteredSocketData();
  const { mappedLocationsHistory } = useLocationHistory();
  const { isHistoricalMode } = useLocalSearchParams();

  const { filteredInitialTrackersMapData, filteredReaders, geofences, lastTrackersLocation } =
    useMapModel(filteredSocketMapData);

  const modifiedLastTrackers = lastTrackersLocation.map((kItem: TrackersProps) => {
    const { assetCategory, ...rest } = {
      ...kItem.anchor,
      tagName: kItem.tagName,
      tagResName: kItem.tagResName,
      visible: kItem.visible,
      x: Number(kItem.location.x) / 100,
      y: Number(kItem.location.y) / 100,
      z: Number(kItem.location.z) / 100,
    };

    return { ...rest };
  });

  const { filteredClusters } = useClusters([...filteredSocketMapData, ...modifiedLastTrackers]);

  // Locations History
  if (isHistoricalMode || isThumbnail) {
    return <HistoricalTrackers locationsHistory={mappedLocationsHistory} mapKey={mapKey} />;
  }

  return (
    <>
      {/* Last Trackers Position */}
      <InitialTrackers
        filteredClusters={filteredClusters}
        offset={OFFSET_Y}
        showLastLocation={false}
        trackers={lastTrackersLocation}
      />

      {/* Initial Trackers */}
      <InitialTrackers
        offset={OFFSET_Y}
        showLastLocation={true}
        trackers={filteredInitialTrackersMapData}
      />

      {/* Anchors */}
      <Anchors
        anchors={filteredSocketMapData}
        filteredClusters={filteredClusters}
        offset={OFFSET_Y}
      />

      {/*Clusters*/}
      <Clusters clusters={filteredClusters} />

      {/*Geofences*/}
      <Geofences geofences={geofences} />

      {/*Readers*/}
      <Readers readers={filteredReaders} />
    </>
  );
};

export default memo(MapModelBody);
