import JSZip from 'jszip';

export async function destructZipFromBuffer(buffer: ArrayBuffer) {
  try {
    const zip = await JSZip.loadAsync(buffer);

    const configFile = await zip.file('Config.json')?.async('text');
    if (!configFile) {
      throw new Error('Config file not found in the zip.');
    }

    const siteMapConfig = JSON.parse(configFile);

    const daeFile = Object.keys(zip.files).find(fileName => fileName.endsWith('.dae'));
    if (!daeFile) {
      throw new Error('Collada file not found in the zip.');
    }

    let daeText = (await zip.file(daeFile)?.async('text')) as string;

    const imageFile = Object.keys(zip.files).find(fileName =>
      /\.(jpg|jpeg|png|gif)$/i.test(fileName),
    );

    if (imageFile) {
      const imageBlob = await zip.file(imageFile)?.async('blob');
      const imageUrl = URL.createObjectURL(imageBlob as Blob);

      daeText = daeText?.replace(new RegExp(imageFile, 'g'), removeLeadingBlobPrefix(imageUrl));
    }

    const updatedDaeBlob = new Blob([daeText], { type: 'application/xml' });
    const updatedDaeUrl = URL.createObjectURL(updatedDaeBlob);

    return { siteMapConfig, updatedDaeUrl };
  } catch (error) {
    console.error(error);
  }
}

function removeLeadingBlobPrefix(blobUrl: string) {
  const lastSlashIndex = blobUrl.lastIndexOf('/');

  return lastSlashIndex === -1 ? blobUrl : blobUrl.slice(Math.max(0, lastSlashIndex + 1));
}
