import type { ReactNode } from 'react';

import { useIsFetching } from '@tanstack/react-query';
import { flexRender, Row } from '@tanstack/react-table';

import { Toggle } from '@/shared/components/form/Toggle';
import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';
import { QUERY_KEY } from '@/shared/utils/constants';

import { UseTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';
import { WorkersDatasource } from '@/shared/types/users/subcontractors.types.ts';

export function useTableWorkerBody<T>({
  contentRowType,
  extendedRow,
  rowClassName,
  table,
  withToggle,
}: UseTableBodyProps<T>) {
  const workers = useGlobalStore(state => state.workers);
  const batchedWorkers = useGlobalStore(state => state.batchedWorkers);
  const setBatchedWorkers = useGlobalStore(state => state.setBatchedWorkers);

  const isPending = !!useIsFetching({
    queryKey: [QUERY_KEY.TOGGLE_ASSET_STATUS_BATCH],
  });

  const handleMutate = (data: WorkersDatasource) => {
    const currentWorker = workers.find(worker => worker.assetResName === data?.id);

    if (!currentWorker) {
      notifyService.error('The worker is missing some required fields.');
      return;
    }

    if (isBatched(data)) {
      setBatchedWorkers(batchedWorkers.filter(worker => worker.assetResName !== data.id));
      return;
    }
    setBatchedWorkers([...batchedWorkers, currentWorker]);
  };

  const isBatched = (data: WorkersDatasource) => {
    return batchedWorkers?.find(worker => worker.assetResName === data?.id);
  };

  const items = <K extends Record<string, string>>(
    data?: K,
    customRow?: (row: Row<T>) => ReactNode,
  ) => {
    const tableRows = table.getRowModel().rows;
    return tableRows.map(row => {
      const isDeactivated = Object.values(row.original as Record<string, string>).includes(
        'deactivated',
      );

      if (customRow) return customRow(row);

      if (contentRowType === 'worker')
        return (
          <TableRow
            className={classnames(isDeactivated && 'bg-slate-50', !data && 'pl-4', rowClassName)}
            data-index={row.index}
            key={row.id}
            ref={node => node}
          >
            {row.getVisibleCells().map((cell, index) => {
              const data = cell.row?.original as WorkersDatasource;
              const isActive = !!(
                (data?.status === 'active' && !isBatched(data)) ||
                (data?.status === 'inactive' && isBatched(data))
              );
              const isInactiveRow = !isActive && withToggle;

              return (
                <TableData
                  className={classnames(
                    extendedRow && 'h-[64px]',
                    !cell.column.columnDef.header && 'min-w-[120px] max-w-[120px]',
                    isInactiveRow && 'opacity-50',
                  )}
                  data-index={row.index}
                  key={cell.id}
                >
                  {index === 0 && withToggle && (
                    <Toggle
                      checked={isActive}
                      color="green"
                      disabled={isPending}
                      onChange={() => handleMutate(data)}
                    />
                  )}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              );
            })}
          </TableRow>
        );
    });
  };

  return { items };
}
