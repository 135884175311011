export type FilterSelectValueType = {
  actionEquipmentSubCategory: string[];
  actionSafetySubCategory: string[];
  actionSystemSubCategory: string[];
  actionZoneSubCategory: string[];
  assetItem: string[];
  equipmentReservedBy: string[];
  equipmentSubType: string[];
  equipmentType: string[];
  firm: string[];
  group: string[];
  resName: string[];
  searchKey: string;
  tag: string[];
  workerRole: string[];
  zoneFloor: string[];
  zoneItem: string[];
  zoneType: string[];
};

export enum FilterSelectNameEnum {
  ACTION_EQUIPMENT_SUBCATEGORY = 'actionEquipmentSubCategory',
  ACTION_SAFETY_SUBCATEGORY = 'actionSafetySubCategory',
  ACTION_SYSTEM_SUBCATEGORY = 'actionSystemSubCategory',
  ACTION_ZONE_SUBCATEGORY = 'actionZoneSubCategory',
  ASSET_ITEM = 'assetItem',
  EQUIPMENT_RESERVED_BY = 'equipmentReservedBy',
  EQUIPMENT_SUB_TYPE = 'equipmentSubType',
  EQUIPMENT_TYPE = 'equipmentType',
  FIRM = 'firm',
  GROUP = 'group',
  RES_NAME = 'resName',
  TAG = 'tag',
  WORKER_ROLE = 'workerRole',
  ZONE_FLOOR = 'zoneFloor',
  ZONE_ITEM = 'zoneItem',
  ZONE_TYPE = 'zoneType',
}
