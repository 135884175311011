import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { translate } from '@/i18n';
import { EquipmentDetails } from '@/modules/Equipment/components/tabs/Details';
import { Button } from '@/shared/components/buttons/Button';
import { GoBackLink } from '@/shared/components/navigation/GoBackLink';
import { PageHeader } from '@/shared/components/ui/PageHeader';
import { PageWrapper } from '@/shared/components/ui/PageWrapper';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { useEquipmentHideReservationsTypes } from '@/shared/feature-flags/useEquipmentHideReservationsTypes.ts';
import { useGetAsset } from '@/shared/hooks/assets/useGetAsset.ts';
import { useGetAssetAttachedTags } from '@/shared/hooks/assets/useGetAssetAttachedTags.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';

import Location from '@/assets/icons/location-sm.svg?react';
import Plus from '@/assets/icons/plus.svg?react';
import { PopupTypeEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

export const EquipmentItem: FC = () => {
  const location = useLocation();

  const { data: equipment, isFetching } = useGetAsset();
  const { handler } = useToggleModal();
  const { equipmentHideReservationsTypesFlag } = useEquipmentHideReservationsTypes();

  useGetAssetAttachedTags();

  return (
    <PageWrapper className="p-0">
      <PageHeader className="flex-col md:flex-row">
        <GoBackLink to={location?.state?.from ?? RoutesEnum.EQUIPMENT}>
          {equipment?.assetName ?? <Skeleton variant="default" />}
        </GoBackLink>

        <div className="ml-auto mt-5 inline-flex flex-wrap items-center gap-x-3 md:mt-0">
          {equipmentHideReservationsTypesFlag.enabled && (
            <Button
              className="inline-flex items-center justify-center gap-x-2"
              disabled
              variant="outline"
            >
              {translate('global.showOnMap')} <Location />
            </Button>
          )}

          <Button
            className="inline-flex items-center justify-center gap-x-2"
            disabled={isFetching}
            onClick={() => handler(PopupTypeEnum.EQUIPMENT_RESERVE)}
            variant="solid"
          >
            <Plus />
            {translate('global.addReservation')}
          </Button>
        </div>
      </PageHeader>

      {isFetching ? (
        <Skeleton subTitleCount={8} variant="equipment-details" />
      ) : (
        <EquipmentDetails {...equipment} />
      )}
    </PageWrapper>
  );
};
