import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  defaultValues,
  endWeek,
  ianaTimeZones,
  startWeek,
  workDays,
} from '@/modules/Settings/utils/constants';
import { useGlobalStore } from '@/shared/store/store.ts';
import { convertStringToDate, getCurrentDay } from '@/shared/utils/date-fns';

import { useTimeExceptions } from './useTimeExceptions';

import { SiteHoursProps } from '@/modules/Settings/types/types';

export const useSiteHoursDetail = () => {
  const form = useForm<SiteHoursProps>({
    defaultValues,
    mode: 'onChange',
  });

  const [startTime, setStartTime] = useState<Date>(defaultValues.startTime);
  const [endTime, setEndTime] = useState<Date>(defaultValues.endTime);
  const [selected, setSelected] = useState<Date[]>([startWeek, endWeek]);

  const timeExpetionParams = useTimeExceptions(defaultValues.startTime, defaultValues.endTime);

  const shifts = useGlobalStore(state => state.siteShifts);
  const policies = useGlobalStore(state => state.sitePolicies);
  const currentLanguage = useGlobalStore(state => state.currentLanguage);

  const defaultShift = shifts[0];
  const defaultPolicy = policies[0];

  const handleResetWorkDays = () => {
    const defaultDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    Promise.all(
      defaultDays.map(day => {
        form.setValue(day, true);
        timeExpetionParams.handleStartTimeChange(day, defaultValues.startTime);
        timeExpetionParams.handleEndTimeChange(day, defaultValues.endTime);
      }),
    );
  };

  useEffect(() => {
    if (defaultShift) {
      const startTime = convertStringToDate(
        defaultShift.shiftStartTime.shiftDate,
        defaultShift.shiftStartTime.shiftTime,
      );
      const endTime = convertStringToDate(
        defaultShift.shiftEndTime.shiftDate,
        defaultShift.shiftEndTime.shiftTime,
      );
      form.setValue('startTime', startTime);
      form.setValue('endTime', endTime);
      setStartTime(startTime);
      setEndTime(endTime);

      setSelected([startTime, endTime]);

      // TODO discuss with backend to use a more unique key for timezone rather than offset
      const timeZoneOffset = defaultPolicy.operationalPeriod.localTimezoneOffset;

      const selectedTimeZone = ianaTimeZones.find(timezone => timezone.offset === timeZoneOffset);

      if (selectedTimeZone) {
        form.setValue('siteTimeZone', selectedTimeZone.id);
      }

      Promise.all(
        defaultShift.shiftPeriods.map(shiftPeriod => {
          const startDate = convertStringToDate(
            shiftPeriod.startTime.shiftDate,
            shiftPeriod.startTime.shiftTime,
          );
          const endDate = convertStringToDate(
            shiftPeriod.endTime.shiftDate,
            shiftPeriod.endTime.shiftTime,
          );
          const day = getCurrentDay(startDate).toLowerCase();
          form.setValue(day, true);
          timeExpetionParams.handleStartTimeChange(day, startDate);
          timeExpetionParams.handleEndTimeChange(day, endDate);
        }),
      );
    } else {
      handleResetWorkDays();
    }
  }, []);

  const handleResetTime = async () => {
    timeExpetionParams.handleResetTime();
    await Promise.all(
      workDays.map(day => {
        form.setValue(day.id, false);
      }),
    );
    handleResetWorkDays();

    setSelected([startWeek, endWeek]);
  };

  const handleSetSelected = (selected: Date[]) => {
    setSelected(selected);
  };

  return {
    form,
    ...timeExpetionParams,
    currentLanguage,
    defaultPolicy,
    defaultShift,
    endTime,
    handleResetTime,
    handleSetSelected,
    selected,
    setEndTime,
    setSelected,
    setStartTime,
    startTime,
  };
};
