import { modes } from '@/modules/Home/utils/constants';

export enum SelectDataEnum {
  LAST_2_HOURS = 'last2Hours',
  LAST_4_HOURS = 'last4Hours',
  LAST_HOUR = 'lastHour',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
}

export type ModeProps = (typeof modes)[0];
