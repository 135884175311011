import { useState } from 'react';

import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { SelectType } from '@/shared/components/form/ExtendedAssetsSelect';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export function useExtendedAssetsSelect(value: string[], assetsWithTags?: boolean) {
  const [selectType, setSelectType] = useState<SelectType>('equipment');
  const [searchValue, setSearchValue] = useState('');

  const assets = useGlobalStore(state => state.assets);

  const { data: assetsCategories = [] } = useAssetHooks.getCategoryTrees();

  const modifiedAssets = assets.map(asset => asset.assetResName);
  const isAllChecked = modifiedAssets.every(assetName => value.includes(assetName));

  const renderFilteredCategory = (value: string) => {
    return assetsCategories.find(category =>
      category.categoryTreeName.toLowerCase().includes(value.toLowerCase()),
    );
  };

  const getFilteredAssetsByType = (type: string) => {
    const category = renderFilteredCategory(type);
    return assets.filter(asset => asset.categoryTreeResName === category?.categoryTreeResName);
  };

  const isAllCheckedForType = (filteredAssets: AssetBody[], value: string[]) => {
    const assetNames = filteredAssets.map(asset => asset.assetResName);
    return assetNames.every(assetName => value.includes(assetName));
  };

  const renderSortedOptions = (initialType?: SelectType) => {
    const searchedAssets = assets.filter(asset =>
      asset.assetName.toLowerCase().includes(searchValue.toLowerCase()),
    );

    const extraModifiedAssets = searchedAssets.filter(asset => {
      const category = renderFilteredCategory(
        initialType?.replace('people', 'worker') ?? selectType.replace('people', 'worker'),
      );

      return asset?.categoryTreeResName === category?.categoryTreeResName;
    });
    const transformedModifiedAssets = new Set(extraModifiedAssets.map(asset => asset.assetResName));

    const filteredAssets = extraModifiedAssets.filter(asset => {
      const currentTagProperty = asset.extraProperties?.find(property => property.key === 'tag');

      return !!currentTagProperty;
    });

    const result = assetsWithTags ? filteredAssets : extraModifiedAssets;

    const sortedAssets = result
      .filter(asset => transformedModifiedAssets.has(asset.assetResName))
      .sort((a, b) => a.assetName.localeCompare(b.assetName));

    return sortedAssets.map(asset => ({
      ...asset,
      assetResName: asset.assetResName ?? '-',
    }));
  };

  const handleToggleAll = (onChange: (value: string[]) => void, selectType?: SelectType) => {
    let values: string[];

    if (selectType === 'equipment') {
      const equipmentAssets = getFilteredAssetsByType('Equipment');
      const isAllCheckedForEquipment = isAllCheckedForType(equipmentAssets, value);

      values = isAllCheckedForEquipment
        ? value.filter(
            selected => !equipmentAssets.map(asset => asset.assetResName).includes(selected),
          )
        : [...new Set([...value, ...equipmentAssets.map(asset => asset.assetResName)])];
    } else if (selectType === 'people') {
      const personAssets = getFilteredAssetsByType('Worker');
      const isAllCheckedForPerson = isAllCheckedForType(personAssets, value);

      values = isAllCheckedForPerson
        ? value.filter(
            selected => !personAssets.map(asset => asset.assetResName).includes(selected),
          )
        : [...new Set([...value, ...personAssets.map(asset => asset.assetResName)])];
    } else {
      values = isAllChecked
        ? value.filter(selected => !modifiedAssets.includes(selected))
        : [...new Set([...value, ...modifiedAssets])];
    }

    onChange(values);
  };

  return {
    assets,
    getFilteredAssetsByType,
    handleToggleAll,
    isAllChecked,
    isAllCheckedForType,
    modifiedAssets,
    renderFilteredCategory,
    renderSortedOptions,
    searchValue,
    selectType,
    setSearchValue,
    setSelectType,
  };
}
