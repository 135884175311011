import { useState } from 'react';

export type DaysOfWeekProps = Record<string, Date>;

export const useTimeExceptions = (defaultStartTime: Date, defaultEndTime: Date) => {
  const [mondayStart, setMondayStart] = useState<Date>(defaultStartTime);
  const [mondayEnd, setMondayEnd] = useState<Date>(defaultEndTime);

  const [tuesdayStart, setTuesdayStart] = useState<Date>(defaultStartTime);
  const [tuesdayEnd, setTuesdayEnd] = useState<Date>(defaultEndTime);

  const [wednesdayStart, setWednesdayStart] = useState<Date>(defaultStartTime);
  const [wednesdayEnd, setWednesdayEnd] = useState<Date>(defaultEndTime);

  const [thursdayStart, setThursdayStart] = useState<Date>(defaultStartTime);
  const [thursdayEnd, setThursdayEnd] = useState<Date>(defaultEndTime);

  const [fridayStart, setFridayStart] = useState<Date>(defaultStartTime);
  const [fridayEnd, setFridayEnd] = useState<Date>(defaultEndTime);

  const [saturdayStart, setSaturdayStart] = useState<Date>(defaultStartTime);
  const [saturdayEnd, setSaturdayEnd] = useState<Date>(defaultEndTime);

  const [sundayStart, setSundayStart] = useState<Date>(defaultStartTime);
  const [sundayEnd, setSundayEnd] = useState<Date>(defaultEndTime);

  const startTimes: DaysOfWeekProps = {
    friday: fridayStart,
    monday: mondayStart,
    saturday: saturdayStart,
    sunday: sundayStart,
    thursday: thursdayStart,
    tuesday: tuesdayStart,
    wednesday: wednesdayStart,
  };

  const endTimes: DaysOfWeekProps = {
    friday: fridayEnd,
    monday: mondayEnd,
    saturday: saturdayEnd,
    sunday: sundayEnd,
    thursday: thursdayEnd,
    tuesday: tuesdayEnd,
    wednesday: wednesdayEnd,
  };

  const handleStartTimeChange = (day: string, time: Date) => {
    switch (day) {
      case 'friday':
        setFridayStart(time);
        break;
      case 'monday':
        setMondayStart(time);
        break;
      case 'saturday':
        setSaturdayStart(time);
        break;
      case 'thursday':
        setThursdayStart(time);
        break;
      case 'tuesday':
        setTuesdayStart(time);
        break;
      case 'wednesday':
        setWednesdayStart(time);
        break;
      default:
        setSundayStart(time);
    }
  };

  const handleEndTimeChange = (day: string, time: Date) => {
    switch (day) {
      case 'friday':
        setFridayEnd(time);
        break;
      case 'monday':
        setMondayEnd(time);
        break;
      case 'saturday':
        setSaturdayEnd(time);
        break;
      case 'thursday':
        setThursdayEnd(time);
        break;
      case 'tuesday':
        setTuesdayEnd(time);
        break;
      case 'wednesday':
        setWednesdayEnd(time);
        break;
      default:
        setSundayEnd(time);
    }
  };

  const handleResetTime = () => {
    setMondayStart(defaultStartTime);
    setMondayEnd(defaultEndTime);
    setTuesdayStart(defaultStartTime);
    setTuesdayEnd(defaultEndTime);
    setWednesdayStart(defaultStartTime);
    setWednesdayEnd(defaultEndTime);
    setThursdayStart(defaultStartTime);
    setThursdayEnd(defaultEndTime);
    setFridayStart(defaultStartTime);
    setFridayEnd(defaultEndTime);
    setSaturdayStart(defaultStartTime);
    setSaturdayEnd(defaultEndTime);
    setSundayStart(defaultStartTime);
    setSundayEnd(defaultEndTime);
  };

  return {
    endTimes,
    handleEndTimeChange,
    handleResetTime,
    handleStartTimeChange,
    startTimes,
  };
};
