import { translate } from '@/i18n';
import { SubcontractorsFormBody } from '@/shared/components/ui/Modal/Subcontractor/FormBody.tsx';
import { Typography } from '@/shared/components/ui/Typography';

import { useInviteForm } from '../hooks/useInviteForm';

export function EditSubcontractorsFirm() {
  const { firmForm, firmFormHandler, props } = useInviteForm();

  props.type = 'firmEdit';

  return (
    <>
      <Typography className="mb-4 text-xl font-bold tracking-[0.1px]">
        {translate('global.editFirm')}
      </Typography>

      <SubcontractorsFormBody {...props} handler={firmFormHandler} params={firmForm} />
    </>
  );
}
