import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { translate } from '@/i18n';
import { FloorSelector } from '@/modules/Home/components/blocks/FloorSelector';
import { useHomePage } from '@/modules/Home/hooks/common';
import { InputWrapper } from '@/modules/Settings/components/common/InputWrapper';
import { useSiteMapSettings } from '@/modules/Settings/hooks/useSiteMapSettings';
import { Fade } from '@/shared/components/animation/Fade';
import { Button } from '@/shared/components/buttons/Button';
import { Select } from '@/shared/components/form/Select';
import MapModel from '@/shared/components/map/MapModel';
import { Typography } from '@/shared/components/ui/Typography';

export const SiteMap: FC = () => {
  const { activeMap, control, getValues, handlePreviewSelected, handleSubmit, mapList } =
    useSiteMapSettings();
  const { mapCoordinates, mapView } = useHomePage();

  return (
    <Fade triggerOnce>
      <div className="relative">
        <form
          autoComplete="off"
          className="relative z-20 flex w-full gap-y-4"
          id="site-map"
          onSubmit={handleSubmit(handlePreviewSelected)}
        >
          {/*Site time zone*/}
          <InputWrapper label={translate('settings.selectSiteMap')}>
            <div className="flex w-[40%] min-w-[250px] justify-start">
              <Controller
                control={control}
                name="selectedSiteMap"
                render={({ field }) => (
                  <Select
                    aria-label="site-map"
                    className="min-h-[38px] min-w-[250px]"
                    data={mapList}
                    defaultValue={getValues('selectedSiteMap')}
                    onChange={value => field.onChange(value)}
                    variant="default"
                  />
                )}
              />
              <Button
                aria-label="site-map-preview"
                className="mx-3 w-full max-w-[100px]"
                type="submit"
                variant="outline"
              >
                {translate('settings.preview')}
              </Button>
            </div>
          </InputWrapper>
        </form>

        <div className="relative my-5 flex h-full items-stretch">
          <div className="border-grey relative max-h-[60vh] min-h-[60vh] w-full overflow-hidden rounded border bg-white">
            {/*Map*/}
            <MapModel
              isGridVisible={false}
              mapCoordinates={mapCoordinates}
              mapKey="mapKey"
              mapView={mapView}
            />
          </div>
          {/*Floor Selector*/}
          <Typography className="absolute bottom-4 left-4 max-w-[172px] truncate text-sm text-slate-500">
            {activeMap?.mapName?.replace('_', ' ')}
          </Typography>
          <FloorSelector isLoading={false} />
        </div>
      </div>
    </Fade>
  );
};
