import { cloneElement, forwardRef, ReactNode, useMemo, useRef, useState } from 'react';

import { Html } from '@react-three/drei';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import { Mesh, Vector3 } from 'three';

import { I18nProvider } from '@/i18n';
import { roundNumberToDp } from '@/modules/Home/utils/ZoneEditor.utils.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';

interface ControlPointProps {
  children?: ReactNode;
  color: string;
  onClick?: (event: ThreeEvent<MouseEvent>) => void;
  onMove?: (event: ThreeEvent<PointerEvent>) => void;
  onPress?: (event: ThreeEvent<PointerEvent>) => void;
  onRelease?: (event: ThreeEvent<PointerEvent>) => void;
  position: Vector3;
  radius: number;
}

export const ControlPoint = forwardRef<Mesh, ControlPointProps>(
  (
    { children, color, onClick, onMove, onPress, onRelease, position, radius }: ControlPointProps,
    ref,
  ) => {
    const { camera } = useThree();
    const [displayChildren, setDisplayChildren] = useState(false);
    const [adaptiveRadius, setAdaptiveRadius] = useState(radius / camera.zoom);
    const hideChildrenTimerId = useRef<NodeJS.Timeout | null>(null);

    const lang = useGlobalStore(store => store.currentLanguage);

    const pos = useMemo(() => {
      // This raises ControlPoints above OutlinedShape
      return new Vector3(position.x, position.y, 10);
    }, [position]);

    const _children = useMemo(() => {
      if (!children) return null;

      return cloneElement(children as never, {
        onMouseEnter: () => {
          handlePointerEnter(null as never);
        },
        onMouseLeave: () => {
          handlePointerLeave(null as never);
        },
      });
    }, [children]);

    const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onPress?.(event);
    };

    const handlePointerMove = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onMove?.(event);
    };

    const handlePointerUp = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onRelease?.(event);
    };

    const handlePointerEnter = (_: unknown) => {
      setDisplayChildren(true);

      if (hideChildrenTimerId.current) {
        clearTimeout(hideChildrenTimerId.current);
      }
    };

    const handlePointerLeave = (_: unknown) => {
      hideChildrenTimerId.current = setTimeout(() => setDisplayChildren(false), 500);
    };

    useFrame(({ camera }) => {
      setAdaptiveRadius(radius / camera.zoom);
    });

    return (
      <mesh
        onClick={onClick}
        onPointerDown={handlePointerDown}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        position={pos} //NOSONAR
        ref={ref}
      >
        <circleGeometry
          args={[adaptiveRadius]} //NOSONAR
        />
        <meshStandardMaterial color={color} />

        <Html
          className="!relative"
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
          zIndexRange={[888888, 0]}
        >
          <I18nProvider locale={lang}>
            <Typography className="font-mono absolute -top-8 -translate-x-1/2 whitespace-nowrap text-xs font-medium tracking-wide text-gray-600">
              ( {roundNumberToDp(pos.x)}, {roundNumberToDp(pos.y)} )
            </Typography>

            {displayChildren && _children}
          </I18nProvider>
        </Html>
      </mesh>
    );
  },
);
