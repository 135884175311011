import { useGlobalStore } from '@/shared/store/store.ts';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useModalHandlerTypes = () => {
  const isModalOpen = useGlobalStore(state => state.isModalOpen);
  const openModal = useGlobalStore(state => state.openModal);
  const closeModal = useGlobalStore(state => state.closeModal);
  const isModalDeleting = useGlobalStore(state => state.isModalDeleting);

  const filterClickHandler = () => {
    openModal(PopupTypeEnum.MAP_FILTER);
  };

  const geofenceDeleteHandler = (args: { geofenceId: string; geofenceName: string }) => {
    openModal(PopupTypeEnum.CONFIRM_DELETE, {
      geofenceId: args.geofenceId,
      geofenceName: args.geofenceName,
    });
  };

  const equipmentDeleteHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.EQUIPMENT_DELETE, {
      data,
    });
  };

  const geofenceSaveHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.CONFIRM_SAVE, {
      geofenceId: data,
      reservation: data,
    });
  };

  const reservationDeleteHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.RESERVATIONS_CONFIRM_DELETE, {
      reservation: data,
    });
  };

  const reservationSubmitHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.RESERVATIONS_CONFIRM_SUBMIT, {
      reservation: data,
    });
  };

  const reservationApproveHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.RESERVATIONS_CONFIRM_APPROVE, {
      reservation: data,
    });
  };

  const reservationRejectHandler = <T>(data: T) => {
    openModal(PopupTypeEnum.RESERVATIONS_CONFIRM_REJECT, {
      reservation: data,
    });
  };

  return {
    closeModal,
    equipmentDeleteHandler,
    filterClickHandler,
    geofenceDeleteHandler,
    geofenceSaveHandler,
    isModalDeleting,
    isModalOpen,
    openModal,
    reservationApproveHandler,
    reservationDeleteHandler,
    reservationRejectHandler,
    reservationSubmitHandler,
  };
};
