import { FC } from 'react';

import { translate } from '@/i18n';
import { MobileCard } from '@/shared/components/ui/MobileCard';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';

import { RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';
import { FirmsDatasource, TableActions } from '@/shared/types/users/subcontractors.types.ts';

type Props = {
  firm: FirmsDatasource;
  action: (type: TableActions, id: string) => void;
};

export const PeopleMobileCard: FC<Props> = ({ action, firm }) => {
  const { renderItemVisibility } = useRole();

  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.KOTO,
  ]);

  return (
    <MobileCard
      action={{
        disabled: !isVisible,
        label: translate('map.edit'),
        onClick: () => action('edit', firm.id),
      }}
      details={[
        {
          label: translate('global.totalWorkers'),
          value: firm.total_workers,
        },
        {
          label: translate('global.onSite'),
          value: firm.on_site,
        },
      ]}
      link={`${RoutesEnum.PEOPLE}/${firm.id}`}
      status={firm.status}
      statusColor={firm.status === 'active' ? 'green' : 'gray'}
      title={firm.firm_name}
    />
  );
};
