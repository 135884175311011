import translations from '@/i18n/translations';

export const keyExistsInTranslation = (key: string): boolean => {
  for (const locale of Object.values(translations)) {
    if (key in locale) {
      return true;
    }
  }

  return false;
};
