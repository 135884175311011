import { useCreateReservations } from '@/modules/Reservations/hooks/useCreateReservations.ts';
import { ReserveEquipment } from '@/shared/components/ui/Modal/Equipment/extensions/ReserveEquipment';

export function CreateReservation() {
  const { mutate, params, states } = useCreateReservations();

  const props = {
    mutate,
    params,
    states,
  };

  return <ReserveEquipment {...props} />;
}
