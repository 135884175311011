import { FC, memo } from 'react';

import { useLoader } from '@react-three/fiber';
import { ColladaLoader } from 'three/addons/loaders/ColladaLoader.js';

import { MAP_FILE_PATH } from '@/modules/Home/utils/MapModel.config.ts';

import { ColladaModelProps, ColladaModelSceneProps } from '@/modules/Home/types/MapModel.types';

const createColladaMapKey = (map: string, mapKey: string): string => `${map}#${mapKey}`;

//Collada file loader
const ColladaModel: FC<ColladaModelProps> = ({ map, mapKey }) => {
  const { scene } = useLoader(
    ColladaLoader,
    createColladaMapKey(`${map ?? MAP_FILE_PATH.FALLBACK.DEFAULT}#${mapKey}`, mapKey),
  ) as ColladaModelSceneProps;

  return (
    <primitive
      dispose={null} //NOSONAR
      key={`${mapKey}-primitive`}
      object={scene} //NOSONAR
    />
  );
};

export default memo(ColladaModel);
