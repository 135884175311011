import ThreeD from '@/assets/icons/3d.svg?react';
import Alert from '@/assets/icons/alert-sm.svg?react';
import Archive from '@/assets/icons/archive.svg?react';
import ArrowSm from '@/assets/icons/arrow-sm.svg?react';
import Bell from '@/assets/icons/bell-sm.svg?react';
import Burger from '@/assets/icons/burger.svg?react';
import Calendar from '@/assets/icons/calendar-black.svg?react';
import AutoApproval from '@/assets/icons/circle-check.svg?react';
import Clock from '@/assets/icons/clock-lg.svg?react';
import CloseSm from '@/assets/icons/close-sm.svg?react';
import Close from '@/assets/icons/close.svg?react';
import Decrease from '@/assets/icons/decrease.svg?react';
import DeleteSm from '@/assets/icons/delete-sm.svg?react';
import Delete from '@/assets/icons/delete.svg?react';
import EditSm from '@/assets/icons/edit-sm.svg?react';
import Edit from '@/assets/icons/edit.svg?react';
import Equipment from '@/assets/icons/equipment.svg?react';
import Actions from '@/assets/icons/error.svg?react';
import EyeSm from '@/assets/icons/eye-sm.svg?react';
import Eye from '@/assets/icons/eye.svg?react';
import Filter from '@/assets/icons/filter.svg?react';
import FullScreen from '@/assets/icons/full-screen.svg?react';
import Geolocation from '@/assets/icons/geolocation.svg?react';
import Increase from '@/assets/icons/increase.svg?react';
import ItemMove from '@/assets/icons/item-move.svg?react';
import LeftArrow from '@/assets/icons/left-arrow.svg?react';
import Location from '@/assets/icons/location-sm.svg?react';
import SiteMap from '@/assets/icons/location.svg?react';
import MoreVertical from '@/assets/icons/more-vertical.svg?react';
import PassEyeSm from '@/assets/icons/pass-eye-sm.svg?react';
import PassEye from '@/assets/icons/pass-eye.svg?react';
import PlusIcon from '@/assets/icons/plus.svg?react';
import Priority from '@/assets/icons/priority.svg?react';
import Profile from '@/assets/icons/profile.svg?react';
import RotateLeft from '@/assets/icons/rotate-left.svg?react';
import RotateRight from '@/assets/icons/rotate-right.svg?react';
import Settings from '@/assets/icons/settings-sm.svg?react';
import SidebarRotate from '@/assets/icons/sidebar-rotate.svg?react';
import Sidebar from '@/assets/icons/sidebar.svg?react';
import DotVertical from '@/assets/icons/vertical-dot.svg?react';
import Worker from '@/assets/icons/worker.svg?react';
import { DefaultButtonProps, WithClassName } from '@/shared/types/global/common.types.ts';

export const iconsButtonMap = {
  '3d': ThreeD,
  actions: Actions,
  all: Bell,
  archive: Archive,
  'arrow-sm': ArrowSm,
  'auto-approval': AutoApproval,
  burger: Burger,
  calendar: Calendar,
  clock: Clock,
  close: Close,
  'close-sm': CloseSm,
  decrease: Decrease,
  delete: Delete,
  'delete-sm': DeleteSm,
  'dot-vertical': DotVertical,
  edit: Edit,
  'edit-sm': EditSm,
  equipment: Equipment,
  eye: Eye,
  'eye-sm': EyeSm,
  filter: Filter,
  'full-screen': FullScreen,
  geolocation: Geolocation,
  increase: Increase,
  'item-move': ItemMove,
  'left-arrow': LeftArrow,
  'more-vertical': MoreVertical,
  'pass-eye': PassEye,
  'pass-eye-sm': PassEyeSm,
  plus: PlusIcon,
  priority: Priority,
  profile: Profile,
  reservation: Calendar,
  'rotate-left': RotateLeft,
  'rotate-right': RotateRight,
  safety: Alert,
  sidebar: Sidebar,
  'sidebar-rotate': SidebarRotate,
  'site-map': SiteMap,
  system: Settings,
  worker: Worker,
  zone: Location,
};

export type IIconButtonProps = DefaultButtonProps &
  WithClassName & {
    icon: keyof typeof iconsButtonMap;
    isToggled?: boolean;
  };
