import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { PARAM_KEY, QUERY_KEY } from '@/shared/utils/constants.ts';

import useCustomParams from '../global/useCustomParams';

export const useGetAsset = () => {
  const params = useCustomParams();

  const site = useGlobalStore(store => store.site);

  const { user } = useFetchUserData();

  const assetId = params[PARAM_KEY.EQUIPMENT_ID];

  return useQuery({
    enabled: !!user?.accountResourceName && !!site && !!assetId,

    queryFn: async () => {
      return await AssetsAPINamespace.getOne({
        accountResName: user?.accountResourceName ?? '',
        assetResName: assetId ?? '',
        siteResName: site,
      });
    },

    queryKey: [QUERY_KEY.EQUIPMENT_GET, user?.accountResourceName, site, assetId],
    retry: false,
    retryOnMount: false,
  });
};
