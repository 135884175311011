import { FC } from 'react';
import { useToggle } from 'react-use';

import { translate } from '@/i18n';
import { InputWrapper } from '@/modules/Settings/components/common/InputWrapper';
import { TableBodyRow } from '@/modules/Settings/components/common/TableBodyRow';
import { buffers, categories, tableHeaders, timeOptions } from '@/modules/Settings/utils/constants';
import { Fade } from '@/shared/components/animation/Fade';
import { Button } from '@/shared/components/buttons/Button';
import { SearchInput } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { Select } from '@/shared/components/form/Select';
import { Toggle } from '@/shared/components/form/Toggle';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { MISSING_DATA } from '@/shared/utils/constants';

import PlusIcon from '@/assets/icons/plus.svg?react';

export const AutoApproval: FC = () => {
  const [isAutoApprovalEnabled, toggleAutoApproval] = useToggle(false);
  const [isActivatedExclusion, toggleActivatedExclusion] = useToggle(false);

  return (
    <Fade>
      <div className="grid gap-y-4">
        <div className="inline-flex gap-x-2.5">
          <Toggle checked={isAutoApprovalEnabled} onChange={toggleAutoApproval} />

          <Typography as="span">{translate('settings.activateAutoApproval')}</Typography>
        </div>

        <InputWrapper className="w-[340px]" label={translate('settings.timeWindow')}>
          <Select
            className="bg-white"
            data={timeOptions}
            disabled={!isAutoApprovalEnabled}
            onChange={() => {}}
            variant="default"
          />
        </InputWrapper>

        <InputWrapper className="w-[340px]" label={translate('settings.buffer')}>
          <Select
            className="bg-white"
            data={buffers}
            disabled={!isAutoApprovalEnabled}
            onChange={() => {}}
            variant="default"
          />
        </InputWrapper>

        <div className="inline-flex gap-x-2.5">
          <Toggle checked={isActivatedExclusion} onChange={toggleActivatedExclusion} />

          <Typography as="span">{translate('settings.activateExclusion')}</Typography>
        </div>

        <div className="flex items-center gap-x-2">
          <Label className="relative mr-4 w-full max-w-56">
            <SearchInput disabled={!isActivatedExclusion} />
          </Label>

          <div className="w-[180px]">
            <Select
              className="bg-white"
              data={categories}
              disabled={!isActivatedExclusion}
              label="settings.category"
              onChange={() => {}}
              variant="default"
            />
          </div>

          <Button
            className="text-red-500 hover:bg-red-50 focus:bg-red-50"
            disabled={!isActivatedExclusion}
            variant="ghost"
          >
            {translate('global.clear')}
          </Button>

          <Button className="ml-auto flex-shrink-0" disabled variant="solid" withIcon>
            <PlusIcon className="h-3 w-3" />
            {translate('settings.addExclusion')}
          </Button>
        </div>

        {/*Table*/}
        <div className="overflow-hidden rounded-md border">
          <table className="min-w-full divide-y divide-slate-200">
            <thead className="bg-slate-50">
              <tr>
                {tableHeaders.map(({ id, name }) => (
                  <th
                    className={classnames(
                      'px-4 py-3 text-start text-xs font-medium uppercase tracking-[0.06px] text-slate-500',
                      id === 'actions' && 'sr-only p-0',
                    )}
                    key={id}
                    scope="col"
                  >
                    {name}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-slate-200 bg-white">
              <TableBodyRow
                category={MISSING_DATA}
                details={MISSING_DATA}
                onDelete={() => {}}
                onEdit={() => {}}
                selection={MISSING_DATA}
              />
            </tbody>
          </table>
        </div>
      </div>
    </Fade>
  );
};
