import { FC } from 'react';

import { useReadersHooks } from '@/shared/api/readers/readers.hooks.ts';
import { useSitesHooks } from '@/shared/api/sites/sites.hooks';
import { useSetAssets } from '@/shared/hooks/assets/useSetAssets.ts';
import { useSetCompanies } from '@/shared/hooks/assets/useSetCompanies';
import { useSetTags } from '@/shared/hooks/assets/useSetTags.ts';
import { useGetZpsUsers } from '@/shared/hooks/users/useGetZpsUser.ts';
import { useSetZpsUsers } from '@/shared/hooks/users/useSetZpsUsers.ts';

export const AssetHooks: FC = () => {
  useGetZpsUsers();
  useSetCompanies();
  useSetZpsUsers();

  useSetTags();
  useSitesHooks.getAllTags();
  useSetAssets();
  useReadersHooks.getAll();

  return null;
};
