import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIsFetching } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { renderColumns } from '@/modules/Reservations/components/TabPanels/HistoryTab/History.utils.tsx';
import { useModifyReservationHistoryData } from '@/modules/Reservations/hooks/useModifyReservationHistoryData';
import { Button } from '@/shared/components/buttons/Button';
import { Toggle } from '@/shared/components/form/Toggle';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { Loader } from '@/shared/components/ui/Loader';
import { TopSection } from '@/shared/components/ui/TopSection';
import { Typography } from '@/shared/components/ui/Typography';
import { QUERY_KEY } from '@/shared/utils/constants';

import Refresh from '@/assets/icons/refresh-sm.svg?react';

export const History = () => {
  const [, setSearchParameters] = useSearchParams();

  const [isShowInactive, setIsShowInActive] = useState(false);

  const { allReservationsHistory, refetch } = useModifyReservationHistoryData();
  const isLoading = useIsFetching({
    queryKey: [QUERY_KEY.RESERVATION_HISTORY],
  });

  const handleRefresh = async () => await refetch();

  const handleToggleShowInactive = () => {
    setSearchParameters(prev => {
      prev.set('status', isShowInactive ? 'active' : 'inactive');
      return prev;
    });
    setIsShowInActive(!isShowInactive);
  };

  return (
    <div className="relative flex h-full flex-col gap-y-2 md:gap-y-4">
      <div className="w-full items-center justify-between lg:flex">
        <TopSection
          className="max-w-[88%]"
          scope={['workflowState', 'firm', 'multi-date']}
          selectedBold
          withMaxDate={false}
        />

        <div className="flex gap-x-2 self-start">
          <div className="hidden w-full items-center gap-x-2 md:ml-auto md:inline-flex md:w-auto">
            <Toggle checked={isShowInactive} onChange={handleToggleShowInactive} />

            <Typography className="text-sm font-medium">
              {translate('reservation.toggleInactive')}
            </Typography>
          </div>

          <Button
            className="hidden gap-x-2 md:inline-flex"
            onClick={handleRefresh}
            variant="outline"
          >
            {translate('reservation.refresh')}

            <Refresh />
          </Button>

          <Button
            className="absolute right-0 top-0 gap-x-2 md:hidden"
            onClick={handleRefresh}
            variant="outline"
          >
            <Refresh />
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex h-full content-center items-center">
          <Loader appearance="section" loaderText={translate('confirm.loading')} />
        </div>
      ) : (
        <DefaultTable
          className="max-h-[calc(100vh-300px)]"
          columns={renderColumns()}
          customPageSize={10}
          data={allReservationsHistory}
          headerClassName="first:min-w-[100px]"
          isPaginated
          pagesCount={allReservationsHistory.length}
        />
      )}
    </div>
  );
};
