import { FloorProps } from '../api/floors/floors.types';

import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { AssetBody, AssetOrganization } from '@/shared/types/assets/assets.types.ts';

export const findTagValue = (item: AssetBody, tag = 'tag') => {
  return item?.extraProperties?.filter(prop => prop.key === tag)?.at(-1)?.value;
};

export const findAttachedTagValue = (item: AssetBody) => {
  if (item.attachedTags && item.attachedTags.length > 0) {
    return item.attachedTags[0].tagResName;
  }
};

export const findCompanyNameForAssetData = (
  asset: AssetBody,
  tags: string[],
  companies: AssetOrganization[],
): string | undefined => {
  for (const tag of tags) {
    const company = companies.find(company => company.orgResName === findTagValue(asset, tag));
    if (company) return company.orgName;
  }

  return undefined;
};

export const isActiveAsset = (item: AssetBody) => item.assetStatus === 'active';

export const isAssetOnMap = (socketMapData: SocketMapDataProps[], itemTag: unknown) => {
  return socketMapData.some(socketItem => socketItem.tagResName === itemTag);
};

export const mapItemWithIsOnMap = (item: AssetBody, isOnMap: boolean) => ({
  ...item,
  isOnMap,
});

export const isWithinZRange = (z: number, floorData: FloorProps): boolean => {
  const zMin = Math.min(floorData.readerPlacementLevel, floorData.ceilingHeight) / 100;
  const zMax = Math.max(floorData.readerPlacementLevel, floorData.ceilingHeight) / 100;

  return z >= zMin && z <= zMax;
};
