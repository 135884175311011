import { ColumnDef, Row } from '@tanstack/react-table';

import { Typography } from '@/shared/components/ui/Typography';
import { useFilteredData } from '@/shared/hooks/global/useFilteredData.ts';
import { secondsToHHMM } from '@/shared/utils/seconds-transform.ts';

import { TimeInZone } from '@/shared/types/reports/analytics.types.ts';

type Props = {
  times: Pick<TimeInZone, 'assetName' | 'assetResourceName' | 'geofenceResResNames'>[];
  initialTimes: TimeInZone[];
  currentExtraGroup: string;
  totalManHours: number;
  totalEntries: number;
};

export function useTimeZoneDeps({
  currentExtraGroup,
  initialTimes,
  times,
  totalEntries,
  totalManHours,
}: Props) {
  const { geofences } = useFilteredData();

  const uniqueTimes: {
    [assetResName: string]: { [geofenceResResName: string]: number };
  } = {};

  initialTimes?.forEach(time => {
    if (time.geofenceResResName && time.assetResName) {
      if (!uniqueTimes[time.assetResName]) {
        uniqueTimes[time.assetResName] = {};
      }

      if (uniqueTimes[time.assetResName][time.geofenceResResName]) {
        uniqueTimes[time.assetResName][time.geofenceResResName] += Number(time.totalTime);
      } else {
        uniqueTimes[time.assetResName][time.geofenceResResName] = Number(time.totalTime);
      }
    }
  });

  const geofenceHeaders = Object.keys(
    initialTimes?.reduce(
      (acc, time) => {
        if (time.geofenceResResName) {
          acc[time.geofenceResResName] = true;
        }
        return acc;
      },
      {} as { [geofenceResResName: string]: boolean },
    ),
  );

  const geofencesTableHeaderData = geofenceHeaders.map(geofenceHeader => {
    const currentGeofence = geofences.find(geofence => geofence.geofenceResName === geofenceHeader);

    return {
      accessorKey: `${currentGeofence?.id}`,
      cell: ({ row }: { row: Row<TimeInZone> }) => {
        const currentAsset = times.find(
          time => time.assetResourceName === row.original.assetResourceName,
        );

        const currentGeofenceHeader = currentAsset?.geofenceResResNames.find(
          name => name.geofenceResResName === geofenceHeader,
        );

        if (currentGeofenceHeader) {
          if (currentExtraGroup === 'time') return secondsToHHMM(+currentGeofenceHeader.totalTime);
          if (currentExtraGroup === 'entries')
            return (currentGeofenceHeader.episodes as string).length ?? '0';
        }

        return '00:00';
      },
      header: () => (
        <Typography as="span" className="truncate text-nowrap pr-4">
          {currentGeofence?.geofenceName || ''}
        </Typography>
      ),
    };
  }) as unknown as ColumnDef<TimeInZone>[];

  const renderTotalCardProps = () => {
    if (currentExtraGroup === 'time') return String(secondsToHHMM(totalManHours));
    if (currentExtraGroup === 'entries') return String(totalEntries);

    return '0';
  };

  return { geofenceHeaders, geofencesTableHeaderData, renderTotalCardProps };
}
