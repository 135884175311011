import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';

import { UseMutateFunction } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Select } from '@/shared/components/form/Select';
import { Toggle } from '@/shared/components/form/Toggle';
import { InputWrapper } from '@/shared/components/ui/Modal/Equipment/components/InputWrapper';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import {
  currenciesList,
  groupsList,
  periodList,
} from '@/shared/components/ui/Modal/Subcontractor/data';
import { Typography } from '@/shared/components/ui/Typography';
import { useFiltersHideGroupsZonesShowMapFlag } from '@/shared/feature-flags/useFiltersHideGroupsZonesShowMapFlag.ts';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { RolesWrapper } from '@/shared/wrappers/Roles.tsx';

import { RolesEnum } from '@/shared/types/global/enums.ts';

type FormBodyProps<T, K> = {
  params: UseFormReturn;
  type: 'create' | 'edit';
  mutate: UseMutateFunction<T, Error, FieldValues, unknown>;
  states: Record<string, K>;
  deleteMutate?: UseMutateFunction<T, Error, void, unknown>;
  deleteStates?: Record<string, K>;
};

export function EquipmentFormBody<T, K>({
  deleteMutate,
  deleteStates,
  mutate,
  params,
  states,
  type = 'create',
}: Readonly<FormBodyProps<T, K>>) {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = params;

  const {
    closeModal,
    modifiedCategories,
    modifiedCategoriesPaths,
    modifiedGeofences,
    modifiedUnusedSiteTags,
  } = useEquipment(params);

  const { renderItemVisibility } = useRole();
  const { equipmentDeleteHandler } = useModalHandlerTypes();
  const { filtersHideGroupsZonesShowMapFlag } = useFiltersHideGroupsZonesShowMapFlag();

  const isVisible = renderItemVisibility([RolesEnum.MANAGER]);

  const renderTitle = () => {
    switch (type) {
      case 'create':
        return translate('global.addEquipment');
      case 'edit':
        return translate('global.editEquipment');
    }
  };

  const renderActions = () => {
    return (
      <>
        {type === 'edit' && (
          <>
            <Button
              className="bg-red-500 hover:bg-red-600"
              disabled={deleteStates?.isPending as boolean}
              isLoading={deleteStates?.isPending as boolean}
              onClick={() => {
                if (deleteMutate)
                  equipmentDeleteHandler({
                    ...getValues(),
                    deleteMutate,
                  });
              }}
              type="button"
              variant="solid"
              withIcon
            >
              {translate('confirm.delete')}
            </Button>

            <Button
              className="trun text-nowrap bg-red-600 hover:bg-red-700"
              disabled={(states?.isPending as boolean) || !getValues('tag')}
              isLoading={states?.isPending as boolean}
              onClick={() => {
                if (mutate)
                  mutate({
                    ...getValues(),
                    deleteTags: true,
                  });
              }}
              type="button"
              variant="solid"
              withIcon
            >
              {translate('assets.removeTags')}
            </Button>
          </>
        )}

        <div className="ml-auto inline-flex gap-x-[10px]">
          <Button
            className="border-slate-200 bg-white text-slate-800 hover:bg-slate-50 hover:text-slate-900 focus:bg-slate-50 focus:text-slate-900"
            onClick={closeModal}
            type="reset"
            variant="outline"
          >
            {translate('filters.cancel')}
          </Button>

          <Button
            disabled={states.isPending as boolean}
            isLoading={states.isPending as boolean}
            type="submit"
            variant="solid"
            withIcon
          >
            {translate('settings.save')}
          </Button>
        </div>
      </>
    );
  };

  const onSubmit = (data: FieldValues) => {
    try {
      mutate(data);
    } catch (error) {
      console.error(error);
    }
  };

  watch();

  return (
    <form className="grid w-full max-w-md gap-y-3 pb-[70px]" onSubmit={handleSubmit(onSubmit)}>
      <Typography as="strong" className="text-xl font-bold tracking-[0.1px]">
        {renderTitle()}
      </Typography>

      <InputWrapper isRequired label={translate('global.title')}>
        <div className="inline-flex w-full gap-x-3">
          <Input
            className="w-full"
            {...register('title')}
            error={errors?.title?.message as string}
            isErrorLabelHidden
          />
        </div>
      </InputWrapper>

      <RolesWrapper action="edit_equipment_types_subtypes">
        <InputWrapper isRequired label={translate('filters.type')}>
          <div className="w-full">
            <Select
              className="w-full"
              data={modifiedCategories}
              defaultValue={getValues('categoryType')}
              label="Select"
              onChange={value => setValue('categoryType', value)}
              variant="default"
            />
          </div>
        </InputWrapper>
      </RolesWrapper>

      <RolesWrapper action="edit_equipment_types_subtypes">
        {getValues('categoryType') &&
          modifiedCategoriesPaths &&
          modifiedCategoriesPaths?.length > 0 && (
            <InputWrapper isRequired label={translate('filters.subType')}>
              <div className="w-full">
                <Select
                  className="w-full"
                  data={getValues('categoryType') && modifiedCategoriesPaths}
                  defaultValue={getValues('categorySubType')}
                  onChange={value => setValue('categorySubType', value)}
                  variant="default"
                />
              </div>
            </InputWrapper>
          )}
      </RolesWrapper>

      <div className="flex items-center justify-start gap-x-2.5">
        {/*<Toggle checked={true} onChange={() => null} />*/}

        <Controller
          control={control}
          name="active"
          render={({ field }) => <Toggle checked={field.value} onChange={field.onChange} />}
        />

        <Typography as="span" className="text-sm font-medium">
          {translate('assets.active')}
        </Typography>
      </div>

      <InputWrapper label={translate('global.ownerRentalCompany')}>
        <div className="inline-flex w-full gap-x-3">
          <Input
            className="w-full"
            {...register('rentalCompany')}
            error={errors?.rentalCompany?.message as string}
            isErrorLabelHidden
          />
        </div>
      </InputWrapper>

      {isVisible && (
        <InputWrapper
          label={<Typography as="span">{translate('global.price')} (Period)</Typography>}
        >
          <div className="inline-flex w-full gap-x-3">
            <Input
              className="w-full"
              defaultValue="0"
              {...register('price')}
              error={errors?.price?.message as string}
              isErrorLabelHidden
            />

            <Select
              data={periodList}
              defaultValue={getValues('period')}
              label="auth.select"
              onChange={value => setValue('period', value)}
              variant="default"
            />
          </div>
        </InputWrapper>
      )}

      {isVisible && (
        <InputWrapper label={translate('settings.currency')}>
          <div className="w-full">
            <Select
              data={currenciesList}
              defaultValue={getValues('iso4217')}
              label="Select"
              onChange={value => setValue('iso4217', value)}
              variant="default"
            />
          </div>
        </InputWrapper>
      )}

      {filtersHideGroupsZonesShowMapFlag.enabled && (
        <InputWrapper label={translate('global.groups')}>
          <div className="w-full">
            <Select
              data={groupsList}
              disabled
              label="auth.select"
              onChange={() => null}
              variant="default"
            />
          </div>
        </InputWrapper>
      )}

      <InputWrapper label={translate('global.tags')}>
        <div className="w-full">
          <Select
            data={modifiedUnusedSiteTags(getValues('tag'))}
            defaultValue={getValues('tag')}
            label="auth.select"
            onChange={value => setValue('tag', value)}
            variant="default"
          />
        </div>
      </InputWrapper>

      <InputWrapper label={translate('global.dropOff')}>
        <div className="w-full">
          <Select
            className="w-full"
            data={modifiedGeofences}
            defaultValue={getValues('dropOff')}
            label="auth.select"
            onChange={value => setValue('dropOff', value)}
            variant="default"
          />
        </div>
      </InputWrapper>

      <div className="absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-between gap-x-2 overflow-x-auto rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2">
        {renderActions()}
      </div>
    </form>
  );
}
