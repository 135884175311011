export enum SidebarParameters {
  ACTIONS = 'actions',
  ADD_ZONE = 'add_zone',
  ALL = 'all',
  ARCHIVE = 'archive',
  AUTO_APPROVAL = 'auto_approval',
  EDIT = 'edit',
  EDIT_ZONE = 'edit_zone',
  EQUIPMENT = 'equipment',
  PROFILE = 'profile',
  RESERVATION = 'reservation',
  SAFETY = 'safety',
  SITE_HOURS = 'site_hours',
  SITE_MAP = 'site_map',
  SYSTEM = 'system',
  VIEW = 'view',
  ZONE = 'zone',
}
