import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants';

import { SitePolicy } from '@/shared/api/site-operations/types';

export const useCreateSitePolicy = () => {
  const site = useGlobalStore(state => state.site);

  const queryClient = useQueryClient();
  const { user } = useFetchUserData();

  const { isPending: isCreatingPolicy, mutate: createSitePolicy } = useMutation({
    mutationFn: async (newPolicy: SitePolicy) => {
      return await SiteOperationsAPINamespace.createOnePolicy({
        ...newPolicy,
        accountResName: user?.accountResourceName as string,
        siteResName: site,
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.success(translate('messages.siteShiftCreateFail'));
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.SITE_SHIFTS_GET],
      });
      notifyService.success(translate('messages.siteShiftCreateSuccess'));
    },
  });

  return {
    createSitePolicy,
    isCreatingPolicy,
  };
};
