import { useLocation } from 'react-router-dom';

import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { TableLink } from '@/shared/components/tables/TableLink';
import { Badge } from '@/shared/components/ui/Badge';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';
import { getCurrentDateAndTime } from '@/shared/utils/date-fns';

import { ReservationStatusEnum, RoutesEnum } from '@/shared/types/global/enums';
import { LocaleEnum } from '@/shared/types/global/i18n.types';
import { ReservationType } from '@/shared/types/global/reservations.types';

export const renderColumns = (): ColumnDef<ReservationType>[] => {
  const location = useLocation();

  return [
    {
      accessorFn: row => {
        return row.equipment?.assetName ?? '';
      },
      accessorKey: 'equipment',
      cell: ({ row }) =>
        row.original.equipment && (
          <TableLink
            state={{ from: location.pathname }}
            to={`${RoutesEnum.EQUIPMENT}/${row.original.equipment.assetResName}`}
          >
            {row.original.equipment.assetName}
          </TableLink>
        ),
      header: () => translate('global.equipmentName'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorFn: row => {
        return row.subCategory?.map(subCategory => subCategory.categoryPath).join('\n') ?? '';
      },
      accessorKey: 'type',
      cell: ({ row }) => (
        <Typography as="span" className="max-w-52 cursor-pointer truncate 2xl:max-w-full">
          {row.original.subCategory?.map(subCategory => subCategory.categoryPath).join('\n')}
        </Typography>
      ),
      header: () => translate('assets.type'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorFn: row => {
        return getCurrentDateAndTime(
          new Date(row.reservationPeriod.startTime.epochTime),
          LocaleEnum.JPN,
        );
      },
      accessorKey: 'reservationStartTime',
      cell: ({ row }) => {
        return getCurrentDateAndTime(
          new Date(row.original.reservationPeriod.startTime.epochTime),
          LocaleEnum.JPN,
        );
      },
      header: () => translate('global.reservationStart'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorFn: row => {
        return getCurrentDateAndTime(
          new Date(row.reservationPeriod.endTime.epochTime),
          LocaleEnum.JPN,
        );
      },
      accessorKey: 'reservationEndTime',
      cell: ({ row }) => {
        return getCurrentDateAndTime(
          new Date(row.original.reservationPeriod.endTime.epochTime),
          LocaleEnum.JPN,
        );
      },
      header: () => translate('global.reservationEnd'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'status',
      cell: ({ row }) => (
        <Badge
          className={classnames(
            'h-6 rounded-md border-slate-400  p-[8px] text-slate-800 first-letter:uppercase',
            {
              'border-green-600 text-green-600':
                row.original.status === ReservationStatusEnum.APPROVED ||
                row.original.status === ReservationStatusEnum.ACTIVE,
            },
            {
              'border-red-600 text-red-600':
                row.original.status === ReservationStatusEnum.REJECTED ||
                row.original.status === ReservationStatusEnum.INACTIVE,
            },
            {
              'border-black-600 text-black-600':
                row.original.status === ReservationStatusEnum.REQUESTED,
            },
            {
              'border-blue-600 text-blue-600':
                row.original.status === ReservationStatusEnum.COMPLETED,
            },
          )}
          variant="outline"
        >
          {row.original.status.charAt(0).toUpperCase() + row.original.status.slice(1)}
        </Badge>
      ),
      header: () => translate('filters.status'),
      meta: {
        isFiltered: true,
      },
    },
  ];
};
