import { FC } from 'react';

import { EquipmentMobileCard } from '@/modules/Equipment/components/common/EquipmentMobileCard/EquipmentMobileCard.tsx';
import { MobileList } from '@/shared/components/ui/MobileList';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { classnames } from '@/shared/utils/classnames.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  equipments: AssetBody[];
  isLoading: boolean;
};

export const EquipmentMobileList: FC<Props> = ({ className, equipments, isLoading }) => {
  if (isLoading) {
    return <Skeleton count={3} subTitleCount={4} variant="mobile-card" withStatus withTitle />;
  }

  return (
    <MobileList className={classnames(className)}>
      {equipments.map(equipment => (
        <EquipmentMobileCard equipment={equipment} key={equipment.assetResName} />
      ))}
    </MobileList>
  );
};
