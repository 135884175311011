import { ianaTimeZones } from '@/modules/Settings/utils/constants.ts';

import { DisplayTimeZoneEnum } from '@/shared/types/settings/system.types.ts';

export const setReportType = (
  reportType: string,
  displayTimeZone: DisplayTimeZoneEnum,
  startTime: Date,
  endTime: Date,
  startTimeEpoch: number,
  endTimeEpoch: number,
) => {
  let modifiedStartTime = startTime;
  let modifiedEndTime = endTime;

  switch (reportType) {
    case 'Site Presence': {
      modifiedStartTime = new Date(startTimeEpoch);
      modifiedEndTime = new Date(endTimeEpoch);
      break;
    }

    case 'TIZ':
    case 'Trip': {
      if (displayTimeZone === DisplayTimeZoneEnum.SITE) {
        modifiedStartTime = new Date(
          startTime.getFullYear(),
          startTime.getMonth(),
          startTime.getDate(),
          0,
          0,
          0,
        );

        modifiedEndTime = new Date(
          endTime.getFullYear(),
          endTime.getMonth(),
          endTime.getDate(),
          23,
          59,
          59,
        );
      }
      break;
    }

    case 'Utilization': {
      if (displayTimeZone === DisplayTimeZoneEnum.SITE) {
        modifiedStartTime = new Date(
          startTime.getFullYear(),
          startTime.getMonth(),
          startTime.getDate(),
          9,
          0,
          0,
        );

        modifiedEndTime = new Date(
          endTime.getFullYear(),
          endTime.getMonth(),
          endTime.getDate(),
          17,
          0,
          0,
        );
      }
      break;
    }

    default:
      break;
  }

  return { modifiedEndTime, modifiedStartTime };
};

export const getTimeZone = (offset: number) => {
  const currentTimeZone = ianaTimeZones.find(timeZone => timeZone.offset === offset);

  return currentTimeZone?.iana ?? ianaTimeZones[1].iana;
};
