import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants';

import { SiteShift } from '@/shared/api/site-operations/types';

export const useUpdateSiteShift = () => {
  const site = useGlobalStore(state => state.site);
  const calendars = useGlobalStore(state => state.siteCalendars);
  const shifts = useGlobalStore(state => state.siteShifts);

  const defaultCalendar = calendars[0];
  const defaultShift = shifts[0];

  const queryClient = useQueryClient();
  const { user } = useFetchUserData();
  const { isPending: isUpdatingShift, mutate: updateSiteShift } = useMutation({
    mutationFn: async (newShift: SiteShift) => {
      return await SiteOperationsAPINamespace.updateOneShift({
        ...newShift,
        accountResName: user?.accountResourceName as string,
        calendarResName: defaultCalendar.calendarResName,
        shiftResName: defaultShift.shiftResName as string,
        siteResName: site,
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.siteShiftUpdateFail'));
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.SITE_SHIFTS_GET],
      });
      notifyService.success(translate('messages.siteShiftUpdateSuccess'));
    },
  });

  return {
    isUpdatingShift,
    updateSiteShift,
  };
};
