import { useUserSettingsMutation } from '@/shared/hooks/settings/useUserSettingsMutation.ts';
import { SettingsService } from '@/shared/services/settings.service.ts';

export const useUserSettings = (
  userSettingsService: SettingsService,
  withLoadingStatus = false,
) => {
  const { create, isCreating, isUpdating, update } = useUserSettingsMutation(withLoadingStatus);

  const currentSetting = userSettingsService.getExistingSetting();
  const settingsKey = userSettingsService.getSettingsKey();
  const settingsName = userSettingsService.getSettingsName();

  const handleSaveSettings = async (data: Record<string, unknown>) => {
    const newProperty = {
      key: settingsKey,
      value: JSON.stringify(data),
    };

    if (currentSetting) {
      const updatedExtraProperties = currentSetting.extraProperties.map(property =>
        property.key === settingsKey
          ? { ...property, value: JSON.stringify({ ...JSON.parse(property.value), ...data }) }
          : property,
      );

      if (!updatedExtraProperties.some(property => property.key === settingsKey)) {
        updatedExtraProperties.push(newProperty);
      }

      const body = {
        entityType: currentSetting.entityType,
        extraProperties: updatedExtraProperties,
        settingsName: currentSetting.settingsName,
        settingsResName: currentSetting.settingsResName,
      };

      update(body);
      return;
    }

    const body = {
      extraProperties: [newProperty],
      settingsName,
    };

    create(body);
  };

  return { handleSaveSettings, isCreating, isUpdating };
};
