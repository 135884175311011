import { FieldValues, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { AssetsAPINamespace } from '@/shared/api/assets';
import { currenciesList, periodList } from '@/shared/components/ui/Modal/Subcontractor/data.ts';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import * as schemas from '@/shared/schemas/validate-schemas';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { AssetsCreateRequest, AssetsUpdateRequest } from '@/shared/api/assets/assets.types.ts';
import { ExtraPropertyKeys } from '@/shared/components/ui/Modal/Subcontractor/types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useCreateEquipment = () => {
  const queryClient = useQueryClient();

  const site = useGlobalStore(store => store.site);
  const assets = useGlobalStore(store => store.assets);
  const tags = useGlobalStore(store => store.assetsTags);
  const updateAssets = useGlobalStore(store => store.updateAssets);
  const closeModal = useGlobalStore(store => store.closeModal);

  const { filteredAssetsCategories } = useAssetsCategories();
  const { user } = useFetchUserData();

  const params = useForm<FieldValues>({
    defaultValues: {
      active: false,
      categorySubType: filteredAssetsCategories[0]?.categoryPathList[0].categoryPathResName,
      categoryType: filteredAssetsCategories[0]?.categoryTreeResName,
      iso4217: currenciesList[0].id,
      period: periodList[0].id,
      rentalCompany: '',
    },
    mode: 'onChange',
    resolver: zodResolver(schemas.equipmentFormSchema),
  });

  const { mutate, ...states } = useMutation({
    mutationFn: async (data: FieldValues) => {
      const { active, categorySubType, categoryType, title, ...rest } = data;

      const renderExtraProperties = () => {
        return Object.entries(rest).map(([key, value]) => {
          return {
            key,
            value,
          };
        });
      };

      const requestBody: AssetsCreateRequest = {
        accountResName: user?.accountResourceName ?? '',
        assetName: title,
        categoryTreeResName: categoryType,
        siteResName: site,
        ...(categorySubType && { categoryPathResName: categorySubType }),
        extraProperties: [
          ...renderExtraProperties(),
          { key: ExtraPropertyKeys.rentalCompany, value: rest.rentalCompany },
        ],
      };

      const category = filteredAssetsCategories.find(
        cat => cat.categoryTreeResName === categoryType,
      );

      const response = await AssetsAPINamespace.createOne(requestBody);

      if (active) {
        const requestBody: AssetsUpdateRequest = {
          accountResName: user?.accountResourceName ?? '',
          assetName: title,
          assetResName: response,
          assetStatus: active ? 'active' : 'inactive',
          categoryTreeResName: categoryType,
          siteResName: site,
          ...(categorySubType && { categoryPathResName: categorySubType }),
          extraProperties: [
            ...renderExtraProperties(),
            { key: ExtraPropertyKeys.rentalCompany, value: rest.rentalCompany },
          ],
        };

        await AssetsAPINamespace.updateOne(requestBody);
      }

      if (rest?.tag) {
        const currentTag = tags.find(assetsTag => assetsTag.tag.tagResName === rest?.tag);
        await AssetsAPINamespace.updateAssetAttachedTags({
          accountResourceName: user?.accountResourceName ?? '',
          assetResourceName: response ?? '',
          siteResourceName: site,
          tags: [
            {
              tagName: currentTag?.tag.tagName ?? '-',
              tagResName: currentTag?.tag.tagResName ?? '-',
            },
          ],
        });
      }

      mixpanelService.logAddEquipment(
        category?.categoryTreeName ?? 'N/A',
        rest.rentalCompany ?? 'N/A',
        title,
      );

      return {
        assetName: requestBody.assetName + '',
        assetResName: response,
        orgResName: requestBody.orgResName + '',
      };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.equipmentCreateFail'));
    },

    onSuccess: async (result: AssetBody) => {
      const incomingValue = {
        assetName: result.assetName,
        assetResName: result.assetResName,
      } as AssetBody;

      const modifiedAssets = [incomingValue, ...assets];

      updateAssets(modifiedAssets);

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ASSETS_TAGS],
      });

      closeModal();
      notifyService.success(translate('messages.equipmentCreateSuccess'));
    },
  });

  return { mutate, params, states };
};
