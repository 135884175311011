import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { OrganizationUpdateRequest } from '@/shared/api/assets/assets.types.ts';

export const useToggleFirmStatus = () => {
  const queryClient = useQueryClient();

  const { user } = useFetchUserData();

  const { isPending, mutate } = useMutation({
    mutationFn: async (firm: OrganizationUpdateRequest) => {
      const requestBody: OrganizationUpdateRequest = {
        accountResName: user?.accountResourceName,
        extraProperties: firm?.extraProperties ?? [],
        orgName: firm?.orgName,
        orgResName: firm?.orgResName,
        status: firm?.status === 'active' ? 'inactive' : 'active',
      };

      await notifyService.promise(AssetsAPINamespace.updateOneOrganization(requestBody), {
        error: 'Failed to update firm status',
        loading: 'Updating firm status...',
        success: 'Firm status updated successfully',
      });
    },

    mutationKey: [QUERY_KEY.TOGGLE_FIRM_STATUS],

    onError: error => {
      notifyService.error('Failed to update firm status');
      console.error(error);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.COMPANIES],
      });
    },
  });

  return {
    isPending,
    mutate,
  };
};
