export const useFullWeekData = (selected: Date) => {
  const week = Array.from({ length: 7 })
    .fill(0)
    .map((_, index) => {
      const date = new Date(selected);
      date.setDate(date.getDate() + index);

      return date;
    });

  return { week };
};
