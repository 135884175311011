import { FC } from 'react';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { Card } from '@/shared/components/ui/Card';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import Organization from '@/assets/icons/organization.svg?react';
import { AnchorCardProps } from '@/modules/Home/types/AnchorCard.types.ts';
import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';

const NO_DATA = 'N/A';

export const AnchorCard: FC<AnchorCardProps> = ({ body, className, togglePopup }) => {
  const { assetName, categoryPath, categoryTreeName, company, device_name, tagName } = body;

  const isEquipment = categoryTreeName?.includes(AssetTypeEnum.Equipment);

  return (
    <Card className={classnames(className)}>
      <IconButton className="absolute right-2 top-2" icon="close" onClick={togglePopup} />

      <div className="grid gap-y-1 whitespace-nowrap text-sm">
        <Typography className="font-bold text-slate-950">
          {assetName ?? tagName ?? device_name ?? NO_DATA}
        </Typography>

        {isEquipment && categoryPath && (
          <Typography className="font-semibold text-slate-800 first-letter:capitalize">
            {categoryPath}
          </Typography>
        )}

        {company && (
          <Typography className="inline-flex items-center gap-x-2 text-slate-500">
            <Organization className="h-5 w-5 shrink-0" />
            {company}
          </Typography>
        )}
      </div>
    </Card>
  );
};
