import { FC } from 'react';

import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { Card } from '@/shared/components/ui/Card';
import { classnames } from '@/shared/utils/classnames.ts';

type Props = {
  variant:
    | 'asset-card'
    | 'button-group'
    | 'default'
    | 'equipment-details'
    | 'mobile-card'
    | 'select-site'
    | 'statistic-card'
    | 'table';
  count?: number;
  isMobile?: boolean;
  withTitle?: boolean;
  withStatus?: boolean;
  subTitleCount?: number;
};

export const Skeleton: FC<Props> = ({
  count = 1,
  isMobile = false,
  subTitleCount = 3,
  variant,
  withStatus,
  withTitle,
}) => {
  const array = Array.from({ length: count });

  switch (variant) {
    case 'asset-card':
      return array.map((_, index) => (
        <div
          className="flex w-full animate-pulse items-center gap-x-1.5 space-y-0.5"
          key={index} //NOSONAR
        >
          <div className="size-5 rounded bg-slate-200" />

          <Card className="relative grid h-[89px] w-full gap-y-1.5 overflow-hidden rounded-sm pl-3">
            <span className="absolute left-0 top-0 h-full w-0.5 border-l-[6px] border-slate-200" />

            <div className="grid justify-items-start gap-y-1 pt-1">
              <div className="grid w-full gap-y-1.5">
                {Array.from({ length: 3 }).map((_, index) => (
                  <span
                    className="block h-[15px] w-full max-w-48 rounded-md bg-slate-200"
                    key={index} //NOSONAR
                  />
                ))}
              </div>
            </div>
          </Card>
        </div>
      ));

    case 'button-group':
      return (
        <div className="flex w-full animate-pulse gap-x-2 p-2 [&_div]:rounded-lg [&_div]:bg-slate-200">
          <div className=" h-10 w-[140px]" />
          <div className=" h-10 w-[140px]" />
        </div>
      );

    case 'equipment-details':
      return (
        <div className="flex h-full w-full animate-pulse flex-col gap-y-2 rounded-lg border border-slate-200 bg-white">
          <div className="flex flex-col divide-y divide-slate-200">
            {Array.from({ length: subTitleCount }).map((_, index) => {
              return (
                <div
                  className="grid h-[44px] grid-cols-[150px_1fr] items-center gap-x-5 px-4 last:border-transparent"
                  key={index} //NOSONAR
                >
                  <div className="h-[10px] w-full rounded bg-slate-200" />
                  <div className="h-[10px] w-full rounded bg-slate-200" />
                </div>
              );
            })}

            <div className="inline-flex h-[38px] items-center justify-center gap-x-2 rounded-t-none border-0 border-t-[1px]">
              <div className="h-[10px] w-16 rounded bg-slate-200" />
            </div>
          </div>
        </div>
      );

    case 'mobile-card':
      return array.map((_, index) => (
        <Card
          className="grid animate-pulse gap-y-5 md:hidden"
          key={index} //NOSONAR
        >
          <div className="space-y-3">
            {withStatus && <div className="h-3 w-12 rounded bg-slate-100" />}

            {withTitle && (
              <div className="flex items-center">
                <div className="h-2.5 w-20 rounded bg-slate-100" />
              </div>
            )}

            <div className="space-y-2.5">
              {Array.from({ length: subTitleCount }).map((_, index) => (
                <div
                  className="grid grid-cols-2 gap-x-4 [&_div]:h-2.5 [&_div]:rounded [&_div]:bg-slate-100"
                  key={index} //NOSONAR
                >
                  <div className="w-1/2" />
                  <div className="w-full" />
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-2">
            {Array.from({ length: 2 }).map((_, index) => (
              <div
                className="inline-flex h-10 w-full items-center justify-center rounded-lg bg-slate-100"
                key={index} //NOSONAR
              />
            ))}
          </div>
        </Card>
      ));

    case 'select-site':
      return (
        <div className="animate-pulse space-y-5 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-500">
          {array.map((_, index) => {
            return (
              <div
                className="flex items-center gap-x-2 py-1"
                key={index} //NOSONAR
              >
                <div className="size-3.5 rounded-full bg-slate-200" />
                <div className="h-2 w-full rounded bg-slate-200" />
              </div>
            );
          })}
        </div>
      );

    case 'statistic-card':
      return (
        <Card
          className={classnames('h-[90px] p-5 md:w-[340px]', {
            'w-full': isMobile,
          })}
        >
          <div className="flex animate-pulse items-center gap-x-4">
            <div className="flex size-10 shrink-0 items-center justify-center rounded-lg bg-slate-100 md:size-[46px]" />

            <div className="w-full space-y-1.5">
              <span className="block h-2.5 w-16 rounded bg-slate-200 md:w-36" />
              <span className="block h-7 w-8  rounded bg-slate-200" />
            </div>
          </div>
        </Card>
      );

    case 'table':
      return (
        <div className="relative w-full overflow-auto rounded-lg border border-slate-200 bg-white">
          <table className="grid w-full table-fixed border-collapse border-spacing-0 animate-pulse">
            <thead className="flex flex-col border-b border-slate-200 bg-slate-50">
              <tr className="flex w-full items-center gap-x-10 px-4 py-3">
                {array.map((_, index) => {
                  return (
                    <th
                      className="flex w-full items-center first:min-w-60"
                      key={index} //NOSONAR
                    >
                      <span className="block h-3.5 w-full rounded bg-slate-200" />
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="relative flex flex-col">
              {Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow
                  className="pointer-events-none flex h-[44px] w-full gap-x-10"
                  key={rowIndex} //NOSONAR
                >
                  {array.map((_, dataIndex) => {
                    return (
                      <TableData
                        key={dataIndex} //NOSONAR
                      >
                        <span className="block h-2 w-full rounded bg-slate-200" />
                      </TableData>
                    );
                  })}
                </TableRow>
              ))}
            </tbody>
          </table>
        </div>
      );

    default:
      return <div className="flex h-4 w-full animate-pulse items-center rounded bg-slate-200" />;
  }
};
