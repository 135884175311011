import { FC } from 'react';

import { Line } from '@react-three/drei';

import { PathHtmlElement } from '@/modules/Home/components/map/Path/PathHtmlElement';
import { usePathDetails } from '@/modules/Home/hooks/map';
import { useMapModelDetails } from '@/modules/Home/hooks/map/useMapModelDetails';

import { PathProps } from '@/modules/Home/types/MapModel.types';

export const Path: FC<PathProps> = ({ color, companyName, locations, name, type, visible }) => {
  const { ref, setIsHovered } = useMapModelDetails();

  const {
    completePositionCoordinates,
    currentElement,
    currentElementRef,
    currentLanguage,
    handlePopupClose,
    handlePopupOpen,
    modifiedLocationsData,
  } = usePathDetails(locations);

  const pathElementProps = {
    color,
    company: companyName,
    currentLanguage,
    epochTime: currentElement?.timeOfLocation.epochTime ?? 0,
    handlePopupClose,
    name,
    type,
  };

  const position = completePositionCoordinates(
    currentElement?.x ?? 0,
    currentElement?.y ?? 0,
    currentElement?.z ?? 0,
  );

  return (
    <group>
      {currentElement && (
        <mesh
          position={position} //NOSONAR
          ref={currentElementRef}
        >
          <PathHtmlElement {...pathElementProps} />
        </mesh>
      )}

      {modifiedLocationsData().map(location => {
        const position = completePositionCoordinates(location.x, location.y, location.z);

        return (
          <mesh
            key={location.timeOfLocation.epochTime}
            onClick={handlePopupOpen}
            onPointerOut={() => setIsHovered(false)}
            onPointerOver={() => setIsHovered(true)}
            position={position} //NOSONAR
            ref={ref}
          >
            <sphereGeometry
              args={[0.2, 32, 32]} //NOSONAR
            />
            <meshStandardMaterial
              color={color}
              wireframe //NOSONAR
            />
          </mesh>
        );
      })}

      <Line
        color={color}
        lineWidth={2}
        points={modifiedLocationsData().map(location =>
          completePositionCoordinates(location.x, location.y, location.z),
        )}
        visible={visible}
      />
    </group>
  );
};
