import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { shutdown } from '@intercom/messenger-js-sdk';

import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { userStorageService } from '@/shared/services/user-storage.service';
import { useGlobalStore } from '@/shared/store/store.ts';
import { BROADCAST_CHANNEL_NAME, BROADCAST_LOGOUT_KEY } from '@/shared/utils/constants.ts';

import { RoutesEnum, StatusEnum } from '@/shared/types/global/enums.ts';
import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';
import { ZPSUser } from '@/shared/types/users/user.types.ts';

export const useClearGlobalStore = () => {
  const [bc] = useState(new BroadcastChannel(BROADCAST_CHANNEL_NAME));
  const navigate = useNavigate();

  const setAuthorized = useGlobalStore(state => state.setAuthorized);
  const closeModal = useGlobalStore(state => state.closeModal);
  const closeActivePopups = useGlobalStore(state => state.closeActivePopups);
  const setAlertsData = useGlobalStore(state => state.setAlertsData);
  const setMetricsData = useGlobalStore(state => state.setMetricsData);
  const store = useGlobalStore(state => state);

  const {
    setCurrentGeofence,
    setCurrentGeofenceId,
    setEquipment,
    setGeofences,
    setReaders,
    setStatus,
    setWorkers,
    setZPSUser,
  } = store;

  const handleClearGlobalStore = () => {
    closeActivePopups();
    closeModal();
    setAlertsData([]);
    setAuthorized(false);
    setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
    setCurrentGeofenceId('');
    setEquipment([]);
    setGeofences([]);
    setMetricsData([]);
    setReaders([]);
    setStatus(StatusEnum.IDLE);
    setWorkers([]);
    shutdown();
    setZPSUser({} as ZPSUser);
    sessionStorage.clear();
    userStorageService.migrateToSession();
    void cognitoUserPoolService.signOut();
    navigate(RoutesEnum.LOGIN);
  };

  const registerLogoutBroadcastListener = () => {
    bc.onmessage = event => {
      if (event.data === BROADCAST_LOGOUT_KEY) {
        handleClearGlobalStore();
      }
    };
  };

  const initiateLogout = () => {
    bc.postMessage(BROADCAST_LOGOUT_KEY);
  };

  return { handleClearGlobalStore, initiateLogout, registerLogoutBroadcastListener };
};
