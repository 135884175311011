import { FC, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { TransitionChild } from '@/shared/components/animation/TransitionChild';
import { Overlay } from '@/shared/components/ui/Overlay';
import { useLockBodyScroll } from '@/shared/hooks/ui/useLockBodyScroll.ts';
import { useModalCleanupEffect } from '@/shared/hooks/ui/useModalCleanupEffect.ts';
import { useRenderModalContent } from '@/shared/hooks/ui/useRenderModalContent.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';

export const Modal: FC = () => {
  const isModalOpen = useGlobalStore(state => state.isModalOpen);
  const modalType = useGlobalStore(state => state.modalType);

  const { renderModalContent } = useRenderModalContent(modalType);

  useLockBodyScroll(isModalOpen);
  useModalCleanupEffect(isModalOpen, modalType);

  if (isModalOpen)
    return (
      <Transition appear as={Fragment} show={isModalOpen}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Overlay className="bg-black/25 backdrop-blur-sm" />

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-3 text-center">
              <TransitionChild to="content">
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 align-middle shadow-xl transition-all md:p-5">
                  {renderModalContent()}
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
};
