import { FC } from 'react';

import { translate } from '@/i18n';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { Typography } from '@/shared/components/ui/Typography';

type Props = {
  type: 'equipment' | 'worker';
  count: number;
};

export const ClusterTab: FC<Props> = ({ count, type }) => {
  return (
    <Tabs.CanvasTab className="flex items-center justify-center gap-2 normal-case">
      <CustomIcon
        className="hidden size-5 shrink-0 md:inline-flex"
        type={type === 'worker' ? 'worker' : 'equipment'}
      />

      <Typography as="span">
        {translate(type === 'worker' ? 'global.workers' : 'global.equipment')}
      </Typography>

      <Typography
        as="span"
        className="flex size-[18px] items-center gap-x-1 rounded-full bg-blue-100 px-1.5 py-0.5 text-[10px] text-blue-600"
      >
        {count}
      </Typography>
    </Tabs.CanvasTab>
  );
};
