import { useSocketMapDataStore } from '@/shared/store/socket-map-data';

import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const useInitialTrackersMapData = (
  filteredSocketMapData: SocketMapDataProps[],
  workers: AssetBody[],
  equipment: AssetBody[],
) => {
  const initialTrackersMapData = useSocketMapDataStore(state => state.initialTrackersMapData);

  return initialTrackersMapData
    ?.filter(tracker => !filteredSocketMapData.some(anchor => tracker.tagName === anchor.tagName))
    .filter(tracker => {
      const hasVisibleWorker = workers.some(
        worker => worker.visible && worker.assetResName === tracker.tagName,
      );
      const hasVisibleEquipment = equipment.some(device => device.visible);

      return hasVisibleWorker || hasVisibleEquipment;
    })
    .map(tracker => {
      return {
        ...tracker,
        anchor: {
          tracker: true,
        },
      };
    })
    .filter(tracker => !!tracker);
};
