import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { iconsButtonMap, IIconButtonProps } from './IconButton.types';

export const IconButton: FC<IIconButtonProps> = ({ className, icon, isToggled, ...props }) => {
  const Icon = iconsButtonMap[icon];

  return (
    <button
      aria-label={icon}
      className={classnames(
        'inline-flex h-5 w-5 items-center justify-center transition-opacity duration-300 hover:opacity-70 active:opacity-80 disabled:pointer-events-none disabled:opacity-30 md:focus:opacity-70',
        {
          'disabled:text-slate-500': icon === 'delete',
          'h-full w-full rounded-lg border border-slate-200 p-2 disabled:bg-slate-100':
            icon === 'left-arrow',
          'shadow-sm': icon === 'sidebar' || icon === 'sidebar-rotate',
          'size-[38px] rounded-lg bg-blue-600 p-2 text-white': icon === 'plus',
          'size-[38px] rounded-lg border border-slate-200 bg-white':
            icon === 'sidebar' ||
            icon === 'sidebar-rotate' ||
            icon === 'full-screen' ||
            icon === '3d' ||
            icon === 'filter' ||
            icon === 'more-vertical',
        },
        className,
      )}
      type="button"
      {...props}
    >
      {
        <Icon
          className={classnames({
            'h-3 w-[18px] transition-colors': icon === '3d',
            'md:rotate-180': icon === 'sidebar' && isToggled,
            'size-2': icon === 'close-sm',
            'size-3': icon === 'plus',
            'size-4':
              icon === 'close' || icon === 'filter' || icon === 'eye-sm' || icon === 'pass-eye-sm',
            'size-5':
              icon === 'left-arrow' ||
              icon === 'delete' ||
              icon === 'geolocation' ||
              icon === 'dot-vertical' ||
              icon === 'eye' ||
              icon === 'pass-eye' ||
              icon === 'sidebar' ||
              icon === 'sidebar-rotate' ||
              icon === 'more-vertical',
            'size-6': icon === 'burger',
          })}
        />
      }
    </button>
  );
};
