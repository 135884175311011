import { baseQuery, signRequest } from '@/shared/api/utils';

import { SiteCreatePayload, SiteResponseProps } from '@/shared/api/sites/sites.types.ts';
import { Tag, TagBody } from '@/shared/types/assets/tags.types.ts';
import { Response } from '@/shared/types/global/common.types.ts';

export namespace SitesAPINamespace {
  const client = baseQuery('ENVIRONMENT');

  export const getAll = async (accountResourceName: string): Promise<SiteResponseProps> => {
    const url = `/accounts/${accountResourceName}/sites?limit=100`;
    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getTags = async (
    accountResourceName: string,
    siteResourceName: string,
  ): Promise<Response<Tag[]>> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/tags?limit=100`;
    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getTag = async ({
    accountResName,
    siteResName,
    tagResName,
  }: {
    accountResName: string;
    siteResName: string;
    tagResName: string;
  }): Promise<TagBody> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/tags/${tagResName}`;
    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getTagsAvailabilities = async (
    accountResourceName: string,
    siteResourceName: string,
  ): Promise<Response<Tag[]>> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/tags/availabilities?availabilities=['available', 'unavailable']`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOne = async ({
    accountResourceName,
    ...rest
  }: SiteCreatePayload): Promise<unknown> => {
    const result = await client.post(`/accounts/${accountResourceName}/sites`, rest);

    return result.data;
  };
}
