import { useEffect, useRef } from 'react';

import { useClearGlobalStore } from '@/shared/hooks/global/useClearGlobalStore.ts';
import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service.ts';
import { LS_KEYS, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

import { AuthStorage } from '@/shared/types/auth/auth.types.ts';
import { UserProps } from '@/shared/types/users/user.types.ts';

const MIN_TOKEN_REFRESH_INTERVAL_MS = 5 * 60 * 1000;
const CHECK_TOKEN_EXPIRY_INTERVAL_MS = 1000;

export const useAuthRefresh = () => {
  const { handleClearGlobalStore } = useClearGlobalStore();
  const lastRefreshMs = useRef(Date.now());

  useEffect(() => {
    const refreshAuthToken = () => {
      const userLocalStorageData = WebAPIStorageNamespace.LocalStorageService.getLocalItem(
        LS_KEYS.USER_DATA,
      );
      const userSessionData = WebAPIStorageNamespace.SessionService.getSessionItem(
        LS_KEYS.USER_DATA,
      );
      const userDataString = userSessionData ?? userLocalStorageData;

      if (userDataString) {
        const authStorageField = userSessionData
          ? AuthStorage.SESSION_STORAGE
          : AuthStorage.LOCAL_STORAGE;

        const user = JSON.parse(userDataString) as UserProps;

        cognitoUserPoolService.refreshTokens(
          {
            refreshToken: user.refreshToken!,
            username: user.username!,
          },
          authStorageField,
          () => {
            lastRefreshMs.current = Date.now();
          },
        );
      }
    };

    // Set up a timer to refresh the token every 10 minutes
    const intervalId = setInterval(() => {
      refreshAuthToken();
    }, MIN_TOKEN_REFRESH_INTERVAL_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Periodically check if the saved token expiry is elapsed
    const timerId = setInterval(() => {
      const userLocalStorageData = WebAPIStorageNamespace.LocalStorageService.getLocalItem(
        LS_KEYS.USER_DATA,
      );
      const userSessionData = WebAPIStorageNamespace.SessionService.getSessionItem(
        LS_KEYS.USER_DATA,
      );
      const userDataString = userSessionData ?? userLocalStorageData;

      if (userDataString) {
        const userData = JSON.parse(userDataString) as UserProps;

        if (!userData?.exp) {
          console.log('[No expiry found!]');
          handleClearGlobalStore();
          clearInterval(timerId);
        }

        const currentTime = Date.now() / REQUESTS_OPTIONS.INTERVAL;
        const timeToAuthExpiry = Math.ceil(userData.exp! - currentTime);

        if (timeToAuthExpiry <= 0 || Number.isNaN(timeToAuthExpiry)) {
          console.log('[Tearing down!]');
          handleClearGlobalStore();
          clearInterval(timerId);
        }
      }
    }, CHECK_TOKEN_EXPIRY_INTERVAL_MS) as never;

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [handleClearGlobalStore]);
};
