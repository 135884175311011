import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

import { translate } from '@/i18n';

import { TripSliceItem } from '@/shared/types/reports/reports.types.ts';

export const renderColumns = (): ColumnDef<TripSliceItem>[] => [
  {
    accessorKey: 'tripGeofenceName',
    cell: ({ row }) => row.original.tripGeofenceName,
    header: () => translate('reports.zoneName'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorFn: row => row.tripGeofenceEntryTime.epochTime,
    accessorKey: 'entryTime',
    cell: ({ row }) =>
      format(new Date(row.original.tripGeofenceEntryTime.epochTime), 'HH:mm dd/MM/yyyy'),
    header: () => translate('reports.zoneEntryTime'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorFn: row => row.tripGeofenceExitTime.epochTime,
    accessorKey: 'exitTime',
    cell: ({ row }) =>
      format(new Date(row.original.tripGeofenceExitTime.epochTime), 'HH:mm dd/MM/yyyy'),
    header: () => translate('reports.zoneExitTime'),
    meta: {
      isFiltered: true,
    },
  },
];
