import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useModalCleanupEffect = (isOpen: boolean, type: PopupTypeEnum) => {
  const [, setSearchParameter] = useSearchParams();
  const [lastModal, setLastModal] = useState(type);

  useEffect(() => {
    if (isOpen) {
      setLastModal(type);
    } else {
      if (lastModal === PopupTypeEnum.SUBCONTRACTORS_FIRM_EDIT) {
        setSearchParameter(searchParameters => {
          searchParameters.delete(URL_PARAMS.FIRM_EDIT_ID);
          return searchParameters;
        });
      }

      if (lastModal === PopupTypeEnum.SUBCONTRACTORS_WORKER_EDIT) {
        setSearchParameter(searchParameters => {
          searchParameters.delete(URL_PARAMS.WORKER_EDIT_ID);
          return searchParameters;
        });
      }
    }
  }, [isOpen, lastModal, setSearchParameter, type]);

  return {};
};
