import { FC } from 'react';

import { translate } from '@/i18n';
import { Badge } from '@/shared/components/ui/Badge';
import { Loader } from '@/shared/components/ui/Loader';
import { Typography } from '@/shared/components/ui/Typography';

type Props = {
  total: number;
  inUse: number;
  isLoading?: boolean;
};

export const EquipmentSummary: FC<Props> = ({ inUse, isLoading, total }) => {
  return (
    <div>
      <Badge className="bg-blue-100 text-[10px] uppercase text-blue-600" variant="solid">
        {isLoading ? <Loader appearance="ping" pingColor="bg-blue-500" /> : total}{' '}
        {translate('assets.total')}
      </Badge>{' '}
      <Typography as="span" className="text-xs">
        /
      </Typography>{' '}
      <Badge className="bg-green-100 text-[10px] uppercase text-green-600" variant="solid">
        {isLoading ? <Loader appearance="ping" pingColor="bg-green-500" /> : inUse}{' '}
        {translate('assets.inUse')}
      </Badge>
    </div>
  );
};
