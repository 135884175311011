import { useEffect, useState } from 'react';

import { MapCoordinates, MapViewType } from '@/modules/Home/types/MapModel.types';

type Props = {
  mapView: MapViewType;
  isGridVisible: boolean;
  mapCoordinates: MapCoordinates;
};

type Position = [x: number, y: number, z: number];

export const useMapGrid = ({ isGridVisible, mapCoordinates, mapView }: Props) => {
  const [currentPosition, setCurrentPosition] = useState<Position>([0, -1, 0]);
  const [currentRotation, setCurrentRotation] = useState<Position>([Math.PI / 3.2, 0, 0]);

  useEffect(() => {
    const modifiedMapCoordinates = mapCoordinates.position as unknown as number[];
    const zCoordinate = modifiedMapCoordinates[1];

    switch (mapView) {
      case 'full':
        setCurrentPosition([0, zCoordinate ?? -1, 0]);
        setCurrentRotation([Math.PI / 3.2, 0, 0]);
        break;

      case 'side':
        setCurrentPosition([0, zCoordinate ?? -1.6, 0]);
        setCurrentRotation([Math.PI / 16, 0, 0]);
        break;

      case 'top':
        setCurrentPosition([0, zCoordinate ?? 0, 0]);
        setCurrentRotation([Math.PI / 2.024, 0, 0]);
        break;
    }
  }, [mapCoordinates, mapView]);

  const gridConfig = {
    cellColor: '#CBD5E1',
    cellSize: 0.9,
    cellThickness: 1,
    fadeDistance: 70,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
    position: currentPosition,
    rotation: currentRotation,
    sectionColor: '#CBD5E1',
    sectionSize: 3.6,
    sectionThickness: 1.5,
    visible: isGridVisible,
  };

  return {
    gridConfig,
  };
};
