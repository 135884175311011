import { useGlobalStore } from '@/shared/store/store.ts';

export const useClearStateData = () => {
  const setSidebarFilterValue = useGlobalStore(state => state.setSidebarFilterValue);
  const clearLocationHistory = useGlobalStore(state => state.clearLocationsHistory);
  const clearTagsId = useGlobalStore(state => state.clearTagsId);

  return {
    clearAllStateData: () => {
      clearLocationHistory();
      clearTagsId();
      setSidebarFilterValue('');
    },
    clearLocationHistory,
    clearTagsId,
    setSidebarFilterValue,
  };
};
