import { FC, memo } from 'react';

import { Grid } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

import { translate } from '@/i18n';
import { CameraDistance } from '@/modules/Home/components/map/CameraDistance';
import ColladaModel from '@/modules/Home/components/map/ColladaModel';
import { CustomOrbitControls } from '@/modules/Home/components/map/CustomOrbitControls';
import { PerspectiveCamera } from '@/modules/Home/components/map/PerspectiveCamera/PerspectiveCamera.tsx';
import { useClickOutsideMap, useMapGrid, useMapModel } from '@/modules/Home/hooks/map';
import { useFloorsHooks } from '@/shared/api/floors/floors.hooks.ts';
import { useLocationsHistoryHooks } from '@/shared/api/locations-history/locations-history.hooks.ts';
import { useMapsHooks } from '@/shared/api/maps/maps.hooks.ts';
import { Fade } from '@/shared/components/animation/Fade';
import MapModelBody from '@/shared/components/map/MapModelBody';
import { Loader } from '@/shared/components/ui/Loader';
import { useGlobalStore } from '@/shared/store/store.ts';

import { MapModelProps } from '@/modules/Home/types/MapModel.types.ts';

const MapModel: FC<MapModelProps> = ({
  isGridVisible,
  isThumbnail,
  mapCoordinates,
  mapKey,
  mapView,
  onUpdateMapCoords,
  thumbnailMapCoords,
}) => {
  const floor = useGlobalStore(state => state.floor);

  const { isLoading: isLocationHistoryLoading } = useLocationsHistoryHooks.getLocationHistory();
  const { isLoading: isMapLoading } = useMapsHooks.getAll();
  const { isLoading: isFloorLoading, isSuccess } = useFloorsHooks.getAll();

  const { handleCanvasClick } = useClickOutsideMap();
  const { currentGeofenceMap, popups } = useMapModel();
  const { gridConfig } = useMapGrid({
    isGridVisible,
    mapCoordinates,
    mapView,
  });

  const isMapReady = currentGeofenceMap !== '';

  if (!floor && isSuccess) return;

  if (isMapLoading || isFloorLoading || isLocationHistoryLoading || !isMapReady) {
    return (
      !isThumbnail && (
        <div className="flex h-full content-center items-center">
          <Loader appearance="section" loaderText={translate('confirm.loading')} />
        </div>
      )
    );
  }

  return (
    <Fade className="relative z-10 h-full" key={`${mapKey}-fade`} triggerOnce>
      <Canvas>
        <PerspectiveCamera />

        <directionalLight
          color="white"
          intensity={2} //NOSONAR
          position={[0, 0, 3]} //NOSONAR
        />
        <ambientLight
          intensity={1} //NOSONAR
        />

        {isMapReady && (
          <group
            // onClick={closeActivePopups} //TODO: Remove this line after successful testing
            onPointerMissed={handleCanvasClick} //NOSONAR
            onPointerUp={handleCanvasClick}
            position={mapCoordinates?.position} //NOSONAR
            rotation={mapCoordinates?.rotation} //NOSONAR
            scale={mapCoordinates?.scale}
          >
            {/*Map Initialisation*/}
            <ColladaModel key={`${mapKey}-ColladaModel`} map={currentGeofenceMap} mapKey={mapKey} />

            {/*MapModelBody*/}
            <MapModelBody isThumbnail={isThumbnail} mapKey={mapKey} />
          </group>
        )}

        {/*CustomOrbitControls*/}
        <CustomOrbitControls
          mapView={mapView}
          onUpdateMapCoords={onUpdateMapCoords}
          popups={popups}
          thumbnailMapCoords={thumbnailMapCoords}
        />

        {/*Camera Distance*/}
        <CameraDistance />

        {/*Map Grid*/}
        <Grid {...gridConfig} />
      </Canvas>
    </Fade>
  );
};

export default memo(MapModel);
