import { baseQuery, generateQueryString, signRequest } from '@/shared/api/utils';

import { AssetsFullDetailsRequest, AssetsGetOneRequest } from '@/shared/api/assets/assets.types.ts';
import { AssetBody, AssetCategory, AssetsResponse } from '@/shared/types/assets/assets.types.ts';

export namespace AssetsAPINamespace {
  const client = baseQuery('ASSETS');

  export const getAllWithDetails = async ({
    accountResName,
    params = {},
    siteResName,
  }: AssetsFullDetailsRequest): Promise<AssetsResponse> => {
    let url = `/accounts/${accountResName}/sites/${siteResName}/assets/full-details`;
    const queryString = generateQueryString(params);

    if (queryString) {
      url += `?${queryString}`;
    }

    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    if (result?.data?.results) {
      return result.data;
    }

    return {
      ...result.data,
      results: [],
    };
  };

  export const getOne = async ({
    accountResName,
    assetResName,
    siteResName,
  }: AssetsGetOneRequest): Promise<AssetBody> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/${assetResName}`;

    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllCategoryTress = async (
    accountResName: string,
    siteResName: string,
  ): Promise<AssetCategory[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/category-trees`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
