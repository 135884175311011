import { translate } from '@/i18n';

export const modes = [
  {
    id: 'firm',
    name: 'Firm',
  },
  {
    id: 'person',
    name: 'Person',
  },
];

/*export const colorsList = [
  {
    id: '1',
    name: 'black',
    color: '#000',
  },
  {
    id: '2',
    name: 'grey',
    color: '#777',
  },
];*/ // Removed as not being used anywhere and creating build issue.

export const rolesList = [
  {
    id: '1',
    name: 'Worker',
  },
  {
    id: '2',
    name: 'Manager',
  },
];

export const periodList = [
  {
    id: 'hour',
    name: translate('assets.hour'),
  },
  {
    id: 'day',
    name: translate('assets.day'),
  },
  {
    id: 'week',
    name: translate('assets.week'),
  },
  {
    id: 'month',
    name: translate('assets.month'),
  },
];

export const currenciesList = [
  {
    id: 'usd',
    name: 'USD',
  },
  {
    id: 'jpy',
    name: 'JPY',
  },
  {
    id: 'eur',
    name: 'EUR',
  },
];

export const groupsList = [
  {
    id: '1',
    name: 'Group 1',
  },
  {
    id: '2',
    name: 'Group 2',
  },
];

export const statusList = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'inactive',
    name: 'Inactive',
  },
];

export const tagsList = [
  {
    id: '1',
    name: 'Tag 1',
  },
  {
    id: '2',
    name: 'Tag 2',
  },
];
