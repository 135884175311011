import { Vector3 } from 'three';

import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

export enum ZoneShapeType {
  CUSTOM = 'Custom',
  RECTANGLE = 'Rectangle',
}

export interface ZoneDrawData {
  done: boolean;
  points: Vector3[];
}

export interface ZoneDrawClickTarget {
  ctrlIdx?: number;
  dragOffsets?: Vector3[];
  type: 'control-point' | 'none' | 'shape';
}

export interface NewZoneFormArgs {
  floor: string;
  maxZ: number;
  minZ: number;
  name: string;
  points: Vector3[];
  type: GeofenceCategoryEnum;
}

export type Vector3Array = [number, number, number];
