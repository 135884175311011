import { useUpdateAssetCompanyName } from '@/modules/Home/hooks/features';
import { useGlobalStore } from '@/shared/store/store.ts';
import { generateHexFromUserId } from '@/shared/utils/helpers.ts';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { LocationHistory } from '@/shared/types/global/locations.types.ts';

const getAssetTags = (assets: AssetBody[]) => {
  return new Set(assets.flatMap(asset => asset.attachedTags).map(tag => tag?.tagResName));
};

export const useLocationHistory = () => {
  const locationsHistory = useGlobalStore(state => state.locationsHistory);
  const workers = useGlobalStore(state => state.workersInUse);
  const equipment = useGlobalStore(state => state.equipmentInUse);
  const tagsId = useGlobalStore(state => state.tagsId);

  const { updatedData } = useUpdateAssetCompanyName([...workers, ...equipment]);

  const mappedWorkersIds = getAssetTags(workers);
  const mappedEquipmentIds = getAssetTags(equipment);

  const filteredLocations = locationsHistory?.reduce(
    (acc: Record<string, LocationHistory[]>, historyItem) => {
      const tagResponseName = historyItem.tagResName;

      if (!acc[tagResponseName]) {
        acc[tagResponseName] = [];
      }

      acc[tagResponseName].push(historyItem);

      return acc;
    },
    {},
  );

  const mappedLocationsHistory = Object.entries(filteredLocations).map(([key, value]) => {
    let type = '';

    const modifiedKey = key.replace('tag-', '');

    if (mappedWorkersIds.has(key)) type = AssetTypeEnum.Worker;
    if (mappedEquipmentIds.has(key)) type = AssetTypeEnum.Equipment;

    const currentAsset = updatedData.find(
      asset => asset?.attachedTags?.some(tag => tag.tagResName === key),
    );

    return {
      color: generateHexFromUserId(modifiedKey),
      companyName: currentAsset?.companyName ?? 'N/A',
      key,
      locations: value?.map((path, pathIndex) => {
        return {
          timeOfLocation: {
            epochTime: value[pathIndex]?.location.timeOfLocation.epochTime * 1000,
          },
          x: +path.location.x / 100,
          y: +path.location.y / 100,
          z: +path.location.z / 100 + 1.5,
        };
      }),
      name: currentAsset?.assetName ?? 'N/A',
      type,
    };
  });

  return { mappedLocationsHistory: tagsId.length > 0 ? mappedLocationsHistory : [] };
};
