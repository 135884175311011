import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { translate } from '@/i18n';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { useSetAssetsGeofenceOccupants } from '../assets/useSetAssetsGeofenceOccupants';

import { Scope } from '@/shared/components/ui/TopSection/TopSection.types.ts';
import { AvailabilityEnum, RolesEnum, TagsAttachedEnum } from '@/shared/types/global/enums';

export const useTopSection = (
  scope?: Scope[],
  assetsWithTags?: boolean,
  extraButtonsGroup?: boolean,
) => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const currentExtraGroup = searchParameters.get(URL_PARAMS.EXTRA_GROUP);
  const multiDate = searchParameters.get(URL_PARAMS.MULTI_DATE);

  const { assets, firms, geofences, setSetSearchFieldValue } = useGlobalStore(store => ({
    assets: store.assets,
    firms: store.companies,
    geofences: store.geofences,
    setSetSearchFieldValue: store.setSearchFieldValue,
  }));

  const { data: geofenceOccupants } = useSetAssetsGeofenceOccupants();

  const params = useForm<FieldValues>({
    defaultValues: {
      ...(multiDate && { 'multi-date': multiDate }),
    },
  });

  const modifiedAssets = () => {
    const filteredAssets = assets.filter(asset => {
      const currentTagProperty = asset.extraProperties?.find(property => property.key === 'tag');

      return !!currentTagProperty;
    });

    const result = assetsWithTags ? filteredAssets : assets;

    return result.map(asset => {
      return {
        id: asset.assetResName,
        name: asset.assetName,
      };
    });
  };

  const modifiedGeofences = () => {
    return geofences.map(geofence => {
      return {
        id: geofence.geofenceResName,
        name: geofence.geofenceName,
      };
    });
  };

  const modifiedFirms = () => {
    return firms.map(firm => {
      return {
        id: firm.orgResName,
        name: firm.orgName,
      };
    });
  };

  const modifiedTagsAttached = () => {
    return [
      {
        id: TagsAttachedEnum.YES,
        name: translate('global.yes'),
      },
      {
        id: TagsAttachedEnum.NO,
        name: translate('global.no'),
      },
    ];
  };

  const modifiedRoles = () => {
    return Object.values(RolesEnum).map(role => {
      return {
        id: role,
        name: role.toUpperCase(),
      };
    });
  };

  const modifiedAvailability = () => {
    return Object.values(AvailabilityEnum).map(role => {
      return {
        id: role,
        name: role,
      };
    });
  };

  const modifiedZones = () => [
    ...(geofenceOccupants ?? [])
      .reduce((uniqueZones, occupant) => {
        occupant.enclosingGeofences.forEach(geofence => {
          uniqueZones.set(geofence.geofenceResName, {
            id: geofence.geofenceResName,
            name: geofence.geofenceName,
          });
        });

        return uniqueZones;
      }, new Map())
      .values(),
  ];

  const handleSetSearchParameter = (item: Scope, value: string) => params.setValue(item, value);

  const onValid = (data: FieldValues) => {
    const objectFields = Object.entries(data);

    setSearchParameters(searchParameters => {
      objectFields.forEach(([key, value]) => {
        searchParameters.set(key, value);
      });

      return searchParameters;
    });
  };

  const handleClearParams = async () => {
    setSearchParameters(searchParameters => {
      const excludeParams = new Set(['']);

      scope?.forEach(key => {
        if (!excludeParams.has(key)) {
          searchParameters.delete(key);
        }
      });

      return searchParameters;
    });

    params.reset(currentParams => {
      const excludeParams = new Set(['']);

      const newParams: Record<string, string> = {};
      Object.entries(currentParams).forEach(([key, value]) => {
        if (excludeParams.has(key)) {
          newParams[key] = value;
        }
      });

      return newParams;
    });
  };

  const handleSearchFieldChange = (value: string) => {
    setSetSearchFieldValue(value);
  };

  useEffect(() => {
    setSetSearchFieldValue('');
  }, [setSetSearchFieldValue]);

  useEffect(() => {
    if (extraButtonsGroup)
      setSearchParameters(searchParameters => {
        if (!currentExtraGroup) searchParameters.set(URL_PARAMS.EXTRA_GROUP, 'time');
        return searchParameters;
      });
  }, [currentExtraGroup, extraButtonsGroup, setSearchParameters]);

  params.watch();

  return {
    handleClearParams,
    handleSearchFieldChange,
    handleSetSearchParameter,
    modifiedAssets,
    modifiedAvailability,
    modifiedFirms,
    modifiedGeofences,
    modifiedRoles,
    modifiedTagsAttached,
    modifiedZones,
    onValid,
    params,
    searchParameters,
  };
};
