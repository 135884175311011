import Equipment from '@/assets/icons/equipment-sm.svg?react';
import Readers from '@/assets/icons/readers.svg?react';
import Square from '@/assets/icons/square.svg?react';
import Worker from '@/assets/icons/worker.svg?react';
import Zone from '@/assets/icons/zone.svg?react';
import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export type AccordionProps = WithChildren &
  WithClassName & {
    open?: boolean;
    addEventListener?: () => void;
  };

export type AccordionItemProps = Pick<AccordionProps, 'children' | 'className'> & {
  iconClassName?: string;
  handleClick?: () => void;
};

export type SummaryTitleProps = WithClassName & {
  icon: keyof typeof accordionIcons;
  count: JSX.Element | number | string;
  showing: JSX.Element | number | string;
  withShowing?: boolean;
  withCount?: boolean;
};

export const accordionIcons = {
  equipment: Equipment,
  inactive: Square,
  readers: Readers,
  'red-zone': Square,
  'work-zone': Square,
  worker: Worker,
  zone: Zone,
};

export enum SummaryTitleEnum {
  EQUIPMENT = 'equipment',
  INACTIVE = 'inactive',
  READERS = 'readers',
  RED_ZONE = 'red-zone',
  WORK_ZONE = 'work-zone',
  WORKER = 'worker',
  ZONE = 'zone',
}
