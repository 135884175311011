import { fromZonedTime } from 'date-fns-tz';

import { ianaTimeZones } from '@/modules/Settings/utils/constants.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { getTimeZone, setReportType } from '@/shared/utils/system-time-zone.ts';

export function useSystemTimeZone() {
  const { displayTimeZone, sitePolicies } = useGlobalStore(state => ({
    displayTimeZone: state.displayTimeZone,
    sitePolicies: state.sitePolicies,
  }));

  const defaultSitePolicy = sitePolicies[0];
  const defaultSitePolicyTimeZoneOffset =
    defaultSitePolicy?.operationalPeriod?.localTimezoneOffset || 0;

  const transformTimeToEpoch = (
    initialStartTime: string,
    initialEndTime: string,
    reportType: string,
  ) => {
    const decodedStartTime = decodeURIComponent(initialStartTime);
    const decodedEndTime = decodeURIComponent(initialEndTime);

    const parsedStartTime = JSON.parse(decodedStartTime);
    const parsedEndTime = JSON.parse(decodedEndTime);

    const startTimeEpoch = Number(parsedStartTime.epochTime);
    const endTimeEpoch = Number(parsedEndTime.epochTime);

    const startTime = new Date(startTimeEpoch);
    const endTime = new Date(endTimeEpoch);

    const { modifiedEndTime, modifiedStartTime } = setReportType(
      reportType,
      displayTimeZone,
      startTime,
      endTime,
      startTimeEpoch,
      endTimeEpoch,
    );

    const timeZone = getTimeZone(defaultSitePolicyTimeZoneOffset);

    const zonedStartTime = fromZonedTime(
      modifiedStartTime,
      defaultSitePolicy ? timeZone : ianaTimeZones[1].iana,
    );
    const zonedEndTime = fromZonedTime(
      modifiedEndTime,
      defaultSitePolicy ? timeZone : ianaTimeZones[1].iana,
    );

    return {
      endTime: encodeURIComponent(JSON.stringify({ epochTime: String(zonedEndTime.getTime()) })),
      startTime: encodeURIComponent(
        JSON.stringify({ epochTime: String(zonedStartTime.getTime()) }),
      ),
    };
  };

  return { transformTimeToEpoch };
}
