import { SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useDebouncedCallback } from 'use-debounce';

import { useCreateSitePolicy } from '@/modules/Settings/hooks/useCreateSitePolicy.ts';
import { useCreateSiteShift } from '@/modules/Settings/hooks/useCreateSiteShift.ts';
import { useFullWeekData } from '@/modules/Settings/hooks/useFullWeekData.ts';
import { DaysOfWeekProps } from '@/modules/Settings/hooks/useTimeExceptions.ts';
import { useUpdateSitePolicy } from '@/modules/Settings/hooks/useUpdateSitePolicy.ts';
import { useUpdateSiteShift } from '@/modules/Settings/hooks/useUpdateSiteShift.ts';
import { defaultValues, ianaTimeZones } from '@/modules/Settings/utils/constants.ts';
import { formatDate, getCurrentDay, getTime } from '@/shared/utils/date-fns.ts';

import { SiteHoursProps } from '@/modules/Settings/types/types.ts';
import { ShiftPeriod, SitePolicy, SiteShift } from '@/shared/api/site-operations/types.ts';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

type SiteHoursMethodsProps = {
  form: UseFormReturn<SiteHoursProps>;
  selected: Date[];
  startTimes: DaysOfWeekProps;
  endTimes: DaysOfWeekProps;
  currentLanguage: LocaleEnum;
  defaultShift: SiteShift;
  defaultPolicy: SitePolicy;
  setStartTime: (value: SetStateAction<Date>) => void;
  setEndTime: (value: SetStateAction<Date>) => void;
  handleResetTime: () => Promise<void>;
  startTime: Date;
  endTime: Date;
  handleSetSelected: (selected: Date[]) => void;
};

export function useSiteHoursMethods({
  currentLanguage,
  defaultPolicy,
  defaultShift,
  endTimes,
  form,
  handleResetTime,
  handleSetSelected,
  selected,
  setEndTime,
  setStartTime,
  startTimes,
}: SiteHoursMethodsProps) {
  const { createSiteShift, isCreatingShift } = useCreateSiteShift();
  const { isUpdatingShift, updateSiteShift } = useUpdateSiteShift();
  const { createSitePolicy, isCreatingPolicy } = useCreateSitePolicy();
  const { isUpdatingPolicy, updateSitePolicy } = useUpdateSitePolicy();

  const { week } = useFullWeekData(selected[0]);

  const handleFormSubmit = (data: SiteHoursProps) => {
    try {
      const startTime = data.startTime as Date;
      const endTime = data.endTime as Date;
      const shiftStartTime = {
        shiftDate: formatDate(selected[0]),
        shiftTime: getTime(startTime.getTime()),
      };
      const shiftEndTime = {
        shiftDate: formatDate(selected[1] ?? selected[0]),
        shiftTime: getTime(endTime.getTime()),
      };

      const validDays = week.filter(date => {
        return data[getCurrentDay(date).toLowerCase()] === true;
      });

      const shiftPeriods: ShiftPeriod[] = validDays.map(date => {
        const shiftStart = startTimes[getCurrentDay(date).toLowerCase()];
        const shiftEnd = endTimes[getCurrentDay(date).toLocaleLowerCase()];

        const period: ShiftPeriod = {
          endTime: {
            shiftDate: formatDate(date),
            shiftTime: getTime(shiftEnd.getTime()),
          },
          periodType: 'work',
          startTime: {
            shiftDate: formatDate(date),
            shiftTime: getTime(shiftStart.getTime()),
          },
        };

        return period;
      });

      const operatingDaysOfWeek = validDays.map(date => {
        return date.toLocaleString(currentLanguage, {
          weekday: 'short',
        });
      });

      const localTimezoneOffset = ianaTimeZones.find(timeZone => timeZone.id === data.siteTimeZone)
        ?.offset as number;

      const policy: SitePolicy = {
        dataType: 'mr',
        logLevel: 'ERROR',
        monitoredThreshold: {
          operator: '=',
          threshold: 0,
          unit: 'percent',
        },
        monitoringFrequency: {
          timePeriod: 10,
          unitOfTime: 'mins',
        },
        operationalPeriod: {
          localTimezoneOffset,
          operatingDayEndTime: getCurrentDay(endTime),
          operatingDaysOfWeek,
          operatingDayStartTime: getCurrentDay(startTime),
          periodEndDate: {
            epochTime: endTime.getTime(),
          },
          periodStartDate: {
            epochTime: startTime.getTime(),
          },
        },
        policyName: 'default',
        status: 'active',
        transmissionFrequency: {
          timePeriod: 10,
          unitOfTime: 'mins',
        },
      };

      const shift: SiteShift = {
        shiftEndTime,
        shiftName: 'default',
        shiftPeriods,
        shiftStartTime,
        shiftType: 'morning',
        status: 'inactive',
      };

      if (defaultShift) {
        updateSiteShift(shift);
      } else {
        createSiteShift(shift);
      }

      if (defaultPolicy) {
        updateSitePolicy(policy);
      } else {
        policy.policyType = 'data-tracking';
        createSitePolicy(policy);
      }
    } catch (error) {
      console.error('[error]:', error);
    }
  };

  const handleReset = async () => {
    setStartTime(defaultValues.startTime);
    setEndTime(defaultValues.endTime);
    form.setValue('startTime', defaultValues.startTime);
    form.setValue('endTime', defaultValues.endTime);

    await handleResetTime();
  };

  const handleDateChange = useDebouncedCallback((type: 'decrement' | 'increment') => {
    const newStartDate = new Date();
    const newEndDate = new Date();
    const increment = type === 'increment' ? 1 : -1;

    newStartDate.setDate(selected[0].getDate() + increment);
    newEndDate.setDate(selected[1].getDate() + increment);

    handleSetSelected([newStartDate, newEndDate]);
  }, 100);

  return {
    handleDateChange,
    handleFormSubmit,
    handleReset,
    isCreatingPolicy,
    isCreatingShift,
    isUpdatingPolicy,
    isUpdatingShift,
    week,
  };
}
