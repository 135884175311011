import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { translate } from '@/i18n';
import { ActionsAPINamespace } from '@/shared/api/actions/actions.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

interface UseActionActionsProps {
  actionResName: string;
  assetResName?: string;
}

export const useActionCardActions = ({ actionResName, assetResName }: UseActionActionsProps) => {
  const navigate = useNavigate();
  const [archiving, setArchiving] = useState(false);

  // const assets = useGlobalStore(store => store.assets);
  const siteResourceName = useGlobalStore(store => store.site);
  const moveAlertDataToArchive = useGlobalStore(store => store.moveAlertToArchive);
  const setMapFilter = useGlobalStore(store => store.setMapFilter);

  const archiveMutation = useMutation({
    mutationFn: async () => {
      return ActionsAPINamespace.archiveAlert(
        user!.accountResourceName!,
        siteResourceName,
        user!.userResourceName!,
        actionResName,
      );
    },
    mutationKey: [QUERY_KEY.ARCHIVE_ACTION, actionResName],
    onError: error => {
      console.error(error);

      if (error instanceof AxiosError && error.response?.status === 422) {
        notifyService.success(translate('messages.alertArchiveSuccess'));
        moveAlertDataToArchive(actionResName);
      } else {
        notifyService.error(translate('errors.archiveAlertFailed'));
      }
    },
    onMutate: () => {
      setArchiving(true);
    },
    onSettled: () => {
      setArchiving(false);
    },
    onSuccess: async () => {
      notifyService.success(translate('messages.alertArchiveSuccess'));
      moveAlertDataToArchive(actionResName);
    },
  });

  const { user } = useFetchUserData();

  const openDetailsPage = () => {
    // const asset = assets.find(asset => asset.assetResName === assetResName);
    //
    // if (asset) {
    //   setMapFilter({ resName: [assetResName] });
    //   navigate(RoutesEnum.HOME);
    // } else {
    //   notifyService.error(translate('errors.noResults'));
    // }

    if (assetResName) {
      setMapFilter({ resName: [assetResName] });
      navigate(RoutesEnum.HOME);
    } else {
      notifyService.error(translate('errors.noResults'));
    }
  };

  return {
    archiveAction: archiveMutation.mutate,
    archiving,
    openDetailsPage,
  };
};
