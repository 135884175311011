import { useQuery } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

export function useGetReservations() {
  const site = useGlobalStore(store => store.site);

  const { user } = useFetchUserData();

  const allData = useQuery({
    enabled: !!user?.accountResourceName,

    queryFn: async () => {
      return await ReservationsAPINamespace.getAllReservations({
        accountResName: user?.accountResourceName ?? '',
        siteResName: site,
      });
    },

    queryKey: [QUERY_KEY.RESERVATION, user?.accountResourceName, site],
    retry: REQUESTS_OPTIONS.LIMIT_REQUEST,
  });

  return allData.data?.results;
}
