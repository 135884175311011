import { useInfiniteQuery } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

import {
  GetReservationHistoryProps,
  ReservationHistoryResponse,
} from '@/shared/api/reservations/reservations.types';

export function useGetReservationsHistory({
  params,
}: Omit<GetReservationHistoryProps, 'accountResName' | 'siteResName'>) {
  const siteResName = useGlobalStore(store => store.site);

  const { user } = useFetchUserData();

  return useInfiniteQuery({
    enabled: !!user?.accountResourceName && !!siteResName && !!user?.userResourceName,

    getNextPageParam: (lastPage: ReservationHistoryResponse) =>
      lastPage.page.hasNext ? lastPage.page.pageEndResPointer : null,

    initialPageParam: '',

    queryFn: async data => {
      return await ReservationsAPINamespace.getAllReservationHistory({
        accountResName: user?.accountResourceName ?? '',
        pageParam: data.pageParam,
        params,
        siteResName,
      });
    },

    queryKey: [
      QUERY_KEY.RESERVATION_HISTORY,
      user?.accountResourceName,
      siteResName,
      user?.userResourceName,
      params?.status || 'active',
    ],
    retry: REQUESTS_OPTIONS.LIMIT_REQUEST,
  });
}
