import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getTagValue } from '@/modules/Equipment/components/tabs/Equipment/EquipmentsAccordsBody';
import { useReservationHooks } from '@/shared/api/reservations/reservations.hooks.ts';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData';
import { useGlobalStore } from '@/shared/store/store.ts';
import { RESERVATIONS, URL_PARAMS } from '@/shared/utils/constants';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums';
import {
  AssetReservation,
  ReservationAssetType,
  ReservationType,
} from '@/shared/types/global/reservations.types.ts';

// Function to check if two time ranges overlap
function isOverlapping(start1: number, end1: number, start2: number, end2: number) {
  return start1 < end2 && start2 < end1;
}

export const useModifyReservationData = () => {
  const [searchParameters] = useSearchParams();
  // Create a map for quick lookup of allReservations items by ID
  const childArray = useRef<ReservationType[]>([]);
  const parentArray = useRef<ReservationType[][]>([]);
  const startTime = useRef(0);
  const endTime = useRef(0);

  const searchFieldValue = useGlobalStore(state => state.searchFieldValue).toLowerCase();

  const { renderItemVisibility } = useRole();
  const selectedDate = searchParameters.get(URL_PARAMS.DATE);
  const selectedFirm = searchParameters.get(URL_PARAMS.FIRM);
  const selectedStatus = searchParameters.get(URL_PARAMS.EQUIPMENT_STATUS);
  const selectedSubTypeCategoryPath = searchParameters.get(URL_PARAMS.SUBTYPE);
  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  const isSubContractor = renderItemVisibility([RolesEnum.SUBCONTRACTOR]);
  const isWorker = renderItemVisibility([RolesEnum.WORKER]);

  const { activeAssets } = useEquipment();
  const { filteredSocketMapData } = useFilteredSocketData();
  const { data: reservationsData = [] } = useReservationHooks.getAll();
  const { user } = useFetchUserData();

  const allReservations = reservationsData.filter((resultItem: ReservationType) => {
    if (isWorker) {
      return resultItem.user.userId === user?.userResourceName;
    }
    if (isSubContractor) {
      return (
        resultItem.workflowContext.currentStateName != RESERVATIONS.draftSub ||
        resultItem.user.userId === user?.userResourceName
      );
    }
    if (isManager) {
      return (
        resultItem.workflowContext.currentStateName == RESERVATIONS.conflictedMgr ||
        resultItem.workflowContext.currentStateName == RESERVATIONS.approvedSub ||
        resultItem.workflowContext.currentStateName == RESERVATIONS.approvedMgr ||
        resultItem.workflowContext.currentStateName == RESERVATIONS.rejectedMgr ||
        (resultItem.workflowContext.currentStateName == RESERVATIONS.draftSub &&
          resultItem.user.userId === user?.userResourceName)
      );
    }
  });

  const isPast = selectedDate
    ? new Date(new Date(selectedDate).toDateString()) < new Date(new Date().toDateString())
    : false;

  // Generate the merged array from arrayB
  const mergeWithSocket = activeAssets.map((asset: AssetBody) => {
    const propertyValue = getTagValue(asset);

    const currentAsset = filteredSocketMapData.find(item => item.tagResName === propertyValue);
    return { ...asset, inUse: !!currentAsset };
  });

  const filteredAssets = mergeWithSocket?.filter(assItem => {
    const matchesSearch = assItem.assetName.toLocaleLowerCase().includes(searchFieldValue);
    const matchesStatus =
      !selectedStatus ||
      (selectedStatus === 'in-use' && assItem.inUse) ||
      (selectedStatus === 'idle' && !assItem.inUse);
    const matchesCategory =
      !selectedSubTypeCategoryPath || assItem.categoryPathResName === selectedSubTypeCategoryPath;

    return matchesSearch && matchesStatus && matchesCategory;
  });

  const combinedArray = filteredAssets.map((itemB: AssetBody) => {
    const matchingItems: ReservationType[] | undefined = allReservations
      ? allReservations
          .filter(
            (itemA: ReservationType) =>
              itemA.assetResName === itemB.assetResName &&
              (!selectedFirm || itemA.user.orgResName == selectedFirm),
          )
          .map((itemA: ReservationType) => ({ ...itemA }))
      : [];

    return {
      ...itemB,
      reservations: matchingItems,
    };
  });
  // Generate the reverse merged array from arrayA
  const reverseMergedArray = allReservations
    ? allReservations
        .filter(
          (itemA: ReservationType) =>
            filteredAssets.some(
              (itemB: AssetBody) =>
                itemB.assetResName === itemA.assetResName &&
                itemB.assetName.toLocaleLowerCase().includes(searchFieldValue),
            ) &&
            (!selectedFirm || itemA.user.orgResName == selectedFirm),
        ) // Include only items that have a matching id in arrayB
        .map((itemB: ReservationType) => {
          const matchingItems: AssetBody[] = filteredAssets
            .filter(itemA => itemA.assetResName === itemB.assetResName)
            .map(itemA => ({ ...itemA }));

          return {
            ...itemB,
            assetData: matchingItems[0],
          };
        })
    : [];

  // Function to group overlapping items inside X
  function groupOverlappingItems(items: ReservationType[]) {
    parentArray.current = [];
    childArray.current = [];

    const sortedItems = [...items].sort(
      (a, b) => a.reservationPeriod.startTime.epochTime - b.reservationPeriod.startTime.epochTime,
    );

    startTime.current = items[0].reservationPeriod.startTime.epochTime;
    endTime.current = items[0].reservationPeriod.endTime.epochTime;

    sortedItems.forEach((dt: ReservationType) => {
      if (
        isOverlapping(
          startTime.current,
          endTime.current,
          dt.reservationPeriod.startTime.epochTime,
          dt.reservationPeriod.endTime.epochTime,
        )
      ) {
        childArray.current = [...childArray.current, dt];

        if (startTime.current >= dt.reservationPeriod.startTime.epochTime) {
          startTime.current = dt.reservationPeriod.startTime.epochTime;
        }

        if (endTime.current <= dt.reservationPeriod.endTime.epochTime) {
          endTime.current = dt.reservationPeriod.endTime.epochTime;
        }
      } else {
        parentArray.current.push(childArray.current);
        startTime.current = dt.reservationPeriod.startTime.epochTime;
        endTime.current = dt.reservationPeriod.endTime.epochTime;
        childArray.current = [dt];
      }
    });

    parentArray.current.push(childArray.current);
    return parentArray.current;
  }

  // Apply grouping to each reservation array in the outer array
  const checkDatesForCalender = (modifiedReservation: AssetReservation[]): AssetReservation[] => {
    const moduleData = modifiedReservation?.map((calData: AssetReservation) => {
      const subData =
        calData.reservations && calData.reservations.length > 0
          ? calData.reservations?.filter(calResult => {
              const sDate = selectedDate
                ? new Date(selectedDate).toDateString()
                : new Date().toDateString();

              if (
                new Date(calResult.reservationPeriod.startTime.epochTime).toDateString() == sDate &&
                (!isPast ||
                  (isPast &&
                    calResult.workflowContext.currentStateName == RESERVATIONS.approvedMgr))
              ) {
                return true;
              }
            })
          : [];
      return { ...calData, reservations: subData };
    });

    return moduleData ?? [];
  };

  const moduleListData = (allReservations: ReservationAssetType[]): ReservationAssetType[] => {
    // First filter by date
    const filteredByDate = allReservations?.filter(listItem => {
      const sDate = selectedDate
        ? new Date(selectedDate).toDateString()
        : new Date().toDateString();

      return (
        new Date(listItem.reservationPeriod.startTime.epochTime).toDateString() === sDate &&
        (!isPast ||
          (isPast && listItem.workflowContext.currentStateName === RESERVATIONS.approvedMgr))
      );
    });

    // Group by assetResName
    const groupedByAsset = filteredByDate.reduce(
      (acc, item) => {
        const key = item.assetResName;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      },
      {} as Record<string, ReservationAssetType[]>,
    );

    // Process each asset group and keep only one entry per overlapping group
    return Object.values(groupedByAsset).flatMap(assetReservations => {
      const sortedReservations = [...assetReservations].sort(
        (a, b) => a.reservationPeriod.startTime.epochTime - b.reservationPeriod.startTime.epochTime,
      );

      const processedIndices = new Set<number>();
      const result: ReservationAssetType[] = [];

      sortedReservations.forEach((reservation, index) => {
        if (processedIndices.has(index)) return;

        const overlappingReservations = sortedReservations.filter((other, otherIndex) => {
          if (index === otherIndex || isWorker) return false;
          const hasOverlap = isOverlapping(
            reservation.reservationPeriod.startTime.epochTime,
            reservation.reservationPeriod.endTime.epochTime,
            other.reservationPeriod.startTime.epochTime,
            other.reservationPeriod.endTime.epochTime,
          );
          if (hasOverlap) {
            processedIndices.add(otherIndex);
          }
          return hasOverlap;
        });

        if (overlappingReservations.length > 0) {
          result.push({
            ...reservation,
            conflictData:
              reservation.workflowContext.currentStateName === RESERVATIONS.conflictedSub ||
              reservation.workflowContext.currentStateName === RESERVATIONS.conflictedMgr
                ? {
                    conflictingReservations: overlappingReservations,
                    endTime: reservation.reservationPeriod.endTime,
                    startTime: reservation.reservationPeriod.startTime,
                  }
                : undefined,
          });
        } else {
          result.push(reservation);
        }
      });

      return result;
    });
  };

  const updatedCalenderData =
    combinedArray && combinedArray.length > 0 ? checkDatesForCalender(combinedArray) : [];
  const updatedListData = moduleListData(reverseMergedArray ?? []);

  const finalArray = updatedCalenderData.map(group => ({
    ...group,
    reservations:
      group.reservations && group.reservations.length > 0
        ? groupOverlappingItems(group.reservations)
        : [],
  }));

  return { updatedCalenderData: finalArray, updatedListData: updatedListData };
};
