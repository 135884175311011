import { DefaultToastOptions } from 'react-hot-toast';

export const toastOptions: DefaultToastOptions = {
  error: {
    style: {
      backgroundColor: '#fef2f2',
    },
  },
  style: {
    border: '1px solid #e2e8f0',
    borderRadius: '8px',
    color: '#1e293b',
    fontWeight: 500,
    padding: '12px',
  },
  success: {
    style: {
      backgroundColor: '#f0fdf4',
    },
  },
};
