import { flexRender, Table } from '@tanstack/react-table';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { classnames } from '@/shared/utils/classnames.ts';

import { ContentRowTypeProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';

type Props<T> = {
  table: Table<T>;
  cellClassName?: string;
  isFirstRowReduced?: boolean;
  headerClassName?: string;
  contentRowType?: ContentRowTypeProps;
};

export const TableHead = <T,>({
  cellClassName,
  contentRowType,
  headerClassName,
  isFirstRowReduced,
  table,
}: Props<T>) => {
  return (
    <thead className="sticky top-0 z-10 grid border-b-[1px] border-slate-200 bg-slate-50">
      {table.getHeaderGroups().map(headerGroup => (
        <tr
          className={classnames('flex w-full items-center px-4 py-3', cellClassName)}
          key={headerGroup.id}
        >
          {headerGroup.headers.map(header => {
            const columnHead = flexRender(header.column.columnDef.header, header.getContext());

            const hasHeaderTitle = !!header.column.columnDef.header;

            const meta = header.column.columnDef.meta as Record<string, T>;

            const renderFilterStatus = () => {
              const filterStatus = header.column.getIsSorted();

              if (filterStatus === 'asc') return 'A-Z';
              if (filterStatus === 'desc') return 'Z-A';
            };

            return (
              <th
                className={classnames(
                  'flex w-full min-w-32 items-center gap-x-1 truncate pr-9 text-start text-xs uppercase text-slate-500 first:min-w-60',
                  isFirstRowReduced && 'first:max-w-[250px]',
                  !hasHeaderTitle && 'min-w-[120px] max-w-[120px]',
                  contentRowType === 'equipment-list' && 'first:min-w-[35%]',
                  contentRowType === 'equipment-reservations' &&
                    'min-w-32 items-center text-center gap-x-2 pr-[unset] last:min-w-[250px] [&:not(:first-child)]:place-content-center',
                  headerClassName,
                )}
                colSpan={header.colSpan}
                key={header.id}
              >
                {columnHead}

                {meta?.isFiltered && (
                  <IconButton icon="priority" onClick={header.column.getToggleSortingHandler()} />
                )}

                {renderFilterStatus()}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
