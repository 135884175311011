import { ComponentProps, FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type TableDataProps = ComponentProps<'td'> & WithChildren & WithClassName;

export const TableData: FC<TableDataProps> = ({ children, className, ...props }) => {
  return (
    <td
      className={classnames(
        'flex min-h-[45px] w-full min-w-32 items-center gap-x-2 text-sm first:min-w-32',
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
};
