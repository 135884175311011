import { useEffect, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { postProcessAlerts } from '@/shared/api/actions/actions.utils.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { SystemViewNamespace } from '@/shared/services/system-view.service.ts';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';
import { useGlobalStore } from '@/shared/store/store.ts';
import { SOCKET } from '@/shared/utils/constants.ts';
import { useConnectLocalSockets } from '@/shared/ws/local';

import { SocketConnectionStatusProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { AlertProps } from '@/shared/types/actions/actions.types.ts';

export const useWebSocketConnection = () => {
  const didUnmount = useRef(false);

  const site = useGlobalStore(state => state.site);
  const setSocketMapData = useSocketMapDataStore(state => state.setSocketMapData);
  const setConnectionStatus = useSocketMapDataStore(state => state.setConnectionStatus);
  const setWsAlertAvailable = useGlobalStore(store => store.setWsAlertAvailable);
  const addAlertData = useGlobalStore(store => store.addAlertData);
  const archiveAlert = useGlobalStore(store => store.moveAlertToArchive);

  const { socketUrl } = useConnectLocalSockets();
  const { user } = useFetchUserData();

  const { readyState, sendMessage } = useWebSocket(socketUrl, {
    onClose: () => {
      console.info(SOCKET.EVENT.CLIENT_CLOSED);
    },
    onError: error => {
      console.error(error);
    },
    onMessage: async (event: { data: unknown }) => {
      if (!event.data) return;

      const parsedData = JSON.parse(event.data as string);
      if (parsedData?.message === SOCKET.EVENT.INTERNAL_SERVER_ERROR) return;

      if (parsedData?.notification && parsedData?.data) {
        const alerts = useGlobalStore.getState().alertsData;
        const alertResName = parsedData.messageAttributes.alertResName.Value;

        if (alertResName) {
          const existingAlert = alerts.find(alert => alert.alertResName === alertResName);
          const alertIsActive = parsedData.data.active?.toString() === 'true';

          const newAlertData: AlertProps = {
            accountResName: parsedData.messageAttributes?.accountResName?.Value ?? '',
            active: alertIsActive,
            alertCategory: parsedData.data.alertCategory,
            alertDescription: 'None',
            alertName: parsedData.data.alertName,
            alertResName: alertResName,
            alertSeverity: parsedData.data.alertSeverity,
            assetResName: 'None',
            attribute: parsedData.data.attribute,
            deviceResName: 'None',
            metadata: parsedData.data.metadata,
            siteResName: 'None',
            timestamp: parsedData.data.alertTime ?? new Date().getTime(),
          };

          const [processedWsAlert] = await postProcessAlerts(
            [newAlertData],
            user?.accountResourceName ?? '',
            site ?? '',
            user?.role ?? null,
          );

          if (!existingAlert && alertIsActive && processedWsAlert) {
            addAlertData(processedWsAlert);
            setWsAlertAvailable(true);
          } else archiveAlert(alertResName);
        }
      } else {
        const arrivalTimestampResult = { arrivalTimestamp: Date.now() };

        const transformedResult =
          SystemViewNamespace.LocationsNamespace.transformSystemView(parsedData);

        setSocketMapData({ ...arrivalTimestampResult, ...transformedResult });
      }
    },
    onOpen: () => {
      console.info(SOCKET.EVENT.CLIENT_OPENED);
    },
    onReconnectStop: numberAttempts => {
      console.info(SOCKET.EVENT.ON_RECONNECT_STOP, numberAttempts);
    },
    reconnectAttempts: SOCKET.OPTIONS.RECONNECT_ATTEMPTS,
    reconnectInterval: SOCKET.OPTIONS.RECONNECT_INTERVAL,
    retryOnError: true,
    shouldReconnect: () => {
      console.info(SOCKET.EVENT.SHOULD_RECONNECT);

      return !didUnmount.current;
    },
  });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const connectionStatus = {
    [ReadyState.CLOSED]: 'CLOSED',
    [ReadyState.CLOSING]: 'CLOSING',
    [ReadyState.CONNECTING]: 'CONNECTING',
    [ReadyState.OPEN]: 'OPEN',
    [ReadyState.UNINSTANTIATED]: 'UNINSTANTIATED',
  }[readyState] as SocketConnectionStatusProps;

  useEffect(() => {
    setConnectionStatus(connectionStatus);
  }, [connectionStatus, readyState, setConnectionStatus]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const data = JSON.stringify({
        accountResName: user?.accountResourceName,
        action: SOCKET.MESSAGE.ACTION,
        messageTypes: SOCKET.MESSAGE.MESSAGE_TYPES,
        siteResName: site,
      });

      if (!data) return;

      sendMessage(data);
    }
  }, [readyState, sendMessage, site, user?.accountResourceName]);
};
