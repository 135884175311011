import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthLayout } from '@/layout/Auth';
import { ConnectionLayout } from '@/layout/Connection';
import { ErrorLayout } from '@/layout/Error';
import { Layout } from '@/layout/Layout';
import { SecureLayout } from '@/layout/Secure';
import { UpdaterLayout } from '@/layout/Updater';
import { EquipmentItem } from '@/modules/Equipment/components/common/SingleItem';
import { Equipments } from '@/modules/Equipment/components/tabs/Equipment';
import { Groups } from '@/modules/Equipment/components/tabs/Groups';
import { SitePresence } from '@/modules/Reports/components/TabPanels/SitePresence';
import { TimeZone } from '@/modules/Reports/components/TabPanels/TimeZone';
import { Trips } from '@/modules/Reports/components/TabPanels/Trips';
import { Utilization } from '@/modules/Reports/components/TabPanels/Utilization';
import { ZoneEntryExit } from '@/modules/Reports/components/TabPanels/ZoneEntryExit';
import { History } from '@/modules/Reservations/components/TabPanels/HistoryTab';
import { Schedule } from '@/modules/Reservations/components/TabPanels/ScheduleTab';
import { Actions } from '@/modules/Settings/components/tabs/Actions';
import { AutoApproval } from '@/modules/Settings/components/tabs/AutoApproval';
import { Profile } from '@/modules/Settings/components/tabs/Profile';
import { SiteHours } from '@/modules/Settings/components/tabs/SiteHours';
import { SiteMap } from '@/modules/Settings/components/tabs/SiteMap';
import { SubcontractorItem } from '@/modules/Subcontractors/components/common/SingleItem';
import { WorkerProfile } from '@/modules/Subcontractors/components/common/WorkerProfile';
import { Details } from '@/modules/Subcontractors/components/tabs/Details';
import { Firms } from '@/modules/Subcontractors/components/tabs/Firms';
import { Reservations } from '@/modules/Subcontractors/components/tabs/Reservations';
import { Login } from '@/pages';
import { MFASetup } from '@/pages/Auth/MFASetup';
import { MobileAuth } from '@/pages/Auth/MobileAuth';
import { TermsOfUse } from '@/pages/TermsOfUse';
import { LazyRoutesNamespace } from '@/routes/lazy-app-routes';
import { ProtectedRoute } from '@/shared/components/navigation/ProtectedRoute';
import { useMixpanelConfigUpdate } from '@/shared/hooks/global/useMixpanelConfigUpdate.ts';
import { useAppFlagsChecks } from '@/shared/hooks/observer/useAppFlagsChecks.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { newRelicService } from '@/shared/services/newrelic.service';

import { RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

newRelicService.init();

export const AppRoutes: FC = () => {
  useMixpanelConfigUpdate();
  useAppFlagsChecks();
  const { renderItemVisibility } = useRole();

  const isKotoVisibility = renderItemVisibility([RolesEnum.KOTO]);
  const isDefaultVisibility = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.WORKER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.ENGINEER,
  ]);

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route element={<UpdaterLayout />}>
          <Route element={<SecureLayout />}>
            <Route element={<ConnectionLayout />}>
              <Route element={<Layout />} path={RoutesEnum.HOME}>
                <Route element={<LazyRoutesNamespace.Home />} index />

                <Route
                  element={<LazyRoutesNamespace.Reservations />}
                  path={RoutesEnum.RESERVATIONS}
                >
                  <Route element={<Schedule />} index />
                  <Route
                    element={
                      <ProtectedRoute hasAccess={isDefaultVisibility}>
                        <History />
                      </ProtectedRoute>
                    }
                    path={RoutesEnum.RESERVATIONS_HISTORY}
                  />
                </Route>

                <Route element={<LazyRoutesNamespace.Equipment />} path={RoutesEnum.EQUIPMENT}>
                  <Route element={<Equipments />} index />
                  <Route element={<EquipmentItem />} path=":equipment-id" />
                  <Route element={<Groups />} path="groups" />
                  <Route path="groups/:group-id" />
                </Route>

                <Route element={<LazyRoutesNamespace.Subcontractors />} path={RoutesEnum.PEOPLE}>
                  <Route element={<Firms />} index />

                  <Route element={<SubcontractorItem />} path=":subcontractor-id">
                    <Route element={<Details />} index />
                    <Route element={<WorkerProfile />} path=":worker-id" />
                    <Route element={<Reservations />} path="reservations" />
                  </Route>

                  <Route element={<Groups />} path="groups" />
                  <Route path="groups/:group-id" />
                </Route>

                <Route element={<LazyRoutesNamespace.Reports />} path={RoutesEnum.REPORTS}>
                  <Route element={<TimeZone />} index />
                  <Route
                    element={
                      <ProtectedRoute hasAccess={isDefaultVisibility}>
                        <Utilization />
                      </ProtectedRoute>
                    }
                    path="utilization"
                  />
                  <Route
                    element={
                      <ProtectedRoute hasAccess={isDefaultVisibility}>
                        <Trips />
                      </ProtectedRoute>
                    }
                    path="trips"
                  />
                  <Route
                    element={
                      <ProtectedRoute hasAccess={isDefaultVisibility}>
                        <SitePresence />
                      </ProtectedRoute>
                    }
                    path="site-presence"
                  />
                  <Route
                    element={
                      <ProtectedRoute hasAccess={isKotoVisibility}>
                        <ZoneEntryExit />
                      </ProtectedRoute>
                    }
                    path="zone-entry-exit"
                  />
                </Route>

                <Route element={<LazyRoutesNamespace.Actions />} path={RoutesEnum.ACTIONS} />

                <Route element={<LazyRoutesNamespace.Settings />} path={RoutesEnum.SETTINGS}>
                  <Route element={<Profile />} index />
                  <Route element={<SiteHours />} path="site-hours" />
                  <Route element={<SiteMap />} path="site-map" />
                  <Route element={<Actions />} path="actions" />
                  <Route element={<AutoApproval />} path="auto-approval" />
                </Route>

                <Route element={<LazyRoutesNamespace.Releases />} path={RoutesEnum.RELEASES} />
                <Route element={<LazyRoutesNamespace.Licences />} path={RoutesEnum.LICENSES} />
              </Route>
            </Route>
          </Route>

          <Route element={<AuthLayout />}>
            <Route element={<Login />} path={RoutesEnum.LOGIN} />
            <Route element={<MFASetup />} path={RoutesEnum.MFA} />
            <Route element={<LazyRoutesNamespace.Terms />} path={RoutesEnum.TERMS} />
            <Route element={<LazyRoutesNamespace.SiteSelect />} path={RoutesEnum.SITE_SELECT} />
            <Route
              element={<LazyRoutesNamespace.RestorePassword />}
              path={RoutesEnum.RESTORE_PASSWORD}
            />
            <Route element={<MobileAuth />} path={RoutesEnum.MOBILE_AUTH} />
            <Route element={<TermsOfUse />} path={RoutesEnum.TERMS_OF_USE} />
          </Route>
        </Route>
      </Route>

      <Route element={<LazyRoutesNamespace.ErrorPage />} path="*" />
    </Routes>
  );
};
