import { useGlobalStore } from '@/shared/store/store.ts';

import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const usePrismicLocale = () => {
  const currentLanguage = useGlobalStore(state => state.currentLanguage);

  const lang = currentLanguage === LocaleEnum.EN ? 'en-us' : 'ja-jp';

  return { lang };
};
