import { FC, useState } from 'react';

import { Tab } from '@headlessui/react';

import { translate } from '@/i18n';
import { classnames } from '@/shared/utils/classnames.ts';

import { TabsDatasource } from '@/shared/types/global/tabs.types.ts';

export const CustomHorizontalTab: FC<TabsDatasource> = props => {
  const { children, setTabMode, tabs } = props;

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    setTabMode(value.toLocaleLowerCase());
  };

  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <Tab.Group>
        <Tab.List className="flex w-full space-x-2 rounded-lg border border-slate-200 bg-slate-100 p-1 shadow-sm">
          {tabs.map(tab => (
            <Tab
              className={classnames(
                'w-full flex-grow rounded-md p-1.5 text-center text-sm font-medium text-slate-800 transition-colors duration-300 focus:text-white focus:outline-none',
                activeTab === tab.name && 'bg-blue-700 text-white hover:text-white',
              )}
              key={tab.name}
              onClick={() => handleTabChange(tab.name)}
            >
              {translate(tab.name)}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="w-full">{children}</Tab.Panels>
      </Tab.Group>
    </div>
  );
};
