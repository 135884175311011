import { FieldValues, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { AssetsAPINamespace } from '@/shared/api/assets';
import { inviteFirmSchema } from '@/shared/schemas/validate-schemas';
import { notifyService } from '@/shared/services/notify.service';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import {
  OrganizationCreateRequestMutation,
  OrganizationCreateRequestMutationSuccess,
  OrganizationUpdateRequest,
  OrganizationUpdateRequestMutation,
} from '@/shared/api/assets/assets.types';

export const useMutateFirm = () => {
  const queryClient = useQueryClient();

  const firmForm = useForm<FieldValues>({
    defaultValues: {
      color: '1',
    },
    mode: 'onChange',
    resolver: zodResolver(inviteFirmSchema),
  });

  const {
    activeFirmId,
    assetsOrganizations,
    clearDeletedFirmId,
    closeModal,
    setModalDeleting,
    setModalSubmitting,
    updateAssetsOrganizations,
  } = usePeopleAsset();

  const { mutate: createFirmMutation } = useMutation({
    mutationFn: async (body: OrganizationCreateRequestMutation) => {
      setModalSubmitting(true);

      // There two lines below should be deleted when create one organization support status
      const { status } = body;
      delete body.status;

      const response = await AssetsAPINamespace.createOneOrganization(body);

      // This if below should be deleted when create one organization support status
      if (status === 'active') {
        await AssetsAPINamespace.updateOneOrganization({
          ...body,
          orgResName: response,
          status,
        });
      }

      return { response, ...body, status };
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.firmCreateError'));

      setModalSubmitting(false);
    },

    onSuccess: async (result: OrganizationCreateRequestMutationSuccess) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.COMPANIES],
      });

      updateAssetsOrganizations([
        ...assetsOrganizations,
        {
          extraProperties: result.extraProperties ?? [],
          orgName: result.orgName,
          orgResName: result.response,
          status: result.status ?? 'inactive',
        },
      ]);
      firmForm.reset();
      notifyService.success(translate('messages.firmCreateSuccess'));

      closeModal();
      setModalSubmitting(false);
    },
  });

  const { mutate: updateFirmMutation } = useMutation({
    mutationFn: async (body: OrganizationUpdateRequestMutation) => {
      setModalSubmitting(true);
      return await AssetsAPINamespace.updateOneOrganization({
        ...body,
        orgResName: activeFirmId ?? '',
        status: body.status,
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.firmEditError'));

      setModalSubmitting(false);
    },

    onSuccess: async response => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.COMPANIES],
      });

      updateAssetsOrganizations([
        ...assetsOrganizations.filter(org => org.orgResName !== activeFirmId),
        response,
      ]);

      notifyService.success(translate('messages.firmEditSuccess'));

      closeModal();
      setModalSubmitting(false);
    },
  });

  const { mutate: deleteFirmMutation } = useMutation({
    mutationFn: async (body: OrganizationUpdateRequest) => {
      setModalDeleting(true);

      return await AssetsAPINamespace.updateOneOrganization({
        ...body,
        orgResName: activeFirmId ?? '',
        status: 'deleted',
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      notifyService.error(translate('messages.firmDeleteError'));
      setModalDeleting(false);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.COMPANIES],
      });

      updateAssetsOrganizations(assetsOrganizations.filter(org => org.orgResName !== activeFirmId));

      notifyService.success(translate('messages.firmDeleteSuccess'));
      clearDeletedFirmId();

      setModalDeleting(false);
      setTimeout(() => closeModal(), 1000);
    },
  });

  return {
    createFirmMutation,
    deleteFirmMutation,
    firmForm,
    updateFirmMutation,
  };
};
