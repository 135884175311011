import { FC } from 'react';
import { Fade as FadeAnimation, FadeProps } from 'react-awesome-reveal';

import { WithChildren } from '@/shared/types/global/common.types.ts';

type AnimationProps = FadeProps & WithChildren;

export const Fade: FC<AnimationProps> = ({ children, ...props }) => {
  return <FadeAnimation {...props}>{children}</FadeAnimation>;
};
