import { ReactNode } from 'react';

import { isActionAllowed } from '@/shared/utils/roles';

import { MixedRules } from '@/shared/types/global/rules.types.ts';

type Props = {
  children: ReactNode;
  action: MixedRules;
};

export const RolesWrapper = ({ action, children }: Props) => {
  if (isActionAllowed(action)) return <>{children}</>;

  return null;
};
