import { baseQuery, signRequest } from '@/shared/api/utils';

import { ReaderGetPayload, ReadersGetPayload } from './readers.types.ts';

import { ReadersProps, ReadersRootProps } from '@/shared/types/global/readers.types.ts';

export namespace ReadersAPINamespace {
  const client = baseQuery('ENVIRONMENT');

  export const getAll = async ({
    accountResourceName,
    siteResourceName,
  }: ReadersGetPayload): Promise<ReadersRootProps> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/readers?limit=1000`;

    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getOne = async ({
    accountResourceName,
    readerResourceName,
    siteResourceName,
  }: ReaderGetPayload): Promise<ReadersProps> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/readers/${readerResourceName}`;

    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
