import { queryOptions, useQuery } from '@tanstack/react-query';

import { MapsAPINamespace } from '@/shared/api/maps/maps.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { destructZipFromBuffer } from '@/shared/utils/zip.ts';

export namespace useMapsHooks {
  export const getAll = () => {
    const siteResourceName = useGlobalStore(store => store.site);
    const floor = useGlobalStore(store => store.floor);

    const {
      geofenceMapBlobUrlList,
      mapPreview,
      setGeoFenceMapBlobUrl,
      setGeoFenceMapBlobUrlList,
      setGeofenceMapBlobUrlLoading,
      setSiteMapConfig,
      setSiteMapList,
    } = useGlobalStore();

    const { user } = useFetchUserData();

    const mapsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!siteResourceName,

      queryFn: async () => {
        setGeofenceMapBlobUrlLoading(true);

        const results = (
          await MapsAPINamespace.getAll({
            accountResourceName: user?.accountResourceName ?? '',
            siteResourceName,
          })
        ).filter(map => map.mapName);

        setSiteMapList(results);

        const preview = results.find(map => map.mapResName == mapPreview);

        const activeMap = floor
          ? preview ?? results.find(map => map.mapName === floor?.name)
          : results?.at(-1);

        if (activeMap) {
          // Get map from bucket
          const signedUrl = await MapsAPINamespace.getSignedURL({
            accountResourceName: user?.accountResourceName ?? '',
            mapResourceName: activeMap?.mapResName,
            siteResourceName,
          });

          // Get zip file
          const zipFileBuffer = await MapsAPINamespace.getZipFile({
            mapSignedUrl: signedUrl,
          });

          // Destruct zip, return blobURL
          const result = await destructZipFromBuffer(zipFileBuffer);

          if (result) {
            setGeoFenceMapBlobUrl(result?.updatedDaeUrl);

            setGeoFenceMapBlobUrlList({
              ...geofenceMapBlobUrlList,
              [activeMap.mapResName]: result?.updatedDaeUrl,
            });

            setSiteMapConfig(result?.siteMapConfig);
          }
        } else {
          setGeoFenceMapBlobUrl(null);
          setSiteMapConfig(null);
        }

        return results;
      },

      queryKey: [QUERY_KEY.MAPS, user?.accountResourceName, siteResourceName, floor],
    });

    return useQuery(mapsQueryOptions);
  };
}
