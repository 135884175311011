import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { translate } from '@/i18n';
import { languages } from '@/i18n/data';
import { Button } from '@/shared/components/buttons/Button';
import { Checkbox } from '@/shared/components/form/Checkbox';
import { Input, PasswordInput } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { Select } from '@/shared/components/form/Select';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { useSetCurrentLanguage } from '@/shared/hooks/global/useSetCurrentLanguage.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { FormProps } from '@/modules/Auth/types/types';
import { RoutesEnum, StatusEnum } from '@/shared/types/global/enums.ts';

export const Form: FC<FormProps> = props => {
  const { errors, handleSubmit, onFormSubmit, register, remembered } = props;

  const navigate = useNavigate();
  const status = useGlobalStore(state => state.status);
  const currentLanguage = useGlobalStore(store => store.currentLanguage);

  const { handleSetLanguage } = useSetCurrentLanguage();

  const navigateToRestorePassword = () => {
    navigate(RoutesEnum.RESTORE_PASSWORD);
  };

  const isLoading = status === StatusEnum.LOADING;

  return (
    <>
      <SiteLogo className="mx-auto" color="blue" />

      <div className="grid gap-y-1 text-center">
        <Typography as="strong" className="text-xl font-semibold text-slate-950">
          {translate('auth.title')}
        </Typography>

        <Typography className="text-sm font-medium text-slate-600">
          {translate('auth.subTitle')}
        </Typography>
      </div>

      <form autoComplete="off" className="grid gap-y-3.5" onSubmit={handleSubmit(onFormSubmit)}>
        <Label className="grid gap-y-2" labelText={translate('auth.accountNameLabel')}>
          <Input
            {...register('accountName')}
            aria-label="account-name-textfield"
            autoFocus={!remembered}
            disabled={isLoading}
            error={errors?.accountName?.message}
          />
        </Label>

        <Label className="grid gap-y-2" labelText={translate('auth.username')}>
          <Input
            {...register('username')}
            aria-label="username-textfield"
            autoFocus={remembered}
            disabled={isLoading}
            error={errors?.username?.message}
          />
        </Label>

        <Label className="grid gap-y-2" labelText={translate('auth.password')}>
          <PasswordInput
            {...register('password')}
            aria-label="password-textfield"
            autoFocus={remembered}
            disabled={isLoading}
            error={errors?.password?.message}
          />
        </Label>

        <div className="grid gap-y-2">
          <Typography className="text-sm font-medium text-slate-950">
            {translate('auth.language')}
          </Typography>

          <Select
            aria-label="language-select"
            data={languages}
            defaultValue={currentLanguage ?? languages[0].id}
            disabled={isLoading}
            forLanguage
            label={translate('auth.select')}
            onChange={handleSetLanguage}
            variant="default"
          />
        </div>

        <Checkbox {...register('rememberMe')} aria-label="remember-me-action" disabled={isLoading}>
          <Typography as="span" className="text-sm font-medium text-slate-950">
            {translate('auth.rememberLabel')}
          </Typography>
        </Checkbox>

        <Button
          aria-label="login-action"
          disabled={isLoading}
          isLoading={isLoading}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate('auth.loginLabel')}
        </Button>

        <Button
          aria-label="forgot-password-action"
          disabled={isLoading}
          onClick={navigateToRestorePassword}
          type="button"
          variant="ghost"
        >
          {translate('auth.forgotLabel')}
        </Button>
      </form>
    </>
  );
};
