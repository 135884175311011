import type { ReactNode } from 'react';

import { flexRender, Row } from '@tanstack/react-table';

import { Toggle } from '@/shared/components/form/Toggle';
import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { useToggleFirmStatus } from '@/shared/hooks/assets/useToggleFirmStatus.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { UseTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';
import { FirmsDatasource } from '@/shared/types/users/subcontractors.types.ts';

export function useTableFirmBody<T>({
  contentRowType,
  extendedRow,
  rowClassName,
  table,
  withToggle,
}: UseTableBodyProps<T>) {
  const assetOrganizations = useGlobalStore(state => state.assetsOrganizations);

  const { isPending, mutate } = useToggleFirmStatus();

  const handleMutate = (data: FirmsDatasource) => {
    const currentFirm = assetOrganizations.find(firm => firm.orgResName === data?.id);

    if (!currentFirm) {
      notifyService.error('The firm is missing some required fields.');
      return;
    }

    mutate(currentFirm);
  };

  const items = <K extends Record<string, string>>(
    data?: K,
    customRow?: (row: Row<T>) => ReactNode,
  ) => {
    const tableRows = table.getRowModel().rows;

    return tableRows.map(row => {
      const isDeactivated = Object.values(row.original as Record<string, string>).includes(
        'deactivated',
      );

      if (customRow) return customRow(row);

      if (contentRowType === 'firm')
        return (
          <TableRow
            className={classnames(isDeactivated && 'bg-slate-50', !data && 'pl-4', rowClassName)}
            data-index={row.index}
            key={row.id}
            ref={node => node}
          >
            {row.getVisibleCells().map((cell, index) => {
              const data = cell.row?.original as FirmsDatasource;
              const isActive = data?.status === 'active';
              const isInactiveRow = !isActive && withToggle;

              return (
                <TableData
                  className={classnames(
                    extendedRow && 'h-[64px]',
                    !cell.column.columnDef.header && 'min-w-[120px] max-w-[120px]',
                    isInactiveRow && 'opacity-50',
                  )}
                  data-index={row.index}
                  key={cell.id}
                >
                  {index === 0 && withToggle && (
                    <Toggle
                      checked={isActive}
                      color="green"
                      disabled={isPending}
                      onChange={() => handleMutate(data)}
                    />
                  )}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              );
            })}
          </TableRow>
        );
    });
  };

  return { items };
}
