import { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { DefaultInputProps } from '@/shared/types/global/common.types.ts';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

type CustomCheckboxProps = DefaultInputProps & {
  text: JSX.Element | string;
  error?: string;
};

export const CustomCheckbox = forwardRef(
  (
    { className, error, text, ...props }: CustomCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { locale } = useIntl();

    return (
      <label
        className={classnames(
          'text-sm font-medium text-slate-950',
          props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
      >
        <input className="originalCheckbox" ref={ref} type="checkbox" {...props} />

        <Typography
          className={classnames(
            'customCheckbox',
            locale === LocaleEnum.JPN ? 'min-w-16' : 'min-w-12',
          )}
        >
          {text}
        </Typography>
      </label>
    );
  },
);

CustomCheckbox.displayName = 'CustomCheckbox';
