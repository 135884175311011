import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithChildren &
  WithClassName & {
    role?: string;
  };

export const Nav: FC<Props> = ({ children, className, role }) => {
  return (
    <nav aria-label="Global" className={classnames(className)} role={role}>
      {children}
    </nav>
  );
};
