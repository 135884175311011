import { FC } from 'react';

import { translate } from '@/i18n';
import { FormWrapper } from '@/modules/Settings/components/common/FormWrapper';
import { useProfileForm } from '@/modules/Settings/hooks/useProfileForm';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { notifyService } from '@/shared/services/notify.service';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages';
import { attributesList } from '@/shared/utils/helpers';

import { SettingsFormProps } from '@/modules/Settings/types/types.ts';
import { Inputs } from '@/shared/types/global/common.types.ts';

export const ProfileForm: FC<SettingsFormProps> = ({ isEditable }) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    isButtonDisabled,
    register,
    reset,
    setAuthorized,
    user,
  } = useProfileForm();

  const handleFormSubmit = async (data: Inputs) => {
    if (user?.username) {
      try {
        const attributes = attributesList(data);

        await cognitoUserPoolService.updateUserAttributes(attributes, user.username);

        setAuthorized(true);
        notifyService.success(ERROR_MESSAGES.UPDATE_USER_SUCCESS);

        reset();
      } catch (error) {
        console.error(error);
        notifyService.error(ERROR_MESSAGES.UPDATE_USER_FAILED);
      }
    }
  };

  return (
    <form
      autoComplete="off"
      className="flex items-end gap-4"
      id="profileForm"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormWrapper>
        <Label labelText={translate('settings.firstName')}>
          <Input
            {...register('given_name')}
            aria-label="first-name-textfield"
            disabled={!isEditable}
            error={errors?.given_name?.message}
          />
        </Label>

        <Label labelText={translate('settings.lastName')}>
          <Input
            {...register('family_name')}
            aria-label="last-name-textfield"
            disabled={!isEditable}
            error={errors?.family_name?.message}
          />
        </Label>
      </FormWrapper>

      {isEditable && (
        <Button
          aria-label="change-name-action"
          disabled={isButtonDisabled || isSubmitting}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate(isSubmitting ? 'settings.saving' : 'settings.save')}
        </Button>
      )}
    </form>
  );
};
