import { ForwardedRef, forwardRef } from 'react';
import { useMedia } from 'react-use';

import { Button } from '@/shared/components/buttons/Button';
import { classnames } from '@/shared/utils/classnames';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithChildren &
  WithClassName & {
    onClick?: () => void;
  };

export const SiteSelectItem = forwardRef(
  ({ children, className, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const isMobile = useMedia('(max-width: 767px)');

    return (
      <Button
        className={classnames(
          'inline-flex w-full items-center justify-between rounded-md text-sm font-medium transition-colors duration-300',
          !isMobile && 'px-3 py-2 hover:bg-slate-100 focus:bg-slate-100',
          className,
        )}
        ref={ref}
        variant="custom"
        {...props}
      >
        {children}
      </Button>
    );
  },
);
