import { useMemo } from 'react';

import { getTagValue } from '@/modules/Equipment/components/tabs/Equipment/EquipmentsAccordsBody.tsx';
import { useGetReservations } from '@/modules/Reservations/hooks/userGetReservations';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment.ts';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

export const useFilteredEquipmentData = (assetResourceName: null | string) => {
  const assetsTags = useGlobalStore(store => store.assetsTags);

  const { assets } = useEquipment();
  const { filteredSocketMapData } = useFilteredSocketData();
  const allReservations = useGetReservations();

  const filteredActiveAllAssets = filteredSocketMapData.filter(item =>
    item.categoryTreeName.includes('Equipment'),
  );

  const filteredAssets = useMemo(() => {
    const unDeletedAssets = assets.filter(asset => asset.assetStatus !== 'deleted');

    const modifiedAssets = unDeletedAssets.map(asset => {
      const propertyValue = getTagValue(asset);
      const currentAsset = filteredSocketMapData.find(item => item.tagResName === propertyValue);
      const isReserved = !!allReservations?.find(
        reservation => reservation.assetResName === asset.assetResName,
      );
      const attachedTags = assetsTags
        ?.filter(assetTag => assetTag.asset?.assetResName === asset.assetResName)
        .map(assetsTag => assetsTag.tag);

      if (currentAsset)
        return {
          ...asset,
          attachedTags,
          inUse: true,
          isReserved,
        };

      return {
        ...asset,
        attachedTags,
        inUse: false,
        isReserved,
      };
    });

    const filteredActiveAssets = modifiedAssets.filter(
      asset => asset.assetResName === assetResourceName,
    );

    return assetResourceName ? filteredActiveAssets : modifiedAssets;
  }, [assets, assetResourceName, filteredSocketMapData]);

  return { filteredActiveAllAssets, filteredAssets };
};
