import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ReservationMobileList } from '@/modules/Subcontractors/components/common/ReservationMobileList';
import { useFilteredReservationData } from '@/modules/Subcontractors/hooks/useFilteredReservationData';
import { useSearchReservation } from '@/modules/Subcontractors/hooks/useSearchReservations';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus';
import { PARAM_KEY, URL_PARAMS } from '@/shared/utils/constants.ts';

import { renderColumns } from './Reservations.utils';

export const Reservations: FC = () => {
  const [searchParameters] = useSearchParams();
  const params = useParams();

  const reservationDate = searchParameters.get(URL_PARAMS.DATE);
  const reservationStatus = searchParameters.get(URL_PARAMS.STATUS);

  const subcontractorId = params[PARAM_KEY.SUBCONTRACTOR_ID];

  const { filteredReservations } = useFilteredReservationData(subcontractorId ?? null);
  const { searchedFilteredReservations } = useSearchReservation({
    reservationDate,
    reservations: filteredReservations,
    reservationStatus,
  });

  const { isMutatingStatus: isLoading } = useAssetStatus();
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <div className="flex flex-col gap-y-4">
      <TopSection scope={['status', 'date']} />

      {isMobile ? (
        <div className="grid gap-y-3 md:hidden">
          <ReservationMobileList
            isLoading={isLoading}
            reservations={searchedFilteredReservations}
          />
        </div>
      ) : (
        <div className="hidden gap-y-5 md:grid">
          <DefaultTable
            columns={renderColumns()}
            contentRowType="reservations"
            data={searchedFilteredReservations}
            isLoading={isLoading}
            isPaginated
          />
        </div>
      )}
    </div>
  );
};
