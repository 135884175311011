import { useGetReservations } from '@/modules/Reservations/hooks/userGetReservations';
import { hideInactiveAssets } from '@/modules/Subcontractors/utils/helpers';
import { useGlobalStore } from '@/shared/store/store.ts';
import { findTagValue } from '@/shared/utils/assets.ts';

import { AssetBody } from '@/shared/types/assets/assets.types';
import { AvailabilityEnum, TagsAttachedEnum } from '@/shared/types/global/enums';

type EquipmentSearchParams = null | string;

type Props = {
  equipments: AssetBody[];
  equipmentSubtype: EquipmentSearchParams;
  equipmentStatus: EquipmentSearchParams;
  equipmentZone: EquipmentSearchParams;
  equipmentType: EquipmentSearchParams;
  equipmentTag: EquipmentSearchParams;
  equipmentAvailability: EquipmentSearchParams;
  isShowInactive: boolean;
};

export const useSearchEquipment = ({
  equipmentAvailability,
  equipments,
  equipmentStatus,
  equipmentSubtype,
  equipmentTag,
  equipmentType,
  equipmentZone,
  isShowInactive,
}: Props) => {
  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const allReservations = useGetReservations();
  const filteredEquipments = equipments?.filter(equipment => {
    const isReserved = !!allReservations?.find(
      reservation => reservation.assetResName === equipment.assetResName,
    );

    const matchesAvailability = equipmentAvailability
      ? (equipmentAvailability === AvailabilityEnum.RESERVED && isReserved) ||
        (equipmentAvailability === AvailabilityEnum.AVAILABLE && !isReserved)
      : true;

    const matchesAttachedTag = equipmentTag
      ? (equipmentTag == TagsAttachedEnum.YES && equipment.attachedTags?.length) ||
        (equipmentTag == TagsAttachedEnum.NO && !equipment.attachedTags?.length)
      : true;

    const matchesSearchField = equipment.assetName
      .toLowerCase()
      .includes(searchFieldValue.toLowerCase());

    const matchesSubtype = equipmentSubtype
      ? equipment.categoryPathResName === equipmentSubtype
      : true;

    const matchesType = equipmentType ? equipment.categoryTreeResName === equipmentType : true;

    const matchesStatus = equipmentStatus
      ? (equipmentStatus === 'in-use' && equipment.inUse) ||
        (equipmentStatus === 'idle' && !equipment.inUse)
      : true;

    const matchesZone = equipmentZone ? findTagValue(equipment, 'dropOff') === equipmentZone : true;

    return (
      matchesSearchField &&
      matchesSubtype &&
      matchesStatus &&
      matchesZone &&
      matchesType &&
      matchesAttachedTag &&
      matchesAvailability
    );
  });

  return { filteredEquipments: hideInactiveAssets(filteredEquipments, isShowInactive) };
};
