import { useEffect } from 'react';

import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

export const useSetAssets = () => {
  const companies = useGlobalStore(state => state.companies);
  const assetsTags = useGlobalStore(state => state.assetsTags);
  const setWorkers = useGlobalStore(state => state.setWorkers);
  const setWorkersInUse = useGlobalStore(state => state.setWorkersInUse);
  const setEquipment = useGlobalStore(state => state.setEquipment);
  const setEquipmentInUse = useGlobalStore(state => state.setEquipmentInUse);
  const setAssets = useGlobalStore(state => state.setAssets);

  const { data: assetsCategories = [] } = useAssetHooks.getCategoryTrees();
  const { data: equipments = [] } = useAssetHooks.getAllEquipments();
  const { data: workers = [] } = useAssetHooks.getAllWorkers();

  useEffect(() => {
    const workersInUse = workers.filter(
      worker => worker.attachedTags && worker.assetStatus === 'active',
    );
    const equipmentsInUse = equipments.filter(
      equipment => equipment?.attachedTags && equipment.assetStatus == 'active',
    );

    setWorkers(workers);
    setWorkersInUse(workersInUse);

    setEquipment(equipments);
    setEquipmentInUse(equipmentsInUse);

    setAssets([...workers, ...equipments], assetsCategories, companies, [], assetsTags);
  }, [
    assetsCategories,
    assetsTags,
    companies,
    equipments,
    setAssets,
    setEquipment,
    setEquipmentInUse,
    setWorkers,
    setWorkersInUse,
    workers,
  ]);
};
