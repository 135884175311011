import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';

import { RolesEnum } from '@/shared/types/global/enums.ts';

export function useRole() {
  const { user } = useFetchUserData();

  const renderItemVisibility = (list: RolesEnum[]) => list.includes(user?.role ?? RolesEnum.WORKER);

  return { renderItemVisibility };
}
