import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { translate } from '@/i18n';
import { SingleItemButtonGroup } from '@/modules/Subcontractors/components/common/SingleItemButtonGroup';
import { useFilteredReservationData } from '@/modules/Subcontractors/hooks/useFilteredReservationData';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { NumberFlow } from '@/shared/components/animation/NumberFlow';
import { GoBackLink } from '@/shared/components/navigation/GoBackLink';
import { PageNavbar } from '@/shared/components/navigation/PageNavbar';
import { Badge } from '@/shared/components/ui/Badge';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { Typography } from '@/shared/components/ui/Typography';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { useIsRootRoute } from '@/shared/hooks/routes/useIsRootRoute.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

export const SubcontractorItem: FC = () => {
  const { 'subcontractor-id': subcontractorId } = useParams();

  const { isRootRoute } = useIsRootRoute([
    `${RoutesEnum.PEOPLE}/${subcontractorId}`,
    `${RoutesEnum.PEOPLE}/${subcontractorId}/reservations`,
  ]);

  const { firms } = usePeopleAsset();
  const { filteredReservations } = useFilteredReservationData(subcontractorId ?? null);
  const { renderItemVisibility } = useRole();

  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
  ]);

  const firmName = firms.find(firm => firm.id === subcontractorId)?.firm_name;

  return (
    <>
      {isRootRoute && (
        <PageNavbar
          buttonsGroup={<SingleItemButtonGroup />}
          navItems={[
            {
              label: translate('global.details'),
              to: `${RoutesEnum.PEOPLE}/${subcontractorId}`,
            },
            {
              condition: isVisible,
              label: (
                <Typography as="span" className="inline-flex items-center gap-x-1 uppercase">
                  {translate('global.reservations')}

                  <Badge className="px-1.5" color="blue" rounded="full" variant="solid">
                    <NumberFlow value={filteredReservations.length} />
                    {/*{filteredReservations.length}*/}
                  </Badge>
                </Typography>
              ),
              to: `${RoutesEnum.PEOPLE}/${subcontractorId}/reservations`,
            },
          ]}
          title={
            <GoBackLink to={RoutesEnum.PEOPLE}>
              {firmName ?? <Skeleton variant="default" />}
            </GoBackLink>
          }
        />
      )}

      <div className={classnames('py-3')}>
        <Outlet />
      </div>
    </>
  );
};
