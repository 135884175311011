import { useLocation } from 'react-router-dom';

export const useIsRootRoute = (rootPaths: string[]) => {
  const location = useLocation();

  const matches = rootPaths.reduce(
    (acc, path) => {
      const regex = new RegExp(`^${path}(/)?$`);

      acc[path] = regex.test(location.pathname);

      return acc;
    },
    {} as Record<string, boolean>,
  );

  return {
    isRootRoute: Object.values(matches).some(Boolean),
  };
};
