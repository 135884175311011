import { queryOptions, useQuery } from '@tanstack/react-query';

import { SettingsAPINamespace } from '@/shared/api/settings/settings.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';

export namespace useSettingsHooks {
  export const getAll = () => {
    const setUserSettings = useGlobalStore(state => state.setUserSettings);
    const setCurrency = useGlobalStore(state => state.setCurrency);
    const setSiteTimeZone = useGlobalStore(state => state.setSiteTimeZone);
    const setDisplayTimeZone = useGlobalStore(state => state.setDisplayTimeZone);
    const setTimeFormat = useGlobalStore(state => state.setTimeFormat);
    const setSystemView = useGlobalStore(state => state.setSystemView);

    const { user } = useFetchUserData();

    const getUserSettings = async () => {
      const settings = await SettingsAPINamespace.getAll({
        accountResName: user?.accountResourceName as string,
        userResName: user?.userResourceName as string,
      });

      setUserSettings(settings?.results);

      const userSettings = new SettingsService(
        settings?.results,
        SettingsNameEnum.UserSettings,
        SettingsKeyEnum.UserSettings,
      );

      // User settings
      setCurrency(userSettings.getSelectedCurrency());
      setSiteTimeZone(userSettings.getSelectedSiteTimeZone());
      setDisplayTimeZone(userSettings.getSelectedDisplayTimeZone());
      setTimeFormat(userSettings.getSelectedTimeFormat());
      setSystemView(userSettings.getSelectedMeasurementSystem());

      return settings;
    };

    const settingsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!user?.userResourceName,

      queryFn: getUserSettings,

      queryKey: [QUERY_KEY.USER_SETTINGS_GET, user?.accountResourceName, user?.userResourceName],
    });

    return useQuery(settingsQueryOptions);
  };
}
