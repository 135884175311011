import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ActionsAPINamespace } from '@/shared/api/actions/actions.requests.ts';
import { postProcessAlerts } from '@/shared/api/actions/actions.utils.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { ActionSubCategoryEnum, AlertDataProps } from '@/shared/types/actions/actions.types.ts';

export namespace useActionsHook {
  export const getAlerts = () => {
    const { user } = useFetchUserData();
    const setAlertsData = useGlobalStore(state => state.setAlertsData);
    const siteResName = useGlobalStore(state => state.site);

    return useQuery({
      enabled: !!user?.accountResourceName && !!siteResName && !!user?.userResourceName,

      queryFn: async () => {
        const alerts = await ActionsAPINamespace.getAlerts(
          user?.accountResourceName as string,
          siteResName,
          user?.userResourceName as string,
        );

        const processedAlerts = await postProcessAlerts(
          alerts ?? [],
          user?.accountResourceName ?? '',
          siteResName,
          user?.role ?? null,
        );

        setAlertsData(processedAlerts);

        return processedAlerts;
      },

      queryKey: [
        QUERY_KEY.ALERTS,
        user?.accountResourceName,
        siteResName,
        user?.userResourceName,
        user?.role,
      ],
    });
  };

  export const getArchivedAlerts = (
    startTimestamp: number,
    endTimestamp: number,
    subCategories: ActionSubCategoryEnum[] = [],
  ) => {
    const { user } = useFetchUserData();

    const setArchivedAlertsData = useGlobalStore(state => state.setArchivedAlertsData);
    const siteResName = useGlobalStore(state => state.site);

    return useInfiniteQuery({
      enabled: !!user?.accountResourceName && !!siteResName && !!user?.userResourceName,

      getNextPageParam: (lastPage: AlertDataProps) =>
        lastPage.page.hasNext ? lastPage.page.pageEndResPointer : null,

      initialPageParam: '',

      queryFn: async data => {
        const response = await ActionsAPINamespace.getArchivedAlerts(
          user?.accountResourceName as string,
          siteResName,
          user?.userResourceName as string,
          startTimestamp,
          endTimestamp,
          subCategories,
          data.pageParam,
        );

        const processedArchivedAlerts = await postProcessAlerts(
          response.results ?? [],
          user?.accountResourceName ?? '',
          siteResName,
          user?.role ?? null,
        );

        setArchivedAlertsData(processedArchivedAlerts, !!data.pageParam);

        return response;
      },

      queryKey: [
        QUERY_KEY.ARCHIVED_ALERTS,
        user?.accountResourceName,
        siteResName,
        user?.userResourceName,
        subCategories,
        startTimestamp,
        endTimestamp,
        user?.role,
      ],
    });
  };

  export const getMetrics = () => {
    const { user } = useFetchUserData();

    const setMetricsData = useGlobalStore(state => state.setMetricsData);
    const site = useGlobalStore(state => state.site);

    return useQuery({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        try {
          const data = await ActionsAPINamespace.getAllMetrics(
            user?.accountResourceName as string,
            site,
          );
          setMetricsData(data?.metrics ?? []);
          return data?.metrics ?? [];
        } catch {
          setMetricsData([]);
        }
      },

      queryKey: [QUERY_KEY.METRICS, user?.accountResourceName, site],
    });
  };
}
