import { useRef, useState } from 'react';

import { ThreeEvent } from '@react-three/fiber';
import { Mesh } from 'three';

import { useGlobalStore } from '@/shared/store/store.ts';
import { dotsPerMeasure } from '@/shared/utils/dots-per-measure';

import {
  CurrentLocationElementProps,
  Vector3NumberProps,
} from '@/modules/Home/types/MapModel.types';
import { Location } from '@/shared/types/global/locations.types.ts';

export const usePathDetails = (locations: Location[]) => {
  const [currentElement, setCurrentElement] = useState<CurrentLocationElementProps | null>(null);
  const currentElementRef = useRef<Mesh>(null);

  const currentLanguage = useGlobalStore(state => state.currentLanguage);

  const modifiedLocationsData = () => {
    const result = dotsPerMeasure(locations.length);

    if (result > 0) {
      return locations.filter((_, index) => index % result === 0);
    }

    return locations ?? [];
  };

  const handlePopupOpen = (data: ThreeEvent<MouseEvent>) => {
    const targetPosition = data.object.position;
    const currentElement = modifiedLocationsData().find(element => element.x === targetPosition.x);

    setCurrentElement({
      ...currentElement,
    } as unknown as CurrentLocationElementProps);
  };

  const handlePopupClose = () => {
    setCurrentElement(null);
  };

  const completePositionCoordinates = (
    x: number | string,
    y: number | string,
    z: number | string,
  ): Vector3NumberProps => {
    if (typeof x === 'string') x = Number(x);
    if (typeof y === 'string') y = Number(y);
    if (typeof z === 'string') z = Number(z);

    return [x, z, y * -1];
  };

  return {
    completePositionCoordinates,
    currentElement,
    currentElementRef,
    currentLanguage,
    handlePopupClose,
    handlePopupOpen,
    modifiedLocationsData,
  };
};
