import { FC, useState } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { CodeInput } from '@/shared/components/form/CodeInput';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';

import { MFAFormVerifyProps } from '@/modules/Auth/types/types';
import { StatusEnum } from '@/shared/types/global/enums.ts';

export const MFAVerifyForm: FC<MFAFormVerifyProps> = props => {
  const { handleCancel, handleSubmit } = props;
  const [code, setCode] = useState<string>('');

  const status = useGlobalStore(state => state.status);
  const isLoading = status === StatusEnum.LOADING;

  return (
    <>
      <SiteLogo className="mx-auto" color="blue" />

      <div className="grid gap-y-1 text-center">
        <Typography as="strong" className="text-xl font-semibold text-slate-950">
          {translate('auth.mfaSetupTitle')}
        </Typography>

        <Typography className="text-sm font-medium text-slate-600">
          {translate('auth.mfaVerifySubtitle')}
        </Typography>
      </div>

      <div className="grid gap-y-3.5">
        <div className="mb-40 flex justify-center">
          <CodeInput onChange={setCode} />
        </div>

        <div className="inline-flex items-center gap-x-3">
          <Button
            aria-label="mfa-setup-cancel"
            className="w-[50%]"
            disabled={isLoading}
            onClick={handleCancel}
            variant="outline"
            withIcon
          >
            {translate('global.back')}
          </Button>

          <Button
            aria-label="mfa-setup-continue"
            className="w-[50%]"
            disabled={code.length !== 6}
            isLoading={isLoading}
            onClick={() => handleSubmit(code)}
            type="submit"
            variant="solid"
            withIcon
          >
            {translate('global.submit')}
          </Button>
        </div>
      </div>
    </>
  );
};
