import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const languages = [
  { id: LocaleEnum.EN, name: 'English - US', title: 'English' },
  {
    id: LocaleEnum.JPN,
    name: 'Japanese - 日本語',
    title: '日本語',
  },
];
