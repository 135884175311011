import { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';

import { translate } from '@/i18n';
import { Collapse } from '@/modules/Settings/components/common/Collapse';
import { InputWrapper } from '@/modules/Settings/components/common/InputWrapper';
import { useSiteHoursDetail } from '@/modules/Settings/hooks/useSiteHoursDetail';
import { useSiteHoursMethods } from '@/modules/Settings/hooks/useSiteHoursMethods.ts';
import { timeZones, workDays } from '@/modules/Settings/utils/constants';
import { Fade } from '@/shared/components/animation/Fade';
import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { CustomCheckbox } from '@/shared/components/form/CustomCheckbox';
import { DatePicker } from '@/shared/components/form/DatePicker';
import { Select } from '@/shared/components/form/Select';
import { Badge } from '@/shared/components/ui/Badge';
import * as Calendar from '@/shared/utils/calendar';
import { DATE_FORMAT, timeCaption } from '@/shared/utils/calendar';
import { classnames } from '@/shared/utils/classnames';

export const SiteHours: FC = () => {
  const {
    currentLanguage,
    defaultPolicy,
    defaultShift,
    endTime,
    endTimes,
    form,
    handleEndTimeChange,
    handleResetTime,
    handleSetSelected,
    handleStartTimeChange,
    selected,
    setEndTime,
    setSelected,
    setStartTime,
    startTime,
    startTimes,
  } = useSiteHoursDetail();

  const {
    handleDateChange,
    handleFormSubmit,
    handleReset,
    isCreatingPolicy,
    isCreatingShift,
    isUpdatingPolicy,
    isUpdatingShift,
    week,
  } = useSiteHoursMethods({
    currentLanguage,
    defaultPolicy,
    defaultShift,
    endTime,
    endTimes,
    form,
    handleResetTime,
    handleSetSelected,
    selected,
    setEndTime,
    setStartTime,
    startTime,
    startTimes,
  });

  const {
    control,
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = form;

  const renderedException = (day: string) => {
    return (
      <div className="flex w-full items-center justify-center gap-x-2">
        <DatePicker
          className="w-full"
          customDateFormat
          dateFormat={DATE_FORMAT}
          icon="clock"
          maxTime={new Date(new Date(endTimes[day]).setHours(endTimes[day].getHours() - 1))}
          minTime={new Date(new Date().setHours(0, 0))}
          onChange={date => handleStartTimeChange(day, date as Date)}
          selected={startTimes?.[day]}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={timeCaption}
          timeIntervals={60}
        />

        <DatePicker
          className="w-full"
          customDateFormat
          dateFormat={DATE_FORMAT}
          icon="clock"
          maxTime={new Date(new Date().setHours(23, 59))}
          minTime={new Date(new Date(startTimes[day]).setHours(startTimes[day].getHours() + 1))}
          onChange={date => handleEndTimeChange(day, date as Date)}
          selected={endTimes?.[day]}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={timeCaption}
          timeIntervals={60}
        />
      </div>
    );
  };

  watch();

  return (
    <Fade>
      <form
        autoComplete="off"
        className="relative grid w-full max-w-[340px] gap-y-4"
        id="site-hours"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {/*Site time zone*/}
        <InputWrapper label={translate('settings.siteTimeZone')}>
          <Controller
            control={control}
            name="siteTimeZone"
            render={({ field }) => (
              <Select
                aria-label="site-time-zone"
                data={timeZones}
                defaultValue={getValues('siteTimeZone') as string}
                onChange={value => field.onChange(value)}
                variant="default"
              />
            )}
          />
        </InputWrapper>

        {/*Default site hours*/}
        <InputWrapper label={translate('settings.defaultSiteHours')}>
          <div className="flex items-center gap-x-2">
            <Controller
              control={control}
              name="startTime"
              render={({ field }) => (
                <DatePicker
                  className="w-full"
                  customDateFormat
                  dateFormat="HH:mm"
                  icon="clock"
                  onChange={date => {
                    field.onChange(date);
                    setStartTime(date as Date);
                  }}
                  selected={startTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption={timeCaption}
                  timeIntervals={60}
                />
              )}
            />

            <Controller
              control={control}
              name="endTime"
              render={({ field }) => (
                <DatePicker
                  className="w-full"
                  customDateFormat
                  dateFormat="HH:mm"
                  icon="clock"
                  onChange={date => {
                    field.onChange(date);
                    setEndTime(date as Date);
                  }}
                  selected={endTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption={timeCaption}
                  timeIntervals={60}
                />
              )}
            />
          </div>
        </InputWrapper>

        {/*Work days*/}
        <InputWrapper label={translate('settings.workDays')}>
          <div className="inline-flex items-center gap-x-2 ">
            {workDays.map(day => (
              <Controller
                control={control}
                key={day.id}
                name={day.id}
                render={({ field }) => (
                  <CustomCheckbox
                    checked={field.value as boolean}
                    id={day.id}
                    key={day.id}
                    onChange={field.onChange}
                    text={day.name}
                  />
                )}
              />
            ))}
          </div>
        </InputWrapper>

        <Collapse>
          <div className="flex justify-center gap-x-1">
            <IconButton
              className="w-[38px] bg-white"
              disabled={Calendar.isToday(selected[0]) || isSubmitting}
              icon="left-arrow"
              onClick={() => handleDateChange('decrement')}
            />

            <DatePicker
              className="w-full text-center"
              dateFormat="MM/dd/yyyy"
              endDate={selected[1]}
              fixedHeight
              minDate={new Date()}
              onChange={date => setSelected(date as unknown as Date[])}
              selectsDisabledDaysInRange
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              selectsRange
              startDate={selected[0]}
            />

            <IconButton
              className="w-[38px] rotate-180 bg-white"
              disabled={isSubmitting}
              icon="left-arrow"
              onClick={() => handleDateChange('increment')}
            />
          </div>

          <div className="grid grid-cols-[92px_1fr] items-center justify-items-start gap-x-4 gap-y-2">
            {week.map(day => {
              const id = workDays[day.getDay()].id;
              return (
                <Fragment key={id}>
                  <Badge
                    className={classnames(
                      'w-full rounded-full border border-slate-200 bg-slate-50 px-2 py-[9px] text-center text-sm font-medium text-slate-950 shadow-sm',
                      {
                        'bg-blue-600 text-white shadow-none': getValues(id),
                      },
                    )}
                    onClick={() => setValue(id, getValues(id) ? false : true)}
                    variant="custom"
                  >
                    {day.toLocaleString(currentLanguage, {
                      weekday: 'short',
                    })}{' '}
                    {day.getDate()}
                  </Badge>

                  {getValues(id) ? (
                    renderedException(id)
                  ) : (
                    <Badge
                      className="w-full rounded-lg border border-slate-200 bg-slate-50 px-2.5 py-[9px] text-center text-sm font-normal text-slate-500"
                      key={id}
                      variant="custom"
                    >
                      {translate('settings.nonWorkingDays')}
                    </Badge>
                  )}
                </Fragment>
              );
            })}
          </div>
        </Collapse>

        <div className="inline-flex items-center gap-x-3">
          <Button
            aria-label="site-hours"
            disabled={isCreatingPolicy || isCreatingShift || isUpdatingPolicy || isUpdatingShift}
            isLoading={isCreatingPolicy || isCreatingShift || isUpdatingPolicy || isUpdatingShift}
            type="submit"
            variant="solid"
            withIcon
          >
            {translate(isSubmitting ? 'settings.saving' : 'settings.save')}
          </Button>

          <Button
            aria-label="site-hours-reset"
            className="text-red-500 hover:bg-red-50 focus:bg-red-50"
            onClick={handleReset}
            variant="outline"
          >
            {translate('settings.resetDefaultHours')}
          </Button>
        </div>
      </form>
    </Fade>
  );
};
