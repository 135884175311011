import { ColumnDef } from '@tanstack/react-table';

import { renderBadgeColor, renderBadgeTitle } from '@/modules/Reservations/utils/helpers';
import { classnames } from '@/shared/utils/classnames';
import { formatTimestamp } from '@/shared/utils/date-fns';

import { history } from '@/shared/types/global/reservations.types.ts';

export const renderColumns = (): ColumnDef<history>[] => [
  {
    accessorKey: 'equipment',
    cell: ({ row }) => <div>{row.original.equipment}</div>,
    header: 'equipment',
  },
  {
    accessorKey: 'type',
    cell: ({ row }) => (
      <div className="truncate">
        {row.original.type}, {row.original.subType}
      </div>
    ),
    header: 'type',
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'startTime',
    cell: ({ row }) => <div>{formatTimestamp(row.original.startTime, 'dd/MM/yyyy HH:mm')}</div>,
    header: 'Start Time',
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'endTime',
    cell: ({ row }) => <div>{formatTimestamp(row.original.endTime, 'dd/MM/yyyy HH:mm')}</div>,
    header: 'End Time',
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'firm',
    cell: ({ row }) => <div>{row.original.firm}</div>,
    header: 'firm',
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'reservation',
    cell: ({ row }) => (
      <div
        className={classnames(
          'w-fit rounded-md border-[1px] p-[6px] px-2.5 text-xs capitalize',
          renderBadgeColor(row.original.reservation),
        )}
      >
        {renderBadgeTitle(row.original.reservation)}
      </div>
    ),
    header: 'reservation',
    meta: {
      isFiltered: true,
    },
  },
];
