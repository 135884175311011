import { Fragment, ReactNode, useEffect, useState } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { AssetCategory } from '@/shared/types/assets/assets.types.ts';

type Props<T> = {
  currentCategories: AssetCategory[];
  handleToggleAccordion: (...args: string[]) => void;
  children: (category: AssetCategory) => ReactNode;
  replacedWithPeople?: boolean;
  meta?: (category: T) => ReactNode;
  isParentHidden?: boolean;
};

export function CategoryTypes<T>({
  children,
  currentCategories,
  handleToggleAccordion,
  isParentHidden = false,
  meta,
  replacedWithPeople,
}: Props<T>) {
  return currentCategories.map(category => {
    return (
      <Fragment key={category.categoryTreeResName}>
        {!isParentHidden && (
          <tr className="sticky top-0 w-full border-[1px] border-slate-200  bg-slate-50 px-4">
            <td className="flex w-full items-center">
              <Button
                className="flex h-[44px] w-full items-center gap-2"
                onClick={() => handleToggleAccordion(category.categoryTreeResName)}
                variant="custom"
              >
                <CustomIcon
                  className={classnames({
                    'rotate-180': category.extended,
                  })}
                  type="arrowSm"
                />

                <Typography className="max-w-[150px] shrink-0 truncate text-start text-sm font-bold text-slate-950">
                  {translate(
                    `assets.${
                      category.categoryTreeName === 'site/Worker' && replacedWithPeople
                        ? 'people'
                        : category.categoryTreeName
                    }`,
                  )}
                </Typography>

                {meta?.(category as T)}
              </Button>
            </td>
          </tr>
        )}

        {category.extended && children(category)}
      </Fragment>
    );
  });
}

export function CategorySubTypes<T, K>({
  category,
  className,
  items,
  meta,
}: {
  category: AssetCategory;
  handleToggleAccordion: (...args: string[]) => void;
  items: (subCategory: K) => ReactNode;
  meta?: (pathList: T) => ReactNode;
  className?: string;
}) {
  const [extendedSubCategories, setExtendedSubCategories] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const initialSubCategories: Record<string, boolean> = {};

    category.categoryPathList.forEach(subCategory => {
      initialSubCategories[subCategory.categoryPathResName] = true;
    });

    setExtendedSubCategories(initialSubCategories);
  }, [category.categoryPathList]);

  const toggleExtended = (subCategoryId: string) => {
    setExtendedSubCategories(prevState => ({
      ...prevState,
      [subCategoryId]: !prevState[subCategoryId],
    }));
  };

  return category.categoryPathList.map(subCategory => {
    const isExtended = extendedSubCategories[subCategory.categoryPathResName] || false;

    if ((items(subCategory as K) as Array<K>).length <= 0) return null;

    return (
      <Fragment key={subCategory.categoryPathResName}>
        <tr className="sticky top-0 w-full border-[1px] border-slate-200 bg-slate-50 px-8 z-[1000]">
          <td className="flex w-full items-center">
            <Button
              className="flex h-[44px] w-full items-center gap-2"
              id={subCategory.categoryPathResName}
              onClick={event => {
                if (subCategory.categoryPathResName !== event.currentTarget.id) return;

                toggleExtended(subCategory.categoryPathResName);
              }}
              variant="custom"
            >
              <CustomIcon
                className={classnames({
                  'rotate-180': isExtended,
                })}
                type="arrowSm"
              />

              <Typography
                className={classnames(
                  'max-w-[150px] shrink-0 truncate text-start text-sm font-bold text-slate-950',
                  className,
                )}
              >
                {subCategory.categoryPath}
              </Typography>

              {meta?.(subCategory as T)}
            </Button>
          </td>
        </tr>

        {isExtended && items(subCategory as K)}
      </Fragment>
    );
  });
}

export function useTableAccordions<T extends 'all' | 'equipment' | 'worker'>(type: T) {
  const assetsCategories = useGlobalStore(store => store.assetsCategories);
  const updateAssetsCategories = useGlobalStore(store => store.updateAssetsCategories);

  const currentCategories = assetsCategories.filter(category => {
    if (type === 'all') return category;

    const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
    return category.categoryTreeName.includes(capitalizedType);
  });

  const handleToggleAccordion = (categoryId: string, subCategoryId?: string) => {
    const modifiedCategories = currentCategories.map(category => {
      if (category.categoryTreeResName === categoryId) {
        if (!subCategoryId) {
          return {
            ...category,
            extended: !category.extended,
          };
        }

        const modifiedItemPaths = category.categoryPathList.map(pathItem => {
          if (pathItem.categoryPathResName === subCategoryId)
            return {
              ...pathItem,
              extended: !pathItem.extended,
            };

          return pathItem;
        });

        return {
          ...category,
          categoryPathList: modifiedItemPaths,
        };
      }

      return category;
    });

    updateAssetsCategories(modifiedCategories);
  };

  return { currentCategories, handleToggleAccordion };
}
