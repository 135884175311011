import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { translate } from '@/i18n';
import { FormWrapper } from '@/modules/Settings/components/common/FormWrapper';
import { Button } from '@/shared/components/buttons/Button';
import { PasswordInput } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { LENGTHS } from '@/shared/schemas/constants';
import { changePasswordFormSchema } from '@/shared/schemas/validate-schemas';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { notifyService } from '@/shared/services/notify.service';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages';

import { Inputs } from '@/shared/types/global/common.types.ts';

export const ChangePasswordForm: FC = () => {
  const { user } = useFetchUserData();

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: zodResolver(changePasswordFormSchema),
  });

  const handleFormSubmit = async (data: Inputs) => {
    if (user?.username) {
      try {
        await cognitoUserPoolService.changePassword(
          data.oldPassword,
          data.newPassword,
          user.username,
        );

        reset();

        notifyService.success(ERROR_MESSAGES.UPDATE_PASSWORD_SUCCESS);
      } catch (error) {
        console.error(error);
        notifyService.error(ERROR_MESSAGES.UPDATE_PASSWORD_FAILED);
      }
    }
  };

  const watchAllFields = watch();
  const isButtonDisabled = Object.values(watchAllFields).some(
    value => value?.length >= LENGTHS.MIN.TEXT,
  );

  return (
    <form
      autoComplete="off"
      className="flex flex-col  gap-4 transition-opacity duration-300 md:flex-row md:items-end mb-2.5"
      id="changePassword"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormWrapper>
        <Label
          className="md:col-span-2 md:w-[calc(50%-(16px/2))]"
          labelText={translate('settings.oldPassword')}
        >
          <PasswordInput
            className="w-full"
            {...register('oldPassword')}
            aria-label="old-password-textfield"
            error={errors?.oldPassword?.message}
          />
        </Label>

        <Label labelText={translate('settings.newPassword')}>
          <PasswordInput
            className="w-full"
            {...register('newPassword')}
            aria-label="new-password-textfield"
            error={errors?.newPassword?.message}
          />
        </Label>

        <Label labelText={translate('settings.newPasswordConfirm')}>
          <PasswordInput
            className="w-full"
            {...register('newPasswordConfirm')}
            aria-label="new-password-textfield"
            error={errors?.newPasswordConfirm?.message}
          />
        </Label>
      </FormWrapper>

      <Button
        aria-label="change-password-action"
        disabled={!isButtonDisabled || isSubmitting}
        isLoading={isSubmitting}
        type="submit"
        variant="solid"
        withIcon
      >
        {translate(isSubmitting ? 'settings.saving' : 'settings.save')}
      </Button>
    </form>
  );
};
