import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

export const TableLink: FC<LinkProps & WithChildren & WithClassName> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Link
      className={classnames(
        'max-w-40 cursor-pointer truncate font-bold hover:underline focus:underline',
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  );
};
