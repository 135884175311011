import { baseQuery } from '@/shared/api/utils';

export namespace BuildTimeAPINamespace {
  const client = baseQuery('BUILD_TIME_URL');

  export const setBuild = async (): Promise<void> => {
    const result = await client.get('');

    return result.data;
  };
}
