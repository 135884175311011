import { Dispatch, FC, SetStateAction } from 'react';

import { translate } from '@/i18n';
import { RolesWrapper } from '@/shared/wrappers/Roles';

import { Button } from '../../buttons/Button';
import { CustomIcon } from '../CustomIcon';
import { CustomIconType } from '../CustomIcon/CustomIcon.types';
// import { IconButton } from '../../buttons/IconButton';
// import { CustomIcon } from '../CustomIcon';
import { Typography } from '../Typography';

interface SwitchTabProps {
  className?: string;
  firstTabIcon: CustomIconType;
  firstTabTitle: string;
  secondTabIcon: CustomIconType;
  secondTabTitle: string;
  selectedIndex: string;
  tabChangeEvent: Dispatch<SetStateAction<string>>;
}

export const SwitchTabs: FC<SwitchTabProps> = ({
  className,
  firstTabIcon,
  firstTabTitle,
  secondTabIcon,
  secondTabTitle,
  selectedIndex,
  tabChangeEvent,
}) => {
  return (
    <div
      className={`ml-auto mt-3 inline-flex cursor-pointer rounded-lg border lg:mt-0 ${className}`}
    >
      <RolesWrapper action="calendar_view">
        <Button
          className={`${
            selectedIndex === '1' ? 'bg-black' : 'bg-white'
          } flex items-center justify-center gap-2 rounded-l-lg px-[12px] py-[9px] normal-case`}
          onClick={() => tabChangeEvent('1')}
          variant="custom"
        >
          <CustomIcon
            className={`${selectedIndex === '1' ? 'text-white' : 'text-slate-800'}`}
            type={firstTabIcon}
          />

          <Typography
            as="span"
            className={`${
              selectedIndex === '1' ? 'text-white' : 'text-slate-800'
            } hidden text-sm capitalize md:block`}
          >
            {translate(firstTabTitle)}
          </Typography>
        </Button>

        <Button
          className={`${
            selectedIndex === '2' ? 'bg-black' : 'bg-white'
          } flex items-center justify-center gap-2 rounded-r-lg px-[12px] py-[9px] normal-case`}
          onClick={() => tabChangeEvent('2')}
          variant="custom"
        >
          {/* <CustomIcon type="worker" className="h-5 w-5" /> */}

          <CustomIcon
            className={`${
              selectedIndex === '2' ? 'text-white' : 'text-slate-800'
            } h-[15px] w-[15px]`}
            type={secondTabIcon}
          />

          <Typography
            as="span"
            className={`${
              selectedIndex === '2' ? 'text-white' : 'text-slate-800'
            } hidden text-sm capitalize md:block`}
          >
            {translate(secondTabTitle)}
          </Typography>
        </Button>
      </RolesWrapper>
    </div>
  );
};
