import { Signer } from 'aws-amplify';

type AccessInfoProps = {
  access_key: string;
  secret_key: string;
  session_token: string;
};

export namespace WSInterfacesNamespace {
  const wssUrl = import.meta.env.VITE_WSS_LINK;

  export const generateSocketSigner = (accessInfo: AccessInfoProps) => {
    return Signer.signUrl(wssUrl, accessInfo);
  };
}
