import Number, { NumberFlowProps } from '@number-flow/react';

export const NumberFlow = ({ ...props }: NumberFlowProps) => {
  return (
    <Number
      continuous
      format={{
        compactDisplay: 'short',
        notation: 'compact',
      }}
      trend
      willChange
      {...props}
    />
  );
};
