import { FC } from 'react';

import { useActionsHook } from '@/shared/api/actions/actions.hook';

export const ActionsHooks: FC = () => {
  useActionsHook.getAlerts();
  // useActionsHook.getActionsMetrics();

  return null;
};
