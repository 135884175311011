import { translate } from '@/i18n';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export const renderPrice = (asset: AssetBody) => {
  const iso4217 = asset.extraProperties?.find(property => property.key === 'iso4217');
  const period = asset.extraProperties?.find(property => property.key === 'period');
  const property = asset.extraProperties?.find(property => property.key === 'price');

  const renderCurrency = () => {
    switch (iso4217?.value) {
      case 'eur':
        return '€';

      case 'jpy':
        return '¥';

      case 'usd':
        return '$';

      default:
        return '-';
    }
  };

  const renderPeriod = () => {
    switch (period?.value) {
      case 'day':
        return (
          <Typography as="span" className="lowercase">
            /{translate('assets.day')}
          </Typography>
        );

      case 'hour':
        return (
          <Typography as="span" className="lowercase">
            /{translate('assets.hour')}
          </Typography>
        );

      case 'month':
        return (
          <Typography as="span" className="lowercase">
            /{translate('assets.month')}
          </Typography>
        );

      case 'week':
        return (
          <Typography as="span" className="lowercase">
            /{translate('assets.week')}
          </Typography>
        );
    }
  };

  if (property && iso4217 && period)
    return (
      <Typography as="span" className="max-w-20 truncate">
        {renderCurrency()}

        {property.value}

        {renderPeriod()}
      </Typography>
    );

  return <Typography as="span">-/-</Typography>;
};

export const renderCurrentZone = (asset: AssetBody) => {
  const geofences = useGlobalStore.getState().geofences;
  const NO_GEOFENCE = '-';

  const property = asset.extraProperties?.find(property => property.key === 'dropOff');
  const currentDropOff = geofences.find(geofence => geofence.geofenceResName === property?.value);

  if (currentDropOff) {
    return (
      <Typography as="span" className="max-w-40 truncate">
        {currentDropOff.geofenceName}
      </Typography>
    );
  }

  return <Typography as="span">{NO_GEOFENCE}</Typography>;
};
