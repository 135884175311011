import { useGlobalStore } from '@/shared/store/store.ts';

import { TagsAttachedEnum } from '@/shared/types/global/enums';
import { WorkersDatasource } from '@/shared/types/users/subcontractors.types.ts';

type WorkerSearchParams = null | string;

type Props = {
  workers: WorkersDatasource[];
  workerRole?: WorkerSearchParams;
  workerTagAttached?: WorkerSearchParams;
  workerZone?: WorkerSearchParams;
};

export const useFirmDetailSearch = ({
  workerRole,
  workers,
  workerTagAttached,
  workerZone,
}: Props) => {
  const searchFieldValue = useGlobalStore(state => state.searchFieldValue);

  const filteredWorkers = workers.filter(worker => {
    const matchesWorkerRole = workerRole
      ? worker.role?.toLowerCase() === workerRole.toLocaleLowerCase()
      : true;
    const matchesWorkerAttachedTag = workerTagAttached
      ? (workerTagAttached == TagsAttachedEnum.YES && worker.attached?.length) ||
        (workerTagAttached == TagsAttachedEnum.NO && !worker.attached?.length)
      : true;
    const normalizedSearchValue = searchFieldValue.toLowerCase();
    const matchesWorkerZone = workerZone
      ? worker.currentZone?.enclosingGeofences.filter(
          geofence => geofence.geofenceResName === workerZone,
        ).length
      : true;
    return (
      (worker?.given_name?.toLowerCase().includes(normalizedSearchValue) ||
        worker?.family_name?.toLowerCase().includes(normalizedSearchValue)) &&
      matchesWorkerAttachedTag &&
      matchesWorkerRole &&
      matchesWorkerZone
    );
  });

  return { filteredWorkers };
};
